import {
	ADD_FILE_ARCHIVE_TO_MANUAL
	, CLEAR_SELECTED_MANUAL_ARCHIVE
	, DEF_MANUAL_ACC_ADDR
	, DELETE_KB_MANUAL_ATTACHMENT
	, DELETE_UPLOADED_MANUAL_ATTACHMENT
	, DELETE_ALL_UPLOADED_MANUAL_ATTACHMENT
	, MANUAL_FOOTER_CHECKBOXES
	, SELECT_MANUAL_ERRAND
	, SELECT_MANUAL_ERRAND_CREATION
	, SELECT_MANUAL_CALL_CREATION
	, SELECT_MANUAL_SOCIAL_MEDIA_ACCOUNT
	, SELECTED_MANUAL_PERSONALIZATION
	, UPLOAD_MANUAL_ERRAND_ATTACHMENT
	, UPLOAD_MANUAL_CALL_ERRAND_ATTACHMENT
	, UPDATE_MANUAL_ERRAND_SAVED_ATTACHMENTS
} from '../constants/constants';
import {
	D_ERRAND_NOTES
	, DEL_ANSWER_ATTACHMENT
	, ME_START
	, RECIPIENT_TO
	, RPLY_COMMENT
	, RPLY_MANUAL
} from '../../common/v5/constants';
import {
	AO_CHANGE
	, areaOperation
	, detailAttachment
	, recipientsChange
	, selectReplyChannel
} from './errand';
import { isCallMemoize, getOutboundPhoneTo } from '../selectors/manual';

export const defaultManualAccountAndAddress = () => ({
	type: DEF_MANUAL_ACC_ADDR
});

export const selectManualSocialMediaAccount = (type, dbID, accountID, value) => ({
	type: SELECT_MANUAL_SOCIAL_MEDIA_ACCOUNT,
	payload:{type, dbID, accountID, value}
});

export const manualFooterCheckboxes = (type, value) => ({
	type: MANUAL_FOOTER_CHECKBOXES,
	payload: {type, value}
});

export const selectManualErrand = value => ({
	type: SELECT_MANUAL_ERRAND,
	payload: value
});

export const selectManualErrandCreation = value => ({
	type: SELECT_MANUAL_ERRAND_CREATION,
	payload: value
});

export const selectManualCallCreation = value => ({
	type: SELECT_MANUAL_CALL_CREATION,
	payload: value
});

export const selectManualReplyChannel = channel => selectReplyChannel(
	channel
	, true
);

export const selectManualPersonalization = (field, value, manual) => ({
	type: SELECTED_MANUAL_PERSONALIZATION,
	payload: {field, value, manual}
})

export const uploadedManualErrandAttachments = file => detailAttachment(UPLOAD_MANUAL_ERRAND_ATTACHMENT, file);

export const uploadedManualCallErrandAttachments = file => detailAttachment(UPLOAD_MANUAL_CALL_ERRAND_ATTACHMENT, file);

export const deleteAllUploadadManualAttachments = () => ({
	type: DELETE_ALL_UPLOADED_MANUAL_ATTACHMENT,
});

export const deleteUploadedManualAttachment = id => ({
	type: DELETE_UPLOADED_MANUAL_ATTACHMENT,
	payload: {id}
});

export const addFileArchiveToManual = (selectedId, archiveList) => ({
	type: ADD_FILE_ARCHIVE_TO_MANUAL,
	payload: {selectedId, archiveList}
});

export const clearSelectedManualArchive = id => ({
	type: CLEAR_SELECTED_MANUAL_ARCHIVE,
	payload: {id}
});

export const deleteKbAttachment = (reply, id) => ({
	type: DELETE_KB_MANUAL_ATTACHMENT,
	payload: {id}
});

export const updateManualErrandSavedAttachments = (files) => ({
	type: UPDATE_MANUAL_ERRAND_SAVED_ATTACHMENTS,
	payload: {files}
});
const manualRecipientsChange = (which, recipients) => recipientsChange(
	which
	, recipients
	, RPLY_MANUAL
);

export const onChangeRecipients = (which, recipients) =>
	(dispatch, getState) => {
		const state = getState();
		if (!isCallMemoize(state) || which !== RECIPIENT_TO) {
			return dispatch(manualRecipientsChange(which, recipients));
		}
		const phone = getOutboundPhoneTo(state);
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: phone:", phone);
		}
		return dispatch(manualRecipientsChange(RECIPIENT_TO, recipients));
	};

export const resetManualErrandSelectedArea = () => areaOperation(AO_CHANGE, 0, true, false); //here last param is isCall
