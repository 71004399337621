// endpoint /Cention/web/awsses/:region/identities/*id
import each from 'lodash/each'
import {
  IDTYPE_DOMAIN,
  IDTYPE_EMAIL,
  doNothing
} from '../../../common/constants'
import { TXT_FETCHING_DATA } from '../../../common/v5/chatbotConstants'
import {
  DKIM_AWS_SES,
  TXT_ARE_YOU_SURE_DELETE,
  TXT_DOMAIN_VERIFICATION_NOTICE,
  TXT_INVALID_DKIM_TOKEN,
  TXT_INVALID_RESPONSE_DATA,
  TXT_MAIL_FROM_DOMAIN_VERIFICATION_NOTICE,
  TXT_UNKNOWN_IDENTITY_TYPE,
  TXT_VERIFICATION_NOTICE,
  getMailFromMX,
  getMailFromTXT,
  initAWSSES,
  toSelectAWSSESIdentity,
  tokenToCNAME
} from '../../../common/v5/smtpConstants'
import {
  deleteAWSSESIdentities,
  getAWSSESIdentities,
  postAWSSESIdentities
} from './ajax'
import {
  keyAWSSESIdentities,
  keyAWSSESIdentity,
  keyCreateAWSSESIdentity,
  keyDeleteAWSSESIdentity
} from '../../constants/keys'
import { async, createBroadcaster } from '../../util'
import {
  adminAsyncMap,
  adminEditDataMemo,
  identityMemo,
  matchParamMemo,
  regionIdentitiesMemo
} from '../../selectors/awsses'
import { awssesMemo, byRegionIdAWSSESMemo } from '../../selectors/c3constants'
import { onlyPopupIfNoConfirmBox, popErrorOnly, wrapPopWait } from '../hmf'
import {
  updateAdminEditField,
  updateAdminFinishEdit,
  updateAdminStartEdit
} from '../awsses'
import {
  createCommonAdminAsyncs,
  createEditFieldUpdater
} from './receiptGreeting'

const mergeParamQuery = (p, q) => {
  if (typeof q === 'undefined') {
    return p
  } else if (typeof p === 'undefined') {
    return q
  }
  return { ...p, ...q }
}

const createGetter = key => (p, q) => async(
  getAWSSESIdentities(p, q),
  adminAsyncMap[key],
  mergeParamQuery(p, q)
)

const getList = createGetter(keyAWSSESIdentities)

const enableReloadQuery = reload => {
  if (typeof reload !== 'undefined') {
    return { reload }
  }
}

const regionIdentitiesBase = (region, reload) => getList(
  { region },
  enableReloadQuery(reload)
)

const regionIdentities = wrapPopWait(TXT_FETCHING_DATA, regionIdentitiesBase)

export const onReload = region => regionIdentities(region, true)

const get = createGetter(keyAWSSESIdentity)

const getIdentity = (region, id) => get({ region, id })

export const onFetchIdentity = wrapPopWait(TXT_FETCHING_DATA, getIdentity)

const toAWSSESIdentities = getState => {
  const match = matchParamMemo(getState())
  if (!match || !match.id || !match.region) {
    return doNothing
  }
  return toSelectAWSSESIdentity(match.id, match.region)
}

const getRegionIdentities = createBroadcaster(regionIdentitiesBase)

const identitiesByRegionCache = (state, region) => {
  const identitiesMap = regionIdentitiesMemo(state, region)
  return identitiesMap[region]
}

export const onLoad = (region, noPopWait) => (dispatch, getState) => {
  const identities = identitiesByRegionCache(getState(), region)
  if (identities) {
    return Promise.resolve(identities)
  }
  if (noPopWait) {
    return dispatch(getRegionIdentities(region))
  }
  return dispatch(wrapPopWait(TXT_FETCHING_DATA, getRegionIdentities)(region))
}

const getIdentitiesThenIdentityBase = (
  region,
  identity,
  ...args
) => dispatch => dispatch(onLoad(region, true)).then(data => {
  if (typeof identity !== 'undefined') {
    return dispatch(getIdentity(region, identity, ...args))
  }
  return data
})

const getIdentitiesThenIdentity = wrapPopWait(
  TXT_FETCHING_DATA,
  getIdentitiesThenIdentityBase,
  onlyPopupIfNoConfirmBox
)

const loadIdentity = () => (dispatch, getState) => {
  const state = getState()
  const identity = identityMemo(state)
  if (identity) {
    return Promise.resolve(identity)
  }
  const match = matchParamMemo(state)
  if (!match || !match.region) {
    return Promise.resolve()
  }
  const { identities, region } = match
  if (!identities) {
    const cache = identitiesByRegionCache(state, region)
    if (cache) {
      return Promise.resolve(identities)
    }
  }
  return dispatch(getIdentitiesThenIdentity(region, identities))
}

const getUrlIds = (region, id, isNew) => {
  if (isNew) {
    return { region }
  }
  return { id, region }
}

const adminAsyncs = createCommonAdminAsyncs(
  getUrlIds,
  loadIdentity,
  adminAsyncMap,
  {
    remove: {
      confirmationTextGetter: (region, id, getState) => {
        const state = getState()
        const regions = byRegionIdAWSSESMemo(state)
        let name
        const r = awssesMemo(state).byId[regions[region]]
        if (r) {
          name = r.region
        }
        if (!name) {
          name = region
        }
        return TXT_ARE_YOU_SURE_DELETE
          .replace('{ID}', id)
          .replace('{ITEM_TYPE}', name)
      },
      ajax: deleteAWSSESIdentities,
      key: keyDeleteAWSSESIdentity
    },
    edit: {
      dataGetter: ({ id, isNew }, getState) => {
        if (isNew) {
          return initAWSSES
        }
        return identityMemo(getState())
      },
      noPopWait: true,
      starter: updateAdminStartEdit
    },
    save: {
      afterPathGetter: toAWSSESIdentities,
      ajax: postAWSSESIdentities,
      key: keyCreateAWSSESIdentity
    }
  }
)

export const { edit: onEditStart, remove: onDelete } = adminAsyncs

export const onEdit = createEditFieldUpdater(
  updateAdminEditField,
  adminEditDataMemo
)

export const onFinishEdit = updateAdminFinishEdit

const getCNAME = tokenToCNAME

const getDomainVerificationNotice = (domain, awssesDKIM, tokens) => {
  let txt = "'{CNAME1}', '{CNAME2}', '{CNAME3}'";
  each(tokens, (token, index) => {
    txt = txt.replace(
      `{CNAME${index + 1}}`,
      getCNAME(domain, awssesDKIM, token)
    )
  })
  return txt
}

export const onSave = (...args) => (
  dispatch,
  getState
) => dispatch(adminAsyncs.save(...args)).then(res => {
  dispatch(onFinishEdit())
  return res
}).then(data => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('add AWS SES response:', data)
  }
  let txt, allowCopy = false, copyAbleTxt = "";
  if (data && data.type) {
    if (data.type === IDTYPE_EMAIL) {
      txt = TXT_VERIFICATION_NOTICE
    } else if (data.type === IDTYPE_DOMAIN) {
      const txts = []
      if (!data.verified) {
        if (data.dkimTokens && data.dkimTokens.length === 3) {
          txt = TXT_DOMAIN_VERIFICATION_NOTICE;
          copyAbleTxt = getDomainVerificationNotice(
            data.id,
            DKIM_AWS_SES,
            data.dkimTokens
          )
          allowCopy = true;
        } else {
          txt = TXT_INVALID_DKIM_TOKEN
        }
        txts.push(txt)
      }
      const params = matchParamMemo(getState())
      if (params && params.region) {
        if (data.mailFromDomain && data.mailFromDomainStatus !== 'SUCCESS') {
          const { id, mailFromDomain } = data
          txts.push(TXT_MAIL_FROM_DOMAIN_VERIFICATION_NOTICE
            .replace('{MAILFROMMX}', getMailFromMX(params.region, id))
            .replace('{MAILFROMTXT}', getMailFromTXT(mailFromDomain)))
        }
      } else {
        console.log("can't derive region from URL")
      }
      if (txts.length) {
        txt = txts.join('. ')
      } else {
        txt = ''
      }
    } else {
      txt = TXT_UNKNOWN_IDENTITY_TYPE
    }
  } else {
    txt = TXT_INVALID_RESPONSE_DATA.replace(
      '{DATA}',
      JSON.stringify({ data })
    )
  }
  let custom = {};
  if (txt) {
    if(allowCopy) {
      custom = {
        withCopy: true,
        copyAbleTxt
      };
    }
    return dispatch(popErrorOnly(txt, custom)).then(() => data);
  }
  return data
})
