import {
	deleteKnowledgeBaseNode
	, getAdminLibrary
	, getAdminLibraryCategory
	, getAdminLibraryQuestion
	, getAdminLibraryRating
	, getAdminLibrarySuggestion
	, getKnowledgeBase
	, getKnowledgeBaseCategory
	, getKnowledgeBaseRoot
	, insertKnowledgeBaseNode
	, removeAdminLibrary
	, removeAdminLibraryCategory
	, removeAdminLibraryQuestion
	, removeAdminLibraryRating
	, saveAdminLibrary
	, saveAdminLibraryCategory
	, saveAdminLibraryQuestion
	, saveAdminLibraryRating
	, fetchLibraryList
	, fetchCategoryList
	, fetchQuestionList
	, fetchSuggestionList
	, fetchRatingList
	, fetchMoreLibraryList
	, fetchMoreCategoryList
	, fetchMoreQuestionList
	, fetchMoreSuggestionList
	, fetchMoreRatingList
} from './ajax';
import { wrapPopWait } from '../hmf'
import {
	changeActiveTab
	, changeSearchState
	, deleteID
	, insertNode
	, removeNode
	, resetEdit
	, saveID
} from '../knowledgebase';
import {
	keyGetAdminLibrary
	, keyGetAdminLibraryCategory
	, keyGetAdminLibraryQuestion
	, keyGetAdminLibrarySuggestion
	, keyGetKnowledgeBaseCategory
	, keyGetKnowledgeBaseRoot
	, keyInsertKnowledgeBaseNode
	, keyRemoveAdminLibrary
	, keyRemoveAdminLibraryCategory
	, keyRemoveAdminLibraryQuestion
	, keyRemoveAdminLibraryRating
	, keyRemoveKnowledgeBaseNode
	, keySaveAdminLibrary
	, keySaveAdminLibraryCategory
	, keySaveAdminLibraryQuestion
	, keyGetKnowledgeBase
	, keyGetAdminLibraryRating
	, keyFetchLibraryList
	, keyFetchCategoryList
	, keyFetchQuestionList
	, keyFetchSuggestionList
	, keyFetchRatingList
	, keyFetchMoreLibraryList
	, keyFetchMoreCategoryList
	, keyFetchMoreQuestionList
	, keyFetchMoreSuggestionList
	, keyFetchMoreRatingList
} from '../../constants/keys';
import {
	admin
} from '../../selectors/admin';
import {
	async
} from '../../util';
import { TXT_INSERTING, TXT_REMOVING } from '../../../common/constants';
import {
	KB_ACTIVE_TAB
	, KBT_CATEGORY
	, KBT_LIBRARY
	, KBT_QUESTION
	, KBT_RATING
	, KBT_SUGGESTION
} from '../../../common/v5/constants';
import { TXT_SAVING } from '../../../common/v5/chatbotConstants';
import { onceAdminChatbots } from './chatbot';

export const fetchKnowledgeBaseRoot = () => async(getKnowledgeBaseRoot(),
	admin[keyGetKnowledgeBaseRoot]
);

export const fetchKnowledgeBaseCategory = (id, param) => async(getKnowledgeBaseCategory(id, param),
	admin[keyGetKnowledgeBaseCategory]
);

export const fetchKnowledgeBase = (id, param) => async(getKnowledgeBase(id, param),
	admin[keyGetKnowledgeBase]
);

export const handleSearchAnswer = (id, param) => (dispatch) => {
	dispatch(fetchKnowledgeBase(id, param))
		.then(dispatch(changeSearchState(false)))
};

const removingKnowledgeBaseNode = p => async(
	deleteKnowledgeBaseNode(p),
	admin[keyRemoveKnowledgeBaseNode]
);

const removeKnowledgeBaseNode = wrapPopWait(
	TXT_REMOVING,
	removingKnowledgeBaseNode
);

export const agentRemoveKnowledgeBaseNode = (p) => (dispatch) => {
	dispatch(removeKnowledgeBaseNode(p))
		.then((id) => dispatch(removeNode(id)))
};

const addingKnowledgeBaseNode = p => async(
	insertKnowledgeBaseNode(p),
	admin[keyInsertKnowledgeBaseNode]
);

const addKnowledgeBaseNode = wrapPopWait(
	TXT_INSERTING,
	addingKnowledgeBaseNode
);

export const agentAddKnowledgeBaseNode = (p) => (dispatch) => {
	dispatch(addKnowledgeBaseNode(p))
		.then((id) => { dispatch(insertNode(id))})
};

const agentEditLibrary = (id) => async(getAdminLibrary(id),
	admin[keyGetAdminLibrary]
);

const agentEditCategory = (id) => async(getAdminLibraryCategory(id),
	admin[keyGetAdminLibraryCategory]
);

const agentEditQuestion = (id) => async(getAdminLibraryQuestion(id),
	admin[keyGetAdminLibraryQuestion]
);

const agentEditSuggestion = (id) => async(getAdminLibrarySuggestion(id),
	admin[keyGetAdminLibrarySuggestion]
);

const agentEditRating = (id) => async(getAdminLibraryRating(id),
	admin[keyGetAdminLibraryRating]
);

const agentSaveLibrary = (p) => async(saveAdminLibrary(p),
	admin[keySaveAdminLibrary]
);

const agentSaveLibraryCategory = (p) => async(saveAdminLibraryCategory(p),
	admin[keySaveAdminLibraryCategory]
);

const savingQuestion = p => async(
	saveAdminLibraryQuestion(p),
	admin[keySaveAdminLibraryQuestion]
);

const saveQuestion = wrapPopWait(TXT_SAVING, savingQuestion);

const agentSaveLibraryQuestion = (p) => saveQuestion(p);

const agentSaveLibraryRating = (p) => async(saveAdminLibraryRating(p),
	admin[keySaveAdminLibraryRating]
);

const agentRemoveLibrary = (id) => async(removeAdminLibrary(id),
	admin[keyRemoveAdminLibrary]
);

const agentRemoveLibraryCategory = (id) => async(removeAdminLibraryCategory(id),
	admin[keyRemoveAdminLibraryCategory]
);

const agentRemoveLibraryQuestion = (id) => async(removeAdminLibraryQuestion(id),
	admin[keyRemoveAdminLibraryQuestion]
);

const agentRemoveLibraryRating = (id) => async(removeAdminLibraryRating(id),
	admin[keyRemoveAdminLibraryRating]
);

export const handleEditKnowledgeBaseList = (type, id) => (dispatch, getState) => {
	const { activeTab } = getState().app.knowledgebase;
	if (activeTab != KB_ACTIVE_TAB[type]) {
		dispatch(changeActiveTab(KB_ACTIVE_TAB[type]))
	};
	switch (type) {
		case KBT_LIBRARY:
			dispatch(agentEditLibrary(id));
			break;
		case KBT_CATEGORY:
			dispatch(agentEditCategory(id));
			break;
		case KBT_QUESTION:
			dispatch(agentEditQuestion(id));
			break;
		case KBT_SUGGESTION:
			dispatch(agentEditSuggestion(id));
			break;
		case KBT_RATING:
			dispatch(agentEditRating(id));
			break;
		default:
			dispatch(resetEdit);
	};
};

export const handleSaveKnowledgeBaseList = (type, data) => (dispatch, getState) => {
	const { savedId } = getState().app.knowledgebase;
	if(data.id === savedId) {
		dispatch(saveID(0));
	}
	switch (type) {
		case KBT_LIBRARY:
			dispatch(agentSaveLibrary(data))
			.then((id) => {
				dispatch(saveID(id));
				if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")) {
					const p = { offset: 0, limit: 100 };
					dispatch(fetchTableList(KBT_LIBRARY, p));
				}
			});
			break;
		case KBT_CATEGORY:
			//adding current category into this tree node
			if(data.currentCategory) {
				if(data.parentLibrary) {
					if(data.parentCategory) {
						const nodeInfo = {
							afterNodeId: 0,
							itemId: data.currentCategory,
							itemType: KBT_CATEGORY,
							containerId: data.parentCategory,
							containerType: KBT_CATEGORY,
							reorder: false,
						}
						dispatch(addKnowledgeBaseNode(nodeInfo))
							.then((id) => { dispatch(insertNode(id))})
					} else {
						const nodeInfo = {
							afterNodeId: 0,
							itemId: data.currentCategory,
							itemType: KBT_CATEGORY,
							containerId: data.parentLibrary,
							containerType: KBT_LIBRARY,
							reorder: false,
						}
						dispatch(addKnowledgeBaseNode(nodeInfo))
							.then((id) => { dispatch(insertNode(id))})
					}
				}
			} else {
				//adding new category
				dispatch(agentSaveLibraryCategory(data))
				.then((rs) => {
					dispatch(saveID(rs));
					//if has parent (added during tree mode), insert them to parent automatically after done saved
					if(data.parentLibrary) {
						if(data.parentCategory) {
							//insert into parent category node
							const nodeInfo = {
								afterNodeId: 0,
								itemId: rs.id,
								itemType: KBT_CATEGORY,
								containerId: data.parentCategory,
								containerType: KBT_CATEGORY,
								reorder: false,
							}
							dispatch(addKnowledgeBaseNode(nodeInfo))
							.then((id) => {
								dispatch(insertNode(id));
							})
						} else {
							//insert into parent library node
							const nodeInfo = {
								afterNodeId: 0,
								itemId: rs.id,
								itemType: KBT_CATEGORY,
								containerId: data.parentLibrary,
								containerType: KBT_LIBRARY,
								reorder: false,
							}
							dispatch(addKnowledgeBaseNode(nodeInfo))
							.then((id) => {
								dispatch(insertNode(id))
							})
						}
					} else {
						if (cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")) {
							const p = { offset: 0, limit: 100 };
							dispatch(fetchTableList(KBT_CATEGORY, p));
						}
					}
				})
			}
			break;
		case KBT_QUESTION:
			dispatch(agentSaveLibraryQuestion(data))
				.then((id) => {
					dispatch(saveID(id));
					if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")) {
						const p = { offset: 0, limit: 100 };
						dispatch(fetchTableList(KBT_QUESTION, p));
						if(id && id.id) {
							dispatch(agentEditQuestion(id.id));
						}
					}
				});
			break;
		case KBT_SUGGESTION:
			dispatch(agentSaveLibraryQuestion(data))
				.then((id) => dispatch(saveID(id)))
			break;
		case KBT_RATING:
			dispatch(agentSaveLibraryRating(data))
				.then((id) => dispatch(saveID(id)))
			break;
	};
};

const createDeleteID = dispatch => id => dispatch(deleteID(id));

const removingHandlers = (type, id) => dispatch => {
	const deleteID = createDeleteID(dispatch)
	switch (type) {
		case KBT_LIBRARY:
			return dispatch(agentRemoveLibrary(id))
			.then((deleteID) => {
				if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")) {
					const p = { offset: 0, limit: 100 };
					dispatch(fetchTableList(type, p));
				}
				return deleteID;
			})
		case KBT_CATEGORY:
			return dispatch(agentRemoveLibraryCategory(id))
			.then((deleteID) => {
				if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")) {
					const p = { offset: 0, limit: 100 };
					dispatch(fetchTableList(type, p));
				}
				return deleteID;
			});
		case KBT_QUESTION:
		case KBT_SUGGESTION:
			return dispatch(agentRemoveLibraryQuestion(id))
			.then((deleteID) => {
				if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")) {
					const p = { offset: 0, limit: 100 };
					dispatch(fetchTableList(type, p));
				}
				return deleteID;
			});
		case KBT_RATING:
			return dispatch(agentRemoveLibraryRating(id)).then(deleteID)
		default:
			return Promise.resolve();
	};
};

export const handleRemoveKnowledgeBaseList = wrapPopWait(
	TXT_REMOVING,
	removingHandlers
);

const agentFetchLibraryList = (p) => async(fetchLibraryList(p),
	admin[keyFetchLibraryList]
);

const agentFetchCategoryList = (p) => async(fetchCategoryList(p),
	admin[keyFetchCategoryList]
);

const agentFetchQuestionList = (p) => async(fetchQuestionList(p),
	admin[keyFetchQuestionList]
);

const agentFetchSuggestionList = (p) => async(fetchSuggestionList(p),
	admin[keyFetchSuggestionList]
);

const agentFetchRatingList = (p) => async(fetchRatingList(p),
	admin[keyFetchRatingList]
);

//fetch more
const agentFetchMoreLibraryList = (p) => async(fetchMoreLibraryList(p),
	admin[keyFetchMoreLibraryList]
);

const agentFetchMoreCategoryList = (p) => async(fetchMoreCategoryList(p),
	admin[keyFetchMoreCategoryList]
);

const agentFetchMoreQuestionList = (p) => async(fetchMoreQuestionList(p),
	admin[keyFetchMoreQuestionList]
);

const agentFetchMoreSuggestionList = (p) => async(fetchMoreSuggestionList(p),
	admin[keyFetchMoreSuggestionList]
);

const agentFetchMoreRatingList = (p) => async(fetchMoreRatingList(p),
	admin[keyFetchMoreRatingList]
);

export const fetchTableList = (type, p) => (dispatch) => {
	switch (type) {
		case KBT_LIBRARY:
			dispatch(agentFetchLibraryList(p));
			dispatch(onceAdminChatbots());
			break;
		case KBT_CATEGORY:
			dispatch(agentFetchCategoryList(p));
			break;
		case KBT_QUESTION:
			dispatch(agentFetchQuestionList(p))
			.then(() => {
				if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui")) {
					const p = { offset: 0, limit: 1000 };
					dispatch(agentFetchRatingList(p));
				}
			});
			break;
		case KBT_SUGGESTION:
			dispatch(agentFetchSuggestionList(p));
			break;
		case KBT_RATING:
			dispatch(agentFetchRatingList(p));
			break;
		default:
			break;
	};
};

export const fetchMoreTableList = (type, p) => (dispatch) => {
	switch (type) {
		case KBT_LIBRARY:
			dispatch(agentFetchMoreLibraryList(p))
			break;
		case KBT_CATEGORY:
			dispatch(agentFetchMoreCategoryList(p))
			break;
		case KBT_QUESTION:
			dispatch(agentFetchMoreQuestionList(p))
			break;
		case KBT_SUGGESTION:
			dispatch(agentFetchMoreSuggestionList(p))
			break;
		case KBT_RATING:
			dispatch(agentFetchMoreRatingList(p))
			break;
		default:
			break;
	};
};
