import update from 'immutability-helper';
import { createAction } from 'redux-actions';
import {
	CHAT_ACQUIRE_ERRAND,
	CHAT_DO_SEND,
	CHAT_GET_MCOUNT,
	CHAT_NEW_SESSION_RECEIVED,
	CHAT_ON_ACCEPT_CHAT,
	CHAT_ON_ACTIVATE,
	CHAT_ON_AGENT_PRESENCE,
	CHAT_ON_ASSOCIATE_ERRAND,
	CHAT_ON_CLIENT_PATHS,
	CHAT_ON_CONNECT_STATUS,
	CHAT_ON_DEAD_SESSION,
	CHAT_ON_FINISH_SESSION,
	CHAT_ON_MESSAGE_ACKED,
	CHAT_ON_PREVIEW,
	CHAT_ON_QUEUE_SEND,
	CHAT_ON_SESSION_INIT,
	CHAT_ON_TAGS,
	CHAT_ON_UNSENT_MESSAGES,
	CHAT_ON_UPDATE_TAGS,
	CHAT_SHOW_SYSTEM_ERROR_MESSAGE,
	CHAT_UPDATE_CLIENT_STATUS,
	CHAT_UPDATE_MESSAGE,
	CHAT_UPDATE_NEW_MESSAGE_STATUS,
	CHAT_SHOW_CONVERSATIONS,
	CHAT_SHOW_FAVOURITES,
	CHAT_SHOW_ALL,
	CHAT_RESET_NEWMESSAGE_COUNT,
	CHAT_UPDATE_INTERNAL_COMMENT,
} from '../constants/constants';
import {
	getChatSocket,
} from '../util';
import {
	getMcount
} from '../../common/v5/helpers';
import {
	getNewMessageId
} from '../../common/v5/chat';

//
// SOCKET
//
export const acquireErrand = payload => ({
	type: CHAT_ACQUIRE_ERRAND,
	payload
});

// TODO: seem useless.
export const doSend = payload => ({
	type: CHAT_DO_SEND,
	payload
});

export const getMcountAction = sessionId => ({
	type: CHAT_GET_MCOUNT,
	payload: sessionId
});

export const newSessionReceived = payload => (dispatch, getState) => {
	// CHAT-01
	const previousChat = payload.chat, cmaxId = payload.cmaxId;
	for(let i=0; i<previousChat.length; i++) {
		payload = update(payload, {chat: {[i]: {read: {
			$set: previousChat[i].id <= cmaxId}}}});
	}
	dispatch({type: CHAT_NEW_SESSION_RECEIVED, payload});

	// CHAT-02
	const sessionId = payload.sessionId,
		chatSession = getChatSocket(getState()).chatSessions[sessionId],
		session = chatSession.session;
	if(payload.autoDisplayIfActive) {
		payload.autoDisplayIfActive(session.dead, session.isDirty,
			session.newMessage);
	}
	dispatch(getMcountAction(sessionId));
	payload.ack(getMcount(getChatSocket(getState()), sessionId),
		payload.chat.length);
};

export const onAcceptChat = payload => ({
	type: CHAT_ON_ACCEPT_CHAT,
	payload
});

export const onActivate = payload => ({
	type: CHAT_ON_ACTIVATE,
	payload
});

export const onAgentPresence = payload => ({
	type: CHAT_ON_AGENT_PRESENCE,
	payload
});

export const onAssociateErrand = payload => ({
	type: CHAT_ON_ASSOCIATE_ERRAND,
	payload
});

function formatTimeHMS(s) {
	var h = Math.floor(s/3600);
	var r = s%3600;
	if(r>0){
		var m = Math.floor(r/60);
		s = r%60;
	}
	return {hour: h, min: m, sec: s};
}

function formatRoundTime(s) {
	var r = Math.floor(s/3600);
	if(r<=0){
		r = Math.floor(s/60);
		if(r<=0){
			return s + ' s';
		}
		return r + ' m';
	}
	return r + ' h';
}

export const onClientPaths = payload => {
	const {paths} = payload;
	let prevTime = Math.round(new Date().getTime()/1000), totalTime = 0,
		newPaths = paths.slice();
	for(let i=0; i<paths.length; i++) {
		const lapsed = prevTime - paths[i].time;
		newPaths = update(newPaths, {[i]: {lapsed: {
			$set: formatRoundTime(lapsed)}}});
		totalTime += lapsed;
		prevTime = paths[i].time;
	}
	totalTime = formatTimeHMS(totalTime);
	payload = update(payload, {$merge: {totalTime, paths: newPaths}});
	return {type: CHAT_ON_CLIENT_PATHS, payload};
};

export const onConnectStatus = payload => ({
	type: CHAT_ON_CONNECT_STATUS,
	payload
});

export const onDeadSession = payload => ({
	type: CHAT_ON_DEAD_SESSION,
	payload
});

export const onMessageAcked = payload => ({
	type: CHAT_ON_MESSAGE_ACKED,
	payload
});

export const onPreview = payload => ({
	type: CHAT_ON_PREVIEW,
	payload
});

// TODO: on q send should not consider async though it has async callback on
// timeout which need further checking to know if it really a need for this.
export const onQueueSend = payload => (dispatch, getState) => {
	const newMessageId = getNewMessageId();
	payload = update(payload, {$merge: {initialChatData, newMessageId}});
	dispatch({type: CHAT_ON_QUEUE_SEND, payload});
	// CHAT-03
	const {callback, sessionId} = payload, state = getChatSocket(getState()),
		chatSession = state.chatSessions[sessionId];
	if(chatSession && callback) {
		dispatch(getMcountAction(sessionId));
		// mcount state will change, thus need to request new state.
		// Chat sessions session should not affected by mcount action.
		const {errandId} = payload, callbackObject = {
				um: chatSession.session.unsentMessagesArray,
				sessionId: sessionId,
				errandId: errandId,
				isInternalChat: payload.isInternalChat,
				internalChatData: payload.internalChatData,
				mCount: getMcount(getChatSocket(getState()), sessionId)
			};
		setTimeout(() => {
			callback(callbackObject);
		});
	}
};

export const onTags = payload => ({
	type: CHAT_ON_TAGS,
	payload
});

const emptyUnsentMsgs = [];

// NOTE: do NOT mutate the return-ed array.
function getUnsentMsgs(state, sessionId) {
	const cs = state.chatSessions[sessionId];
	if(cs) {
		return cs.session.unsentMessagesArray;
	}
	return emptyUnsentMsgs;
}

// CHAT-04
export const onUnsentMessages = payload => (dispatch, getState) => {
	// dispatch({type: CHAT_ON_UNSENT_MESSAGES});
	const state = getChatSocket(getState());
	if(Object.keys(state.chatSessions).length > 0) {
		const {callback} = payload, doCallback = c => {
				setTimeout(() => {
					callback(c);
				});
			};
		$.each(state.chatSessions, (k,v) => {
			if(!v.session) {
				// We have not yet received the full chat session (most
				// likely this is immediately after a browser reload so
				// there are no unsent messages yet)
				return;
			}
			const sessionId = v.session.sessionId,
				unsent = getUnsentMsgs(getChatSocket(getState()), sessionId);
			if(unsent.length > 0) {
				dispatch(getMcountAction(sessionId));
				callback && doCallback({
						um: unsent,
						sessionId: sessionId,
						errandId: v.session.errandId,
						mCount: getMcount(getChatSocket(getState()),
							sessionId)
					});
			}
		});
	}
};

export const onUpdateTags = payload => ({
	type: CHAT_ON_UPDATE_TAGS,
	payload
});

export const showSystemErrorMessage = payload => ({
	type: CHAT_SHOW_SYSTEM_ERROR_MESSAGE,
	payload
});

export const updateClientStatus = payload => ({
	type: CHAT_UPDATE_CLIENT_STATUS,
	payload
});

export const updateMessage = payload => ({
	type: CHAT_UPDATE_MESSAGE,
	payload
});

export const updateNewMessageStatus = (sessionId, status) => ({
	type: CHAT_UPDATE_NEW_MESSAGE_STATUS,
	payload: {sessionId, status}
});

export const resetNewMessageCount = (sessionId) => ({
	type: CHAT_RESET_NEWMESSAGE_COUNT,
	payload: {sessionId}
});

// Internal chat UI actions
export const updateShowConversations = createAction(CHAT_SHOW_CONVERSATIONS);
export const updateShowFavourites = createAction(CHAT_SHOW_FAVOURITES);
export const updateShowAll = createAction(CHAT_SHOW_ALL);
export const updateChatComment = createAction(CHAT_UPDATE_INTERNAL_COMMENT);
