import {
	ADD_FILE_ARCHIVE_TO_COLLABORATION,
	CHANGE_COLLABORATION_ROLE,
	CLEAR_COLLABORATION_INPUTS,
	COL_CLEAR_SELECTED_ARCHIVE,
	COL_CLEAR_SELECTED_LIBRARY,
	DELETE_UPLOADED_COL_ATTACHMENT,
	RELOAD_CURRENT_EXPERT,
	CLEAR_INTERNAL_COLLABORATORS,
	SET_COLLABORATION_TRANSLATE_TO,
	UPDATE_COLLABORATE_CB,
	UPDATE_EXPERT_ANS_STATE,
	UPDATE_EXPERT_IDS,
	UPLOAD_COLLABORATION_ATTACHMENT,
	SET_AREA_RECIPIENT,
	SAVE_EE,
	CKEDITOR_REFORMAT_INPUTEE,
	AGENTEE_BUSY_TYPING,
	AGENTEE_NOT_TYPING
} from '../constants/constants';
import {
	ROLE_EXPERT,
	RPLY_COLLABORATE,
	THROTTLED_TMR
} from '../../common/v5/constants';
import {
	detailAttachment,
	selectShowReply,
	inputTextChanges
} from './errand';
import { throttledDispatch } from '../util';

export const changeCollaborationRole = (role, threadID, queryID) => ({
	type: CHANGE_COLLABORATION_ROLE,
	payload: {role, threadID, queryID}
});

export const reloadCurrentExpert = () => dispatch => {
	dispatch({type: RELOAD_CURRENT_EXPERT});
	dispatch({type: CLEAR_COLLABORATION_INPUTS});
};

export const setupCollaborationQuery = (threadID, queryID) => dispatch => {
	console.log('dbg: setup collaboration answer, query:', {threadID, queryID});
	dispatch(changeCollaborationRole(ROLE_EXPERT, threadID, queryID));
	dispatch(selectShowReply(RPLY_COLLABORATE, true));
};

export const updateCollaborationCheckboxes = (type, value) => ({
	type: UPDATE_COLLABORATE_CB,
	payload: {type, value}
});

export const updateCurrentExpertIDs = (errandID, threadID, queryID, answerID) => ({
	type: UPDATE_EXPERT_IDS,
	payload: {errandID, threadID, queryID, answerID}
});

export const updateExpertAnswerState = value => ({
	type: UPDATE_EXPERT_ANS_STATE,
	payload: value
});

export const clearInternalCollaborators = () => ({
	type: CLEAR_INTERNAL_COLLABORATORS
});

export const setCollaborationTranslateTo = to => ({
	type: SET_COLLABORATION_TRANSLATE_TO,
	payload: to
});

export const addColFileArchives = (selectedId, archiveList) => ({
	type: ADD_FILE_ARCHIVE_TO_COLLABORATION,
	payload: {selectedId, archiveList}
});

export const colClearSelectedArchive = (which, afId) => ({
	type: COL_CLEAR_SELECTED_ARCHIVE,
	payload: {which, id: afId}
});

export const colClearSelectedLibrary = (which, afId) => ({
    type: COL_CLEAR_SELECTED_LIBRARY,
    payload: {which, id: afId}
});

export const deleteUploadedColAttachment = (which, aId) => ({
	type: DELETE_UPLOADED_COL_ATTACHMENT,
	payload: {which, id: aId}
});

export const uploadedColAttachments = file => detailAttachment(UPLOAD_COLLABORATION_ATTACHMENT, file);

export const selectAreaRecipient = (selectedAreas) => ({
	type: SET_AREA_RECIPIENT,
	payload: selectedAreas
})

export const saveCollabStart = () => ({type: SAVE_EE});

export const agentEENotTyping = () => ({type: AGENTEE_NOT_TYPING});

const throttledAgentEEIsTyping = throttledDispatch(() => ({type: AGENTEE_BUSY_TYPING}), THROTTLED_TMR);

export const agentEEIsTyping = () => throttledAgentEEIsTyping;

export const updateCollabReformatAnswer = (value, plain, reply) => (dispatch, getState) => {
	if(!getState().app.errand.ui.lastSaveEE.ckeditor) {
		dispatch(inputTextChanges('update_answer', value, plain, reply));
	} else {
		dispatch({
			type: CKEDITOR_REFORMAT_INPUTEE,
			payload: {value, plain}
		});
	}
};