// common for all - errand, chat, workflow, etc.
import { C3ERR_SAME_ID } from '../../../common/v5/constants';
import {
	keyC3Common
} from '../../constants/keys';
import {
	createActionCreators
} from '../../util';
import RESTful from './restful';
import { getCurrentErrand } from '../../selectors/errand';

const common = createActionCreators([
	keyC3Common
]);

// TODO: to be removed.
export const noReducerAsync = common[keyC3Common];

export function generateCentionWebURL(url) {
	return RESTful(url).endpoint;
}

export function centionWebURLWithIdAndQuery(url, id, query) {
	let queryString = "";
	if (query) {
		queryString = "?" + $.param(query);;
	}
	return RESTful(url).createIdEndpoint(id) + queryString;
}

export const compareSameID = (
	id
	, compareeeGetter
) => (_, getState) => new Promise((
	resolve
	, reject
) => {
	if (id === compareeeGetter(getState)) {
		if (process.env.NODE_ENV !== 'production') {
			console.log('dbg: same ID:', id);
		}
		return reject(C3ERR_SAME_ID);
	}
	return resolve(id);
});

const currentErrandGetter = getState => getCurrentErrand(getState());

export const sameAsOpenedErrandID = id => compareSameID(
	id
	, currentErrandGetter
);
