import { CentionWeb, CentionFile, FilePrefix } from '../../constants/endpoints';

const WEB_HOST = window.location.protocol + "//" + window.location.hostname;
const WEB_ROOT = WEB_HOST + CentionWeb;

export function CheckEndpoint(endpoint) {
    if (endpoint.startsWith(FilePrefix)) {
        endpoint = endpoint.replace(FilePrefix, '');
        if (cflag.IsActive("2024-04-01.CEN-1479.new.service.serve.file.attachment")) {
            endpoint = `${WEB_HOST}/s/${space}${CentionFile}${endpoint.replace(/^\/+|\/+$/g, '')}`;
        } else {
            endpoint = `${WEB_ROOT}${endpoint.replace(/^\/+|\/+$/g, '')}`;
        }
    } else {
        endpoint = `${WEB_ROOT}${endpoint.replace(/^\/+|\/+$/g, '')}`;
    }
    return endpoint;
}

// Function to handle XHR streaming requests
export function getStreamXHRData(endpoint, params, onProgress) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        endpoint = CheckEndpoint(endpoint);
        xhr.open('POST', endpoint, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.responseType = 'text';

        let output = '';
        let previousLength = 0;

        xhr.onprogress = (event) => {
            const newChunk = xhr.responseText.slice(previousLength);
            previousLength = xhr.responseText.length;

            const chunks = newChunk.split('\n');
            chunks.forEach((chunk) => {
                if (chunk.startsWith('data:')) {
                    // To skip the data: prefix
                    const data = chunk.slice(5);
                    output += data;
                    onProgress(output);
                }
            });
        };

        xhr.onload = () => {
            if (output.endsWith('done')) {
                // To skip the done: prefix
                output = output.slice(0, -4);
            }
            resolve(output);
        };

        xhr.onerror = (error) => {
            console.error('Streaming Error:', error);
            reject(error);
        };

        xhr.send(JSON.stringify(params));
    });
}

export function getStreamXHRDataWithJSON(endpoint, params, onProgress) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        endpoint = CheckEndpoint(endpoint);
        xhr.open('POST', endpoint, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Cache-Control', 'no-cache');
        xhr.responseType = 'text';

        let output = '';
        let previousLength = 0;
        let currentEvent = '';  // To keep track of the current event type

        xhr.onprogress = () => {
            const newChunk = xhr.responseText.slice(previousLength);
            previousLength = xhr.responseText.length;
            const lines = newChunk.split('\n');
            lines.forEach((line) => {
                line = line.trim();  // Remove extra whitespace

                if (line.startsWith('event:')) {
                    currentEvent = line.slice(6).trim();
                } else if (line.startsWith('data:')) {
                    const data = line.slice(5);  // Get the data after 'data:'

                    // Handle the data based on the current event type
                    let jsonOutput = {};
                    switch (currentEvent) {
                        case 'chunk':
                            output += data;
                            break;
                        case 'errand_id':
                            jsonOutput = { errand_id: data };
                            break;
                        case 'received':
                            jsonOutput = { received: data };
                            break;
                        case 'session':
                            jsonOutput = { session: data };
                            break;
                        case 'prompt':
                            jsonOutput = { prompt: data };
                            break;
                        case 'context':
                            jsonOutput = { context: data };
                            break;
                        case 'ai_answer':
                            jsonOutput = { ai_answer: data };
                            break;
                        case 'status':
                            jsonOutput = { status: data };
                            break;
                        default:
                            console.warn('Unknown event type:', currentEvent);
                    }
                    onProgress(output, jsonOutput);
                }
            });
        };

        xhr.onload = () => {
            if (output.endsWith('done')) {
                // To skip the done: prefix
                output = output.slice(0, -4);
            }
            resolve(output);  // Resolve with the accumulated text chunks when the stream ends
        };

        xhr.onerror = (error) => {
            console.error('Streaming Error:', error);
            reject(error);
        };

        xhr.send(JSON.stringify(params));
    });
}
