import update from 'immutability-helper';
import { getAction } from 'connected-react-router';
import { REVIEW } from '../../../common/path';
import { CTX_REVIEW, HISTORY_POP } from '../../../common/v5/constants';
import {
	TXT_EXIT_REVIEW
	, TXT_INVALID_CIPHER_KEY
	, TXT_OPEN_REVIEW
} from '../../../common/v5/reviewConstants';
import { push } from '../../../common/v5/utils';
import { reviewErrandURL } from '../../../common/v5/helpers';
// import { sameAsOpenedErrandID } from './common';
import {
	checkSameIDAndUnsaveChange
	, closeErrandWithoutSyncURL
} from './errand';
import {
	contextChangeByFolder
	, resetWorkflowFilter
	, setListParams
	, setSelectedAgent
	, setSelectedFolder
} from '../workflow';
import { clearPopWaiting, popError, popErrorOnly, popPleaseWait } from '../hmf';
import { loadList } from './workflow';
import { waitLoadListMemo, waitLoadListPostponeMemo } from '../../selectors/review';
import { inboxSizeMemo } from '../../selectors/workflow';

export const pushReviewErrandURL = (
	id
	, cipherKey
	, isSwitching
	, state
) => dispatch => {
	if (!cipherKey) {
		return dispatch(popErrorOnly(
			new Error(TXT_INVALID_CIPHER_KEY.replace("{ERRAND_ID}", id))
		));
	}
	return dispatch(checkSameIDAndUnsaveChange(id))
		.then(() => dispatch(push(reviewErrandURL(id, cipherKey), state)))
		;
};

export const switchReviewErrandURL = ({
	id
	, cipherKey
	, previousErrand
}) => pushReviewErrandURL(id, cipherKey, true, {review: {isSwitching: true}});

export const cancellableReviewClick = () => dispatch => {
	return dispatch(closeErrandWithoutSyncURL())
		.then(() => dispatch(push(REVIEW, {review: {waitLoadList: true}})));
};

const isHistoryPOP = state => getAction(state) === HISTORY_POP;

const closeErrandIfPOP = isPOP => dispatch => {
	if (isPOP) {
		return dispatch(closeErrandWithoutSyncURL(true));
	}
	return Promise.resolve();
};

export const openReviewFolder = () => (dispatch, getState) => {
	dispatch(popPleaseWait(TXT_OPEN_REVIEW));
	return dispatch(closeErrandIfPOP(isHistoryPOP(getState())))
		.then(() => {
			const state = getState();
			// NOTE: no need reset workflow filter because review should not do
			// not allow select agent nor folder nor tag. They all ALWAYS zero
			// for time being.
			// TODO: why need to reset the size everytime switch folder?
			dispatch(setListParams({offset: 0, size: inboxSizeMemo(state)}));
			dispatch(loadList("openReviewFolder"))
			.then(()=>{
				if (waitLoadListMemo(state)) {
					return;
				}
			});
		})
		.then(() => {
			return dispatch(clearPopWaiting())
		})
		.catch(err => {
			return dispatch(popError(err))
		})
		.catch(() => {
			return dispatch(clearPopWaiting())
		})
		;
};
export const exitReview = () => (dispatch, getState) => {
	return dispatch(closeErrandWithoutSyncURL(true));
};
