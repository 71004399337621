// endpoint /Cention/web/constants/:name
import { getConstants } from './ajax'
import { keyAllConstants, keyConstants } from '../../constants/keys'
import { async, loadOnceCreator } from '../../util'
import {
  appAsyncMap,
  appStateByKey,
  byNameMemo
} from '../../selectors/c3constants'

const loadOnce = (key, ajax) => loadOnceCreator(
  appAsyncMap,
  key,
  ajax,
  appStateByKey
)

const onceAll = loadOnce(keyAllConstants, getConstants)

const getAllConstants = () => async(
  getConstants(),
  appAsyncMap[keyAllConstants]
)

const getConstantByName = name => {
  const params = { name }
  return async(getConstants(params), appAsyncMap[keyConstants], params)
}

const existingValue = (state, name) => {
  const value = byNameMemo(state)
  if (name) {
    return value[name]
  }
  return value
}

export const getByName = name => (dispatch, getState) => {
  if (!name) {
    return dispatch(onceAll())
  }
  const value = existingValue(getState(), name)
  if (value) {
    return Promise.resolve(value)
  }
  return dispatch(getConstantByName(name))
}
