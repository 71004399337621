import {
  SMTP_EDIT_FIELD,
  SMTP_FINISH_EDIT,
  SMTP_START_EDIT
} from '../constants/constants'
import { adminEditActionMap } from '../reducers/smtp'

export const {
  [SMTP_START_EDIT]: updateAdminStartEdit,
  [SMTP_EDIT_FIELD]: updateAdminEditField,
  [SMTP_FINISH_EDIT]: updateAdminFinishEdit
} = adminEditActionMap
