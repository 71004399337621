import update from 'immutability-helper';
import {sipCallConn
	, sipGetXferRef
	, sipCallStatus
	, sipGetCurrentManualEid
	, sipMakeCallCurrentErrand
	, sipCallIsRecording
	, sipGetCurrentEid
} from '../../selectors/call';
import {
	getAppErrand,
	getCurrentErrandId,
	currentErrandDisplayIdMemo,
	getEmailRecipients,
	replyAnswerSelector,
	replyPlainAnswerSelector
} from '../../selectors/errand';
import {
	isManualCallPopup
	, manualCallMinimize
} from '../../selectors/manual';
import {UPDATE_AVENTA_CONN
	, SIP_SHOW_INCOMING_POPUP
	, SIP_SHOW_OUTGOING_POPUP
	, SIP_SAVE_OUTGOING_AVATAR
	, SIP_UPDATE_AGENT_LIST
	, SIP_SHOW_AGENT_LIST
	, SIP_SHOW_DTMF_KEYPAD
	, SIP_RESET_CALL_TIMER
	, SIP_SET_TRANSFER_TARGET
	, SIP_SET_TRANSFER_TARGET_ID
	, SIP_UPDATE_XFER_REF
	, SIP_UPDATE_ERRAND_ID
	, SIP_TRANSFER_MODE
	, SIP_UPDATE_EXTERNAL_TRANSFER_KEYPAD
	, SIP_UPDATE_SNOOP_STRING
} from '../../constants/constants';
import {
	updateAventaStatus
	, updateSipTransferStatus
	, updateSipIncomingOngoing
	, resetOutboundErrandId
	, updateOutboundErrandId
	, sipMakeCallFromErrand
	, sipSetCallTransferData
} from '../call';
import {
	SIP_CONNECTED
	, SIP_CALL_CONNECTING
	, SIP_CALL_CONNECTED
	, SIP_CALL_WAITING_CONNECTION
	, SIP_DISCONNECTED
	, SIP_CALL_ON_HOLD
	, SIP_CALL_IDLE
	, SIP_OUTGOING_CALL_CONNECTING
	, SIP_OUTGOING_CALL_CONNECTED
	, SIP_NEW_RTC_SESSION
	, SIP_REGISTER
	, SIP_UNREGISTER
	, SIP_REGISTER_FAIL
	, CALL_STR_HOLD
	, CALL_STR_ACCEPT
	, CALL_STR_INVITE
	, CALL_STR_ESTABLISH
	, CALL_STR_END
	, CALL_STR_CANCELED
	, CALL_STR_TRANSFER
	, CALL_STR_SNOOP
	, COLD_TRANSFER
	, WARM_TRANSFER
} from '../../../common/v5/callConstants';
import {
	BEEP_AUDIO_SRC
	, ME_CREATE
	, ME_CREATE_AS_MY
	, ME_CREATE_AS_CLOSED
	, CTX_MANUAL
	, MP_NONE
	, NOTIFY_SIP_INCOMING
	, ME_ST_CREATED
	, ME_ST_IDLE
	, RPLY_MANUAL
} from '../../../common/v5/constants.js';
import {
	DISMISS_BUTTONS
	, customConfirm
} from './hmf';
import {
	stopRecordingSip
	, startRecordingSip
	, stopAutoRecordingSip
	, forceRecordingPause
	, forceRecordingResume
	, updateSipRecording
} from '../../../common/v5/webRtcVoice.js';
import {
	notifyOS
} from '../../../common/v5/helpers.js';
import {
	Invitation
	, Inviter
	, InviterOptions
	, Referral
	, Registerer
	, RegistererOptions
	, Session
	, SessionState
	, UserAgent
	, UserAgentOptions
	, InvitationAcceptOptions
	, Web
} from 'sipjs';
import {
	postCommonCallApi
	, postClientAvatar
	, getAvailableSipAgents
	, postCommonSnoop
	, postAgentFwdCallToExt
	, postSipManualCallStatus
} from './ajax';
//WARNING: cyclical dependency. refactor code to fix this
import {
	setErrandPinToTop
	, closeErrand
	, loadList
} from './workflow';
import {
	toggleManualOrCallPopup
	, clearManualCallInputs
	, manualCallState
} from '../workflow';
import {
	loadAndOpenErrand
	, forceSaveErrand
	, forwardErrandToAgent
	, submitManualErrand
	, forwardManualSipToAgent
	, doCloseErrand
	, attachmentObjectToArrayIDs
	, endOfCall
	, callTransferred
	, shouldSaveManualCall
	, removeAgentWorkingOnErrand
	, askForClassification
} from './errand';
import { callRecipientsChange
	, inputTextChangesForCall
	, setPreviousErrand
	, setCurrentErrandOpening
} from '../errand';

const { Errand } = Workflow;

export const onLoadSippConn = () => (dispatch, getState) => {
	const state = getState()
		, wf = state.app.workflow
		, sipEnabled = wf.fetchWfSettings.data.sipEnabled
		, sipServerUrl = wf.fetchWfSettings.data.sipServerUrl
		, sipUserName = wf.fetchWfSettings.data.sipUserName
		, sipPwd = wf.fetchWfSettings.data.sipPwd
		, sipExtension = wf.fetchWfSettings.data.sipExtension
		, sipStunTurn = wf.fetchWfSettings.data.sipStunTurn
		, iceGatheringTimeout = wf.fetchWfSettings.data.sipIceTimeout
		, conn = sipCallConn(state);
	if(sipEnabled == false){
		return;
	}
	if (sipServerUrl != "" && sipUserName != "" && sipPwd != "" && conn == null) {
		let sipp = new SipUserAgent(sipServerUrl, sipUserName,
			sipPwd, sipExtension, iceGatheringTimeout, sipStunTurn,
			dispatch, getState);
		sipp.connect();
		if (sipp != null) {
			dispatch(updateAventaConn(sipp));
		}
	}
};

const updateAventaConn = (conn) => {
	return {
		type: UPDATE_AVENTA_CONN,
		payload: {
			conn: conn
		}
	}
}

const updateSnoopString = (snoopDisplayString) => {
	return {
		type: SIP_UPDATE_SNOOP_STRING,
		payload: {
			snoopDisplayString: snoopDisplayString
		}
	}
}

const toggleSipIncomingPopup = (show, data, avatar) => ({
	type: SIP_SHOW_INCOMING_POPUP
	, payload: {
		show: show
		, data: data
		, avatar: avatar
	}
});

export const getWebRtcStats = async (session) => {
    if (typeof session === "undefined" || session == null) {
        throw new Error("Session is undefined or null");
    }
    let sdh = session.sessionDescriptionHandler;
    if (typeof sdh === "undefined" || sdh == null) {
        throw new Error("SessionDescriptionHandler is undefined or null");
    }
    let peerConn = sdh.peerConnection;
    if (typeof peerConn === "undefined" || peerConn == null) {
        throw new Error("PeerConnection is undefined or null");
    }

    try {
        const stats = await peerConn.getStats(null);
        let ping;
        stats.forEach((report) => {
            switch (report.type) {
                // candidate-pair is based on STUN request
                case "candidate-pair":
                    /*
                    console.info("type:", report.type, " id:", report.id,
                        " writeable:", report.writable, " state:", report.state,
                        " currentrountriptime:", report.currentRoundTripTime,
                        " totalroundtriptime:", report.totalRoundTripTime);
                    */
                    break;
                // not sure where these are collected from
                case "remote-inbound-rtp":
                    ping = report.roundTripTime * 1000;
                    console.info("ping =", ping, "ms");
                    break;
                default:
                    break;
            }
        });
        return ping;
    } catch (err) {
        throw new Error(err);
    }
};



export const toggleSipOutgoingPopup = (show, openedErrand, data) => (dispatch, getState) => {
	const sipPhone = sipCallConn(getState());
	let ua = sipPhone.conn;
	if(openedErrand) {
		if(show) {
			const state = getState();
			let realId = getCurrentErrandId(state);
			let createdId = currentErrandDisplayIdMemo(state);
			let currentRecipient = getEmailRecipients(state);
			let recipient = {
				id: currentRecipient.to[0],
				value: currentRecipient.to[0]
			}
			dispatch(callRecipientsChange([recipient]
				, "to"
				, RPLY_MANUAL));
			let html = replyAnswerSelector(state);
			let plain = replyPlainAnswerSelector(state);
			dispatch(inputTextChangesForCall("update_answer", html, plain, RPLY_MANUAL));
			dispatch(manualCallState(ME_ST_CREATED, createdId, realId));
			dispatch(sipMakeCallFromErrand(true, realId));
			dispatch(updateOutboundErrandId(realId));
			ua.startManualCallStatus();
		} else {
			dispatch(sipMakeCallFromErrand(false, 0));
		}
	}
	if(show) {
		ua.getSipAvatar(true, data);
	} else {
		ua.stopManualCallStatus();
	}
	return dispatch({
		type: SIP_SHOW_OUTGOING_POPUP
		, payload: {
			show: show
			, data: data
		}
	})
};

export const updateSipOutgoingAvatar = (avatar) => ({
	type: SIP_SAVE_OUTGOING_AVATAR
	, payload: {
		avatar: avatar
	}
});

export const updateSipXferRef = (extRef) => {
	return {
		type: SIP_UPDATE_XFER_REF
		, payload: {
			transferredRefId: extRef
		}
	}
}

export const updateSipErrandId = (eid) => {
	return {
		type: SIP_UPDATE_ERRAND_ID
		, payload: {
			currentSipErrandId: eid
		}
	}
}

class SipUserAgent {
	constructor(server, user, password, extension, iceGatheringTimeout,
		sipStunTurn, dispatch, getState) {
		this.server = server;
		this.user = user;
		this.password = password;
		this.extension = extension;
		this.localDispatch = dispatch;
		this.localGetState = getState;
		this.userAgent = {};
		this.registerer = {};
		this.session = null;
		this.xferSession = null;
		this.xferAgentId = "";
		this.timerId = 0;
		this.manualCallStateTimer = 0;
		this.webRtcStatTimer = 0;
		this.timeoutVar = null;
		this.otherPartyNumber = "";
		this.xStasisArea = "";
		this.xStasisChannelId = "";
		this.xStasisCallId = "";
		this.altOtherPartyNumber = "";
		this.dialogId = "";
		this.extRefId = "";
		this.xferRefId = "";
		this.sessionCallId = "";
		this.currentSipCallId = "";
		this.remoteStream = new MediaStream();
		this.localStream = null;
		this.reconnect = this.reconnect.bind(this);
		this.startWebRtcStats = this.startWebRtcStats.bind(this);
		this.setupRemoteMedia = this.setupRemoteMedia.bind(this);
		this.setupPeerListeners = this.setupPeerListeners.bind(this);
		this.setupRemoteTransferMedia = this.setupRemoteTransferMedia.bind(this);
		this.enableSenderTracks = this.enableSenderTracks.bind(this);
		this.holdCall = this.holdCall.bind(this);
		this.endCall = this.endCall.bind(this);
		this.endXferCall = this.endXferCall.bind(this);
		this.holdTransferCall = this.holdTransferCall.bind(this);
		this.getSipAvatar = this.getSipAvatar.bind(this);
		this.getNewSipAvatar = this.getNewSipAvatar.bind(this);
		this.setExtRefId = this.setExtRefId.bind(this);
		this.setCallEid = this.setCallEid.bind(this);
		this.getSipAddress = this.getSipAddress.bind(this);
		this.handleAutoAnswerTimeout = this.handleAutoAnswerTimeout.bind(this);
		this.handleSaveManualErrand = this.handleSaveManualErrand.bind(this);
		this.getSnoopDisplayString = this.getSnoopDisplayString.bind(this);
		this.startManualCallStatus = this.startManualCallStatus.bind(this);
		this.stopManualCallStatus = this.stopManualCallStatus.bind(this);
		this.updateManualCallStatus = this.updateManualCallStatus.bind(this);
		this.isRefer = false;
		this.isWarm = false;
		this.wasXferred = false;
		this.isSnooping = false;
		this.snoopType = "";
		this.iceGatheringTimeout = iceGatheringTimeout;
		this.sipStunTurn = sipStunTurn;
		this.aventaEnabled = false;
		this.switchdHandleTransfer = false;
		this.autoRecording = false;
		this.backendRecording = false;
		this.manualCall = false;
	}
	connect() {
		const transportOptions = {
			connectionTimeout: 20,
			server: this.server
		};
		const uri = UserAgent.makeURI(this.user);
		let stunServers = [];
		if(typeof this.sipStunTurn !== 'undefined' &&
			this.sipStunTurn != null) {
			if(this.sipStunTurn.stun !== null){
				for(let i=0;i<this.sipStunTurn.stun.length;i++) {
					let oneServer = {
						urls: this.sipStunTurn.stun[i]
					}
					stunServers.push(oneServer);
				}
			}
			if(this.sipStunTurn.turn !== null){
				for(let i=0;i<this.sipStunTurn.turn.length;i++) {
					let sArr = this.sipStunTurn.turn[i].split(";")
					if(sArr.length < 3){
						continue;
					}
					let oneServer = {
						urls: sArr[0],
						username: sArr[1],
						credential: sArr[2]
					}
					stunServers.push(oneServer);
				}
			}
		}
		console.info("using ice server:",stunServers);
		const state = this.localGetState()
			, wf = state.app.workflow;
		this.aventaEnabled = wf.fetchWfSettings.data.aventaEnabled;
		this.switchdHandleTransfer =
					wf.fetchWfSettings.data.sipSwitchdHandleXfer;
		this.autoRecording =
					wf.fetchWfSettings.data.sipAutoRecord;
		this.backendRecording =
					wf.fetchWfSettings.data.sipBackendRecording;
		const agentOptions = {
			authorizationPassword: this.password,
			authorizationUsername: this.extension,
			hackWssInTransport: true,
			transportOptions: transportOptions,
				/*
			stunServers: ["stun:stun.l.google.com:19302",
				"stun:stun.freeswitch.org"],
				*/
			sessionDescriptionHandlerFactoryOptions: {},
			autostart: false,
			delegate: {},
			logLevel: 'warn', //debug log warn error
			//logLevel: 'log', //debug log warn error
			uri
		};
		const registererOptions = {
			uri
		};

		/*
		 *  0 means use sip.js default
		 *  -1 means no time limit. 0 in sip.js
		 *  > 0 means limit in seconds
		 */
		if(this.iceGatheringTimeout != 0){
			let sessOptions = {}
			let peerConnectionConfiguration = {
				iceServers: stunServers
			};
			if(this.iceGatheringTimeout < 0) {
				sessOptions.iceGatheringTimeout = 0;
			} else {
				sessOptions.iceGatheringTimeout = this.iceGatheringTimeout *
					1000;
			}
			sessOptions.peerConnectionConfiguration =
				peerConnectionConfiguration;
			agentOptions.sessionDescriptionHandlerFactoryOptions =
				sessOptions;
		}
		try{
			this.userAgent = new UserAgent(agentOptions);
		} catch (e) {
			console.info("new UserAgent error",e);
			return;
		}
		this.registerer = new Registerer(this.userAgent, registererOptions);

		this.userAgent.delegate.onConnect = () => {
			clearInterval(this.timerId);
		}
		this.userAgent.delegate.onInvite = (invitation) => {
			const state = this.localGetState()
				, wf = state.app.workflow
				, sipUseDisplayName = wf.fetchWfSettings.data.sipUseDisplayName;
			let referedby =
				invitation.incomingInviteRequest.message.headers['Referred-By'];
			this.isRefer = false;
			let nowTime = new Date();
			this.isWarm = false;
			let isFromSwitchd = false;
			if(typeof referedby !== 'undefined' && referedby != null &&
				referedby != ""){
				this.isRefer = true;
				console.log("sip invite has Referred-By header");
			} else {
				let xferInvite =
					invitation.incomingInviteRequest.message.headers['X-Invite'];
				if(typeof xferInvite !== 'undefined' && xferInvite != null &&
					xferInvite.length > 0){
					if(xferInvite[0].raw == "warm"){
						this.isRefer = true;
						this.isWarm = true;
					}
					isFromSwitchd = true;
					console.log("sip invite has X-Invite header");
				} else {
					console.log("sip invite does not have X-Invite header and Referred-By header");
				}
			}
			console.log("received SIP invite:",nowTime, " is from switchd:",
				isFromSwitchd);
			if (typeof invitation.incomingInviteRequest === "undefined" ||
				invitation.incomingInviteRequest == null) {
				invitation.reject();
				this.otherPartyNumber = "";
				this.altOtherPartyNumber = "";
				this.dialogId = "";
				console.info("rejected call:",
					invitation.incomingInviteRequest);
				return;
			}
			let answerDelay = wf.fetchWfSettings.data.sipAnswerDelay;
			if(this.isRefer == false && answerDelay > 0){
				this.timeoutVar = setTimeout(function() {
					this.handleAutoAnswerTimeout();
				}.bind(this), answerDelay * 1000);
			}
			if (this.session == null) {
				this.sessionCallId = invitation.incomingInviteRequest.callId;
				this.localDispatch(updateSipIncomingOngoing(true));
				notifyOS("Cention", NOTIFY_SIP_INCOMING, 0);
				this.handleSaveManualErrand();
				this.otherPartyNumber =
					this.getSipAddress(invitation.incomingInviteRequest.message.from);
				this.dialogId = invitation.incomingInviteRequest.message.callId
					+ ":" + invitation.incomingInviteRequest.message.fromTag
					+ ":" + invitation.incomingInviteRequest.message.toTag;
				invitation.stateChange.addListener((state) => {
					switch (state) {
					case SessionState.Initial:
					break;
					case SessionState.Establishing:
						if(this.timeoutVar != null){
							clearTimeout(this.timeoutVar);
							this.timeoutVar == null;
						}
					break;
					case SessionState.Established:
						console.info(invitation.incomingInviteRequest.message);
						console.info("invited session established");
						this.currentSipCallId =
							invitation.incomingInviteRequest.message.callId;
						this.localDispatch(updateAventaStatus(SIP_CALL_CONNECTED));
						this.localDispatch(resetCallTimer(Date.now()));
						this.setupRemoteMedia(invitation);
						this.setupPeerListeners(invitation);
						this.localDispatch(makeCommonAPICall(CALL_STR_ESTABLISH,
							this.otherPartyNumber, false, 0, "", "", false,0));
						//by default snoop calls are muted at connection
						if(this.isSnooping){
							this.holdCall(true, this.localDispatch);
						} else if(this.isRefer == true  &&
							this.isWarm == false) {
							if(this.backendRecording == true){
								this.localDispatch(updateSipRecording(true));
							} else if(this.autoRecording == true){
								this.localDispatch(startRecordingSip(
									sipGetCurrentEid(this.localGetState()),
									this.getStreams(), false));
							}
						} else if(isFromSwitchd == true && this.isWarm == false){
							if(this.backendRecording == true){
								this.localDispatch(updateSipRecording(true));
							}
						}
						this.webRtcStatTimer = setInterval(function(){
							this.startWebRtcStats()}.bind(this),2000);
						
					break;
					case SessionState.Terminating:
					case SessionState.Terminated:
						console.info("invited session terminates");
						if(this.currentSipCallId != "" &&
							this.currentSipCallId !==
							invitation.incomingInviteRequest.message.callId){
							console.info("termination id:",invitation.incomingInviteRequest.message.callId);
							console.info("session callId mismatch. ignore termination");
							break;
						}
						let theNumber = this.otherPartyNumber;
						let refId = this.extRefId;
						if( refId == ""){
							refId = this.dialogId;
						}
						let xferRef = sipGetXferRef(this.localGetState());
						let wasXferred = this.wasXferred;
						let callString = CALL_STR_END
						if(invitation.isCanceled == true){
							callString = CALL_STR_CANCELED
							this.localDispatch(toggleSipIncomingPopup(false,
								"", null));
						}
						if(this.backendRecording == true){
							this.localDispatch(updateSipRecording(false));
						} else if(this.autoRecording == true){
							this.localDispatch(stopAutoRecordingSip());
						}
						if(xferRef != ""){
							this.localDispatch(makeCommonAPICall(
								callString, theNumber, false, 0,
								xferRef, "", false, 0));
						} else if(this.isRefer == false && wasXferred == false){
							this.localDispatch(makeCommonAPICall(
								callString, theNumber, false, 0, refId,
								"", false,0));
						} else {
							this.localDispatch(makeCommonAPICall(
								callString, theNumber, false, 0,
								"", "", false, 0));
						}
						if(this.aventaEnabled == false){
							notifyOS("Cention", endOfCall,0);
						}
						this.localDispatch(updateAventaStatus(SIP_CONNECTED));
						this.localDispatch(resetCallTimer(0));
						this.cleanup();
					break;
				}});
				if(invitation._state == SessionState.Initial){
					this.localDispatch(updateAventaStatus(SIP_CALL_CONNECTING));
				}
				let incomingSession = invitation;
				this.session = incomingSession;
				incomingSession.delegate = {}
				incomingSession.delegate.onRefer = (referral) => {
					//no action
				}
				let isReferred = this.localGetState().app.call.ui.status.sip.conn.conn.isRefer;
				let wfAgents = this.localGetState().app.workflow.agents.data.agents;
				let agentAvatar = "";
				if(isReferred) {
					let agentSipId = parseInt(invitation.incomingInviteRequest.message.from.uri.normal.user, 10);
					if(wfAgents && wfAgents.length > 0) {
						for(let i=0;i<wfAgents.length;i++) {
							let ag = wfAgents[i];
							if(ag.ExtId === agentSipId) {
								agentAvatar = ag.Avatar;
								break;
							}
						}
					}
				}
				let xStasisArea =
					invitation.incomingInviteRequest.message.headers['X-Stasisarea'];
				let xStasisChannelId =
					invitation.incomingInviteRequest.message.headers['X-Stasischannelid'];
				let xStasisCallId =
					invitation.incomingInviteRequest.message.headers['X-Stasiscallid'];
				let xStasisSnoop =
					invitation.incomingInviteRequest.message.headers['X-Stasissnoop'];
				let xStasisSnoopId =
					invitation.incomingInviteRequest.message.headers['X-Stasissnoopid'];
				let xStasisSnoopCallerId =
					invitation.incomingInviteRequest.message.headers['X-Stasissnoopcallerid'];
				if(typeof xStasisChannelId !== 'undefined' &&
					xStasisChannelId.length > 0){
					if(typeof xStasisChannelId[0].raw !== 'undefined'){
						this.xStasisChannelId = xStasisChannelId[0].raw;
					}
					console.info("stasis channelId:",this.xStasisChannelId);
				}
				if(typeof xStasisCallId !== 'undefined' &&
					xStasisCallId.length > 0){
					if(typeof xStasisCallId[0].raw !== 'undefined'){
						this.xStasisCallId = xStasisCallId[0].raw;
					}
					this.dialogId = this.xStasisCallId;
					console.info("stasis callId:",this.xStasisCallId);
				}
				if(typeof xStasisArea !== 'undefined' &&
					xStasisArea.length > 0){
					if(typeof xStasisArea[0].raw !== 'undefined'){
						this.xStasisArea = xStasisArea[0].raw;
					}
					console.info("stasis call for area:",this.xStasisArea);
				}
				this.isSnooping = false;
				if(typeof xStasisSnoop !== 'undefined' &&
					xStasisSnoop.length > 0 ){
					this.isSnooping = true;
					console.info("this call is for snooping");
					if(typeof xStasisSnoop[0].raw !== 'undefined'){
						this.snoopType = xStasisSnoop[0].raw;
					}
				}
				this.localDispatch(updateSnoopString(
					this.getSnoopDisplayString()));
				if(this.isSnooping == false){
					this.altOtherPartyNumber = this.getSipDisplayName(
						sipUseDisplayName,
						invitation.incomingInviteRequest.message.from._displayName);
				} else if(typeof xStasisSnoopId !== 'undefined' &&
					xStasisSnoopId.length > 0 &&
					typeof xStasisSnoopId[0].raw !== 'undefined'){
					this.otherPartyNumber = xStasisSnoopId[0].raw;
					if(typeof xStasisSnoopCallerId !== 'undefined' &&
						xStasisSnoopCallerId.length > 0 &&
						typeof xStasisSnoopCallerId[0].raw !== 'undefined'){
						this.otherPartyNumber = this.otherPartyNumber + " & "
							+ xStasisSnoopCallerId[0].raw;
					}
				}
				if(this.altOtherPartyNumber != ""){
					this.localDispatch(toggleSipIncomingPopup(true,
						this.altOtherPartyNumber, agentAvatar));
				} else {
					this.localDispatch(toggleSipIncomingPopup(true,
						this.otherPartyNumber, agentAvatar));
				}
				this.localDispatch(makeCommonAPICall(CALL_STR_INVITE,
					this.otherPartyNumber, false, 0, this.dialogId, "", false,
					0));
				this.getSipAvatar();
			} else {
				invitation.reject();
				console.info("already have an ongoing session");
			}
		}

		this.userAgent.start().then(() => {
			this.userAgent.delegate.onDisconnect = (error) => {
				this.registerer.unregister()
					.catch((e) => {
						console.info(e)
					});
				if (error) {
					this.timerId = setInterval(this.reconnect, 60000);
				}
			};
			this.registerer.stateChange.addListener((newState) => {
				switch (newState) {
					case "Registered":
						this.localDispatch(updateAventaStatus(SIP_REGISTER));
						this.localDispatch(makeCommonAPICall("idle",
							"", false, 0, "", "", false, 0));
						console.log("Registered");
						break;
					case "Unregistered":
						console.log("Unregistered");
						this.localDispatch(updateAventaStatus(SIP_UNREGISTER));
						this.localDispatch(makeCommonAPICall("noconnection",
							"", false, 0, "", "", false, 0));
						break;
					case "Terminated":
						this.localDispatch(updateAventaStatus(SIP_UNREGISTER));
						this.localDispatch(makeCommonAPICall("noconnection",
							"", false, 0, "", "", false, 0));
						console.log("Terminated");
						break;
				}
			});
			this.registerer.register()
				.then((request) => {
					console.log("Successfully sent REGISTER");
				})
				.catch((error) => {
					console.error("Failed to send REGISTER");
				});
		})
		.catch((error) => {
			console.error(error);
			this.timerId = setInterval(this.reconnect, 60000);
			this.localDispatch(updateAventaStatus(SIP_DISCONNECTED));
		});
	}
	reconnect() {
		console.info("sip user agent reconnecting");
		this.userAgent.reconnect()
			.then(() => {
				this.registerer.register();
				console.info("sent reconnect");
				clearInterval(this.timerId);
				this.localDispatch(updateAventaStatus(SIP_REGISTER));
			})
			.catch((error) => {
				console.info(error);
				this.localDispatch(updateAventaStatus(SIP_DISCONNECTED));
			});
	}
	startWebRtcStats(){
		getWebRtcStats(this.session);
	}
	setupRemoteMedia(session) {
		session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
		if (receiver.track) {
			console.info("receiver has track");
			this.remoteStream.addTrack(receiver.track);
		}
		});
		this.localStream = session.sessionDescriptionHandler.localMediaStream;
		const audio = document.getElementById('CentionManualCallAudio');
		if(audio  !== null){
			console.info("have audio element");
			audio.srcObject = this.remoteStream;
		} else {
			console.error("getelementbyid CentionManualCallAudio is null");
		}
	}
	setupPeerListeners(session) {
		if(typeof session.sessionDescriptionHandler === 'undefined' ||
			session.sessionDescriptionHandler === null){
			return;
		}
		const peerConnection =
			session.sessionDescriptionHandler.peerConnection;
		if(typeof peerConnection === 'undefined' || peerConnection == null){
			return;
		}
		peerConnection.addEventListener("connectionstatechange", ev => {
			console.info("iceconnectionstate",
				peerConnection.connectionState);
		});
	}
	setupRemoteTransferMedia(session) {
		session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
		if (receiver.track) {
			console.info("transfer receiver has track");
			this.remoteStream.addTrack(receiver.track);
		}
		});
		this.localStream = session.sessionDescriptionHandler.localMediaStream;
		let audio = document.getElementById('CentionTransferCallAudio');
		audio.srcObject = this.remoteStream;
	}
	cleanup(){
		this.localDispatch(updateSipXferRef(""));
		this.localDispatch(updateSipErrandId(0));
		this.localDispatch(updateSipTransferStatus(SIP_CALL_IDLE));
		this.localDispatch(updateSipIncomingOngoing(false));
		this.localDispatch(setCallTransferTarget(""));
		this.localDispatch(updateSnoopString(""));
		this.localDispatch(selectTransferMode(COLD_TRANSFER, false));
		this.localDispatch(sipSetCallTransferData(null));
		this.session = null;
		this.xferSession = null;
		this.xferAgentId = "";
		this.wasXferred = false;
		this.currentSipCallId = "";
		this.isRefer = false;
		this.isWarm = false;
		this.isSnooping = false;
		this.snoopType = "";
		this.xferRefId = "";
		this.otherPartyNumber = "";
		this.altOtherPartyNumber = "";
		this.dialogId = "";
		this.extRefId = "";
		this.sessionCallId = "";
		this.xStasisChannelId = "";
		this.xStasisCallId = "";
		this.manualCall = false;

		if(this.timeoutVar != null){
			clearTimeout(this.timeoutVar);
			this.timeoutVar == null;
		}
		if(this.webRtcStatTimer != 0){
			clearInterval(this.webRtcStatTimer);
			this.webRtcStatsTimer = 0;
		}
	}

	codecModifier(val){
		let sdpArr = val.sdp.split('\n');
		let newArr = [];
		$.each(sdpArr, (i, sdpLine) => {
			if(sdpLine.includes("a=rtpmap:")){
				return;
			}
			if(sdpLine.includes("UDP/TLS/RTP/SAVPF")){
				sdpLine = "m=audio 9 UDP/TLS/RTP/SAVPF 111 0 8";
			}
			newArr.push(sdpLine);
		});
		newArr.push("a=rtpmap:111 opus/48000/2");
		newArr.push("a=rtpmap:0 PCMU/8000");
		newArr.push("a=rtpmap:8 PCMA/8000");
		let finalSdp = newArr.join("\n");
		return val;
	}

	makeSipCall(callAddr, errandDisplayId, targetPhoneNumber, isFromManual,
		callPlanId, state, dispatch, getState) {
		var inviter;
		if (this.userAgent != null) {
			callAddr = callAddr.replace(/\s+/g, '');
			//for CEN-2677
			/*
			let doRecording = "false";
			if(this.backendRecording == true){
				doRecording = "true";
			}
			*/
			const target = UserAgent.makeURI(callAddr);
			const inviterOptions = {
				sessionDescriptionHandlerOptions: {
					constraints: { audio: true, video: false }
				},
				extraHeaders: [
					'X-outgoingtrunk: ' + callPlanId,
					'X-errand: ' + errandDisplayId,
					'X-workspace: ' + workspace
					/*
					'X-workspace: ' + workspace,
					'X-callrecord: ' + doRecording
					*/
				],
			};
			inviter = new Inviter(this.userAgent, target, inviterOptions);
			let outgoingSession = inviter;

			// Setup outgoing session delegate
			outgoingSession.delegate = {
				// Handle incoming REFER request.
				onRefer(referral) {
					console.info("onrefer")

				},
				onCallHold(e) {
					console.info("onCallHold")
				}
			};

			this.session = outgoingSession;
			inviter.stateChange.addListener((newState) => {
				switch (newState) {
					case SessionState.Establishing:
						break;
					case SessionState.Established:
						console.info("outgoing invite session established");
						this.currentSipCallId =
							inviter.outgoingRequestMessage.callId;
						dispatch(updateAventaStatus(
							SIP_OUTGOING_CALL_CONNECTED));
						dispatch(resetCallTimer(Date.now()));
						this.setupRemoteMedia(inviter);
						/* to be implemented in CEN-2677
						if(this.backendRecording) {
							this.localDispatch(updateSipRecording(true));
						} else if(this.autoRecording == true){
						*/
						if(this.autoRecording == true){
							console.info("start recording:", errandDisplayId);
							this.localDispatch(startRecordingSip(
								errandDisplayId, this.getStreams(),
								isFromManual));
						}
						/* commented out until we decide how to do CEN-9*/
						this.webRtcStatTimer = setInterval(function(){
							this.startWebRtcStats()}.bind(this),2000);
						
						this.updateManualCallStatus(false, false,
							"established", targetPhoneNumber, errandDisplayId);
						break;
					case SessionState.Terminated:
						if(this.currentSipCallId != "" &&
							this.currentSipCallId !==
							inviter.outgoingRequestMessage.callId){
							console.info("outgoing session termination received. call id mismatch. ignore");
							break;
						}
						/* for CEN-2677
						if(this.backendRecording == true){
							this.localDispatch(updateSipRecording(false));
						} else if(sipCallIsRecording(getState()) == true){
						*/
						if(sipCallIsRecording(getState()) == true){
							//doesn't look like the eid in stopRecordingSip
							//is used for anything
							this.localDispatch(stopRecordingSip(0));
						} else if(this.autoRecording == true){
								this.localDispatch(stopAutoRecordingSip(
									errandDisplayId));
						}
						console.info("outgoing invite session terminated");
						dispatch(updateAventaStatus(SIP_CONNECTED));
						dispatch(resetCallTimer(0));
						if(this.xferRefId != "") {
							this.localDispatch(makeCommonAPICall(CALL_STR_END,
								callAddr, false, 0, this.xferRefId, "", false, 0));
						} else {
							this.localDispatch(makeCommonAPICall(CALL_STR_END,
								callAddr, false, 0, "", errandDisplayId,
								false, 0));
						}
						if(this.aventaEnabled == false){
							notifyOS("Cention", endOfCall, 0);
						}
						this.updateManualCallStatus(true, false,
							"terminated", targetPhoneNumber, errandDisplayId);

						this.cleanup();
						break;
					default:
						break;
				}
			});

			if (this.session != null &&
				this.session.outgoingRequestMessage != null) {
				this.sessionCallId =
					this.session.outgoingRequestMessage.callId;
			}
			//Send initial INVITE request
			dispatch(updateAventaStatus(SIP_OUTGOING_CALL_CONNECTING));
			this.otherPartyNumber =  targetPhoneNumber;

			/*
			 * for reference. if we ever need to modify the outgoing SDP
			 * this is one way we can do it
			inviter.invite({
				sessionDescriptionHandlerModifiers: [
					this.codecModifier,
				]
			})
			*/
			inviter.invite()
				.then(() => {
					//this.startManualCallStatus();
				})
				.catch((error) => {
					dispatch(updateAventaStatus(SIP_CONNECTED));
					this.stopManualCallStatus();
					this.session = null;
					console.info("invite error:" + error);
				});

		} else {
			console.info("no connection to sip server");
			dispatch(updateAventaStatus(SIP_DISCONNECTED));
			return;
		}
	}

	transferSipCall(callAddr, errandDisplayId, state, dispatch){
		const replacementSession = new Inviter(this.userAgent, UserAgent.makeURI(callAddr));
		this.session.stateChange.addListener((newState) => {
			switch (newState) {
			case SessionState.Establishing:
			break;
			case SessionState.Established:
				console.info("session established");
				dispatch(updateAventaStatus(SIP_CALL_CONNECTED));
				this.setupRemoteMedia(inviter);
				this.session.refer(replacementSession);
			break;
			case SessionState.Terminated:
				console.info("session terminated");
				dispatch(updateAventaStatus(SIP_CONNECTED));
				this.localDispatch(makeCommonAPICall(CALL_STR_END,
					callAddr, false, 0, "", errandDisplayId, false, 0));
				this.session = null;
			break;
			default:
			break;
			}
		});
	}
	sipColdTransfer(callAddr, targetAgentId, displayId, isExternalForward, isManual, state, dispatch){
		const target = UserAgent.makeURI(callAddr);
		console.info("sipColdTransfer: " + callAddr + " " + targetAgentId +
			" " + displayId + " " + isExternalForward + " " + isManual);
		dispatch(updateSipTransferStatus(SIP_CALL_CONNECTING));
		this.session.refer(target,{
			/*
			not working with asterisk. we can see the REFER request going out
			with the exra headers. however, for unattended transfer in asterisk
			it is converted to INVITE before being sent to target agent. I am
			unable to figure out how to extract headers from REFER and add
			it to the INVITE in asterisk's extensions.conf
			requestOptions: {
				extraHeaders: [
				'X-Referred-By-Someone: Username',
				'X-ErrandId: 1234'
				]
			},
			*/
			requestDelegate: {
				onAccept: (message) => {
					let theNumber = this.otherPartyNumber;
					let refId = this.dialogId;
					let dispId = "";
					if(displayId !== ""){
						refId = "";
						dispId = displayId;
					} else if(this.extRefId !== "") {
						refId = this.extRefId;
					}
					dispatch(updateAventaStatus(SIP_CONNECTED));
					dispatch(makeCommonAPICall(CALL_STR_END,
						theNumber, false, -1, refId, dispId, false, 0));
					dispatch(makeCommonAPICall(CALL_STR_TRANSFER,
						theNumber, false, -1, refId, dispId, false,
						targetAgentId, isManual , isExternalForward));
					this.otherPartyNumber = "";
					this.dialogId = "";
					if(isExternalForward == true ){
						this.endCall();
					}
					this.cleanup();
					notifyOS("Cention", callTransferred, 0);
				},
			}
		})
		.then(data => {
			//nothing here
			if(sipMakeCallCurrentErrand(state)) {
				dispatch(sipMakeCallFromErrand(false, 0));
			}
		});
	}
	sipWarmTransfer(callAddr, targetAgentId, displayId, eid, state,
		mCipherKey, isManual, dispatch, extRefId){
		const target = UserAgent.makeURI(callAddr);
		const inviterOptions = {
			extraHeaders: [
					'X-Invite: warm'
			],
			sessionDescriptionHandlerOptions: {
				constraints: { audio: true, video: false }
			},
		};
		console.log("sipWarmTransfer set X-Invite to warm");
		let inviter = new Inviter(this.userAgent, target, inviterOptions);
		let xferSession = inviter;
		this.xferSession = xferSession;
		this.xferAgentId = targetAgentId;
		let refId = this.dialogId;
		if(isManual && extRefId !== "") {
			refId = this.extRefId;
		} else if(this.extRefId !== ""){
			refId = this.extRefId;
		} else if(this.xferRefId != ""){
			refId = this.xferRefId;
		}
		let dispId = ""
		if(displayId !== ""){
			refId = ""
			dispId = displayId
		}
		inviter.stateChange.addListener((newState) => {
			console.info("sipWarmTransfer new state:",newState);
			switch (newState) {
				case SessionState.Establishing:
					dispatch(updateSipTransferStatus(SIP_CALL_CONNECTING));
					break;
				case SessionState.Established:
					console.info("warm xfer session established");
					dispatch(updateSipTransferStatus(SIP_CALL_CONNECTED));
					this.setupRemoteTransferMedia(inviter);
                    break;
				case SessionState.Terminated:
					this.holdCall(false, dispatch);
					dispatch(forceRecordingResume());
					dispatch(updateSipTransferStatus(SIP_CALL_IDLE));
					dispatch(setCallTransferTarget(""));
					dispatch(showDtmfKeyboard(false));
					console.info("xfer session terminated/rejected");
					this.xferSession = null
					this.xferAgentId = ""
					break;
				default:
					break;
			}
		});
		/* for CEN-2677
		if(this.backendRecording == true){
			let isRecording = sipCallIsRecording(this.localGetState());
			if(isRecording == true){
				this.pauseBackendSipRecording(false);
			}
		} else {
			this.localDispatch(forceRecordingPause());
		}
		*/
		this.holdCall(true, dispatch);
		this.localDispatch(forceRecordingPause());
		dispatch(updateSipTransferStatus(SIP_CALL_CONNECTING));
		let sipNameOnly = this.getSipNameOnly(callAddr);
		dispatch(setCallTransferTarget(sipNameOnly));
		dispatch(setCallTransferTargetId(targetAgentId));
		inviter.invite({
			requestOptions: {
				extraHeaders: [
					'X-Invite: warm'
				],
			},
		});
	}
	sipWarmFinalizeTransfer(displayId, eid, mCipherKey, isManual, isExternalForward, state, dispatch){
		let opn = this.otherPartyNumber;
		let refId = this.dialogId;
		if(this.extRefId !== ""){
			refId = this.extRefId;
		} else if(this.xferRefId != ""){
			refId = this.xferRefId;
		}
		let dispId = ""
		if(displayId !== ""){
			//refId = ""
			dispId = displayId
		}
		this.wasXferred = true;
		let xferAgentId = this.xferAgentId, createMode = ME_CREATE_AS_MY;
		if(isExternalForward) {
			createMode = ME_CREATE_AS_CLOSED;
		}
		if(this.manualCall == false && this.backendRecording == true){
			this.localDispatch(updateSipRecording(false));
		} else if(this.autoRecording == true){
			this.localDispatch(stopAutoRecordingSip());
		}
		if(isManual == true){
			//NOTES: Review this
			dispatch(submitManualErrand(ME_CREATE,
				createMode, true, true, true))
			.then(res => {
				let dispatchee;
				if(isExternalForward) {
					//for external transfer, close the manual errand immediately
					//todo: TO BE REVIEW : should this follow how auto save/close with tagging done on outbound call?
					const resultData = getAppErrand(state).updateManualErrand.data;
					let attIdStr = "";
					$.each(resultData.answer_mail_attachments, (i,v) => {
						attIdStr += v.id + ",";
					});
					if(attIdStr.length > 0){
						attIdStr = attIdStr.substr(0, attIdStr.length-1)
					}
					let param = getAppErrand(state).manualCallInputs;
					let archive_attachments = "";
					if(param.archive_attachments.length > 0) {
						let uaf = Object.keys(param.archive_attachments).map(v => {
							return param.archive_attachments[v].id;
						});
						archive_attachments = uaf.join(",");
					}
					dispatchee = closeErrand(update(param,
						{
							update_archive_attachments: {$set: archive_attachments},
							update_uploaded_attachments: {$set: attIdStr},
							update_id: {$set: eid},
							update_cipher_key: {$set: mCipherKey},
							update_area_id: {$set: param.area},
							is_sip: {$set: true},
							extRefId: {$set: refId},
							current_context_name: {$set: CTX_MANUAL},
					}))
					xferAgentId = "";
				} else {
					dispatchee = forwardManualSipToAgent(eid, xferAgentId, mCipherKey)
				}
				dispatch(dispatchee)
				.then(data => {
					if(isExternalForward == false){
						dispatch(removeAgentWorkingOnErrand(eid));
					}
					dispatch(makeCommonAPICall(
						CALL_STR_TRANSFER, opn, false, -1, refId, dispId, false,
						xferAgentId));
				});
			});
		} else {
			if(eid) {
				dispatch(forceSaveErrand())
				.then(res => {
					let dispatchee;
					if(isExternalForward) {
						dispatchee = doCloseErrand(eid, false, false, refId);
						xferAgentId = "";
					} else {
						dispatchee = forwardErrandToAgent(eid, xferAgentId, true,
							refId);
					}
					dispatch(dispatchee)
					.then(data => {
						if(isExternalForward == false){
							dispatch(removeAgentWorkingOnErrand(eid));
						}
						dispatch(makeCommonAPICall(
							CALL_STR_TRANSFER, opn, false, -1, refId, dispId, false,
							xferAgentId));
						if(sipMakeCallCurrentErrand(state)) {
							dispatch(sipMakeCallFromErrand(false, 0));
						}
					});
				});
			} else {
				dispatch(makeCommonAPICall(
					CALL_STR_TRANSFER, opn, false, -1, refId, dispId, false,
					xferAgentId));
				if(sipMakeCallCurrentErrand(state)) {
					dispatch(sipMakeCallFromErrand(false, 0));
				}
			}
		}
		this.xferSession.refer(this.session);
		this.cleanup();
		notifyOS("Cention", callTransferred, 0);
	}
	holdTransferCall(isHold, dispatch) {
		if(this.xferSession == null){
			return;
		}
		if (isHold) {
			this.xferSession.invite({
				sessionDescriptionHandlerModifiers: [Web.holdModifier]
			});
			dispatch(updateSipTransferStatus(SIP_CALL_ON_HOLD));
		} else {
			this.xferSession.invite({
				sessionDescriptionHandlerModifiers: []
			});
			dispatch(updateSipTransferStatus(SIP_CALL_CONNECTED));
		}
	}
	//this is used to mute/unmute a call
	enableSenderTracks(enable){
		const sessionDescriptionHandler =
			this.session.sessionDescriptionHandler;
        const peerConnection = sessionDescriptionHandler.peerConnection;
        if (!peerConnection) {
            console.info("Peer connection closed.");
			return false;
        }
		console.info("mute call:", !enable);
        peerConnection.getSenders().forEach((sender) => {
            if (sender.track) {
                sender.track.enabled = enable;
            }
        });
		return true;
	}
	//difference between mute and hold call is that for mute, agent
	//can still hear incoming stream
	holdCall(isHold, dispatch, isManual, isCurrentErrand) {
		if(this.session == null){
			return;
		}
		let muteOk = true;
		if (isHold) {
			if(this.isSnooping){
				muteOk = this.enableSenderTracks(false);
			} else {
				this.session.invite({
					sessionDescriptionHandlerModifiers: [Web.holdModifier]
				});
			}
			if (muteOk) {
				dispatch(updateAventaStatus(SIP_CALL_ON_HOLD));
				dispatch(makeCommonAPICall("onhold",this.otherPartyNumber,
					true, 0, "", "", false, 0));
			}
		} else {
			if(this.isSnooping){
				muteOk = this.enableSenderTracks(true);
			} else {
				this.session.invite({
					sessionDescriptionHandlerModifiers: []
				});
			}
			if(isManual === true || isCurrentErrand){
				dispatch(updateAventaStatus(SIP_OUTGOING_CALL_CONNECTED));
			} else{
				if(muteOk){
				dispatch(updateAventaStatus(SIP_CALL_CONNECTED));
				}
			}
			if(muteOk){
				dispatch(makeCommonAPICall("onresume",this.otherPartyNumber,
					false, 0, "", "", false, 0));
			}
		}
	}
	// sent via RTP - RFC 4733
	handleDtmfInband(key){
		if(this.session == null){
			return;
		}
		this.session.sessionDescriptionHandler.sendDtmf(key);
	}
	handleDtmfInbandToAgent(key){
		if(this.xferSession == null){
			return;
		}
		this.xferSession.sessionDescriptionHandler.sendDtmf(key);
	}
	//send DTMF via SIP info() - RFC 2833
	handleDtmfOutband(key) {
		if(this.session == null){
			return;
		}
		const duration = 2000;
		const body = {
			contentDisposition: "render",
			contentType: "application/dtmf-relay",
			content: "Signal=" + key + "\r\nDuration=" + duration
		};
		const requestOptions = { body };

		return this.session.info({ requestOptions }).then(() => {
			return;
		});
	}
	endCall() {
		if (this.session == null) {
			return;
		}
		switch (this.session.state) {
			case SessionState.Initial:
			case SessionState.Establishing:
				if (this.session instanceof Inviter) {
					// An unestablished outgoing session
					this.session.cancel();
				} else {
					// An unestablished incoming session
					if (this.session) {
						this.session.reject();
					}
				}
				break;
			case SessionState.Established:
				// An established session
				this.session.bye();
				break;
			case SessionState.Terminating:
			case SessionState.Terminated:
				// Cannot terminate a session that is already terminated
				break;
		}
	}
	endXferCall() {
		if (this.xferSession == null) {
			return;
		}
		switch (this.xferSession.state) {
			case SessionState.Initial:
			case SessionState.Establishing:
				if (this.xferSession instanceof Inviter) {
					this.xferSession.cancel();
				}
				break;
			case SessionState.Established:
				this.xferSession.bye();
				break;
		}
		this.xferSession = null;
	}
	acceptIncomingSip(xferRefId) {
		let invitation = this.session;
		if (invitation != null) {
			try{
				invitation.accept();
			} catch (err){
				console.info("accepting call error:",err);
				invitation.reject();
				return;
			}
			if(this.isSnooping == true){
				this.localDispatch(makeCommonAPICall(CALL_STR_SNOOP,
					this.otherPartyNumber, false, 0, this.dialogId, "", false,
					0, null));
			} else if(this.isRefer == true){
				this.localDispatch(makeCommonAPICall(CALL_STR_ACCEPT,
					this.otherPartyNumber, false, 0, this.dialogId, "", false,
					0, this.setCallEid));
			} else {
				this.localDispatch(makeCommonAPICall(CALL_STR_ACCEPT,
					this.otherPartyNumber, false, 0, this.dialogId, "", true,
					0, false, false, this.xStasisArea, this.setCallEid,
					this.xStasisChannelId, this.xStasisCallId));
			}
			this.localDispatch(updateAventaStatus(SIP_CALL_WAITING_CONNECTION));
			this.xferRefId = xferRefId;
		}
	}
	rejectIncomingSip() {
		let invitation = this.session;
		if (invitation != null && typeof invitation.reject === 'function') {
			invitation.reject();
		}
	}
	getSipAvatar(outgoing, fromAddr) {
		let list = [];
		list.push({
			fromId: 0
			, fromAddr: fromAddr ? fromAddr : this.otherPartyNumber
			, serviceType: Errand.SERVICE_VOICE
		});
		this.localDispatch(postClientAvatar({ list, autoRefresh: false }))
			.then(data => {
				let clientAvatar = null;
				if(data && data[0]) {
					//has contact card record
					if(data[0].avatar !== null) {
						clientAvatar = data[0];
					}
				}
				if (Array.isArray(data) && data.length >= 1) {
					if(this.altOtherPartyNumber != ""){
						if(!outgoing) {
							this.localDispatch(toggleSipIncomingPopup(true,
								this.altOtherPartyNumber, clientAvatar));
						} else {
							this.localDispatch(updateSipOutgoingAvatar(clientAvatar.avatar));
						}
					} else {
						if(!outgoing) {
							this.localDispatch(toggleSipIncomingPopup(true,
								this.otherPartyNumber, clientAvatar));
						} else if(clientAvatar !== null) {
							this.localDispatch(updateSipOutgoingAvatar(clientAvatar.avatar));
						}
					}
				}
			});
	}
	getNewSipAvatar(newOtherPartyNumber) {
		this.otherPartyNumber = newOtherPartyNumber;
		let list = [];
		list.push({
			fromId: 0
			, fromAddr: this.otherPartyNumber
			, serviceType: Errand.SERVICE_VOICE
		});
		this.localDispatch(postClientAvatar({ list, autoRefresh: false }))
			.then(data => {
				let clientAvatar = null;
				if(data[0]) {
					//has contact card record
					if(data[0].avatar !== null) {
						clientAvatar = data[0];
					}
				}
				let displayNumber = this.otherPartyNumber
					if(this.altOtherPartyNumber != ""){
						displayNumber = this.altOtherPartyNumber;
					}
				if (Array.isArray(data) && data.length >= 1) {
					this.localDispatch(toggleSipIncomingPopup(true,
						displayNumber, clientAvatar));
				} else {
					this.localDispatch(toggleSipIncomingPopup(true,
						displayNumber, null));
				}
			});
	}
	setExtRefId(extRefId) {
		if(typeof extRefId !== 'undefined' && extRefId != ""){
			this.extRefId = extRefId;
		}
	}
	setCallEid(eid) {
		if(this.backendRecording == false && this.autoRecording == true){
			console.info("start recording:", eid);
			let	started  = this.localDispatch(startRecordingSip(
				eid, this.getStreams(), false));
			if(started == false){
				setTimeout(() => {
					started = this.setCallEid(eid);
				}, 1000);
				console.info("set one timeout");
			}
		}
	}
	getSipAddress(from) {
		let fromAddress = ""
		if (from.uri != null && from.uri.normal != null) {
			fromAddress = from.uri.normal.user;
			/*
			if (from.uri.normal.scheme == "sip") {
				fromAddress = from.uri.normal.scheme + ":" +
					from.uri.normal.user + "@" + from.uri.normal.host;
			} else {
				fromAddress = from.uri.normal.user;
			}
			*/

		}
		return fromAddress;
	}
	getSipDisplayName(useDisplay, nameStr){
		if(useDisplay !== true){
			return "";
		}
		if(typeof nameStr === 'undefined' || nameStr == ""){
			return "";
		}
		return nameStr.replace(":","-");
	}
	getSipNameOnly(sipAddr){
		if(sipAddr == ""){
			return "";
		}
		const sipArr = sipAddr.split("@");
		return sipArr[0];
	}
	getStreams() {
		let streams = {};
		streams.remoteStream = this.remoteStream;
		streams.localStream = this.localStream;
		return streams;
	}
	pauseBackendSipRecording(isRecording) {
		this.handleDtmfInband('*3');
		this.localDispatch(updateSipRecording(isRecording));
	}
	handleAutoAnswerTimeout() {
		if(sipCallStatus(this.localGetState()) == SIP_CALL_CONNECTING){
			this.acceptIncomingSip(this.xferRefId);
		}
	}
	getExtRefId() {
		return this.extRefId;
	}
	handleSaveManualErrand() {
		let localState = this.localGetState();
		let eid = sipGetCurrentManualEid(localState);
		let isPopup = isManualCallPopup(localState);
		if(isPopup == true){
			if(typeof eid !== 'undefined' && eid !== "" && eid !== 0){
				this.localDispatch(shouldSaveManualCall(false));
				this.localDispatch(resetOutboundErrandId());
			}
			this.localDispatch(toggleManualOrCallPopup(MP_NONE, true));
			this.localDispatch(toggleSipOutgoingPopup(false, ""));
			this.localDispatch(clearManualCallInputs());
		}
	}
	getSnoopDisplayString() {
		if(this.isSnooping == false){
			return ""
		}
		switch (this.snoopType){
			case "listen":
				return I("listen call");
			break;
			case "whisper":
				return I("whisper call");
			break;
			case "barge":
				return I("barge call");
			break;
		}
		return ""
	}
	startManualCallStatus() {
		this.updateManualCallStatus(true, true, "start", "", "");
		this.manualCall = true;
		if(this.manualCallStateTimer != 0){
			clearInterval(this.manualCallStateTimer);
		}
		this.manualCallStateTimer = setInterval(
			function() {
				this.updateManualCallStatus(true);
			}.bind(this), 150000);
	}
	stopManualCallStatus() {
		this.manualCall = false;
		clearInterval(this.manualCallStateTimer);
		this.manualCallStateTimer = 0;
		this.updateManualCallStatus(false, true, "end", "", "");
	}
	updateManualCallStatus(callStatus, notifySwitchd, sType,
		calledNumber, errandDisplayId) {
		let param = {};
		param.manualcallactive = callStatus;
		param.sipid = this.user;
		param.notifyswitchd = notifySwitchd;
		param.callid = this.currentSipCallId;
		param.status = sType;
		param.callednumber = calledNumber;
		param.erranddisplayid = errandDisplayId;

		this.localDispatch(postSipManualCallStatus(param))
		.then(data => {
			console.info(data);
		});
	}
	playBeepToLocalStream() {
		if(this.localStream != null) {
			let streamBleeper = new Audio(BEEP_AUDIO_SRC);
			//bleepStream is a MediaStream
			bleepStream = streamBleeper.captureStream()
		}
	}
}

export const makeSipVoiceCall = (sipPhone, callAddr, errandDisplayId,
	targetPhoneNumber, isFromManual, callPlanId) => (dispatch, getState) => {
	if (sipPhone == null) {
		return;
	}
	const state = getState();
	let ua = sipPhone.conn;
	ua.makeSipCall(callAddr, errandDisplayId, targetPhoneNumber, isFromManual,
		callPlanId, state, dispatch, getState);
}

export const transferSipVoiceCall = (sipPhone, callAddr, errandDisplayId) => (dispatch,
	getState) => {
	if (sipPhone == null) {
		return;
	}
	const state = getState();
	let ua = sipPhone.conn;
	ua.transferSipCall(callAddr, errandDisplayId, state, dispatch);
}

export const onHoldSIPTransferCall = (eid, sipPhone, isHold) => (dispatch, getState) => {
	if (sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	ua.holdTransferCall(isHold, dispatch);
}

export const onHoldSIPCall = (eid, sipPhone, isHold, isManual) => (dispatch, getState) => {
	const isCurrentErrand = sipMakeCallCurrentErrand(getState());
	if (sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	if(typeof isManual === "undefined") {
		if(manualCallMinimize(getState())) {
			isManual = true;
		}
	}
	ua.holdCall(isHold, dispatch, isManual, isCurrentErrand);
}

export const onEndSIPCall = (sipPhone) => (dispatch, getState) => {
	if (sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	ua.endCall();
}

export const onDtmfPress = (sipPhone, key) => (dispatch, getState) =>{
	if(typeof key === 'undefined'){
		return;
	}
	playBeepNotif();
	let ua = sipPhone.conn;
	ua.handleDtmfInband(key);
}

export const onDtmfPressToAgent = (sipPhone, key) => (dispatch, getState) =>{
	if(typeof key === 'undefined'){
		return;
	}
	playBeepNotif();
	let ua = sipPhone.conn;
	ua.handleDtmfInbandToAgent(key);
}

export const answerIncomingSip = (sipPhone, xferRefId) => (dispatch,
	getState) => {
	if (sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	ua.acceptIncomingSip(xferRefId);
}

export const closeIncomingSip = (sipPhone, callStatus, closePopup) =>
	(dispatch, getState) => {
	if (sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	if (callStatus == SIP_CALL_CONNECTED || callStatus == SIP_CALL_ON_HOLD) {
		ua.endCall();
	} else {
		if (callStatus != SIP_CALL_IDLE && callStatus != SIP_CALL_ON_HOLD &&
			callStatus != SIP_CONNECTED) {
			ua.rejectIncomingSip();
		}
	}
	dispatch(updateAventaStatus(SIP_CONNECTED));
	if (closePopup == true) {
		dispatch(toggleSipIncomingPopup(false, "", null));
	}
}

export const closeXferSip = (sipPhone, callStatus) =>
	(dispatch, getState) => {
	if (sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	if (callStatus == SIP_CALL_CONNECTED || callStatus == SIP_CALL_ON_HOLD ||
		callStatus == SIP_CALL_CONNECTING) {
		ua.endXferCall();
	}
	dispatch(updateSipTransferStatus(SIP_CALL_IDLE));
	dispatch(setCallTransferTarget(""));
}

const commonCallAPI = (type, number, isHold, duration, ref, edId,
	createErrand, targetAgentId, isManual, isExternalTransfer,
	stasisArea, afterEidCreatedFunc, stasisChannelId,
	stasisCallId) => (dispatch, getState) => {
	const state = getState();
	let currentErrandId = state.app.errand.currentErrand.id;
	let wfSettings = state.app.workflow.fetchWfSettings.data
	let param = {};
	param.type = type;
	param.number = number;
	param.isHold = isHold;
	param.refId = ref;
	param.errand = edId;
	param.createErrand = createErrand;
	param.targetAgentId = targetAgentId;
	param.stasisArea = stasisArea;
	param.stasisChannelId = stasisChannelId;
	param.stasisCallId = stasisCallId;
	if (typeof duration !== 'undefined' && duration != null) {
		param.duration = duration;
	}
	dispatch(postCommonCallApi(param))
	.then(data => {
		if(data.result != null && data.result == "success"){
			switch(type) {
				case CALL_STR_HOLD:
					dispatch(onHoldSIPCall(data.eid, sipCallConn(state),
						isHold, isManual));
					break;
				case CALL_STR_ACCEPT:
					if(data.eid != 0){
						console.info("commonCallAPI:", state);
						console.info("current errand:"+ currentErrandId +
							" new errand id:"+data.eid);
						if(currentErrandId != 0){
							dispatch(forceSaveErrand())
							.then(res => {
								dispatch(setErrandPinToTop(data.eid,
									data.cipherKey, true));
								dispatch(setCurrentErrandOpening(true));
								dispatch(loadAndOpenErrand(data.eid))
								.then(res => {
									if(typeof afterEidCreatedFunc !=='undefined'
										&& afterEidCreatedFunc !== null){
										afterEidCreatedFunc(data.eid);
									}
									if(wfSettings.voiceReopenPreviousErrand ==
										true){
										dispatch(setPreviousErrand(
											currentErrandId));
									}
									dispatch(setCurrentErrandOpening(false));
								});
							});
						} else {
							dispatch(setErrandPinToTop(data.eid,
								data.cipherKey, true));
							dispatch(setCurrentErrandOpening(true));
							dispatch(loadAndOpenErrand(data.eid))
							.then(res => {
								if(typeof afterEidCreatedFunc !== 'undefined'
									&& afterEidCreatedFunc !== null){
									afterEidCreatedFunc(data.eid);
								}
								if(wfSettings.voiceReopenPreviousErrand ==
									true){
									dispatch(setPreviousErrand(
										currentErrandId));
								}
								dispatch(setCurrentErrandOpening(false));
							});
						}
						dispatch(updateSipErrandId(data.eid));
						dispatch(updateSipRefId(data.extRefId));
					}
					break;
				case CALL_STR_TRANSFER:
					if(!isManual || !isExternalTransfer) {
						dispatch(toggleSipOutgoingPopup(false, false, ""));
						dispatch(toggleManualOrCallPopup(MP_NONE, true));
						dispatch(resetOutboundErrandId());
						dispatch(clearManualCallInputs());
						//dispatch(updateSipErrandId(0));
						dispatch(showSipAgentList(false));
					}
					if(isManual && !isExternalTransfer) {
						dispatch(manualCallState(ME_ST_IDLE));
					}
					dispatch(loadList("sipCallTransfer"));
					break;
				case CALL_STR_SNOOP:
					break;
			}
		}
	});
};

//TODO: the parameter list is getting unmanagable
export const makeCommonAPICall = (type, num, isHold, duration, ref, edId,
	createErrand, targetAgentId, isManual, isExternalForward, stasisArea,
	afterEidCreatedFunc, stasisChannelId, stasisCallId) =>
	commonCallAPI(type, num, isHold, duration, ref, edId, createErrand,
		targetAgentId, isManual, isExternalForward, stasisArea,
		afterEidCreatedFunc, stasisChannelId, stasisCallId);
 
var sipBeeper = null;

export const playBeepNotif = () => {
	if(sipBeeper  === null){
		sipBeeper = new Audio(BEEP_AUDIO_SRC);
	}
	var promise = sipBeeper.play();
	if (promise !== undefined) {
		promise.then(response => {
		}).catch(error => {
			console.log("dbg: Sound notification error : ", error);
		});
	}
}

const updateSipAgentList = (agentList) => {
	return {
		type: SIP_UPDATE_AGENT_LIST
		, payload: {
			agentList: agentList
		}
	}

}

export const showSipAgentList = (isShow) => {
	return {
		type: SIP_SHOW_AGENT_LIST
		, payload: {
			showAgentList: isShow
		}
	}

}

export const showDtmfKeyboard = (isShow) => {
	return {
		type: SIP_SHOW_DTMF_KEYPAD
		, payload: {
			showDtmfKeypad: isShow
		}
	}

}

export const showExtTransferKeypad = (isShow) => {
	return {
		type: SIP_UPDATE_EXTERNAL_TRANSFER_KEYPAD
		, payload: {
			showExternalTransferKeypad: isShow
		}
	}
}

export const resetCallTimer = (val) => {
	return {
		type: SIP_RESET_CALL_TIMER
		, payload: {
			sipCallTimer: val
		}
	}

}

export const setCallTransferTarget = (target) => {
	return {
		type: SIP_SET_TRANSFER_TARGET
		, payload: {
			currentTransferTargetNumber: target
		}
	}

}

export const setCallTransferTargetId = (id) => {
	return {
		type: SIP_SET_TRANSFER_TARGET_ID
		, payload: {
			currentTargetId: id
		}
	}
}

export const selectTransferMode = (mode, isExternal) => {
	return {
		type: SIP_TRANSFER_MODE
		, payload: {
			currentTransferMode: mode
			, isExternalTransfer: isExternal
		}
	}

}

export const sipGetAgentList = (conn, eid) => (dispatch, getState) =>{
	let param = {}
	const state = getState()
		, wf = state.app.workflow
		, sipExtension = wf.fetchWfSettings.data.sipExtension;
	dispatch(getAvailableSipAgents(param))
	.then(data => {
		let agentArr = [];
		if(data.status == "success" && typeof data.data != 'undefined' &&
			data.data != null && data.data.length > 0){
			data.data.forEach(function(agent){
				if(agent.sipid !== sipExtension && agent.callstatus == "idle"){
					agentArr.push(agent);
				}
			});
		}
		dispatch(updateSipAgentList(agentArr));
		dispatch(showSipAgentList(true));
	});
}

const sipColdFwdExt = (targetAddr, agentAddr, callerAddr, dispatch) => {
	let param = {};
	param.targetsipaddr = targetAddr;
	param.agentsipaddr = agentAddr;
	param.callersipaddr = callerAddr;
	param.fwdtype = "cold";
	dispatch(postAgentFwdCallToExt(param))
	.then((data) =>{
		console.info(data);
	});
}

export const sipColdTransfer = (sipPhone, callAddr, targetAgentId,
	displayId, isExternalForward, isManual) => (dispatch, getState) => {
	if (sipPhone == null) {
		return;
	}
	const state = getState();
	let ua = sipPhone.conn;
	dispatch(selectTransferMode(COLD_TRANSFER, isExternalForward));
	if(ua.switchdHandleTransfer == true && isExternalForward == true){
		sipColdFwdExt(callAddr, ua.user, ua.otherPartyNumber, dispatch)
		return;
	}
	ua.sipColdTransfer(callAddr, targetAgentId, displayId, isExternalForward, isManual, state, dispatch);
}

export const sipWarmTransfer = (sipPhone, callAddr, targetAgentId,
	displayId, eid, mCipherKey, isManual, isExternalForward, extRefId) => (dispatch,
	getState) => {
	if (sipPhone == null) {
		return;
	}
	const state = getState();
	let ua = sipPhone.conn;
	dispatch(selectTransferMode(WARM_TRANSFER, isExternalForward));
	if(ua.backendRecording == true && sipCallIsRecording(state) == true){
		ua.pauseBackendSipRecording(false);
		setTimeout(() => {
		ua.sipWarmTransfer(callAddr, targetAgentId, displayId,  eid, state,
			mCipherKey, isManual, dispatch, extRefId);
		},250);
	} else {
		ua.sipWarmTransfer(callAddr, targetAgentId, displayId,  eid, state,
			mCipherKey, isManual, dispatch, extRefId);
	}
			
	
}

export const sipWarmFinalizeTransfer = (sipPhone, displayId,
	eid, mCipherKey, isManual, isExternalForward) => (dispatch, getState) => {
	if (sipPhone == null) {
		return;
	}
	const state = getState();
	let ua = sipPhone.conn;
	if(eid) {
		console.log("dbg: Finalize transferring errand id ", eid);
	}
	ua.sipWarmFinalizeTransfer(displayId, eid, mCipherKey,
		isManual, isExternalForward, state, dispatch)
}

export const sipCloseManualErrandAfterColdTransfer = (eid, mCipherKey,
	extRefId) => (dispatch, getState) => {
	const resultData = getAppErrand(getState()).updateManualErrand.data;
	if(typeof eid === "string") {
		eid = parseInt(eid, 10);
	}
	if(eid === 0) {
		eid = resultData.result;
		mCipherKey = resultData.cipherKey;
	}
	let attIdStr = "";
	$.each(resultData.answer_mail_attachments, (i,v) => {
		attIdStr += v.id + ",";
	});
	if(attIdStr.length > 0){
		attIdStr = attIdStr.substr(0, attIdStr.length-1)
	}
	let param = getAppErrand(getState()).manualCallInputs;
	let archive_attachments = "";
	if(param.archive_attachments.length > 0) {
		let uaf = Object.keys(param.archive_attachments).map(v => {
			return param.archive_attachments[v].id;
		});
		archive_attachments = uaf.join(",");
	}
	let option = {
		isCall: true
		, manual: true
		, isSip: true
		, isClose: true
	};
	let newParam = update(param,
		{
			update_archive_attachments: {$set: archive_attachments},
			update_uploaded_attachments: {$set: attIdStr},
			update_id: {$set: eid},
			update_cipher_key: {$set: mCipherKey},
			update_area_id: {$set: param.area},
			is_sip: {$set: true},
			extRefId: {$set: extRefId},
			current_context_name: {$set: CTX_MANUAL},
		})
	dispatch(askForClassification(
		newParam
		, option
		, false
		, false
		, false
	))
	.then((data) => {
		dispatch(closeErrand(newParam))
		.then((data) => {
			console.log("done close errand ", data);
			dispatch(manualCallState(ME_ST_IDLE));
			dispatch(toggleSipOutgoingPopup(false, false, ""));
			dispatch(resetOutboundErrandId());
			dispatch(clearManualCallInputs());
			dispatch(updateSipErrandId(0));
			dispatch(showSipAgentList(false));
			dispatch(toggleManualOrCallPopup(MP_NONE, true));
		})
	})
}

export const sipUpdateAvatar = (newIncomingNumber) => (dispatch, getState) => {
	const state = getState();
	let sipPhone = sipCallConn(state);
	if(sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	ua.getNewSipAvatar(newIncomingNumber);
}

export const updateSipRefId = (extRefId) => (dispatch, getState) => {
	const state = getState();
	let sipPhone = sipCallConn(state);
	if(sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	ua.setExtRefId(extRefId);
}

export function sipGetRefId(sipPhone){
	if(typeof sipPhone === 'undefined' || sipPhone == null) {
		return "";
	}
	let ua = sipPhone.conn;
	return ua.extRefId;
}

export const sipStartSnoop = (mode, sipId) => (dispatch, getState) => {
	let param = {};
	param.mode = mode;
	param.sipid = sipId;
	let sipState  = sipCallStatus(getState());
	if(sipState != SIP_CONNECTED && sipState != SIP_REGISTER &&
			sipState != SIP_CALL_IDLE){
		let badState = I("unable to snoop due to SIP connection error");
		dispatch(customConfirm([badState], DISMISS_BUTTONS));
		return;
	}
	dispatch(postCommonSnoop(param))
	.then((data) =>{
		if(typeof data.result !== "undefined" && data.result == "fail"){
			dispatch(customConfirm([data.message], DISMISS_BUTTONS));
			console.info(data.message);
		}
	});
}

export const manualCallStatus = (isOn) => (dispatch, getState) => {
	const state = getState();
	let sipPhone = sipCallConn(state);
	if(sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	if(isOn == true){
		ua.startManualCallStatus();
	} else {
		ua.stopManualCallStatus();
	}
}

export const stopAutoRecording = () => (dispatch, getState) => {
	const state = getState();
	let sipPhone = sipCallConn(state);
	if(sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	if(ua.autoRecording){
		return dispatch(stopAutoRecordingSip());
	}
}

export const startAutoRecording = (eid) => (dispatch, getState) => {
	const state = getState();
	let sipPhone = sipCallConn(state);
	if(sipPhone == null) {
		return;
	}
	let ua = sipPhone.conn;
	if(ua.backendRecording == true){
		dispatch(updateSipRecording(true));
	} else if(ua.autoRecording == true){
		dispatch(startRecordingSip(eid, ua.getStreams(), false));
	}
}
