import each from 'lodash/each'
import $ from 'jquery'

import { doNothing } from '../../../common/constants'
import {
  deleteAdminReceiptgreetings,
  getAdminFilesAreaarchiveimages,
  getAdminReceiptgreetings,
  getAdminServiceaccounts,
  postAdminReceiptgreetings,
  postAdminReceiptgreetingsFeedbacksResponseimages,
  postAdminReceiptgreetingsFeedbacksResponsetemplates
} from './ajax'
import {
  keyAdminFilesAreaarchiveimages,
  keyAdminDeleteReceiptgreetings,
  keyAdminRGServiceaccounts,
  keyAdminReceiptgreetings,
  keyAdminSaveReceiptgreetings
} from '../../constants/keys'
import {
  async,
  createBroadcaster,
  loadOnceCreator
} from '../../util'
import {
  TXT_DELETING,
  TXT_FETCHING_DATA,
  TXT_SAVING,
  TXT_SYNCING
} from '../../../common/v5/chatbotConstants'
import {
  TXT_ARE_YOU_SURE_DELETE,
  UPLOAD_TYPE_IMAGE,
  initData,
  pathParams,
  tabToShort,
  toSelectPath
} from '../../../common/v5/receiptGreetingConstants'
import { push } from '../../../common/v5/utils'
import { serviceTypes } from '../../selectors/server'
import {
  adminAsyncMap,
  adminEditDataMemo,
  adminReceiptByIdSelector,
  adminStateByKey,
  matchParamMemo
} from '../../selectors/receiptGreeting'
import { updateAdminEditField, updateAdminStartEdit } from '../receiptGreeting'
import { wrapPopWait } from '../hmf'
import { optionalConfirm } from './hmf'
import { adminActionStatus } from '../admin';

const loadOnce = (key, ajax) => loadOnceCreator(
  adminAsyncMap,
  key,
  ajax,
  adminStateByKey
)

const onceAdminFilesAreaarchiveimages = loadOnce(
  keyAdminFilesAreaarchiveimages,
  getAdminFilesAreaarchiveimages
)

const validServices = ['SERVICE_EMAIL']

const getServiceTypes = state => {
  const services = []
  each(validServices, service => {
    services.push(serviceTypes(state)[service])
  })
  return { channels: services.join(',') }
}

const onceAdminRGServiceaccounts = (state, force) => loadOnce(
  keyAdminRGServiceaccounts,
  () => getAdminServiceaccounts(getServiceTypes(state))
)(force)

const onceAdminReceiptgreetings = loadOnce(
  keyAdminReceiptgreetings,
  getAdminReceiptgreetings
)

const onceOnloadBase = () => (dispatch, getState) => {
  const dispatches = [
    // dispatch(onceAgentAreas()),
    dispatch(onceAdminRGServiceaccounts(getState())),
    dispatch(onceAdminReceiptgreetings()),
    dispatch(onceAdminFilesAreaarchiveimages())
  ]
  return $.when(...dispatches)
}

export const onceOnload = createBroadcaster(onceOnloadBase)

const getUrlIds = (which, id, isNew) => {
  const result = ({ receipt: tabToShort[which] })
  if (!isNew) {
    result.id = id
  }
  return result
}

const editAsync = (map, onLoad) => {
  if (!map) {
    return
  }
  const { dataGetter, starter, noPopWait } = map
  const f = info => (dispatch, getState) => dispatch(onLoad()).then(() => {
    const data = dataGetter(info, getState)
    if (typeof data === 'undefined') {
      return
    }
    return dispatch(starter({ data, which: info.which }))
  })
  if (noPopWait) {
    return f
  }
  return wrapPopWait(TXT_FETCHING_DATA, f)
}

const defaultConfirmText = (which, id) => TXT_ARE_YOU_SURE_DELETE
  .replace('{ITEM_TYPE}', which)
  .replace('{ID}', id)

const deleteConfirmText = (which, id, textGetter, getState) => {
  if (typeof textGetter === 'function') {
    return textGetter(which, id, getState)
  }
  return defaultConfirmText(which, id)
}

// createCommonAdminAsyncs creates common async functions to reduce boilerplate
// as long as the backend use common field name and data structure.
// urlParamGetter: function return the params needed for AJAX.
// onLoad:         function that will be called to get the needed data before
//                 enable editing.
// adminAsyncMap:  map of declared asyncs result from reduxCreators or the
//                 indirect commonSelectorsCreator.
// map:            object of:
//                 {
//                   remove: {
//                     ajax: function for delete,
//                     key: redux key of the function
//                   },
//                   edit: {
//                     dataGetter: function return edit data,
//                     noPopWait: not showing popup waiting box if true. Caller
//                                should handle the popup inside onLoad if this
//                                field true as only that can fine tune the
//                                popup to indicate if an AJAX actually
//                                trigger-ed.
//                     starter: redux action creator to start edit
//                   },
//                   save: {
//                     afterPathGetter: function to return path to be used after save,
//                     ajax: function for save,
//                     key: redux key of the function
//                   }
//                 }
export const createCommonAdminAsyncs = (
  urlParamGetter,
  onLoad,
  adminAsyncMap,
  map
) => {
  const { remove, edit, save } = map
  const ___del = (which, id) => {
    const param = urlParamGetter(which, id)
    const { ajax, key } = remove
    return async(ajax(param), adminAsyncMap[key], param)
  }
  const __del = (which, id) => dispatch => dispatch(___del(which, id))
    .then(doNothing) // TODO: refresh anything like list?
  const _del = wrapPopWait(TXT_DELETING, __del)
  const _save = ({ which, id, isNew }, changes, data) => (dispatch, getState) => {
    const param = urlParamGetter(which, id, isNew)
    const { ajax, key } = save
    dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
    return dispatch(async(ajax(param, isNew ? data : changes), adminAsyncMap[key], param))
  }
  return {
    activate: wrapPopWait(
      TXT_SYNCING,
      (which, id, active) => _save({ which, id }, { id, active: !active })
    ),
    edit: editAsync(edit, onLoad),
    remove: (which, id) => (dispatch, getState) => dispatch(optionalConfirm(
      deleteConfirmText(which, id, remove.confirmationTextGetter, getState)
    )).then(() => dispatch(_del(which, id))),
    save: wrapPopWait(
      save.text ? save.text : TXT_SAVING,
      (...args) => (dispatch, getState) => dispatch(_save(...args))
        .then(res => {
          if (typeof save.success === 'function') {
            res = save.success(res)
          }
          if (typeof save.afterPathGetter === 'function') {
            dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
            return dispatch(push(save.afterPathGetter(getState)))
              .then(() => res)
          }
          dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
          return res
        })
    )
  }
}

export const {
  activate,
  edit: adminEdit,
  remove: deleteItem,
  save
} = createCommonAdminAsyncs(
  getUrlIds,
  onceOnload,
  adminAsyncMap,
  {
    remove: {
      ajax: deleteAdminReceiptgreetings,
      key: keyAdminDeleteReceiptgreetings
    },
    edit: {
      dataGetter: ({ id, isNew, which }, getState) => {
        if (isNew) {
          return initData[which]
        }
        return adminReceiptByIdSelector(getState(), { tab: which })[id]
      },
      starter: updateAdminStartEdit
    },
    save: {
      afterPathGetter: getState => {
        const match = matchParamMemo(getState())
        let tab
        if (match && match.params) {
          tab = match.params[0]
        }
        return toSelectPath(pathParams(tab))
      },
      ajax: postAdminReceiptgreetings,
      key: keyAdminSaveReceiptgreetings
    }
  }
)

export const resolveUploadedFeedbackFile = (
  formData,
  uploadType
) => new Promise((resolve, reject) => {
  let request
  if (uploadType === UPLOAD_TYPE_IMAGE) {
    request = postAdminReceiptgreetingsFeedbacksResponseimages
  } else {
    request = postAdminReceiptgreetingsFeedbacksResponsetemplates
  }
  request(formData)
    .then(file => { resolve(file) })
    .catch(err => { reject(err) })
})

// createEditFieldUpdater creates updater that allow value to support function
// type.
export const createEditFieldUpdater = (updater, selector) => {
  const updaterWithFunctionValue = (field, operand, value) => (
    dispatch,
    getState
  ) => dispatch(updater({
    field,
    operand,
    value: value(selector(getState())[field])
  }))
  return (field, operand, value) => {
    if (typeof value === 'function') {
      return updaterWithFunctionValue(field, operand, value)
    }
    return updater({ field, operand, value })
  }
}

export const setAdminEditField = createEditFieldUpdater(
  updateAdminEditField,
  adminEditDataMemo
)
