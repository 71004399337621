import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import update from 'immutability-helper';
import {
	keyChangeAcceptCall
	, keyGetAcceptCall
	, keyGetOutboundPhones
	, keyGetAllSipAgents
	, keyGetActiveCalls
	, keyGetSipNumberInUse
} from '../constants/keys';
import {
	CALL_AGENTS_CHANGES
	, CALL_AUTO_OPEN_ERRAND_TICKER
	, CALL_CHANGE_AGENT_SELECTION
	, CALL_CHANGE_FORWARDEE_INDEX
	, CALL_PHONE_AREA
	, CALL_RESET_TRANSFER
	, CALL_RESET_TWILIO_STATUS
	, CALL_SHOW_HIDE_POPUP
	, CALL_UPDATE_ACCEPT
	, CALL_UPDATE_AUDIO_INPUT
	, CALL_UPDATE_BUSY
	, CALL_UPDATE_C3_CONNECTED_PHONE
	, CALL_UPDATE_INBOUND_CALL_SID
	, CALL_UPDATE_INBOUND_ERRAND
	, CALL_UPDATE_INBOUND_ERRAND_ID
	, CALL_UPDATE_ME
	, CALL_UPDATE_MY_CALL
	, CALL_UPDATE_MY_EXIST
	, CALL_UPDATE_OUTBOUND_CALL_SID
	, CALL_UPDATE_OUTBOUND_DIAL_BACK
	, CALL_UPDATE_OUTBOUND_ERRAND_ID
	, CALL_UPDATE_OUTBOUND_PHONE_ID
	, CALL_UPDATE_OTHERS
	, CALL_UPDATE_PHONE
	, CALL_UPDATE_READY
	, CALL_UPDATE_REPLACING
	, CALL_UPDATE_TRANSFER
	, CALL_UPDATE_TRANSFER_METHOD
	, CALL_UPDATE_TWILIO_CALL_TYPE
	, CALL_UPDATE_TWILIO_STATUS
	, CALL_UPDATE_TWILIO_TOKEN
	, UPDATE_AVENTA_CONN
	, UPDATE_AVENTA_SESSION
	, CALL_UPDATE_AVENTA_STATUS
	, CALL_UPDATE_SIP_TRANSFER_STATUS
	, CALL_SIP_INCOMING_ONGOING
	, SIP_UPDATE_RECORDING_STATE
	, SIP_UPDATE_XFER_REF
	, SIP_UPDATE_ERRAND_ID
	, SIP_UPDATE_AGENT_LIST
	, SIP_SHOW_AGENT_LIST
	, SIP_SHOW_DTMF_KEYPAD
	, SIP_SET_TRANSFER_TARGET
	, SIP_SET_TRANSFER_TARGET_ID
	, SIP_RESET_CALL_TIMER
	, SIP_SHOW_INCOMING_POPUP
	, SIP_SHOW_OUTGOING_POPUP
	, SIP_SAVE_OUTGOING_AVATAR
	, SIP_TRANSFER_MODE
	, SIP_UPDATE_TRANSFER_DATA
	, SIP_UPDATE_EXTERNAL_TRANSFER_KEYPAD
	, SIP_MAKE_CALL_FROM_ERRAND
	, SIP_UPDATE_SNOOP_STRING
} from '../constants/constants';
import {
	UNSELECT
	, emptyArray
	, emptyObject
} from '../../common/v5/constants';
import {
	AE_CONNECT
	, AE_NEW_LOGIN
	, AE_REMOVED
	, AE_STATUS
	, AS_UNKNOWN
	, AS_CONFIRM_NO
	, AS_CONFIRM_YES
	, CALLS_NO_TOKEN
	, CALLT_UNKNOWN
	, RS_CHCK_BRWSR
	, RS_CHECKING_ACCEPT
	, RS_CONNECTING_SERVER
	, RS_FAILED_SERVER
	, RS_READY
	, SIP_DISCONNECTED
	, TM_AGENT_ID,
	COLD_TRANSFER
} from '../../common/v5/callConstants';
import {
	actionReducerCreator
	, asyncDoneActionType
	, asyncFailActionType
	, asyncRequestActionType
	, asyncInitState
	, createReducer
	, createReducerSubBranch
	, defAsyncReducer
	, getStateName
	, tickerTimerActionReducerCreator
} from '../util';
import { autoOpenErrand, callMap, stateName } from '../selectors/call';

const defState = {
		typ: CALLT_UNKNOWN
		, st: CALLS_NO_TOKEN
		, conn: null
		, dvc: null
	}
	, defSipState = {
		typ: CALLT_UNKNOWN
		, st: SIP_DISCONNECTED
		, isRecording: false
		, transferredRefId: ""
		, currentSipErrandId: 0
		, conn: null
		, dvc: null
		, agentList: []
		, showAgentList: false
		, showDtmfKeypad: false
		, currentTransferMode: COLD_TRANSFER
		, isExternalTransfer: false
		, transferSipLine: SIP_DISCONNECTED
		, incomingOngoing: false
		, sipCallTimer: 0
		, currentTransferTargetNumber: ""
		, sipTargetAgentId: 0
		, showExternalTransferKeypad: false
		, sipCallFromErrand: false
		, snoopDisplayString: ""
		, sipTransferData: null
	}
	, defStatus = {twilio: defState, sip: defSipState}
	, defSipIncoming = {show: false, data: "", avatar: null}
	, defSipOutgoing = {show: false, data: "", avatar: null}
	, notReadyState = {id: 0, tokens: {twilio: ""}}
	, idleTransfer = {inProgress: false}
	, [
		initAutoOpenErrand
		, startAutoOpenErrandTickerAction
		, stopAutoOpenErrandTickerAction
		, autoOpenErrandTickerReducer
	] = tickerTimerActionReducerCreator(
		CALL_AUTO_OPEN_ERRAND_TICKER
		, state => autoOpenErrand(state)
	)
	;
export const startAutoOpenErrandTicker = startAutoOpenErrandTickerAction
	, stopAutoOpenErrandTicker = stopAutoOpenErrandTickerAction
	;
export const uiInitState = {
		accept: AS_UNKNOWN
		, allowOut: false
		, audioInput: null
		, autoOpenErrand: initAutoOpenErrand
		, busy: false
		, callTransferTo: null
		, inboundCallSid: ""
		, inboundErrandId: 0
		, inboundErrandAreaId: 0
		, internal: {
			agents: emptyArray
			, inbound: emptyArray
			, outbound: emptyArray
			, conference: emptyArray
		}
		, me: notReadyState
		, outboundCallSid: ""
		, outboundDialBack: false
		, outboundErrandId: 0
		, others: emptyArray
		, phone: "" // client phone number
		, phoneC3: "" // phone connected to c3 that client call to
		, phoneMailOrigin: 0
		, ready: RS_CHCK_BRWSR
		, replacing: false
		, selectedForwardeeIndex: UNSELECT
		, selectedOutboundPhoneId: UNSELECT
		, selectedTransfereeAgentId: UNSELECT
		, showCallPopup: false
		, showSipIncomingPopup: defSipIncoming
		, showSipOutgoingPopup: defSipOutgoing
		, status: defStatus
		, transfer: idleTransfer
		, transferMethod: TM_AGENT_ID
	}
	;
const createMonoUpdater = field => mono => ({[field]: {$set: mono}});

const [ actionMap, reducerMap ] = actionReducerCreator([
	[CALL_CHANGE_FORWARDEE_INDEX, createMonoUpdater("selectedForwardeeIndex")]
	, [CALL_RESET_TWILIO_STATUS, () => ({ status: { twilio: { $set: defState } } })]
	, [CALL_RESET_TRANSFER, () => ({
		selectedForwardeeIndex: {$set: UNSELECT}
		, selectedTransfereeAgentId: {$set: UNSELECT}
		, transfer: {$set: idleTransfer}
		, transferMethod: {$set: TM_AGENT_ID}
	})]
	, [CALL_UPDATE_ACCEPT, accept => ({accept: {$set: accept}})]
	, [CALL_UPDATE_AUDIO_INPUT, input => ({ audioInput: { $set: input } })]
	, [CALL_UPDATE_BUSY, busy => ({busy: {$set: busy}})]
	, [CALL_UPDATE_INBOUND_CALL_SID, callSid => ({inboundCallSid: {$set: callSid}})]
	, [CALL_UPDATE_INBOUND_ERRAND, ({ errand, area }) => {
		const result = {};
		if (typeof errand === "number") {
			result.inboundErrandId = {$set: errand};
		}
		if (typeof area === "number") {
			result.inboundErrandAreaId = {$set: area};
		}
		return result;
	}]
	, [CALL_UPDATE_INBOUND_ERRAND_ID, id => ({inboundErrandId: {$set: id}})]
	, [CALL_UPDATE_ME, me => ({me: {$set: me}})]
	, [CALL_UPDATE_MY_CALL, call => ({me: {call: {$set: call}}})]
	, [CALL_UPDATE_MY_EXIST, exist => ({me: {exist: {$set: exist}}})]
	, [CALL_UPDATE_OUTBOUND_CALL_SID, callSid => ({outboundCallSid: {$set: callSid}})]
	, [CALL_UPDATE_OUTBOUND_DIAL_BACK, dialBack => ({outboundDialBack: {$set: dialBack}})]
	, [CALL_UPDATE_OUTBOUND_ERRAND_ID, id => ({outboundErrandId: {$set: id}})]
	, [CALL_UPDATE_OUTBOUND_PHONE_ID, id => ({selectedOutboundPhoneId: {$set: id}})]
	, [CALL_UPDATE_OTHERS, others => ({others: {$set: others}})]
	, [CALL_UPDATE_PHONE, ({ number, mailOrigin }) => {
		const result = {};
		if (typeof number === "string") {
			result.phone = {$set: number};
		}
		if (typeof mailOrigin === "number") {
			result.phoneMailOrigin = {$set: mailOrigin};
		}
		return result;
	}]
	, [CALL_UPDATE_C3_CONNECTED_PHONE, createMonoUpdater("phoneC3")]
	, [CALL_UPDATE_READY, ready => ({ready: {$set: ready}})]
	, [CALL_UPDATE_REPLACING, replace => ({replacing: {$set: replace}})]
	, [CALL_UPDATE_TRANSFER, transfer => ({transfer: {$set: transfer}})]
	, [CALL_UPDATE_TRANSFER_METHOD, createMonoUpdater("transferMethod")]
	, [CALL_UPDATE_TWILIO_CALL_TYPE, typ => ({status: {twilio: {typ: {$set: typ}}}})]
	, [CALL_UPDATE_TWILIO_STATUS, st => ({status: {twilio: {st: {$set: st}}}})]
	, [CALL_UPDATE_TWILIO_TOKEN, token => ({me: {tokens: {twilio: {$set: token}}}})]
	, [CALL_UPDATE_AVENTA_STATUS, st => ({status: {sip: {st: {$set: st}}}})]
	, [CALL_UPDATE_SIP_TRANSFER_STATUS, st => ({status: {sip: {transferSipLine: {$set: st}}}})]
	, [CALL_SIP_INCOMING_ONGOING, st => ({status: {sip: {incomingOngoing: {$set: st}}}})]
	, [SIP_UPDATE_RECORDING_STATE, data => ({status: {sip: {isRecording: {$set: data.isRecording}}}})]
	, [SIP_UPDATE_TRANSFER_DATA, data => ({status: {sip: {sipTransferData: {$set: data.sipTransferData}}}})]
	, [SIP_UPDATE_XFER_REF, data => ({status: {sip: {transferredRefId: {$set: data.transferredRefId}}}})]
	, [SIP_UPDATE_ERRAND_ID, data => ({status: {sip: {currentSipErrandId: {$set: data.currentSipErrandId}}}})]
	, [SIP_UPDATE_AGENT_LIST, data => ({status: {sip: {agentList: {$set: data.agentList}}}})]
	, [SIP_SHOW_AGENT_LIST, data => ({status: {sip: {showAgentList: {$set: data.showAgentList}}}})]
	, [SIP_SHOW_DTMF_KEYPAD, data => ({status: {sip: {showDtmfKeypad: {$set: data.showDtmfKeypad}}}})]
	, [SIP_SET_TRANSFER_TARGET, data => ({status: {sip: {currentTransferTargetNumber: {$set: data.currentTransferTargetNumber}}}})]
	, [SIP_SET_TRANSFER_TARGET_ID, data => ({status: {sip: {sipTargetAgentId: {$set: data.currentTargetId}}}})]
	, [SIP_RESET_CALL_TIMER, data => ({status: {sip: {sipCallTimer: {$set: data.sipCallTimer}}}})]
	, [SIP_TRANSFER_MODE, data => ({status: {sip: {
		currentTransferMode: {$set: data.currentTransferMode}
		, isExternalTransfer: {$set: data.isExternalTransfer}
		}}})]
	, [UPDATE_AVENTA_SESSION, sess => ({status: {sip: {dvc: {$set: sess}}}})]
	, [UPDATE_AVENTA_CONN, conn => ({status: {sip: {conn: {$set: conn}}}})]
	, [SIP_UPDATE_SNOOP_STRING, data => ({status: {sip: {snoopDisplayString: {$set: data.snoopDisplayString}}}})]
	, [SIP_UPDATE_EXTERNAL_TRANSFER_KEYPAD, data => ({status: {sip: {showExternalTransferKeypad: {$set: data.showExternalTransferKeypad}}}})]
	, [SIP_MAKE_CALL_FROM_ERRAND, data => (
		{
			status: {
				sip: {
					sipCallFromErrand: {$set: data.sipCallFromErrand}
					, currentSipErrandId: {$set: data.errandId}
				}
			},
			outboundErrandId: {$set: data.errandId}
		}
		)]
]);

export const callActionMap = actionMap;

const [ _phonesActionMap, phonesReducerMap ] = actionReducerCreator([
	[CALL_PHONE_AREA, ({ phone, area }) => ({[phone]: {$set: area}})]
]);

export const phonesActionMap = _phonesActionMap;

const done = key => asyncDoneActionType(callMap[key]);

const fail = key => asyncFailActionType(callMap[key]);

const request = key => asyncRequestActionType(callMap[key]);

export function searchOthers(others, id, res) {
	res.found = false;
	$.each(others, (i, v) => {
		if (v.id == id) {
			res.found = true;
			res.index = i;
			return false;
		}
	});
	return res;
}

function othersReducer(state, { payload: data }) {
	const { others } = state
		, res = {}
		;
	searchOthers(others, data.id, res);
	const { found, index } = res;
	if (data.type === AE_NEW_LOGIN) {
		const newAgent = {
			id: data.id
			, name: data.name
			, status: data.status
			, statusId: data.statusId
			, connect: data.connect
		};
		if (!found) {
			return update(state, {others: {$push: [newAgent]}});
		}
		return update(state, {others: {$splice: [[
			index
			, 1
			, update(others[index], {$merge: newAgent})
		]]}});
	} else if (!found) {
		console.log("dbg: inconsistent agent list:", data);
		return state;
	}
	if (data.type === AE_REMOVED) {
		const { selectedTransfereeAgentId: id } = state
			, updater = {others: {$set: update(
				others
				, {$splice: [[index, 1]]}
			)}}
			;
		if (id && parseInt(id, 10) === data.id) {
			// agent already disconnected, so should not selecting
			// him/her.
			console.log("dbg: remove agent selection.");
			updater.forwardTo = {$set: UNSELECT};
		}
		return update(state, updater);
	} else if (data.type === AE_CONNECT) {
		return update(state, {others: {$set: update(others, {$splice: [[
			index
			, 1
			, update(others[index], {$merge: {connect: data.connect}})
		]]})}});
	} else if (data.type === AE_STATUS) {
		return update(state, {others: {$set: update(others, {$splice: [[
			index
			, 1
			, update(
				others[index]
				, {$merge: {status: data.status, statusId: data.statusId}}
			)
		]]})}});
	} else {
		// TODO: check why has unhandle event calling
		console.log("dbg: unhandle agent event:", data);
	}
	return state;
}

// TODO: refactor this very common function for other reducers to use.
const simpleSetReducer = field =>
	(state, { payload }) => update(state, {[field]: {$set: payload}});

const createReadyReducer = ready => state => update(
	state
	, {ready: {$set: ready}}
);

const updateReadyToFailedServer = createReadyReducer(RS_FAILED_SERVER);

const updateReadyToCheckingAccept = createReadyReducer(RS_CHECKING_ACCEPT);

const updateReadyToConnectingServer = createReadyReducer(RS_CONNECTING_SERVER);

const socketReducers = {
	// [WS_EVENT]: state => state
};

function acceptBoolToInt(accept) {
	if (accept) {
		return AS_CONFIRM_YES;
	}
	return AS_CONFIRM_NO;
}

function acceptChangeReady(accept) {
	if (accept) {
		return RS_CONNECTING_SERVER;
	}
	return RS_READY;
}

function updateAccept(state, accept) {
	return update(state, {
		accept: {$set: acceptBoolToInt(accept)}
		, ready: {$set: acceptChangeReady(accept)}
	});
}

function updateSipIncomingPopup(state, { payload: data }) {
	return update(state, {showSipIncomingPopup: {
		show: {$set: data.show}
		, data: {$set: data.data}
		, avatar: {$set: data.avatar}
	}});
}

function updateSipOutgoingPopup(state, { payload: data }) {
	return update(state, {showSipOutgoingPopup: {
		show: {$set: data.show}
		, data: {$set: data.data}
	}});
}

function updateSipOutgoingAvatar(state, { payload: avatar}) {
	return update(state, {showSipOutgoingPopup: {
		avatar: {$set: avatar}
	}});
}

function createUpdateAcceptReducer(getter) {
	return (state, { payload }) => updateAccept(state, getter(payload));
}

const uiReducers = {
	[CALL_AGENTS_CHANGES]: othersReducer
	, [CALL_SHOW_HIDE_POPUP]: simpleSetReducer("showCallPopup")
	, [CALL_CHANGE_AGENT_SELECTION]: simpleSetReducer("selectedTransfereeAgentId")
	, [SIP_SHOW_INCOMING_POPUP]: updateSipIncomingPopup
	, [SIP_SHOW_OUTGOING_POPUP]: updateSipOutgoingPopup
	, [SIP_SAVE_OUTGOING_AVATAR]: updateSipOutgoingAvatar
	, [request(keyChangeAcceptCall)]: updateReadyToCheckingAccept
	, [done(keyChangeAcceptCall)]: createUpdateAcceptReducer(payload => payload.param.accept)
	, [fail(keyChangeAcceptCall)]: updateReadyToFailedServer
	, [request(keyGetAcceptCall)]: updateReadyToCheckingAccept
	, [done(keyGetAcceptCall)]: createUpdateAcceptReducer(payload => payload.data.acceptcall)
	, [fail(keyGetAcceptCall)]: updateReadyToFailedServer
	, ...reducerMap
};

let call = combineReducers({
	socket: createReducer(emptyObject, socketReducers)
	, api: (state = null) => state
	, ui: reduceReducers(
		createReducer(uiInitState, uiReducers)
		, createReducerSubBranch(
			(state, value) => update(state, {autoOpenErrand: {$set: value}})
			, ({ autoOpenErrand }) => autoOpenErrand
			, autoOpenErrandTickerReducer
		)
	)
	, [stateName(keyGetOutboundPhones)]: defAsyncReducer(keyGetOutboundPhones, asyncInitState)
	, [stateName(keyGetAllSipAgents)]: defAsyncReducer(keyGetAllSipAgents, asyncInitState)
	, [stateName(keyGetActiveCalls)]: defAsyncReducer(keyGetActiveCalls, asyncInitState)
	, [stateName(keyGetSipNumberInUse)]: defAsyncReducer(keyGetSipNumberInUse, asyncInitState)
	, phones: createReducer(emptyObject, phonesReducerMap)
});

const globalReducers = {
};

// call = reduceReducers(call, createReducer(initState, globalReducers));

export default call;
