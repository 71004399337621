// PLEASE SPEND SOME TIME ARRANGE IN ALPHANUMERIC ORDER WHEN ADD IN CONSTANTS
// DURING INSERTION.
import {
	CL_OVERVIEW
	, ERRANDS_REPORT
	, EXECUTIVE_REPORT
	, EXPORTS_REPORT
	, ORGANIZATION_REPORT
	, SATISFACTION_REPORT
	, SCHEDULED_REPORT
} from '../../common/v5/constants';
import {
	CHANGE_OVERVIEW_GRID
	, CLEAR_CHART_DATA
	, CLEAR_SELECTION_CONFIG_TIME
	, LOAD_STATISTICS_VIEW
	, GET_CHART_IMAGE
	, OPEN_CONFIG_TIME
	, OPEN_CREATE_REPORT
	, OPEN_SCHEDULE_REPORT
	, REMOVE_OVERVIEW_CHART_CONTENT
	, REMOVE_SELECTION_CONFIG_TIME
	, REORDER_CONFIG_TIME
	, REORDER_CREATE_REPORT_KEY
	, REMOVE_SELECTED_REPORT_KEY
	, REORDER_CREATE_REPORT_GROUP
	, REMOVE_SELECTED_REPORT_GROUP
	, RESET_CONFIG_TIME
	, RESET_CREATE_REPORT
	, RESET_SCHEDULE_REPORT
	, RESET_SHARE_REPORT
	, STATISTICS_READY
	, SET_SCHEDULE_TOGGLE
	, SET_SHARE_REPORT
	, SET_SHARE_TOGGLE
	, SHOW_DRILLDOWN_DATA_TABLE
	, STORE_CLEAR_DRILLDOWN_PARAMETERS
	, TOGGLE_CONFIG_TIME
	, TOGGLE_CREATE_REPORT
	, TOGGLE_REPORT_PREVIEW
	, UPDATE_CREATE_REPORT
	, UPDATE_CHART_LAYOUT
	, UPDATE_CHART_PARAMETER
	, UPDATE_CHART_PARAMETERS
	, UPDATE_CONFIG_TIME
	, UPDATE_OVERVIEW_CHART_MAP
	, UPDATE_OVERVIEW_GRID
	, UPDATE_REPORT_PARAM_ID
	, UPDATE_REPORT_COMPARE_PARAM_ID
	, UPDATE_SCHEDULE_REPORT
	, UPDATE_SHARE_PARAM
	, SHOW_STAT_REPORT_COMPARE
} from '../constants/constants';
import { statisticChartsActionMap } from '../reducers/statistics';
import {
	featureChatEnabled
	, findChart
	, getPinReportsById
} from '../selectors/statistics';

export const changeView = (view, data) => ({
	type: LOAD_STATISTICS_VIEW,
	payload: {view, data}
});

export const clearChartDataById = id => ({type: CLEAR_CHART_DATA, payload: {id}});

const showHideDrilldownDataTable = show => ({
	type: SHOW_DRILLDOWN_DATA_TABLE
	, payload: show
});

export const showDrilldownDataTable = () => showHideDrilldownDataTable(true);

function storeOrClearDrilldownParameters(payload) {
	return {type: STORE_CLEAR_DRILLDOWN_PARAMETERS, payload};
}

const clearDrilldownParamters = () => storeOrClearDrilldownParameters(null);

export const storeDrilldownParamters = payload => storeOrClearDrilldownParameters(payload);

export const hideDrilldownDataTableAndClearParameter = () => dispatch => {
	dispatch(showHideDrilldownDataTable(false));
	dispatch(clearDrilldownParamters());
};

export const statisticsReady = () => ({
	type: STATISTICS_READY
});

export const updateReportLayout = layout => ({
	type: UPDATE_CHART_LAYOUT
	, payload: layout
});

export const updateReportParamId = (chart, id) => ({
	type: UPDATE_REPORT_PARAM_ID
	, payload: {chart, id}
});

export const updateReportCompareParamId = (chart, id) => ({
	type: UPDATE_REPORT_COMPARE_PARAM_ID
	, payload: {chart, id}
});

export const updateChartParameter = (id, field, value) => ({
	type: UPDATE_CHART_PARAMETER
	, payload: {id, field, value}
});

export const updateChartParameters = (id, param) => ({
	type: UPDATE_CHART_PARAMETERS
	, payload: {id, param}
});

export const toggleSchedule = toggle => ({
	type: SET_SCHEDULE_TOGGLE
	, payload: toggle
});

export const openScheduleReport = id => ({
	type: OPEN_SCHEDULE_REPORT
	, payload: id
});

export const updateScheduleReport = (field, value) => ({
	type: UPDATE_SCHEDULE_REPORT
	, payload: {field, value}
});

export const resetScheduleReport = () => ({
	type: RESET_SCHEDULE_REPORT
});

export const toggleCreateReport = toggle => ({
	type: TOGGLE_CREATE_REPORT
	, payload: toggle
});

export const openCreateReport = id => ({
	type: OPEN_CREATE_REPORT
	, payload: id
});

export const updateCreateReport = (field, value) => ({
	type: UPDATE_CREATE_REPORT
	, payload: {field, value}
});

export const resetCreateReport = () => ({
	type: RESET_CREATE_REPORT
});

export const toggleReportPreview = (toggle) => ({
	type: TOGGLE_REPORT_PREVIEW
	, payload: toggle
});

export const toggleShare = toggle => ({
	type: SET_SHARE_TOGGLE
	, payload: toggle
});

export const updateShareParam = (field, value) => ({
	type: UPDATE_SHARE_PARAM
	, payload: {field, value}
});

export const clearShareReport = () => ({
	type: RESET_SHARE_REPORT
});

export const toggleConfigTime = toggle => ({
	type: TOGGLE_CONFIG_TIME
	, payload: toggle
});

export const openConfigTimeForm= id => ({
	type: OPEN_CONFIG_TIME
	, payload: id
});

export const updateConfigTime = (field, value) => ({
	type: UPDATE_CONFIG_TIME
	, payload: {field, value}
});

export const reOrderConfigTime = (info) => ({
	type: REORDER_CONFIG_TIME
	, payload: info
});

export const reOrderCreateReportKey = (key) => ({
	type: REORDER_CREATE_REPORT_KEY
	, payload: key
});

export const removeSelectedKey = (index) => ({
	type: REMOVE_SELECTED_REPORT_KEY
	, payload: index
});

export const reOrderCreateReportGroup = (index) => ({
	type: REORDER_CREATE_REPORT_GROUP
	, payload: index
});

export const removeSelectedGroup = (index) => ({
	type: REMOVE_SELECTED_REPORT_GROUP
	, payload: index
});

export const removeConfigTimeSelection = (index) => ({
	type: REMOVE_SELECTION_CONFIG_TIME
	, payload: index
})

export const resetConfigTime = () => ({
	type: RESET_CONFIG_TIME
});

export const clearSelectionConfigTime = () => ({
	type: CLEAR_SELECTION_CONFIG_TIME
});

export const requestChartImageData = (chartLayoutId, start) => ({
	type: GET_CHART_IMAGE
	, payload: {id: chartLayoutId, value: start}
});

export const changeOverviewGrid = (grid, store) => ({
	type: CHANGE_OVERVIEW_GRID
	, payload: {
		cflag
		, chatEnabled: featureChatEnabled(store)
		, createChartLayoutIdFinder: chartMap => (
			chartLayoutId
			, idx
		) => findChart(
			{ [CL_OVERVIEW]: chartMap }
			, getPinReportsById(store)
			, chartLayoutId
			, CL_OVERVIEW
			, idx
		)
		, grid
	}
});

export const {
	[UPDATE_OVERVIEW_CHART_MAP]: updateOverviewChartMap
	, [UPDATE_OVERVIEW_GRID]: updateOverviewGrid
	, [REMOVE_OVERVIEW_CHART_CONTENT]: removeOverviewChartContent
} = statisticChartsActionMap;

export const toggleStatReportCompare = (show) => ({
	type: SHOW_STAT_REPORT_COMPARE
	, payload: {show: show}
});