import {
	// selectShowReplyChannel,
	selectShowReply
} from '../../../redux/actions/errand';

import {
	togglePopAlert,
} from '../hmf';
import {
	async,
	// asyncDoneActionType,
	// mapKeyToActionsAndStateName,
	periodicPoll,
	createActionCreators
	// getAreasIDViaOrgObj
} from '../../util';
import {
	getInternalMessageList,
	getInternalMessage,
	getAgentBook,
	getGroupBook,
	postIMSendMessage,
	getIMCounter,
	postDeleteIMs,
	postUpdateMsgRead,
	postIMSaveDraft,
	getNewIMCount,
	postRestoreIMs,
	getInternalMessageHistory
} from '../../actions/async/ajax';
import * as keys from '../../constants/keys';
import * as action from '../../actions/internalMessages';
import {
	updateAgentCard,
	updateGroupCard,
	updateAgentBook,
	updateGroupBook,
	IMState,
	setSelectAgentList,
	setSelectGroupList
} from '../internalMessages';

import * as constant from '../../../common/v5/constants';
import {
	onceAgents,
	onceAgentGroups
} from '../../actions/async/workflow'
// import {
	// setErrandFullView,
	// setErrandMobileView,
	// setListReady
// } from '../workflow';

import {
	customConfirm,
	enableConfirm
} from './hmf';

export const imKeys = createActionCreators([
	keys.keyGetAgentBook,
	keys.keyGetGroupBook,
	keys.keySendIMAnswer,
	keys.keyDeleteIMs,
	keys.keyUpdateRead,
	keys.keySaveIMDraft,
	keys.keyAgentGroups,
	keys.keyGetInternalMessage,
	keys.fetchInternalMessageList,
	keys.keyIMCounter,
	keys.keyRestoreIMs,
	keys.keyFetchMsgHistory
]);

export const fetchInternalMessages = (fid) =>(dispatch, getState)=>{
	let sortDir = getState().app.internalMessage.ui.sorting.sort_direction;

	// dispatch(action.internalMessageFetching(true)); // not use to avoid flip
	dispatch(async(getInternalMessageList({fid:fid, msortDir:sortDir}), imKeys[keys.fetchInternalMessageList]))
	.then((r) =>{
		dispatch(action.internalMessageFetching(false));
		// dispatch(action.fetchInternalMessagesDone(r));
	})
	.catch(() => {/* get nothing*/});
}

export const fetchInternalMessagesHistory = (id, param) =>(dispatch, getState)=>{
	dispatch(async(getInternalMessageHistory(id, param), imKeys[keys.keyFetchMsgHistory]))
	// .then((r) =>{
	// 	dispatch(action.internalMessageFetching(false));
	// 	// dispatch(action.fetchInternalMessagesDone(r));
	// })
	// .catch(() => {/* get nothing*/});
}

export const saveIMDraft = p=> async(postIMSaveDraft(p), imKeys[keys.keySaveIMDraft]);

export const sendAnswer = p => async(postIMSendMessage(p), imKeys[keys.keySendIMAnswer]);

export const IMCounter = p => async(getIMCounter(p), imKeys[keys.keyIMCounter]);

const warnSendEmptyAnswer = I('Are you sure you wish to send an empty message?'),
	warnCreateEmptyAnswer = I('Are you sure you wish to create an empty message?'),
	warnSaveEmptyAnswer = I('Are you sure you wish to save an empty message?');

export const checkEmptyAnswer = (param, option) => (dispatch, getState) => {
	if(param.update_answer) {
		return Promise.resolve({param, option});
	}
	let text;
	if (option.type=="save") {
		text = warnSaveEmptyAnswer;
	} else {
		text = warnSendEmptyAnswer;
	}
	return dispatch(enableConfirm('optional_confirm', text, {param, option}));
};

export function createUpdateIMObject(obj, option, state) {
	const im = state.app.internalMessage,
		{manual} = option
	let o, inpt, inputs;
	if(obj) {
		o = obj;
	} else {
		o = {};
	}
	if(!manual) {
		inpt = 'inputs';
		o.current_context_name = constant.RPLY_IM;
		o.currentMsgId = im.currentMessage.data.mid;
		o.mid = option.mid;
	} else {
		inpt = 'manualInputs';
		o.current_context_name = constant.CTX_MANUAL_IM;
		o.mid = option.mid;
		o.update_id = 0;
	}
	inputs = im[inpt];

	//for history thread if not a new msg
	if (!im.ui.isNewThread && im.currentMessage.data && (im.currentMessage.data.threadId > 0)) {
		o.thread_id = im.currentMessage.data.threadId
	}

	// below are all common update code
	// o.update_area_id = inputs.area;

	// answer content filtering
	const answer = inputs.update_answer,
		panswer = inputs.plain_answer,
		trim = inputs.plain_answer.replace(/^\s+|\s+$/, '');
	if((trim == "" || (trim.length == 1 && trim.charCodeAt(0) == 8203)) &&
		/^(<div>[\s\xA0]*<\/div>\s*)+$/.test(answer)) {
		o.update_answer = "";
		o.plain_answer = "";
	} else {
		o.update_answer = answer;
		o.plain_answer = panswer;
	}
	o.update_subject = inputs.update_subject;

	// update_to is different from update_from where update_to is to
	// fill up answer.Response.To field and not errand.Mail. update_from
	// on the other hand is filling errand.Mail.From. So, when come to
	// email manual errand update_from and update_to should have the
	// same value.
	let update_to = [], update_from = [], update_group=[]; //, update_cc = [], update_bcc = [], update_forward = [];
	// if(!isSecureUserID) { 		// manual errand always has this flag undefined.
	// there is a little hack for manual errand here as update_to is used
	// for the 'from' field creation manual errand. The manual errand will
	// not be sent out but merely to fill up the update_from later on.
	if(inputs.update_to.length > 0) {
		$.each(inputs.update_to, (i,v) => {
			update_to.push(v.id);
		});
	}
	if(inputs.update_group.length > 0) {
		$.each(inputs.update_group, (i,v) => {
			update_group.push(v.id);
		});
	}
	o.update_from = update_from.join( ',' );
	o.update_to = update_to.join( ',' );
	o.update_group = update_group.join( ',' );
	if(!manual) {
		o.done_date = inputs.done_date !== "" ? inputs.done_date : DEFAULT_NO_DUE_DATE;
	}
	// const ext = state.external; //TODO: get external from React router
	// if(ext.externalManual) {
	// 	o.external_manual = true
	// }
	// if(ext.openedByExternal) {
	// 	o.openedByExternal = ext.openedByExternal;
	// }
	return o;
}


const warnSendEmptySubject = I('Are you sure you want to send without a subject?'),
	warnSaveEmptySubject = I('Are you sure you want to save without a subject?'),
	warnCreateEmptySubject = I('Are you sure you want to create a message without a subject?');

const checkEmptySubject = option => (dispatch, getState) => {
	const im = getState().app.internalMessage, {manual} = option;
	let inpt;
	if(!manual) {
		inpt = 'inputs';
	} else {
		inpt = 'manualInputs';
	}
	if(!im[inpt].update_subject) {
		let text;
		if(!manual) {
			if (option.type=="save") {
			text = warnSaveEmptySubject;
			} else {
				text = warnSendEmptySubject;
			}
		} else {
			text = warnCreateEmptySubject;
		}
		return dispatch(enableConfirm('optional_confirm', text, {option}))
		// return new Promise((resolve, reject) => {
		// 	dispatch(enableConfirm('optional_confirm', text, {option}))
		// 		.then(() => {
		// 			resolve({option});
		// 		})
		// 		.catch(() => {
		// 			reject({option});
		// 		});
		// });
	}
	return Promise.resolve({option});
};

const alertEmptyReciever = I('No receiver agent or group is selected');

const alertBttnOK = 1,
	alertMessageOKButtons = [
		{
			type: alertBttnOK,
			color: 'danger',
			text: I('OK')
		}
	];

const checkEmptyReceiver = option => (dispatch, getState) => {
	const im = getState().app.internalMessage, {manual} = option;
	let inpt;
	if(!manual) {
		inpt = 'inputs';
	} else {
		inpt = 'manualInputs';
	}
	if((!im[inpt].update_to.length > 0) && (!im[inpt].update_group.length > 0)) {
		return new Promise((resolve, reject) => {
			dispatch(customConfirm(alertEmptyReciever, alertMessageOKButtons))
				.then(() => {
					reject({option});
				})
				.catch(() => {
					reject({option});
				});
		});
	}
	return Promise.resolve({option});
};

const checkWithoutSend = option => (dispatch, getState) => dispatch(checkEmptyReceiver(option))
	.then(({option}) => dispatch(checkEmptySubject(option)))
	.then(({option}) => Promise.resolve({param: createUpdateIMObject(undefined, option, getState()), option}))
	.then(({param, option}) => dispatch(checkEmptyAnswer(param, option)))

const sendIM = (param, option) => (dispatch, getState) => {
	return dispatch(sendAnswer(param))
		.then(() => {
			const resultData = getState().app.internalMessage.sendAnswer.data;
			if(resultData == null) {
				return Promise.reject({param, option, err: "No result was returned"});
			}
			if(!resultData.success) {
				return Promise.reject({param, option, err: resultData.message});
			}
			return resultData.internalMessage;
		});
};

const IMCreation = (param, option) => (dispatch, getState) => {
		return dispatch(sendIM(param, option));
};

const createIM = (type, createType) => (dispatch, getState) => {
	const mid = getState().app.internalMessage.ui.isNewIM.imID;
	let manual;
	if (createType == constant.RPLY_IM) {
		manual = false;
	} else {
		manual = true;
	}

	dispatch(IMState(constant.ME_ST_BUSY));
	dispatch(checkWithoutSend({manual}))
		.then(({param, option}) => dispatch(IMCreation(param, option)))
		.then(IMID => {
			if (mid != 0) {
				//  delete msg in draft after sent (if any)
				let selectedFolderID = getState().app.internalMessage.ui.filter.selectedFolder
				dispatch(async(postDeleteIMs({list: mid, folderID: 4}), imKeys[keys.keyDeleteIMs]))
				.then(() =>{
					if (selectedFolderID == 4){ //draft
						dispatch(action.setCurrentIM(0));
						dispatch(action.selectAllMessages(false));
						dispatch(action.changeMessageListSelection(0, true));
					}
					dispatch(getIMCounter(4))  //draft
					.then((r) =>{
						dispatch(action.IMDraftCounter(r["folderCount"],'c'));
					})
				})
			}
			console.log('dbg: created internal message ID', IMID);
			dispatch(IMState(constant.ME_ST_CREATED, IMID));
			dispatch(action.inputTextChanges('update_answer', '', constant.RPLY_IM));  //reset answer for reply
			dispatch(selectShowReply("reply", false));
			dispatch(action.isNewIM(true, 0));
			dispatch(openSingleIM(IMID));
		})
		.catch(e => {
			if(e instanceof Error) {
				console.log('dbg: JS error:', {e});
			}
			dispatch(IMState(constant.ME_ST_IDLE));
			return;
		});
};

export const submitIM = (type, createType) => (dispatch, getState) => {
	const meState = getState().app.internalMessage.ui.manual.state;
	if(meState === constant.ME_ST_IDLE || meState === constant.ME_ST_SAVE) {
		dispatch(createIM(type, createType));
		return;
	}
	//close new IM popup after send. when Done button clicked
	dispatch(action.toggleIMPopup(false));
	dispatch(action.clearIMInputs());
	dispatch(loadList("submitIM"));
	dispatch(action.isNewThread(false))
};

const textMsgSaved = I('Message saved');

export const saveIM = (type, createType) => (dispatch, getState) => {
	let manual, mid;
	const meState = getState().app.internalMessage.ui.manual.state;
	const NewIM = getState().app.internalMessage.ui.isNewIM.newIM
	const editID = getState().app.internalMessage.ui.isNewIM.imID;
	if (NewIM) {
		mid = 0
	} else {
		mid = editID
	}
	if (createType == constant.RPLY_IM) {
		manual = false;
	} else {
		manual = true;
	}
	if(meState !== constant.ME_ST_BUSY) {  //=== constant.ME_ST_IDLE)
		dispatch(checkWithoutSend({manual, type: type, mid: mid}))
		.then(({param, option}) => dispatch(saveIMDraft(param, option)))
		.then(() => {
			const resultData = getState().app.internalMessage.saveDraft.data;
			if(resultData == null) {
				return Promise.reject({type, err: "No result was returned"});
			}
			if(!resultData.success) {
				return Promise.reject({type, err: resultData.message});
			}
			dispatch(action.isNewIM(false, resultData.IMDraft))
			dispatch(IMState(constant.ME_ST_SAVE, resultData.IMDraft));
			dispatch(getIMCounter(4))  //draft
			.then((r) =>{
				dispatch(action.IMDraftCounter(r["folderCount"],'c'));
			})
			dispatch(loadList("saveIM"));
			dispatch(customConfirm(textMsgSaved, alertMessageOKButtons))
			return resultData.IMDraft;
		});

		return;
	}
};

const agentBookAsync = p => async(getAgentBook(p), imKeys[keys.keyGetAgentBook]);
export const fetchAgentBook = (search) => (dispatch, getState) => {
	const state = getState(), im = state.app.internalMessage, ui = im.ui.agentBook;
	let p = {
		searchText: search
	};
	if(ui.show){
		dispatch(agentBookAsync(p))
		.then(rs =>{
			if(rs != null) {
				dispatch(updateAgentBook("list", rs));
			}
		});
	}
};

const groupBookAsync = p => async(getGroupBook(p), imKeys[keys.keyGetGroupBook]);
export const fetchGroupBook = (search) => (dispatch, getState) => {
	const state = getState(), im = state.app.internalMessage, ui = im.ui.groupBook;
	let p = {
		searchText: search
	};
	if(ui.show){
		dispatch(groupBookAsync(p))
		.then(rs =>{
			if(rs != null) {
				dispatch(updateGroupBook("list", rs));
			}
		});
	}
};

export const getAgentInfo = id => (dispatch, getState) => {
	const state = getState(), wf = state.app.workflow, //im = state.app.internalMessage,
		ca = wf.agents.data.agents;
	let agentId = "";
	let agentName = "";
	let agentAvatar = "";
	$.each(ca, (i,v) => {
		if (v.Id == id) {
			agentId = id;
			agentName = v.Name;
			agentAvatar = v.Avatar;
			return false;
		}
	});
	// {"1": "name"}
	let re = {agent: {id: agentId, name: agentName, avatar: agentAvatar}};
	// let re = {agent: {id: agentId, name: agentName}};
	dispatch(updateAgentCard("assign", re));
};

export const getGroupInfo = id => (dispatch, getState) => {
	const state = getState(), wf = state.app.workflow, // im = state.app.internalMessage,
		ca = wf.agentGroups.data.groups;
	let groupId = "";
	let groupName = "";
	$.each(ca, (i,v) => {
		if (v.Id == id) {
			groupId = id;
			groupName = v.Name;
			return false;
		}
	});
	let re = {group: {id: groupId, name: groupName}};
	dispatch(updateGroupCard("assign", re));

};

const openIMCore = (id) => {
	return (dispatch, getState) => {
		return Promise.resolve()
		.then(() => {
			const st = getState();
			let data = st.app.internalMessage.messageList.data
			, internalMsg
			;
			if (data && data.norm) {
				internalMsg = data.norm[id];
			}
			if (!internalMsg) {
				return dispatch(async(getInternalMessage(id), imKeys[keys.keyGetInternalMessage]))
					.catch(err => {
						dispatch(togglePopAlert(I("This message is not found")));
						return Promise.reject()
					});
			} else {
				return dispatch(action.updateCurrentInternalMessage(internalMsg))
			}
		})
		.then(() => {
			const currentMsg = getState().app.internalMessage.currentMessage;
			if (currentMsg.data) {
				dispatch(action.selectFolder(currentMsg.data.folder));
				dispatch(fetchInternalMessages(currentMsg.data.folder));
				dispatch(action.selectAllMessages(false));
				dispatch(action.changeMessageListSelection(id, true));
				dispatch(action.setCurrentIM(id));
				if (currentMsg.data.folder == 1 ) {
					dispatch(action.toggleShowIMReply(true))
				} else {
					dispatch(action.toggleShowIMReply(false))
				}
					// don't update read for draft folder
				// if (currentMsg.data.folder != 4) {
					dispatch(updateMessageRead(id));
					dispatch(action.updateMessageListRead(id));
				// }
				// allow reply only if inbox messge
				if (currentMsg.data.folder == 1 ) {
					dispatch(action.setIMReplyAddressType("to", "reply", "agentBook"));   //for reply inputs state
					dispatch(action.appendReplyAgentAddress());	//set origin as received for reply msg
					//don't reply to group
					// dispatch(setIMReplyAddressType("group", "reply", "groupBook"));
					// dispatch(appendReplyGroupAddress());	//set origin as received for reply msg
					dispatch(action.inputTextChanges('update_answer', '', constant.RPLY_IM));  //reset answer for reply
					dispatch(getNewIMCount())	//New IM
					.then((r) =>{
						dispatch(action.NewIMCounter(r["folderCount"],'c'));
					})
				}
				if (currentMsg.data.threadId >= 0) {
					dispatch(fetchInternalMessagesHistory(
						currentMsg.data.threadId,
							{msgId: currentMsg.data.mid}));
				}

			} else {
				dispatch(action.setCurrentIM(0));
				dispatch(action.selectAllMessages(false));
				dispatch(action.changeMessageListSelection(0, true));
			}
		})
	}
};

export const openSingleIM = id => {
	return (dispatch, getState) => {
		const currentMsg = getState().app.internalMessage.currentMessage;
		if (currentMsg.id != id) {
			dispatch(action.isNewIM(true, 0));
			dispatch(openIMCore(id));
		}
	}
};

const _IMCounters = () => (dispatch, getState) => {
	// console.log('dbg: _IMCounters called');
	//update state
	const p1 = dispatch(getIMCounter(1))  //Inbox
	.then((r) =>{
		dispatch(action.IMCounter(r["folderCount"],'c'));
	});

	// const p2 = dispatch(getIMCounter(2))	//Sent
	// .then((r) =>{
	// 	dispatch(action.IMSentCounter(r["folderCount"],'c'));
	// })

	// const p3 = dispatch(getIMCounter(3))	//Deleted
	// .then((r) =>{
	// 	dispatch(action.IMTrashCounter(r["folderCount"],'c'));
	// })

	// const p4 = dispatch(getIMCounter(4))	//Draft
	// .then((r) =>{
	// 	dispatch(action.IMDraftCounter(r["folderCount"],'c'));
	// })

	const p5 = dispatch(getNewIMCount({autoRefresh: true}))	//New IM
	.then((r) =>{
		dispatch(action.NewIMCounter(r["folderCount"],'c'));
	})
	// const promises = [p1, p2, p3, p4, p5];
	const promises = [p1, p5];
	if(getState().app.internalMessage.currentMessage.id === 0) {  //refresh msg list, dispatch only if no message selected
		const pp =dispatch(fetchInternalMessages(getState().app.internalMessage.ui.filter.selectedFolder));
		promises.push(pp);
	}
	return Promise.all(promises);
};

const countersPollHandlers = periodicPoll(_IMCounters(), constant.TMR_IM_COUNTER);

export const stopIMCounters = countersPollHandlers.stop;

const startIMCounters = countersPollHandlers.start;

export const IMOnLoad = () => (dispatch, getState) => {
	let isIMLoad = getState().app.internalMessage.ui.isIMLoad;
	const dispatches = [
		dispatch(onceAgents()),
		dispatch(onceAgentGroups())
	];
	return $.when(...dispatches)
	.then(() => {
		// if(getState().app.workflow.ui.showMobileView) {
		// 	dispatch(setErrandMobileView(false));
		// }

		//reset default
		if (!isIMLoad) {
			dispatch(action.filterMessageList("Internal messages"));
			dispatch(action.selectFolder(1));
			dispatch(fetchInternalMessages(1))
			dispatch(action.setCurrentIM(0));
			dispatch(action.selectAllMessages(false));
			dispatch(action.changeMessageListSelection(0, true));
			dispatch(action.isIMLoad(true))
		}
		dispatch(getIMCounter(2))	//Sent
		.then((r) =>{
			dispatch(action.IMSentCounter(r["folderCount"],'c'));
		})
		dispatch(getIMCounter(3))	//Deleted
		.then((r) =>{
			dispatch(action.IMTrashCounter(r["folderCount"],'c'));
		})
		dispatch(getIMCounter(4))	//Draft
		.then((r) =>{
			dispatch(action.IMDraftCounter(r["folderCount"],'c'));
		})
		const agentData = getState().app.workflow.agents.data;
		if (agentData != null) {
			const agents = getState().app.workflow.agents.data.agents;
			dispatch(setSelectAgentList(agents))
		}
		const agentGroupData = getState().app.workflow.agentGroups.data;
		if (agentGroupData != null) {
			const groups = getState().app.workflow.agentGroups.data.groups;
			dispatch(setSelectGroupList(groups))
		}

		// add any timer to handle counter
		dispatch(startIMCounters());
});
};

export const NewIMOnLoad = () => (dispatch, getState) => {
	const agentData = getState().app.workflow.agents.data;
	if (agentData != null) {
		const agentList = getState().app.workflow.agents.data.agents;
		dispatch(setSelectAgentList(agentList))
	}
	const groupData = getState().app.workflow.agentGroups.data;
		if (groupData != null) {
			const groupList = getState().app.workflow.agentGroups.data.groups;
			dispatch(setSelectGroupList(groupList))
		}
	};

// const warnMessageNotSave = I("Are you sure you want to discard the unsaved message?");

// const warnBttnSave = 1, warnBttnLeave = 2, warnBttnCancel = 3,
// 	warnMessageNotSaveButtons = [
// 		{
// 			type: warnBttnSave,
// 			color: 'danger',
// 			text: I('Save and leave')
// 		},
// 		{
// 			type: warnBttnLeave,
// 			color: 'secondary',
// 			text: I('Leave without save')
// 		},
// 		{
// 			type: warnBttnCancel,
// 			color: 'secondary',
// 			cancel: true,
// 			text: I('Cancel')
// 		}
// 	];

	// function messageInputsChanged(previous, current, checkCkeditor) {
	// 	if(previous === current) {
	// 		return false;
	// 	} else if(tagsChanged(previous.tags, current.tags)) {
	// 		return true;
	// 	} else if(false && previous.area !== current.area) {
	// 		// NOTE: update area is directly update backend server so no need to be
	// 		// detected by auto save if it is changed.
	// 		return true;
	// 	} else if(previous.done_date !== current.done_date) {
	// 		return true;
	// 	} else if(previous.update_subject !== current.update_subject) {
	// 		return true;
	// 	} else if(previous.update_salutation !== current.update_salutation) {
	// 		return true;
	// 	} else if(previous.update_signature !== current.update_signature) {
	// 		return true;
	// 	} else if(checkboxesChanged(previous.checkboxes, current.checkboxes)) {
	// 		return true;
	// 	} else if(recipientsChanged(previous.update_to, current.update_to)) {
	// 		return true;
	// 	} else if(recipientsChanged(previous.update_cc, current.update_cc)) {
	// 		return true;
	// 	} else if(recipientsChanged(previous.update_bcc, current.update_bcc)) {
	// 		return true;
	// 	} else if(previous.update_lock !== current.update_lock) {
	// 		return true;
	// 	} else if(false && previous.update_priority !== current.update_priority) {
	// 		// NOTE: update priority is directly update backend server so no need to
	// 		// be detected by auto save if it is changed.
	// 		return true;
	// 	} else if(checkCkeditor && isCkeditorChanged(previous, current)) {
	// 		return true;
	// 	} else if(previous.update_question_subject !== current.update_question_subject) {
	// 		return true;
	// 	} else if(questionAttachmentsChange(previous, current)) {
	// 		return true;
	// 	} else if(current.uploaded_attachments && current.uploaded_attachments.length) {
	// 		return true;
	// 	} else if(current.archive_attachments && current.archive_attachments.length) {
	// 		return true;
	// 	} else if(current.library_attachments && current.library_attachments.length) {
	// 		return true;
	// 	} else if(previous.update_external_expert_Answer_attachments.length !== current.update_external_expert_Answer_attachments.length) {
	// 		return true;
	// 	} else if(previous.saved_attachments.length !== current.saved_attachments.length) {
	// 		return true;
	// 	}
	// 	return false;
	// }

// function isInputsChanged(state) {
// 	const im = state.app.internalMessage, lastSave = im.ui.lastSave;
// 	if(!lastSave.dirty) {
// 		return false;
// 	}
// 	const checkCkeditor = !lastSave.ckeditor;
// 	return messgeInputsChanged(lastSave.previous, e.inputs, checkCkeditor);
// }

// const warnForUnsaveChange = () => (dispatch, getState) => new Promise((resolve,
// 	reject) => {
// 	const state = getState(), e = state.app.internalMessage,
// 		cm = e.currentMessage, previousID = cm.id;
// 	if(previousID === 0 || cm.type === constant.EOPR_ANSWERED ||
// 		!isInputsChanged(state)) {
// 		resolve();
// 	} else {
// 		resolve(dispatch(customConfirm(warnMessageNotSave,
// 			warnMessageNotSaveButtons))
// 			.then(({button}) => {
// 				if(button === warnBttnSave) {
// 					return dispatch(saveMessage());
// 				}
// 			}));
// 	}
// });

export const closeMessageView = force => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
			resolve();
		})
		.then(() => dispatch(action.setCurrentIM(0)));
};

export const updateMessageRead = (id) => (dispatch, getState) =>{
	dispatch(async(postUpdateMsgRead({mid: id}), imKeys[keys.keyUpdateRead]))
	.then(() =>{
		dispatch(getIMCounter(1))  //Inbox
		.then((r) =>{
			dispatch(action.IMCounter(r["folderCount"],'c'));
		})
	})
}

export const loadList = () => {
	return (dispatch, getState) => {
		let selectedFolder = getState().app.internalMessage.ui.filter.selectedFolder;
		if(selectedFolder) {
			// dispatch(setListReady(false));
			dispatch(fetchInternalMessages(selectedFolder));
			dispatch(getIMCounter(1))  //Inbox
			.then((r) =>{
				dispatch(action.IMCounter(r["folderCount"],'c'));
			})
			dispatch(getIMCounter(2))  //send
			.then((r) =>{
				dispatch(action.IMSentCounter(r["folderCount"],'c'));
			})
			dispatch(getIMCounter(3))  // Trash
			.then((r) =>{
				dispatch(action.IMTrashCounter(r["folderCount"],'c'));
			})
			dispatch(getIMCounter(4))  //draft
			.then((r) =>{
				dispatch(action.IMDraftCounter(r["folderCount"],'c'));
			})
			dispatch(getNewIMCount())  //new msg
			.then((r) =>{
				dispatch(action.NewIMCounter(r["folderCount"],'c'));
			})

			return; //dispatch(setListReady(true));
		}
	};
};

// call enable confirm and also clear the answer state if confirmation is
// rejected.
const enableSendConfirm = (opr, text, data) => (dispatch, getState) => {
	return dispatch(enableConfirm(opr, text, data))
		.catch(() => {
			console.log('dbg: catch rejection from confirmation');
			// dispatch(clearAnswerState());
		});
};

export const doDeleteMessage = (id, multiple) => (dispatch, getState) =>{
	let state = getState();
	let option = {};
	let dispatchDelete = (id, multiple) =>{
		dispatch(async(postDeleteIMs({list: id, folderID: state.app.internalMessage.ui.filter.selectedFolder}), imKeys[keys.keyDeleteIMs]))
		.then(() =>{
			dispatch(loadList("doDeleteMessage"));
			dispatch(action.setCurrentIM(0));
			dispatch(action.selectAllMessages(false));
			dispatch(action.changeMessageListSelection(0, true));
});
	}
	const F = state.app.workflow.fetchWfSettings.data;
	if(F["mustConfirmDelete"]){
		let confirmMsg = I("Are you sure you want to delete the selected messages?");
		dispatch(enableSendConfirm('deleteMessages', confirmMsg, {option}))
		.then( yn =>{
			if( typeof yn !== 'undefined' ){
						dispatchDelete(id, multiple);
			}
		})
		.catch(err =>{
			console.log("dbg: error catch to delete message");
		});
	}else{
					dispatchDelete(id, multiple);
	}
}

export const doRestoreMessage = (id, multiple) => (dispatch, getState) =>{
	let state = getState();
	dispatch(async(postRestoreIMs({list: id}), imKeys[keys.keyRestoreIMs]))
	.then(() =>{
		dispatch(loadList("doRestoreMessage"));
		dispatch(action.setCurrentIM(0));
		dispatch(action.selectAllMessages(false));
		dispatch(action.changeMessageListSelection(0, true));
	});
}
