import { $ } from '../../../common/globals'
import { deleteAdminSmtp, getAdminSmtp, postAdminSmtp } from './ajax'
import {
  keyAdminDeleteSmtp,
  keyAdminSmtp,
  keyAdminSaveSmtp
} from '../../constants/keys'
import { createBroadcaster, loadOnceCreator } from '../../util'
import { TXT_FETCHING_DATA } from '../../../common/v5/chatbotConstants'
import { initData, toListPath } from '../../../common/v5/smtpConstants'
import { C_AWSSES } from '../../selectors/c3constants'
import {
  adminAsyncMap,
  adminEditDataMemo,
  adminListMemo,
  adminStateByKey
} from '../../selectors/smtp'
import { updateAdminEditField, updateAdminStartEdit } from '../smtp'
import { wrapPopWait } from '../hmf'
import { getByName } from './c3constants'
import {
  createCommonAdminAsyncs,
  createEditFieldUpdater
} from './receiptGreeting'
import { push } from '../../../common/v5/utils'
import { changeAdminView } from '../../../redux/actions/admin'

const loadOnce = (key, ajax) => loadOnceCreator(
  adminAsyncMap,
  key,
  ajax,
  adminStateByKey
)

const onceAdminSmtp = loadOnce(keyAdminSmtp, getAdminSmtp)

const multipleLoadOnces = () => dispatch => {
  const dispatches = [
    dispatch(onceAdminSmtp()),
    dispatch(getByName(C_AWSSES))
  ]
  return $.when(...dispatches)
}

const onceOnloadBase = createBroadcaster(multipleLoadOnces)

export const onceOnload = wrapPopWait(TXT_FETCHING_DATA, onceOnloadBase)

export const onChangeView = (view, url) => (dispatch) => {
  dispatch(push(url)).then(() => dispatch(changeAdminView(view)));
}
const getUrlIds = (_, id, isNew) => {
  if (isNew) {
    return {}
  }
  return { id }
}

export const {
  activate,
  edit: adminEdit,
  remove: deleteItem,
  save
} = createCommonAdminAsyncs(
  getUrlIds,
  onceOnload,
  adminAsyncMap,
  {
    remove: {
      ajax: deleteAdminSmtp,
      key: keyAdminDeleteSmtp
    },
    edit: {
      dataGetter: ({ id, isNew }, getState) => {
        if (isNew) {
          return initData
        }
        return adminListMemo(getState()).byId[id]
      },
      starter: updateAdminStartEdit
    },
    save: {
      afterPathGetter: toListPath,
      ajax: postAdminSmtp,
      key: keyAdminSaveSmtp
    }
  }
)

export const setAdminEditField = createEditFieldUpdater(
  updateAdminEditField,
  adminEditDataMemo
)
