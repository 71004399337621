// Must contains underscore just to differentiate from other constants.

export const DEV_TEST = "@@DEV_TEST"

export const REDUX_SOCKET = '@@C3SOCKET/';

// CALL
export const CALL_AGENTS_CHANGES = 'CALL_AGENTS_CHANGES';
export const CALL_AUTO_OPEN_ERRAND_TICKER = 'CALL_AUTO_OPEN_ERRAND_TICKER';
export const CALL_CHANGE_AGENT_SELECTION = 'CALL_CHANGE_AGENT_SELECTION';
export const CALL_CHANGE_FORWARDEE_INDEX = 'CALL_CHANGE_FORWARDEE_INDEX';
export const CALL_PHONE_AREA = 'CALL_PHONE_AREA';
export const CALL_RESET_TRANSFER = 'CALL_RESET_TRANSFER';
export const CALL_RESET_TWILIO_STATUS = 'CALL_RESET_TWILIO_STATUS';
export const CALL_SHOW_HIDE_POPUP = 'CALL_SHOW_HIDE_POPUP';
export const CALL_UPDATE_ACCEPT = 'CALL_UPDATE_ACCEPT';
export const CALL_UPDATE_AUDIO_INPUT = 'CALL_UPDATE_AUDIO_INPUT';
export const CALL_UPDATE_BUSY = 'CALL_UPDATE_BUSY';
export const CALL_UPDATE_C3_CONNECTED_PHONE = 'CALL_UPDATE_C3_CONNECTED_PHONE';
export const CALL_UPDATE_INBOUND_CALL_SID = 'CALL_UPDATE_INBOUND_CALL_SID';
export const CALL_UPDATE_OUTBOUND_DIAL_BACK = 'CALL_UPDATE_OUTBOUND_DIAL_BACK';
export const CALL_UPDATE_INBOUND_ERRAND = 'CALL_UPDATE_INBOUND_ERRAND';
export const CALL_UPDATE_INBOUND_ERRAND_ID = 'CALL_UPDATE_INBOUND_ERRAND_ID';
export const CALL_UPDATE_OUTBOUND_PHONE_ID = 'CALL_UPDATE_OUTBOUND_PHONE_ID';
export const CALL_UPDATE_ME = 'CALL_UPDATE_ME';
export const CALL_UPDATE_MY_CALL = 'CALL_UPDATE_MY_CALL';
export const CALL_UPDATE_MY_EXIST = 'CALL_UPDATE_MY_EXIST';
export const CALL_UPDATE_OUTBOUND_CALL_SID = 'CALL_UPDATE_OUTBOUND_CALL_SID';
export const CALL_UPDATE_OUTBOUND_ERRAND_ID = 'CALL_UPDATE_OUTBOUND_ERRAND_ID';
export const CALL_UPDATE_OTHERS = 'CALL_UPDATE_OTHERS';
export const CALL_UPDATE_PHONE = 'CALL_UPDATE_PHONE';
export const CALL_UPDATE_READY = 'CALL_UPDATE_READY';
export const CALL_UPDATE_REPLACING = 'CALL_UPDATE_REPLACING';
export const CALL_UPDATE_TRANSFER = 'CALL_UPDATE_TRANSFER';
export const CALL_UPDATE_TRANSFER_METHOD = 'CALL_UPDATE_TRANSFER_METHOD';
export const CALL_UPDATE_TRANSFER_STATE = 'CALL_UPDATE_TRANSFER_STATE';
export const CALL_UPDATE_TWILIO_CALL_TYPE = 'CALL_UPDATE_TWILIO_CALL_TYPE';
export const CALL_UPDATE_TWILIO_STATUS = 'CALL_UPDATE_TWILIO_STATUS';
export const CALL_UPDATE_TWILIO_TOKEN = 'CALL_UPDATE_TWILIO_TOKEN';
export const CALL_UPDATE_AVENTA_STATUS = 'CALL_UPDATE_AVENTA_STATUS';
export const CALL_UPDATE_SIP_TRANSFER_STATUS = 'CALL_UPDATE_SIP_TRANSFER_STATUS';
export const CALL_SIP_INCOMING_ONGOING = 'CALL_SIP_INCOMING_ONGOING';
export const UPDATE_AVENTA_CONN = 'UPDATE_AVENTA_CONN'
export const UPDATE_AVENTA_SESSION = 'UPDATE_AVENTA_SESSION'
export const SIP_SHOW_INCOMING_POPUP = 'SIP_SHOW_INCOMING_POPUP';
export const SIP_SHOW_OUTGOING_POPUP = 'SIP_SHOW_OUTGOING_POPUP';
export const SIP_SHOW_MAKECALL_POPUP = 'SIP_SHOW_MAKECALL_POPUP';
export const SIP_SAVE_OUTGOING_AVATAR = 'SIP_SAVE_OUTGOING_AVATAR';
export const SIP_UPDATE_RECORDING_STATE = 'SIP_UPDATE_RECORDING_STATE';
export const SIP_UPDATE_XFER_REF = 'SIP_UPDATE_XFER_REF';
export const SIP_UPDATE_ERRAND_ID = 'SIP_UPDATE_ERRAND_ID';
export const SIP_UPDATE_AGENT_LIST = 'SIP_UPDATE_AGENT_LIST';
export const SIP_SHOW_AGENT_LIST = 'SIP_SHOW_AGENT_LIST';
export const SIP_SHOW_DTMF_KEYPAD = 'SIP_SHOW_DTMF_KEYPAD';
export const SIP_SET_TRANSFER_TARGET = 'SIP_SET_TRANSFER_TARGET';
export const SIP_RESET_CALL_TIMER = 'SIP_RESET_CALL_TIMER';
export const SIP_TRANSFER_MODE = 'SIP_TRANSFER_MODE';
export const SIP_UPDATE_TRANSFER_DATA = 'SIP_UPDATE_TRANSFER_DATA';
export const SIP_SET_TRANSFER_TARGET_ID = 'SIP_SET_TRANSFER_TARGET_ID';
export const SIP_UPDATE_EXTERNAL_TRANSFER_KEYPAD = 'SIP_UPDATE_EXTERNAL_TRANSFER_KEYPAD';
export const SIP_MAKE_CALL_FROM_ERRAND = 'SIP_MAKE_CALL_FROM_ERRAND';
export const SIP_UPDATE_SNOOP_STRING = 'SIP_UPDATE_SNOOP_STRING';
export const SIP_TRUNK_SET_MATCH = 'SIP_TRUNK_SET_MATCH';

//IVR CALL
export const CALL_UPDATE_IVR_CALLFLOW = 'CALL_UPDATE_IVR_CALLFLOW';
export const CALL_UPDATE_IVR_CALLFLOW_VIEW = 'CALL_UPDATE_IVR_CALLFLOW_VIEW';
export const CALL_REMOVE_IVR_KEY = 'CALL_REMOVE_IVR_KEY';

// CHAT
export const CHAT_ACQUIRE_ERRAND = 'CHAT_ACQUIRE_ERRAND';
export const CHAT_ADD_INTERNAL_COMMENT = 'CHAT_ADD_INTERNAL_COMMENT';
export const CHAT_DELETE_INTERNAL_COMMENT = 'CHAT_DELETE_INTERNAL_COMMENT';
export const CHAT_GET_MCOUNT = 'CHAT_GET_MCOUNT';
export const CHAT_NEW_SESSION_RECEIVED = 'CHAT_NEW_SESSION_RECEIVED';
export const CHAT_ON_ACCEPT_CHAT = 'CHAT_ON_ACCEPT_CHAT';
export const CHAT_ON_ACTIVATE = 'CHAT_ON_ACTIVATE';
export const CHAT_ON_AGENT_PRESENCE = 'CHAT_ON_AGENT_PRESENCE';
export const CHAT_ON_ASSOCIATE_ERRAND = 'CHAT_ON_ASSOCIATE_ERRAND';
export const CHAT_ON_CLIENT_PATHS = 'CHAT_ON_CLIENT_PATHS';
export const CHAT_ON_CONNECT_STATUS = 'CHAT_ON_CONNECT_STATUS';
export const CHAT_ON_DEAD_SESSION = 'CHAT_ON_DEAD_SESSION';
export const CHAT_ON_FINISH_SESSION = 'CHAT_ON_FINISH_SESSION';
export const CHAT_ON_MESSAGE_ACKED = 'CHAT_ON_MESSAGE_ACKED';
export const CHAT_ON_PREVIEW = 'CHAT_ON_PREVIEW';
export const CHAT_ON_QUEUE_SEND = 'CHAT_ON_QUEUE_SEND';
export const CHAT_DO_SEND = 'CHAT_DO_SEND'
export const CHAT_ON_SESSION_INIT = 'CHAT_ON_SESSION_INIT';
export const CHAT_ON_TAGS = 'CHAT_ON_TAGS';
export const CHAT_ON_UNSENT_MESSAGES = 'CHAT_ON_UNSENT_MESSAGES';
export const CHAT_ON_UPDATE_TAGS = 'CHAT_ON_UPDATE_TAGS';
export const CHAT_RESET_NEWMESSAGE_COUNT = 'CHAT_RESET_NEWMESSAGE_COUNT';
export const CHAT_SHOW_SYSTEM_ERROR_MESSAGE = 'CHAT_SHOW_SYSTEM_ERROR_MESSAGE';
export const CHAT_UPDATE_CLIENT_STATUS = 'CHAT_UPDATE_CLIENT_STATUS';
export const CHAT_UPDATE_INTERNAL_COMMENT = 'CHAT_UPDATE_INTERNAL_COMMENT';
export const CHAT_UPDATE_MESSAGE = 'CHAT_UPDATE_MESSAGE';
export const CHAT_UPDATE_NEW_MESSAGE_STATUS = 'CHAT_UPDATE_NEW_MESSAGE_STATUS';
export const SET_CHAT_TRANSLATION = 'SET_CHAT_TRANSLATION';
export const CHAT_MAX_EXTERNAL_DATA_HEADER_DISPLAY = 3;
export const CHAT_VIDEO_AGENT_SHOW = 'CHAT_VIDEO_AGENT_SHOW';
export const CHAT_VIDEO_CLIENT_READY = 'CHAT_VIDEO_CLIENT_READY';
export const CHAT_VIDEO_CALL_INPROGRESS = 'CHAT_VIDEO_CALL_INPROGRESS';
export const CHAT_VIDEO_CALL_FULLSCREEN = 'CHAT_VIDEO_CALL_FULLSCREEN';
export const CHAT_VIDEO_CALL_CLIENT_MUTED = 'CHAT_VIDEO_CALL_CLIENT_MUTED';
export const CHAT_VIDEO_CALL_CLIENT_WEBCAM = 'CHAT_VIDEO_CALL_CLIENT_WEBCAM';
export const CHAT_VIDEO_BG_EXTRACTION_STATUS = 'CHAT_VIDEO_BG_EXTRACTION_STATUS';
export const CHAT_VIDEO_UPDATE_TIMER = 'CHAT_VIDEO_UPDATE_TIMER';
export const CHAT_AGENT_UNABLE_ANSWER_VIDEO = 'CHAT_AGENT_UNABLE_ANSWER_VIDEO';
export const CLIENT_REJECT_VIDEO_CALL = 'CLIENT_REJECT_VIDEO_CALL';
export const CLIENT_VID_CALLING = 'CLIENT_VID_CALLING';
export const CHAT_AGENT_RECORD_VID = 'CHAT_AGENT_RECORD_VID';
export const CHAT_VIDEO_REQUEST_WIP = 'CHAT_VIDEO_REQUEST_WIP';
export const CHAT_AGENT_SCREEN_SHARING = 'CHAT_AGENT_SCREEN_SHARING';
export const CHAT_AGENT_SCREEN_SHARING_OFFER = 'CHAT_AGENT_SCREEN_SHARING_OFFER';
export const CHAT_CLIENT_SCREEN_SHARING = 'CHAT_CLIENT_SCREEN_SHARING';
export const CHAT_CLIENT_SCREEN_SHARING_OFFER = 'CHAT_CLIENT_SCREEN_SHARING_OFFER';
export const CHAT_CLIENT_COBROWSE_OFFER = 'CHAT_CLIENT_COBROWSE_OFFER';
export const CHAT_CLIENT_SELECTED_DISPLAY = 'CHAT_CLIENT_SELECTED_DISPLAY';
export const CHAT_TOGGLE_MOUSE_CONTROL = 'CHAT_TOGGLE_MOUSE_CONTROL';
export const CHAT_TOGGLE_KEYBOARD_CONTROL = 'CHAT_TOGGLE_KEYBOARD_CONTROL';

// CHATBOTS
export const CHATBOTS_ADD_QUESTION = 'CHATBOTS_ADD_QUESTION';
export const CHATBOTS_ADMIN_START = 'CHATBOTS_ADMIN_START';
export const CHATBOTS_CLEAR_ALL_QUESTIONS = 'CHATBOTS_CLEAR_ALL_QUESTIONS';
export const CHATBOTS_EDIT_FIELD = 'CHATBOTS_EDIT_FIELD';
export const CHATBOTS_FINISH_EDIT = 'CHATBOTS_FINISH_EDIT';
export const CHATBOTS_START_EDIT = 'CHATBOTS_START_EDIT';
export const CHATBOTS_LIST = 'CHATBOTS_LIST';
export const CHATBOTS_UPDATE_UI_FIELD = 'CHATBOTS_UPDATE_UI_FIELD';
export const CHATBOTS_RESET_INTENT_LIST = 'CHATBOTS_RESET_INTENT_LIST';

// receipt greetings
export const RECEIPT_GREETINGS_EDIT_FIELD = 'RECEIPT_GREETINGS_EDIT_FIELD';
export const RECEIPT_GREETINGS_FINISH_EDIT = 'RECEIPT_GREETINGS_FINISH_EDIT';
export const RECEIPT_GREETINGS_START_EDIT = 'RECEIPT_GREETINGS_START_EDIT';

// SMTP
export const SMTP_EDIT_FIELD = 'SMTP_EDIT_FIELD';
export const SMTP_FINISH_EDIT = 'SMTP_FINISH_EDIT';
export const SMTP_START_EDIT = 'SMTP_START_EDIT';
export const AWSSES_EDIT_FIELD = 'AWSSES_EDIT_FIELD';
export const AWSSES_FINISH_EDIT = 'AWSSES_FINISH_EDIT';
export const AWSSES_START_EDIT = 'AWSSES_START_EDIT';

// Please keep these alphabetical
export const CHAT_ADD_UNSENT_MESSAGE = 'CHAT_ADD_UNSENT_MESSAGE';
export const CHAT_DONE_GETTING_CANDIDATES = 'CHAT_DONE_GETTING_CANDIDATES';
export const CHAT_ERROR = 'CHAT_ERROR';
export const CHAT_GETTING_CANDIDATES = 'CHAT_GETTING_CANDIDATES'
export const CHAT_IP_BLOCKED = 'CHAT_IP_BLOCKED';
export const CHAT_MESSAGE_SENT = 'CHAT_MESSAGE_SENT';
export const CHAT_SENT_ONE_ATTACHMENT = 'CHAT_SENT_ONE_ATTACHMENT';
export const CHAT_SET_CANDIDATES = "CHAT_SET_CANDIDATES";
export const CHAT_SET_CURRENT_MESSAGE = 'CHAT_SET_CURRENT_MESSAGE';
export const CHAT_UPDATE_TAGS = 'CHAT_UPDATE_TAGS';
export const UPDATE_CHAT_TYPING_STATUS = 'UPDATE_CHAT_TYPING_STATUS';

//Facebook PM message typing on/off
export const CHAT_SEND_TYPING_STATUS = 'CHAT_SEND_TYPING_STATUS';


// Internal chat UI action type
export const CHAT_SHOW_CONVERSATIONS = 'CHAT_SHOW_CONVERSATIONS';
export const CHAT_SHOW_FAVOURITES = 'CHAT_SHOW_FAVOURITES';
export const CHAT_SHOW_ALL = 'CHAT_SHOW_ALL';

// External chat UI actions
export const ECHAT_ANSWER_INVITE = "ECHAT_ANSWER_INVITE";
export const CHAT_ADD_FILE_ARCHIVE = "CHAT_ADD_FILE_ARCHIVE";
export const CHAT_REMOVE_FILE_ARCHIVE = "CHAT_REMOVE_FILE_ARCHIVE";
export const CHAT_ADD_KB_FILE = "CHAT_ADD_KB_FILE";
export const CHAT_REMOVE_KB_FILE = "CHAT_REMOVE_KB_FILE";
export const FORWARD_CHAT_TO_AREA_SUCCEEDED = "FORWARD_CHAT_TO_AREA_SUCCEEDED";
export const FORWARD_CHAT_TO_AREA_STARTED = "FORWARD_CHAT_TO_AREA_STARTED";
export const FORWARD_CHAT_TO_AREA_FAILED = "FORWARD_CHAT_TO_AREA_FAILED";
export const FETCH_CHAT_AREA_CANDIDATES_FAILED = "FETCH_CHAT_AREA_CANDIDATES_FAILED";
export const FETCHED_CHAT_AREA_CANDIDATES = "FETCHED_CHAT_AREA_CANDIDATES";
export const FETCHING_CHAT_AREA_CANDIDATES = "FETCHING_CHAT_AREA_CANDIDATES";
export const FETCHING_CHAT_AGENT_CANDIDATES = "FETCHING_CHAT_AGENT_CANDIDATES";
export const FETCH_CHAT_AGENT_CANDIDATES_FAILED = "FETCH_CHAT_AGENT_CANDIDATES_FAILED";
export const FETCHED_CHAT_AGENT_CANDIDATES = "FETCHED_CHAT_AGENT_CANDIDATES";
export const FORWARD_CHAT_TO_AGENT_STARTED = "FORWARD_CHAT_TO_AGENT_STARTED";
export const FORWARD_CHAT_TO_AGENT_SUCCEEDED = "FORWARD_CHAT_TO_AGENT_SUCCEEDED";
export const CHAT_SET_SEEN = "CHAT_SET_SEEN";
export const CHAT_SET_SUMMARY = "CHAT_SET_SUMMARY";

export const DATA_SYNC = 'DATA_SYNC';

// socket events. use same name and value as the ones in chatd's websocket.go
export const evtSYNC_STATE = "SYNC_STATE";
export const evtCHAT_FORWARDED = "CHAT_FORWARDED";
export const evtCHAT_ERRAND_ADD = "CHAT_ERRAND_ADD";
export const evtCHAT_NEW_MESSAGE =  "CHAT_NEW_MESSAGE";
export const evtDELETE_MESSAGE = "DELETE_MESSAGE";
export const evtCHAT_ANSWER_INVITE = "CHAT_ANSWER_INVITE";
export const evtCHAT_HISTORY_SENT = "CHAT_HISTORY_SENT";
export const evtCHAT_MOVE_UPLOADED = 'CHAT_MOVE_UPLOADED';
export const evtCHAT_SET_TAGS = "CHAT_SET_TAGS";
export const evtCHAT_MESSAGES_SEEN = "CHAT_MESSAGES_SEEN";
export const evtCHAT_TAGS = "CHAT_TAGS";
export const evtCHAT_PARTICIPANTS = 'CHAT_PARTICIPANTS';
export const evtCHAT_UPDATE_IPADDR = 'CHAT_UPDATE_IPADDR';
export const evtCHAT_UNBLOCK_IP = "CHAT_UNBLOCK_IP";
export const evtDATA_SYNC = 'DATA_SYNC';
export const evtAGENT_ACCEPT_CHAT = "AGENT_ACCEPT_CHAT";
export const evtAGENT_ACCEPT_VIDEO_CALL = "AGENT_ACCEPT_VIDEO_CALL"
export const evtAGENT_REJECT_VIDEO_CALL = 'AGENT_REJECT_VIDEO_CALL';
export const evtAGENT_REJECT_SCREEN_SHARE = 'AGENT_REJECT_SCREEN_SHARE';


export const evtERROR = "ERROR"

export const evtREFRESH_DATA = 'REFRESH_DATA';

// Notification event
export const evtNOTIFICATION_MESSAGE = "NOTIFICATION_MESSAGE";
export const evtNOTIFICATION_MESSAGE_DISMISS = "NOTIFICATION_MESSAGE_DISMISS";
export const evtNOTIFICATION_MESSAGE_READ = "NOTIFICATION_MESSAGE_READ";
export const evtNOTIFICATION_MESSAGE_UNREAD = "NOTIFICATION_MESSAGE_UNREAD";
export const evtCHAT_SEND_SOCIAL_MEDIA_EMOTICON = 'CHAT_SEND_SOCIAL_MEDIA_EMOTICON';

// Notification action
export const SHOW_ALL_NOTIFICATION = "SHOW_ALL_NOTIFICATION";
export const SHOW_POPUP_NOTIFICATION = "SHOW_POPUP_NOTIFICATION";
export const HIDE_POPUP_NOTIFICATION = "HIDE_POPUP_NOTIFICATION";
export const ADD_NEW_POPUP_NOTIFICATION = "ADD_NEW_POPUP_NOTIFICATION";
export const SET_NOTIFICATION_TAB = "SET_NOTIFICATION_TAB";
export const SHOW_ANNOUNCEMENT = "SHOW_ANNOUNCEMENT";
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const SET_CURRENT_NOTIFICATION_ID = 'SET_CURRENT_NOTIFICATION_ID';


// These used to be hardcoded in socketMan.js. Make them constants here, to
// manage them better.  Consider these "legacy" socket-io events.
// They correspond to the same socket event ids in chatd.
export const evtCHAT_FINISH_SESSION = 'finish chat session';
export const evtCHAT_REGISTER = 'register';
export const evtCHAT_SET_AGENT = 'set agents';
export const evtCHAT_CHAT_MESSAGE = 'chat message';
export const evtCHAT_INVITED_CHAT_MESSAGE = 'invited chat message';
export const evtCHAT_SUBSCRIBE_AGENT_PRESENCE = 'subscribe agent presence';
export const evtCHAT_AGENT_PRESENCE = 'agent presence';
export const evtCHAT_AGENT_PREVIEW = 'agent preview';
export const evtCHAT_ACCEPT_INTERNAL_CHAT = 'accept internal chat';
export const evtCHAT_PREVIEW = 'preview';
export const evtCHAT_BLOCK_IP = 'block ip';
export const evtCHAT_MESSAGE_ACKED = 'message acked';
export const evtCHAT_DEAD_CHAT_SESSION = 'dead chat session';
export const evtCHAT_MAYBE_DEAD_CHAT_SESSION = 'maybe dead chat session';
export const evtCHAT_CHAT_SESSION_IS_NOT_DEAD = 'chat session is not dead';
export const evtCHAT_CHAT_SESSION_VISITOR_PATH = 'paths';
export const evtCHAT_SET_ERRAND_HIDDEN = 'set chat errand hide';
export const evtCHAT_HAS_NEW_EMOTICON =  "chat message has new emoticon";
export const evtCHAT_WA_SESSION_LOCKED =  "whatsapp chat message locked";

// main app
export const SET_CLEAR_PREVIEW_SAVE_EML_BUSY = 'SET_CLEAR_PREVIEW_SAVE_EML_BUSY';
export const SET_UI_KB_NODE_QUESTION = 'SET_UI_KB_NODE_QUESTION';
export const SET_WORKFLOW_READY = 'SET_WORKFLOW_READY';
export const SHOW_INSERT_PROTECT = 'SHOW_INSERT_PROTECT';
export const ACQUIRING_ERRANDS = 'ACQUIRING_ERRANDS';
export const ACTIVATE_ANSWERED_VIEW = 'ACTIVATE_ANSWERED_VIEW';
export const ADD_DOMAIN_DATA = 'ADD_DOMAIN_DATA';
export const ADD_FILE_ARCHIVE_TO_COLLABORATION = 'ADD_FILE_ARCHIVE_TO_COLLABORATION';
export const AGENT_BUSY_TYPING = 'AGENT_BUSY_TYPING';
export const AGENTEE_BUSY_TYPING = 'AGENTEE_BUSY_TYPING';
export const AGENT_NOT_TYPING = 'AGENT_NOT_TYPING';
export const AGENTEE_NOT_TYPING = 'AGENT_NOT_TYPING';
export const ADD_FILE_ARCHIVE_TO_MANUAL = 'ADD_FILE_ARCHIVE_TO_MANUAL';
export const ALERT_CONFIRM_CUSTOM_BUTTON_CLICK = 'ALERT_CONFIRM_CUSTOM_BUTTON_CLICK';
export const ALERT_CONFIRM_ENABLE_CUSTOM = 'ALERT_CONFIRM_ENABLE_CUSTOM';
export const ALERT_CONFIRM_AUTO_LOGOUT = 'ALERT_CONFIRM_AUTO_LOGOUT';
export const ALERT_CONFIRM_TO_LOGIN = 'ALERT_CONFIRM_TO_LOGIN';
export const APP_READY = 'APP_READY';
export const CHANGE_OTHER_CONTACT_SHOW_IDS = 'CHANGE_OTHER_CONTACT_SHOW_IDS';
export const CHANGE_REPLY_OPTION = 'CHANGE_REPLY_OPTION';
export const CHANGE_SELECTED_FILTER_AREAS = 'CHANGE_SELECTED_FILTER_AREAS';
export const CHAT_SWITCH_OC_ERRAND = 'CHAT_SWITCH_OC_ERRAND';
export const CKEDITOR_CLEAR_INSERTION_TEXT = 'CKEDITOR_CLEAR_INSERTION_TEXT';
export const CKEDITOR_INSERT_TEXT = 'CKEDITOR_INSERT_TEXT';
export const CKEDITOR_REFORMAT_INPUT = 'CKEDITOR_REFORMAT_INPUT';
export const CKEDITOR_REFORMAT_INPUTEE = 'CKEDITOR_REFORMAT_INPUTEE';
export const CLEAR_CHART_DATA = 'CLEAR_CHART_DATA';
export const CLEAR_COLLABORATE_ANS_ATTACHMENT = 'CLEAR_COLLABORATE_ANS_ATTACHMENT';
export const HEADER_ALERT_EDIT_DATA = 'HEADER_ALERT_EDIT_DATA';
export const INVALIDATE_LAST_SAVE_START = 'INVALIDATE_LAST_SAVE_START';
export const CLEAR_SELECTED_MANUAL_ARCHIVE = 'CLEAR_SELECTED_MANUAL_ARCHIVE';
export const DELETE_UPLOADED_MANUAL_ATTACHMENT = 'DELETE_UPLOADED_MANUAL_ATTACHMENT';
export const DELETE_ALL_UPLOADED_MANUAL_ATTACHMENT = 'DELETE_ALL_UPLOADED_MANUAL_ATTACHMENT';
export const ERRAND_OPENED_AND_READY = 'ERRAND_OPENED_AND_READY';
export const ERRAND_ATTACHMENT_LIST_DISPLAY = 'ERRAND_ATTACHMENT_LIST_DISPLAY';
export const HAS_OTHER_OPEN = 'HAS_OTHER_OPEN';
export const INITIAL_OPEN_ERRAND_THREAD_ID = 'INITIAL_OPEN_ERRAND_THREAD_ID';
export const INSERT_COLLABORATE_ANS_ATTACHMENT = 'INSERT_COLLABORATE_ANS_ATTACHMENT';
export const MANUAL_FOOTER_CHECKBOXES = 'MANUAL_FOOTER_CHECKBOXES';
export const MARK_LAST_SAVE_TRIGGERED = 'MARK_LAST_SAVE_TRIGGERED';
export const MARK_EE_LAST_SAVE_TRIGGERED = 'MARK_EE_LAST_SAVE_TRIGGERED';
export const MOVE_OPEN_TO_ASSOCIATE = 'MOVE_OPEN_TO_ASSOCIATE';
export const MOVE_LINKED_TO_ASSOCIATE = 'MOVE_LINKED_TO_ASSOCIATE';
export const NO_SCROLL_TO_QUESTION = 'NO_SCROLL_TO_QUESTION';
export const NORMALIZE_AGENTS = 'NORMALIZE_AGENTS';
export const PREPARE_POST_LINK = 'PREPARE_POST_LINK';
export const SAVE_ERRAND = 'SAVE_ERRAND';
export const SAVE_EE = 'SAVE_EE';
export const SELECT_FORWARD_ALL_HISTORIES = 'SELECT_FORWARD_ALL_HISTORIES';
export const SELECT_INCLUDE_COLLAB_HISTORY = 'SELECT_INCLUDE_COLLAB_HISTORY';
export const SELECT_INCLUDE_ERRAND_HISTORY = 'SELECT_INCLUDE_ERRAND_HISTORY';
export const SELECT_FORWARD_ALL_Q_ATTACHMENTS = 'SELECT_FORWARD_ALL_Q_ATTACHMENTS';
export const CLEAR_INTERNAL_COLLABORATORS = 'CLEAR_INTERNAL_COLLABORATORS';
export const SELECT_ERRAND_IN_ERRAND_LIST = 'SELECT_ERRAND_IN_ERRAND_LIST';
export const SELECT_ALL_ERRANDS_IN_ERRAND_LIST = 'SELECT_ALL_ERRANDS_IN_ERRAND_LIST';
export const SELECT_ALL_ERRANDS_WITHOUT_CHAT = 'SELECT_ALL_ERRANDS_WITHOUT_CHAT';
export const SELECT_AREA_IN_AREA_LIST = 'SELECT_AREA_IN_AREA_LIST';
export const SELECT_ALL_AREAS = 'SELECT_ALL_AREAS';
export const SELECTED_MANUAL_PERSONALIZATION = 'SELECTED_MANUAL_PERSONALIZATION';
export const SET_CURRENT_ERRAND = 'SET_CURRENT_ERRAND';
export const SET_PREVIOUS_ERRAND = 'SET_PREVIOUS_ERRAND';
export const CURRENT_ERRAND_OPENING = 'CURRENT_ERRAND_OPENING';
export const SET_WFSETTINGS_FROM_LOCAL = 'SET_WFSETTINGS_FROM_LOCAL';
export const SET_AGENTDATA_FROM_LOCAL = 'SET_AGENTDATA_FROM_LOCAL';
export const SHOW_HIDE_POST_PREVIEW = 'SHOW_HIDE_POST_PREVIEW';
export const SHOW_HIDE_OCE_POPUP = 'SHOW_HIDE_OCE_POPUP';
export const SYNC_OTHER_CONTACT_ERRANDS = 'SYNC_OTHER_CONTACT_ERRANDS';
export const SYNC_RELATED_ERRANDS = 'SYNC_RELATED_ERRANDS';
export const UPDATE_CURRENT_ERRAND_HAS_EE = 'UPDATE_CURRENT_ERRAND_HAS_EE';
export const SYNC_ACQUIRE_DATA = 'SYNC_ACQUIRE_DATA';
export const SYNC_ACQUIRED_STATE = 'SYNC_ACQUIRED_STATE';
export const SYNC_ACQUIRED_OWNER = 'SYNC_ACQUIRED_OWNER';
export const SYNC_ALL_BASIC_ERRAND_DATA = 'SYNC_ALL_BASIC_ERRAND_DATA';
export const SYNC_EXTENDED_DATA = 'SYNC_EXTENDED_DATA';
export const SYNC_REVIEW_DATA = 'SYNC_REVIEW_DATA';
export const UPLOAD_MANUAL_ERRAND_ATTACHMENT = 'UPLOAD_MANUAL_ERRAND_ATTACHMENT';
export const UPLOAD_MANUAL_CALL_ERRAND_ATTACHMENT = 'UPLOAD_MANUAL_CALL_ERRAND_ATTACHMENT';
export const CHANGE_COLLABORATION_ROLE = 'CHANGE_COLLABORATION_ROLE';
export const CLEAR_COLLABORATION_INPUTS = 'CLEAR_COLLABORATION_INPUTS';
export const CLEAR_MANUAL_ERRAND_INPUTS = 'CLEAR_MANUAL_ERRAND_INPUTS';
export const CLEAR_MANUAL_CALL_INPUTS = 'CLEAR_MANUAL_CALL_INPUTS';
export const COL_CLEAR_SELECTED_ARCHIVE = 'COL_CLEAR_SELECTED_ARCHIVE';
export const COL_CLEAR_SELECTED_LIBRARY = 'COL_CLEAR_SELECTED_LIBRARY';
export const DEF_MANUAL_ACC_ADDR = 'DEF_MANUAL_ACC_ADDR';
export const DELETE_UPLOADED_COL_ATTACHMENT = 'DELETE_UPLOADED_COL_ATTACHMENT';
export const DELETE_SAVED_ATTACHMENT = 'DELETE_SAVED_ATTACHMENT';
export const DELETE_SAVED_COL_ATTACHMENT = 'DELETE_SAVED_COL_ATTACHMENT';
export const DELETE_SAVED_INTERNAL_COMMENT_ATTACHMENT = 'DELETE_SAVED_INTERNAL_COMMENT_ATTACHMENT';
export const DELETE_UPLOADED_INTERNAL_COMMENT_ATTACHMENT = 'DELETE_UPLOADED_INTERNAL_COMMENT_ATTACHMENT';
export const DELETE_UPLOADED_MANUAL_INTERNAL_COMMENT_ATTACHMENT = 'DELETE_UPLOADED_MANUAL_INTERNAL_COMMENT_ATTACHMENT';
export const DELETE_NOTE_ATTACHMENT = 'DELETE_NOTE_ATTACHMENT';
export const EXPAND_HIDE_OPTION = 'EXPAND_HIDE_OPTION';
export const RELOAD_CURRENT_EXPERT = 'RELOAD_CURRENT_EXPERT';
export const SELECT_MANUAL_REPLY = 'SELECT_MANUAL_REPLY';
export const SELECT_MANUAL_CALL_REPLY = 'SELECT_MANUAL_CALL_REPLY';
export const SELECT_MANUAL_ERRAND = 'SELECT_MANUAL_ERRAND';
export const SELECT_MANUAL_ERRAND_CREATION = 'SELECT_MANUAL_ERRAND_CREATION';
export const SELECT_MANUAL_CALL_CREATION = 'SELECT_MANUAL_CALL_CREATION';
export const SELECT_MANUAL_SOCIAL_MEDIA_ACCOUNT = 'SELECT_MANUAL_SOCIAL_MEDIA_ACCOUNT';
export const SELECT_REPLY_TOGGLE_SHOW = 'SELECT_REPLY_TOGGLE_SHOW';
export const SELECT_TOGGLE_SORT_ERRAND_LIST = 'SELECT_TOGGLE_SORT_ERRAND_LIST';
export const SELECT_TOGGLE_REPLY_CHANNEL = 'SELECT_TOGGLE_REPLY_CHANNEL';
export const SELECT_TOGGLE_REPLY_ACTION = 'SELECT_TOGGLE_REPLY_ACTION';
export const UPDATE_MANUAL_ERRAND_SAVED_ATTACHMENTS = 'UPDATE_MANUAL_ERRAND_SAVED_ATTACHMENTS';
export const UPLOAD_COLLABORATION_ATTACHMENT = 'UPLOAD_COLLABORATION_ATTACHMENT';
export const UPLOAD_INTERNAL_COMMENT_ATTACHMENT = 'UPLOAD_INTERNAL_COMMENT_ATTACHMENT';
export const UPLOAD_MANUAL_INTERNAL_COMMENT_ATTACHMENT = 'UPLOAD_MANUAL_INTERNAL_COMMENT_ATTACHMENT';
export const UPLOAD_CHAT_ATTACHMENT = 'UPLOAD_CHAT_ATTACHMENT';
export const UPLOAD_CUSTOMER_NOTE_ATTACHMENT = 'UPLOAD_CUSTOMER_NOTE_ATTACHMENT';
export const TOGGLE_AGENT_STATUS = 'TOGGLE_AGENT_STATUS';
export const TOGGLE_HEADER_ARROW = 'TOGGLE_HEADER_ARROW';
export const TOGGLE_SHOW_RECIPIENTS = 'TOGGLE_SHOW_RECIPIENTS';
export const TOGGLE_SHOW_REPLY_TOOLBAR = 'TOGGLE_SHOW_REPLY_TOOLBAR';
export const TOGGLE_SHOW_REPLY_ASSIST = 'TOGGLE_SHOW_REPLY_ASSIST';
export const TOGGLE_SHOW_SUBJECT = 'TOGGLE_SHOW_SUBJECT';
export const TOGGLE_REPLY_OPTION_TAB = 'TOGGLE_REPLY_OPTION_TAB';
export const TOGGLE_ERRAND_FAV_FORWARD_TO_AREA = 'TOGGLE_ERRAND_FAV_FORWARD_TO_AREA';
export const TOGGLE_ERRAND_FORWARD_TO_AREA = 'TOGGLE_ERRAND_FORWARD_TO_AREA';
export const UPDATE_COLLABORATE_CB = 'UPDATE_COLLABORATE_CB';
export const UPDATE_EXPERT_ANS_STATE = 'UPDATE_EXPERT_ANS_STATE';
export const UPDATE_EXPERT_IDS = 'UPDATE_EXPERT_IDS';
export const UPDATE_PRIORITY_SORT = 'UPDATE_PRIORITY_SORT';
export const UPDATE_REPLY_CHECKBOXES = 'UPDATE_REPLY_CHECKBOXES';
export const SELECT_FORWARD_HISTORY = 'SELECT_FORWARD_HISTORY';
export const PRE_SELECT_FORWARD_HISTORY = 'PRE_SELECT_FORWARD_HISTORY';
export const SELECT_EXTERNALFORWARD_ATTACHMENT = 'SELECT_EXTERNALFORWARD_ATTACHMENT';
export const SELECT_EXIST_ATTACHMENT = 'SELECT_EXIST_ATTACHMENT';
export const SELECT_QUESTION_ATTACHMENT = 'SELECT_QUESTION_ATTACHMENT';
export const SELECT_ALL_QUESTION_ATTACHMENT = 'SELECT_ALL_QUESTION_ATTACHMENT';
export const SELECT_HISTORY_ATTACHMENT = 'SELECT_HISTORY_ATTACHMENT';
export const SELECT_REPLY_CHANNEL = 'SELECT_REPLY_CHANNEL';
export const SELECT_COLLAB_REPLY_CHANNEL = 'SELECT_COLLAB_REPLY_CHANNEL';
export const SELECT_TOGGLE_PRIORITY_FILTER = 'SELECT_TOGGLE_PRIORITY_FILTER';
export const SELECT_TOGGLE_AREAS_FILTER = 'SELECT_TOGGLE_AREAS_FILTER';
export const SELECT_TOGGLE_AGENTS_FILTER = 'SELECT_TOGGLE_AGENTS_FILTER';
export const SELECT_TOGGLE_TAGS_FILTER = 'SELECT_TOGGLE_TAGS_FILTER';
export const SET_COLLABORATION_TRANSLATE_TO = 'SET_COLLABORATION_TRANSLATE_TO';
export const SET_ERRAND_COUNTDOWN_TIMER = 'SET_ERRAND_COUNTDOWN_TIMER';
export const CHANGE_CONTEXT = 'CHANGE_CONTEXT';
export const SET_MY_ERRANDS_FOLDER = 'SET_MY_ERRANDS_FOLDER';
export const SET_SELECTED_FOLDER = 'SET_SELECTED_FOLDER';
export const SELECT_TOGGLE_SIDEBAR = 'SELECT_TOGGLE_SIDEBAR';
export const SELECT_COLLAPSE_SIDEBAR = 'SELECT_COLLAPSE_SIDEBAR';
export const SELECT_LAUNCHPAD_LAYOUT = 'SELECT_LAUNCHPAD_LAYOUT';
export const UPDATE_LAUNCHPAD_WIDGET = 'UPDATE_LAUNCHPAD_WIDGET';
export const ADD_LAUNCHPAD_WIDGET = 'ADD_LAUNCHPAD_WIDGET';
export const DELETE_LAUNCHPAD_WIDGET = 'DELETE_LAUNCHPAD_WIDGET';
export const UPDATE_LAUNCHPAD_GRID_LAYOUT = 'UPDATE_LAUNCHPAD_GRID_LAYOUT';
export const ERRAND_TAGS_OPERATION = 'ERRAND_TAGS_OPERATION';
export const INPUT_TEXT_CHANGES = 'INPUT_TEXT_CHANGES';
export const INPUT_TEXT_RESET = 'INPUT_TEXT_RESET';
export const INVALIDATE_DOMAIN_CACHE = 'INVALIDATE_DOMAIN_CACHE';
export const ACQUIRE_ERRAND_OPERATION = 'ACQUIRE_ERRAND_OPERATION';
export const MANUAL_ERRAND_BUSY = 'MANUAL_ERRAND_BUSY';
export const MANUAL_CALL_BUSY = 'MANUAL_CALL_BUSY';
export const MANUAL_ERRAND_MAX_RECIPIENT = -1;
export const MANUAL_CALL_ERRAND_MAX_TO = 1;
export const NORMALIZED_DOMAIN_OPERATIONS = 'NORMALIZED_DOMAIN_OPERATIONS';
export const ERRAND_AREA_OPERATION = 'ERRAND_AREA_OPERATION';
export const SET_SELECTED_AGENT = 'SET_SELECTED_AGENT';
export const SET_SELECTED_TAGS = 'SET_SELECTED_TAGS';
export const SET_FILTERED_TAGS = 'SET_FILTERED_TAGS';
export const SET_FILTERED_AREAS = 'SET_FILTERED_AREAS';
export const RESET_WORKFLOW_FILTER = 'RESET_WORKFLOW_FILTER';
export const XFER_CHANGED_DOMAIN_AREA_DATA = 'XFER_CHANGED_DOMAIN_AREA_DATA';
export const XFER_DOMAIN_BASIC_ERRAND = 'XFER_DOMAIN_BASIC_ERRAND';
export const XFER_DOMAIN_AREA_DATA = 'XFER_DOMAIN_AREA_DATA';
export const XFER_DOMAIN_EXTEND_DATA = 'XFER_DOMAIN_EXTEND_DATA';
export const XFER_DOMAIN_EE_EDIT = 'XFER_DOMAIN_EE_EDIT';
export const XFER_DOMAIN_EE_LIST = 'XFER_DOMAIN_EE_LIST';
export const XFER_DOMAIN_EE_QUERY = 'XFER_DOMAIN_EE_QUERY';
export const XFER_DOMAIN_EE_THREAD = 'XFER_DOMAIN_EE_THREAD';
export const XFER_DOMAIN_ERRAND_PREVIEW = 'XFER_DOMAIN_ERRAND_PREVIEW';
export const XFER_DOMAIN_WF_EXPERT_QUERIES = 'XFER_DOMAIN_WF_EXPERT_QUERIES';
export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW';
export const SET_ERRAND_MOBILE_VIEW = 'SET_ERRAND_MOBILE_VIEW';
export const SET_ERRAND_HEADER_MOBILE_VIEW = 'SET_ERRAND_HEADER_MOBILE_VIEW';
export const SET_ERRAND_OPTION_MOBILE_VIEW = 'SET_ERRAND_OPTION_MOBILE_VIEW';
export const TOGGLE_REPLYBOX_ERRANDS_MENU = 'TOGGLE_REPLYBOX_ERRANDS_MENU';
export const TOGGLE_CHAT_WINDOW = 'TOGGLE_CHAT_WINDOW';
export const UPDATE_WF_PARAMS = 'UPDATE_WF_PARAMS';
export const NOTE_SIZE = 'NOTE_SIZE';
export const SET_LIST_READY = 'SET_LIST_READY';
export const EDIT_ERRAND_NOTES = 'EDIT_ERRAND_NOTES';
export const ALERT_CONFIRM_CLOSED = 'ALERT_CONFIRM_CLOSED';
export const ALERT_CONFIRM_TOGGLE = 'ALERT_CONFIRM_TOGGLE';
export const ERRAND_NOTES_OPERATION = 'ERRAND_NOTES_OPERATION';
export const ERRAND_CONFIRM_CANCEL = 'ERRAND_CONFIRM_CANCEL';
export const ERRAND_CONFIRM_YES = 'ERRAND_CONFIRM_YES';
export const ERRAND_ENABLE_CONFIRM = 'ERRAND_ENABLE_CONFIRM';
export const UPDATE_EXPORT_VALUE = 'UPDATE_EXPORT_VALUE';
export const UPDATE_EXPORT_LIST = 'UPDATE_EXPORT_LIST';
export const UPDATE_INPUT_TAGS = 'UPDATE_INPUT_TAGS';
export const SHOW_GENERATED_EXPORT = 'SHOW_GENERATED_EXPORT';
export const UPDATE_GENERATED_EXPORT = 'UPDATE_GENERATED_EXPORT';
export const TOGGLE_LOCK_TO_ME = 'TOGGLE_LOCK_TO_ME';
export const TOGGLE_POSTPONE_ERRAND = 'TOGGLE_POSTPONE_ERRAND';
export const SET_ERRAND_WF_SETTINGS = 'SET_ERRAND_WF_SETTINGS';
export const TOGGLE_ERRAND_PRIORITY = 'TOGGLE_ERRAND_PRIORITY';
export const TOGGLE_POP_ALERT = 'TOGGLE_POP_ALERT';
export const TOGGLE_POP_WAIT = 'TOGGLE_POP_WAIT';
export const ADD_TOAST_ALERT = 'ADD_TOAST_ALERT';
export const UPDATE_TOAST_LIST = 'UPDATE_TOAST_LIST';
export const TOGGLE_WF_POPUP = 'TOGGLE_WF_POPUP';
export const ERRAND_CLASSIFICATION = 'ERRAND_CLASSIFICATION';
export const CLASSIFICATION_TAGS_OPERATION = 'CLASSIFICATION_TAGS_OPERATION';
export const RECIPIENTS_CHANGE = 'RECIPIENTS_CHANGE';
export const ERRAND_ANSWER_STATE = 'ERRAND_ANSWER_STATE';
export const CONTROL_FORWARD_TO_ACTION = 'CONTROL_FORWARD_TO_ACTION';
export const ERRAND_POST_ANSWER = 'ERRAND_POST_ANSWER';
export const ERRAND_SHOULD_POP_PRINT = 'ERRAND_SHOULD_POP_PRINT';
export const SET_VIEW_SINGLE_ERRAND_ONLY = 'SET_VIEW_SINGLE_ERRAND_ONLY';
export const SET_POST_MESSAGE_CHAT = 'SET_POST_MESSAGE_CHAT';
export const RESET_ERRAND_VIEW = 'RESET_ERRAND_VIEW';
export const TOGGLE_PINTOP_ERRAND = 'TOGGLE_PINTOP_ERRAND';
export const TOGGLE_MENU_DROPDOWN = 'TOGGLE_MENU_DROPDOWN';
export const ERRAND_DUEDATE_OPERATION = 'ERRAND_DUEDATE_OPERATION';
export const CONTROL_ERRAND_MULTIPLE_ACTION = 'CONTROL_ERRAND_MULTIPLE_ACTION';
export const SOCIAL_MEDIA_ERRANDS_ACTIONS = 'SOCIAL_MEDIA_ERRANDS_ACTIONS';
export const SOCIAL_MEDIA_UI_ACTION = 'SOCIAL_MEDIA_UI_ACTION';
export const SOCIAL_MEDIA_MSG_SAVE_ACTION = 'SOCIAL_MEDIA_MSG_SAVE_ACTION';
export const ENABLE_PICK_UP_NEXT = 'ENABLE_PICK_UP_NEXT';
export const SOCIAL_MEDIA_SEND_ACTION = 'SOCIAL_MEDIA_SEND_ACTION';
export const ERRAND_ERROR_NOTIF_POPUP = 'ERRAND_ERROR_NOTIF_POPUP';
export const SELECTED_PERSONALIZATION = 'SELECTED_PERSONALIZATION';
export const SELECTED_PERSONALIZATION_DEFAULT = 'SELECTED_PERSONALIZATION_DEFAULT';
export const TOGGLE_ERRAND_ATTACHMENT_LIST = 'TOGGLE_ERRAND_ATTACHMENT_LIST';
export const TOGGLE_ERRAND_VISITOR_PATH = 'TOGGLE_ERRAND_VISITOR_PATH';
export const UPLOAD_ERRAND_ANSWER_ATTACHMENT = 'UPLOAD_ERRAND_ANSWER_ATTACHMENT';
export const DELETE_UPLOADED_ATTACHMENT = 'DELETE_UPLOADED_ATTACHMENT';
export const SHOW_UPLOADED_ATTACHMENT_BOX = 'SHOW_UPLOADED_ATTACHMENT_BOX';
export const ADD_FILE_ARCHIVE_TO_ANSWER = 'ADD_FILE_ARCHIVE_TO_ANSWER';
export const TOGGLE_FILE_ARCHIVE = 'TOGGLE_FILE_ARCHIVE';
export const CLEAR_SELECTED_ARCHIVE = 'CLEAR_SELECTED_ARCHIVE';
export const TOGGLE_ATTACHMENT_PLUS_MINUS = 'TOGGLE_ATTACHMENT_PLUS_MINUS';
export const SET_TEXT_FILE_PREVIEW_CONTENT = 'SET_TEXT_FILE_PREVIEW_CONTENT';
export const SET_CSV_FILE_PREVIEW_CONTENT = 'SET_CSV_FILE_PREVIEW_CONTENT';
export const SET_ANSWER_TRANSLATION = 'SET_ANSWER_TRANSLATION';
export const SET_NAV_BUTTON_BUSY = 'SET_NAV_BUTTON_BUSY';
export const SELECT_ERRAND_LIBRARY = 'SELECT_ERRAND_LIBRARY';
export const SEARCH_ERRAND_LIBRARY = 'SEARCH_ERRAND_LIBRARY';
export const CLEAR_LIBRARY_SEARCH_TIMEOUT = 'CLEAR_LIBRARY_SEARCH_TIMEOUT';
export const ADD_LIBRARY_FILE_TO_ANSWER = 'ADD_LIBRARY_FILE_TO_ANSWER';
export const ADD_LIBRARY_FILE_TO_MANUAL = 'ADD_LIBRARY_FILE_TO_MANUAL';
export const ADD_LIBRARY_FILE_TO_COLLABORATE = 'ADD_LIBRARY_FILE_TO_COLLABORATE';
export const SELECTED_LIBRARY_QUESTION_TO_ANSWER = 'SELECTED_LIBRARY_QUESTION_TO_ANSWER';
export const CLEAR_SELECTED_LIBRARY = 'CLEAR_SELECTED_LIBRARY';
export const RESET_LIBRARY_SEARCH = 'RESET_LIBRARY_SEARCH';
export const SET_SHOW_LIBRARY_ANSWER = 'SET_SHOW_LIBRARY_ANSWER';
export const SET_LIBRARY_SEARCH_TEXT = 'SET_LIBRARY_SEARCH_TEXT';
export const SET_REWRITE_ANSWER_QUESTION = 'SET_REWRITE_ANSWER_QUESTION';
export const RESET_REWRITE_ANSWER = 'RESET_REWRITE_ANSWER';
export const SHOW_ERRAND_LIBRARY_POPUP = 'SHOW_ERRAND_LIBRARY_POPUP';
export const SHOW_ERRAND_REWRITE_ANSWER_POPUP = 'SHOW_ERRAND_REWRITE_ANSWER_POPUP';
export const UPDATE_REWRITE_ANSWER_STATE = 'UPDATE_REWRITE_ANSWER_STATE';
export const SHOW_ERRAND_LIBRARY_LIST = 'SHOW_ERRAND_LIBRARY_LIST';
export const SHOW_CONTACT_BOOK = 'SHOW_CONTACT_BOOK';
export const SHOW_CONTACT_CARD = 'SHOW_CONTACT_CARD';
export const SHOW_CONTACT_CARD_HISTORY = 'SHOW_CONTACT_CARD_HISTORY';
export const SHOW_CONTACT_CARD_CHANNEL = 'SHOW_CONTACT_CARD_CHANNEL';
export const SET_CONTACT_CARD = 'SET_CONTACT_CARD';
export const SELECT_CONTACT_CARD_ANSWERED_HISTORY = 'SELECT_CONTACT_CARD_ANSWERED_HISTORY';
export const SELECT_CONTACT_CARD_UNANSWERED_HISTORY = 'SELECT_CONTACT_CARD_UNANSWERED_HISTORY';
export const SELECT_CONTACT_CARD_OPEN_ERRAND = 'SELECT_CONTACT_CARD_OPEN_ERRAND';
export const SHOW_COMPANY_INFO = 'SHOW_COMPANY_INFO';
export const TOGGLE_HOTKEYS_HELPER = 'TOGGLE_HOTKEYS_HELPER';
export const STORE_HOTKEYS_ACTIVE = 'STORE_HOTKEYS_ACTIVE';
export const SETUP_HOTKEY_LIST = 'SETUP_HOTKEY_LIST';
export const SET_HOTKEY_ACTIVE = 'SET_HOTKEY_ACTIVE';
export const ACTIVE_HOTKEY_DIALOG = 'ACTIVE_HOTKEY_DIALOG';
export const UPDATE_CONTACT_CARD = 'UPDATE_CONTACT_CARD';
export const SET_CUSTOMER_NOTES = 'SET_CUSTOMER_NOTES';
export const UPDATE_CUSTOMER_NOTE = 'UPDATE_CUSTOMER_NOTE';
export const UPDATE_CONTACT_BOOK = 'UPDATE_CONTACT_BOOK';
export const APPEND_REPLY_ADDRESS = 'APPEND_REPLY_ADDRESS';
export const SET_REPLY_ADDRESS = 'SET_REPLY_ADDRESS';
export const SET_REPLY_ADDRESS_TYPE = 'SET_REPLY_ADDRESS_TYPE';
export const SHOW_CHAT_SOURCE_CONFIG = 'SHOW_CHAT_SOURCE_CONFIG';
export const TOGGLE_CHAT_SOURCE = 'TOGGLE_CHAT_SOURCE';
export const UPDATE_CHAT_CONFIG_STATUS = 'UPDATE_CHAT_CONFIG_STATUS';
export const SET_MANUAL_ERRAND_TRANSLATE_TO = 'SET_MANUAL_ERRAND_TRANSLATE_TO';
export const HANDLE_POPUP_FILTER_BY_HISTORY_ELEMENTS = 'HANDLE_POPUP_FILTER_BY_HISTORY_ELEMENTS';
export const CHANGE_ERRAND_LIST_VIEW_MODE = 'CHANGE_ERRAND_LIST_VIEW_MODE';
export const ERRAND_NOTES_COUNT = 'ERRAND_NOTES_COUNT';
export const UPDATE_REPLY_TO_AND_CHANNEL = 'UPDATE_REPLY_TO_AND_CHANNEL';
export const DELETE_KB_MANUAL_ATTACHMENT = 'DELETE_KB_MANUAL_ATTACHMENT';
export const SET_ERRAND_MESSAGE_TRUNCATE = 'SET_ERRAND_MESSAGE_TRUNCATE';
export const UPDATE_TWEET_BOX_TOGGLE_NUMBER_OF_TWEET = 'UPDATE_TWEET_BOX_TOGGLE_NUMBER_OF_TWEET';
export const TOGGLE_EXTERNAL_DATA = 'TOGGLE_EXTERNAL_DATA';
export const TOGGLE_CHAT_RATING_BOX = 'TOGGLE_CHAT_RATING_BOX';
export const TOGGLE_CLIENT_CHAT_RATING_BOX = 'TOGGLE_CLIENT_CHAT_RATING_BOX';
export const SET_CHAT_SATISFACTION_INPUT = 'SET_CHAT_SATISFACTION_INPUT';
export const RESET_AGENT_SATISFACTION_INPUT = 'RESET_AGENT_SATISFACTION_INPUT';
export const SET_CONTACT_BOOK_UI = 'SET_CONTACT_BOOK_UI';
export const SHOW_ALL_AREA_IN_AREA_FILTER = 'SHOW_ALL_AREA_IN_AREA_FILTER';
export const SELECT_ALL_HISTORY_ATTACHMENT= 'SELECT_ALL_HISTORY_ATTACHMENT';
export const ERRAND_TOGGLE_RIGHT_PANEL = 'ERRAND_TOGGLE_RIGHT_PANEL';
export const ERRAND_TOGGLE_CHAT_SIDEPANEL = 'ERRAND_TOGGLE_CHAT_SIDEPANEL';
export const ERRAND_SET_FOLDER_INDEX = 'ERRAND_SET_FOLDER_INDEX';
export const ERRAND_TOGGLE_RIGHT_SIDEPANEL = 'ERRAND_TOGGLE_RIGHT_SIDEPANEL';
export const TOGGLE_CHAT_REPLY_NAV_DD = 'TOGGLE_CHAT_REPLY_NAV_DD';
export const SELECT_CHAT_REPLY_TABS = 'SELECT_CHAT_REPLY_TABS';
export const SHOW_REPLY_SEARCH_SHORTCUT_DROPDOWN = 'SHOW_REPLY_SEARCH_SHORTCUT_DROPDOWN';
export const SHOW_REPLY_SEARCH_INPUT = 'SHOW_REPLY_SEARCH_INPUT';
export const HIDE_REPLY_SEARCH = 'HIDE_REPLY_SEARCH';
export const INPUT_CK_RESET = 'INPUT_CK_RESET';
export const PM_SUBCRIBE_ERRAND_COUNT = 'PM_SUBCRIBE_ERRAND_COUNT';
export const WHATSAPP_TEMPLATE_POPUP = 'WHATSAPP_TEMPLATE_POPUP';
export const SET_WHATSAPP_TEMPLATE_CONTENT = 'SET_WHATSAPP_TEMPLATE_CONTENT';
export const SAVE_WHATSAPP_TEMPLATE_CODE = 'SAVE_WHATSAPP_TEMPLATE_CODE';
export const SAVE_WHATSAPP_TEMPLATE_ID = 'SAVE_WHATSAPP_TEMPLATE_ID';
export const SHOW_HIDE_ACQUIRE_POPUP = 'SHOW_HIDE_ACQUIRE_POPUP';
export const SELECT_ALL_ACQUIRE_ERRAND = 'SELECT_ALL_ACQUIRE_ERRAND';
export const SELECT_ALL_LINK_ERRAND_MY_ERRAND = 'SELECT_ALL_LINK_ERRAND_MY_ERRAND';
export const SELECT_ALL_LINKED_ERRAND = 'SELECT_ALL_LINKED_ERRAND';
export const SELECT_MORE_ACQUIRE_ERRAND = 'SELECT_MORE_ACQUIRE_ERRAND';
export const DESELECT_ALL_ACQUIRE_ERRAND = 'DESELECT_ALL_ACQUIRE_ERRAND';
export const SET_AREA_RECIPIENT = 'SET_AREA_RECIPIENT';
export const SET_QUICK_REPLY_CONTENT = 'SET_QUICK_REPLY_CONTENT';
export const SET_CRM_EMAILTRIGGER_CONTENT = 'SET_CRM_EMAILTRIGGER_CONTENT';
export const COLLAB_QUERY_THREAD_EXPAND = 'COLLAB_QUERY_THREAD_EXPAND';
export const EXPAND_KB_PANEL = 'EXPAND_KB_PANEL';
export const SET_KB_TREE_PARENT = 'SET_KB_TREE_PARENT';
export const SELECT_TOGGLE_CHAT_SUMMARY = 'SELECT_TOGGLE_CHAT_SUMMARY';
export const SET_SUGGESTED_ANSWER_USED = 'SET_SUGGESTED_ANSWER_USED';
export const FETCH_AGENT_ASSIST_REQUEST = 'FETCH_AGENT_ASSIST_REQUEST';
export const FETCH_AGENT_ASSIST_SUCCESS = 'FETCH_AGENT_ASSIST_SUCCESS';
export const FETCH_AGENT_ASSIST_FAILURE = 'FETCH_AGENT_ASSIST_FAILURE';
export const FETCH_AGENT_ASSIST_INFO = 'FETCH_AGENT_ASSIST_INFO';
export const UPDATE_AGENT_ASSIST_PROMPT = 'UPDATE_AGENT_ASSIST_PROMPT';
export const UPDATE_AGENT_ASSIST_RECEIVED = 'UPDATE_AGENT_ASSIST_RECEIVED';
export const UPDATE_AGENT_ASSIST_RESPONSE = 'UPDATE_AGENT_ASSIST_RESPONSE';
export const FETCH_AGENT_ASSIST_DONE = 'FETCH_AGENT_ASSIST_DONE';

//errand search
export const SET_ERRAND_SEARCH_BOX = 'SET_ERRAND_SEARCH_BOX';
export const SHOW_ERRAND_SEARCH_CROSS_BOX = 'SHOW_ERRAND_SEARCH_CROSS_BOX';
export const GLOBAL_SEARCH_TEXT_CHANGE = 'GLOBAL_SEARCH_TEXT_CHANGE';
export const DO_GLOBAL_SEARCH = 'DO_GLOBAL_SEARCH';
export const HEADER_SEARCH_RESULT_HIDE = 'HEADER_SEARCH_RESULT_HIDE';
export const REPLY_SEARCH_RESULT_HIDE = 'REPLY_SEARCH_RESULT_HIDE';
export const WS_EVENT_GLOBAL_SEARCH = 'WS_EVENT_GLOBAL_SEARCH';
export const evtGLOBAL_SEARCH = 'evtGLOBAL_SEARCH';
export const GLOBAL_SEARCH_FROM_HEADER = 'GLOBAL_SEARCH_FROM_HEADER';
export const GLOBAL_SEARCH_FROM_REPLY = 'GLOBAL_SEARCH_FROM_REPLY';
export const GLOBAL_SEARCH_HEADER_DROP_DOWN= 'GLOBAL_SEARCH_HEADER_DROP_DOWN';
export const GLOBAL_SEARCH_FROM_BODY = 'GLOBAL_SEARCH_FROM_BODY';
export const SET_CURRENT_SEARCH_SIDEBAR_OPTION = 'SET_CURRENT_SEARCH_SIDEBAR_OPTION';
export const UNSET_SEARCH_SIDEBAR_OPTION = 'UNSET_SEARCH_SIDEBAR_OPTION';
export const HANDLE_SEARCH_FILTER_OPTION = 'HANDLE_SEARCH_FILTER_OPTION';
export const HANDLE_SEARCH_RESET_FILTER = 'HANDLE_SEARCH_RESET_FILTER';
export const HANDLE_SEARCH_FILTER_BY_AREA = 'HANDLE_SEARCH_FILTER_BY_AREA';
export const HANDLE_SEARCH_FILTER_BY_AGENT = 'HANDLE_SEARCH_FILTER_BY_AGENT';
export const HANDLE_SEARCH_FILTER_BY_CHANNEL = 'HANDLE_SEARCH_FILTER_BY_CHANNEL';
export const HANDLE_SEARCH_FILTER_BY_TAG = 'HANDLE_SEARCH_FILTER_BY_TAG';
export const HANDLE_TOGGLE_SEARCH_DATE_INTERVAL = 'HANDLE_TOGGLE_SEARCH_DATE_INTERVAL';
export const HANDLE_TOGGLE_SEARCH_ADVANCED_OPT = 'HANDLE_TOGGLE_SEARCH_ADVANCED_OPT';
export const HANDLE_SEARCH_ERRAND_TYPE = 'HANDLE_SEARCH_ERRAND_TYPE';
export const HANDLE_SEARCH_FROM_DATE = 'HANDLE_SEARCH_FROM_DATE';
export const HANDLE_SEARCH_TO_DATE = 'HANDLE_SEARCH_TO_DATE';
export const HANDLE_SEARCH_SHOW_ALL_ITEM = 'HANDLE_SEARCH_SHOW_ALL_ITEM';
export const GLOBAL_SEARCH_INIT_BY_MCAM = 'GLOBAL_SEARCH_INIT_BY_MCAM';
export const HANDLE_SEARCH_PROCESSING = 'HANDLE_SEARCH_PROCESSING';
export const SHOW_WORKFLOW_MULTIPLE_ACTIONS = 'SHOW_WORKFLOW_MULTIPLE_ACTIONS';
export const HEADER_SEARCH_ONGOING = 'HEADER_SEARCH_ONGOING';
export const REPLY_SEARCH_ONGOING = 'REPLY_SEARCH_ONGOING';
export const HANDLE_SEARCH_PAGINATION = 'HANDLE_SEARCH_PAGINATION';
export const HANDLE_FILTER_SEARCH = 'HANDLE_FILTER_SEATCH';
export const FILTER_SEARCH_ERRAND_MY = 'FILTER_SEARCH_ERRAND_MY';
export const FILTER_SEARCH_ERRAND_ALL = 'FILTER_SEARCH_ERRAND_ALL';
export const FILTER_SEARCH_ERRAND_CLOSE = 'FILTER_SEARCH_ERRAND_CLOSE';
export const HANDLE_SEARCH_RESET = 'HANDLE_SEARCH_RESET';
export const SEARCH_TEXT_CLEAR = 'SEARCH_TEXT_CLEAR';
export const LIST_SHOW_HIDE = 'LIST_SHOW_HIDE';
export const SELECT_TOP_LIST = 'SELECT_TOP_LIST';
export const SEARCH_ERRAND = 'errand';
export const SEARCH_COLLABORATION = 'collaboration';
export const SEARCH_AREA = 'area';
export const SEARCH_TAGS = 'tag';
export const SEARCH_KB = 'knowledgebase';
export const SEARCH_CONTACTCARD = 'contactCard';
export const SEARCH_EECONTACT = 'eeContact';
export const SEARCH_CUSTOMERNOTES = 'custNotes';
export const SEARCH_ERRANDNOTES = 'errandNotes';
export const SET_SEARCH_PREVIEW_ID = 'SET_SEARCH_PREVIEW_ID';
export const SELECT_ERRAND_IN_SEARCH_RESULT = 'SELECT_ERRAND_IN_SEARCH_RESULT';
export const SELECT_ALL_ERRANDS_IN_SEARCH_RESULT = 'SELECT_ALL_ERRANDS_IN_SEARCH_RESULT';
export const SELECT_ALL_SEARCH_RESULT='allSelected';
export const FETCH_SUMMARIZED_HISTORY_REQUEST = 'FETCH_SUMMARIZED_HISTORY_REQUEST';
export const FETCH_SUMMARIZED_HISTORY_SUCCESS = 'FETCH_SUMMARIZED_HISTORY_SUCCESS';
export const FETCH_SUMMARIZED_HISTORY_FAILURE = 'FETCH_SUMMARIZED_HISTORY_FAILURE';
// print
export const DISABLE_PRINT = 'DISABLE_PRINT';
export const ENABLE_PRINT_COL_CONVERSATION = 'ENABLE_PRINT_COL_CONVERSATION';
export const ENABLE_PRINT_COL_MESSAGE = 'ENABLE_PRINT_COL_MESSAGE';
export const ENABLE_PRINT_ERRANDS = 'ENABLE_PRINT_ERRANDS';

//exports
export const SET_SELECTED_EXPORT = 'SET_SELECTED_EXPORT';
export const SET_EXPORT_INFORMATION = 'SET_EXPORT_INFORMATION';
export const CANCEL_CURRENT_EXPORT = 'CANCEL_CURRENT_EXPORT';
export const EXPORT_PREVIEW_CLOSE = 'EXPORT_PREVIEW_CLOSE';
export const REMOVE_ERRAND_CATEGORY = 'REMOVE_ERRAND_CATEGORY';
export const REMOVE_SEARCH_TERM = 'REMOVE_SEARCH_TERM';
export const SET_ERRAND_CATEGORY = 'SET_ERRAND_CATEGORY';
export const TOGGLE_EXPORT_POPUP = "TOGGLE_EXPORT_POPUP";
export const UPDATE_EXPORT_CALL_LIST = "UPDATE_EXPORT_CALL_LIST";
export const CALL_EXPORT_CDR_CLOSE = 'CALL_EXPORT_CDR_CLOSE';

//internal messages
export const INTERNAL_MESSAGES_ISFETCHING = 'INTERNAL_MESSAGES_ISFETCHING';
export const FETCH_INTERNAL_MESSAGES_DONE = 'FETCH_INTERNAL_MESSAGES_DONE';
export const FILTER_MESSAGE_LIST_BY = 'FILTER_MESSAGE_LIST_BY';
export const FILTER_FOLDER = 'FILTER_FOLDER';
export const OPEN_CURRENT_INTERNAL_MESSAGE = 'OPEN_CURRENT_INTERNAL_MESSAGE';
export const TOGGLE_IM_POPUP = 'TOGGLE_IM_POPUP';
export const SHOW_AGENT_BOOK = 'SHOW_AGENT_BOOK';
export const SHOW_GROUP_BOOK = 'SHOW_GROUP_BOOK';
export const UPDATE_AGENT_CARD = 'UPDATE_AGENT_CARD';
export const UPDATE_GROUP_CARD = 'UPDATE_GROUP_CARD';
export const UPDATE_AGENT_BOOK = 'UPDATE_AGENT_BOOK';
export const UPDATE_GROUP_BOOK = 'UPDATE_GROUP_BOOK';
export const SET_CURRENT_IM = 'SET_CURRENT_IM';
export const UPDATE_CURRENT_IM = 'UPDATE_CURRENT_IM';
export const SHOW_AGENT_CARD = 'SHOW_AGENT_CARD';
export const IM_BUSY = 'IM_BUSY';
export const CLEAR_IM_INPUTS = 'CLEAR_IM_INPUTS';
// export const IM_OPENED_AND_READY = 'IM_OPENED_AND_READY';
export const IM_COUNTER = 'IM_COUNTER';
export const IM_DRAFT_COUNTER = 'IM_DRAFT_COUNTER';
export const IM_SENT_COUNTER = 'IM_SENT_COUNTER';
export const IM_TRASH_COUNTER = 'IM_TRASH_COUNTER';
export const NEW_IM_COUNTER = 'NEW_IM_COUNTER'
export const SELECT_MESSAGE_IN_MESSAGE_LIST = 'SELECT_MESSAGE_IN_MESSAGE_LIST';
export const SELECT_ALL_MESSAGES_IN_MESSAGE_LIST = 'SELECT_ALL_MESSAGES_IN_MESSAGE_LIST';
export const INPUT_IM_TEXT_CHANGES = 'INPUT_IM_TEXT_CHANGES';
export const SORT_PARAM = 'SORT_PARAM';
export const IM_RECIPIENTS_CHANGE = 'IM_RECIPIENTS_CHANGE';
export const IM_GROUPS_CHANGE='IM_GROUPS_CHANGE'
// export const IM_VALIDATE_RECIPIENT = 'IM_VALIDATE_RECIPIENT';
export const IM_CKEDITOR_REFORMAT_INPUT = 'IM_CKEDITOR_REFORMAT_INPUT';
export const IM_APPEND_AGENT_REPLY_ADDRESS = 'IM_APPEND_AGENT_REPLY_ADDRESS';
export const IM_APPEND_GROUP_REPLY_ADDRESS = 'IM_APPEND_GROUP_REPLY_ADDRESS';
export const IM_SET_REPLY_ADDRESS_TYPE = 'IM_SET_REPLY_ADDRESS_TYPE';
export const IM_APPEND_TO_AGENT = 'IM_APPEND_TO_AGENT'
export const IM_APPEND_TO_GROUP = 'IM_APPEND_TO_GROUP'
export const SELECT_TOGGLE_SORT_MESSAGE_LIST ='SELECT_TOGGLE_SORT_MESSAGE_LIST'
export const TOGGLE_SHOW_IM_REPLY = 'TOGGLE_SHOW_IM_REPLY'
// export const SELECT_TOGGLE_IM_SIDEBAR = 'SELECT_TOGGLE_IM_SIDEBAR';
export const UPDATE_MESSAGE_READ = 'UPDATE_MESSAGE_READ'
export const IS_NEW_IM = 'IS_NEW_IM'
export const SELECT_AGENT_IN_AGENT_LIST = 'SELECT_AGENT_IN_AGENT_LIST'
export const IM_SET_AGENT_LIST = 'IM_SET_AGENT_LIST'
export const IM_UPDATE_SELECTED_AGENT = 'IM_UPDATE_SELECTED_AGENT'
export const SELECT_GROUP_IN_GROUP_LIST = 'SELECT_GROUP_IN_GROUP_LIST'
export const IM_SET_GROUP_LIST = 'IM_SET_GROUP_LIST'
export const IM_UPDATE_SELECTED_GROUP = 'IM_UPDATE_SELECTED_GROUP'
export const IS_IM_LOAD = 'IS_IM_LOAD'
export const IS_NEW_THREAD = 'IS_NEW_THREAD'

// event received from /agent.ws websocket
export const WS_EVENT = 'WS_EVENT';

// statistics
export const CHANGE_OVERVIEW_GRID = 'CHANGE_OVERVIEW_GRID';
export const GET_CHART_IMAGE = 'GET_CHART_IMAGE';
export const LOAD_STATISTICS_VIEW = "LOAD_STATISTICS_VIEW";
export const REMOVE_OVERVIEW_CHART_CONTENT = 'REMOVE_OVERVIEW_CHART_CONTENT';
export const STATISTICS_READY = 'STATISTICS_READY';
export const UPDATE_CHART_LAYOUT = 'UPDATE_CHART_LAYOUT';
export const UPDATE_CHART_PARAMETER = 'UPDATE_CHART_PARAMETER';
export const UPDATE_OVERVIEW_CHART_MAP = 'UPDATE_OVERVIEW_CHART_MAP';
export const UPDATE_OVERVIEW_GRID = 'UPDATE_OVERVIEW_GRID';
export const UPDATE_REPORT_PARAM_ID = 'UPDATE_REPORT_PARAM_ID';
export const UPDATE_REPORT_COMPARE_PARAM_ID = 'UPDATE_REPORT_COMPARE_PARAM_ID';
export const SET_SCHEDULE_TOGGLE = 'SET_SCHEDULE_TOGGLE';
export const SHOW_DRILLDOWN_DATA_TABLE = 'SHOW_DRILLDOWN_DATA_TABLE';
export const STORE_CLEAR_DRILLDOWN_PARAMETERS = 'STORE_CLEAR_DRILLDOWN_PARAMETERS';
export const UPDATE_SCHEDULE_REPORT = 'UPDATE_SCHEDULE_REPORT';
export const RESET_SCHEDULE_REPORT = 'RESET_SCHEDULE_REPORT';
export const SET_SHARE_TOGGLE = 'SET_SHARE_TOGGLE';
export const UPDATE_SHARE_PARAM = 'UPDATE_SHARE_PARAM';
export const OPEN_SCHEDULE_REPORT = 'OPEN_SCHEDULE_REPORT';
export const RESET_SHARE_REPORT = 'RESET_SHARE_REPORT';
export const UPDATE_CHART_PARAMETERS = 'UPDATE_CHART_PARAMETERS';
export const OPEN_CREATE_REPORT = 'OPEN_CREATE_REPORT';
export const TOGGLE_CREATE_REPORT = 'TOGGLE_CREATE_REPORT';
export const UPDATE_CREATE_REPORT = 'UPDATE_CREATE_REPORT';
export const RESET_CREATE_REPORT = 'RESET_CREATE_REPORT';
export const TOGGLE_REPORT_PREVIEW = 'TOGGLE_REPORT_PREVIEW';
export const OPEN_CONFIG_TIME = 'OPEN_CONFIG_TIME';
export const RESET_CONFIG_TIME = 'RESET_CONFIG_TIME';
export const TOGGLE_CONFIG_TIME = 'TOGGLE_CONFIG_TIME';
export const UPDATE_CONFIG_TIME = 'UPDATE_CONFIG_TIME';
export const REORDER_CONFIG_TIME = 'REORDER_CONFIG_TIME';
export const REORDER_CREATE_REPORT_KEY = 'REORDER_CREATE_REPORT_KEY';
export const REMOVE_SELECTED_REPORT_KEY = 'REMOVE_SELECTED_REPORT_KEY';
export const REORDER_CREATE_REPORT_GROUP ='REORDER_CREATE_REPORT_GROUP';
export const REMOVE_SELECTED_REPORT_GROUP ='REMOVE_SELECTED_REPORT_GROUP';
export const REMOVE_SELECTION_CONFIG_TIME = 'REMOVE_SELECTION_CONFIG_TIME';
export const CLEAR_SELECTION_CONFIG_TIME = 'CLEAR_SELECTION_CONFIG_TIME';
export const SHOW_STAT_REPORT_COMPARE = 'SHOW_STAT_REPORT_COMPARE';

// gdpr
export const GRANT_EXPORT = "GRANT_EXPORT";
export const REVOKE_GRANTED_ACCESS = "REVOKE_GRANTED_ACCESS";
export const SET_SELECTED_TZ = "SET_SELECTED_TZ";
export const SET_SELECTED_LANG = "SET_SELECTED_LANG";

// admin
export const ADMIN_INSERT_EDITOR_CONTENT = "ADMIN_INSERT_EDITOR_CONTENT";
export const TOGGLE_ADMIN_SIDEBAR = "TOGGLE_ADMIN_SIDEBAR";
export const SET_ADMIN_VIEW = "SET_ADMIN_VIEW";
export const SET_ADMIN_LIST_FILTER = "SET_ADMIN_LIST_FILTER";
export const TOGGLE_AGENT_EDIT = "TOGGLE_AGENT_EDIT";
export const SET_AGENT_AVATAR_PREVIEW = "SET_AGENT_AVATAR_PREVIEW";
export const SET_AGENT_INPUT = "SET_AGENT_INPUT";
export const TOGGLE_ADMIN_EDIT = "TOGGLE_ADMIN_EDIT";
export const TOGGLE_ADMIN_FORM_TYPE = "TOGGLE_ADMIN_FORM_TYPE";
export const SET_ADMIN_COLUMN_WIDTH = "SET_ADMIN_COLUMN_WIDTH";
export const SET_ADMIN_INPUT = "SET_ADMIN_INPUT";
export const RESET_ADMIN_INPUT = "RESET_ADMIN_INPUT";
export const GET_ADMIN_DATA_FROM_LIST = "GET_ADMIN_DATA_FROM_LIST";
export const FILTER_ADMIN_LIST = "FILTER_ADMIN_LIST";
export const UPLOAD_AGENT_IN_PROGRESS = "UPLOAD_AGENT_IN_PROGRESS";
export const SET_ADMIN_CURRENT_SORT_FIELD = "SET_ADMIN_CURRENT_SORT_FIELD";
export const SET_ADMIN_CURRENT_SORT_ORDER = "SET_ADMIN_CURRENT_SORT_ORDER";
export const SET_AGENT_SOUND_OUTPUT_LIST = "SET_AGENT_SOUND_OUTPUT_LIST";
export const EXIST_AGENT_EMAIL_ADDRESS = "EXIST_AGENT_EMAIL_ADDRESS";
export const RESET_EMAIL_AVAILABILITY = "RESET_EMAIL_AVAILABILITY";
export const APPEND_SUB_TAGS = "APPEND_SUB_TAGS";
export const REMOVE_SUB_TAGS = "REMOVE_SUB_TAGS";
export const ADMIN_ACTION_ALERT = "ADMIN_ACTION_ALERT";
export const ADMIN_ACTION_STATUS = "ADMIN_ACTION_STATUS";
export const RESET_FILTER_ADMIN = "RESET_FILTER_ADMIN";

// admin - accounts
export const SHOW_EMAIL_TEST_POPUP = "SHOW_EMAIL_TEST_POPUP";

//admin - contact
export const SHOW_MERGE_CONTACT_CARD_POPUP = "SHOW_MERGE_CONTACT_CARD_POPUP";
export const SHOW_ADMIN_HIGHLIGHT = "SHOW_ADMIN_HIGHLIGHT";

// knowledge base
export const KB_CHANGE_ACTIVE_TAB = "KB_CHANGE_ACTIVE_TAB";
export const KB_CREATE_NEW = "KB_CREATE_NEW";
export const KB_DELETE_LIST = 'KB_DELETE_LIST'
export const KB_EDIT_CATEGORY = "KB_EDIT_CATEGORY";
export const KB_EDIT_LIBRARY = "KB_EDIT_LIBRARY";
export const KB_EDIT_QUESTION = "KB_EDIT_QUESTION";
export const KB_EDIT_SUGGESTION = "KB_EDIT_SUGGESTION";
export const KB_INSERT_NODE = 'KB_INSERT_NODE';
export const KB_REMOVE_NODE = 'KB_REMOVE_NODE';
export const KB_RESET_EDIT = "KB_RESET_EDIT";
export const KB_SAVE_ID = 'KB_SAVE_ID';
export const KB_SELECT_TREE_ROOT = "KB_SELECT_TREE_ROOT";
export const KB_LOADING_LIBRARY = "KB_LOADING_LIBRARY";
export const KB_UPDATE_CHATBOTS = "KB_UPDATE_CHATBOTS";

// Search Answer
export const SA_CHANGE_INPUT = 'SA_CHANGE_INPUT';
export const SA_CHANGE_SEARCH_STATE = 'SA_CHANGE_SEARCH_STATE';
export const SA_CHANGE_SOURCE = 'SA_CHANGE_SOURCE';

// User Vote
export const KB_UPDATE_USERVOTE_COMMENT = 'KB_UPDATE_USERVOTE_COMMENT';
export const KB_UPDATE_USERVOTE_EDIT = 'KB_UPDATE_USERVOTE_EDIT';
export const KB_UPDATE_USERVOTE_VOTE = 'KB_UPDATE_USERVOTE_VOTE';
export const KB_UPDATE_USERVOTE_WARNING = 'KB_UPDATE_USERVOTE_WARNING';

//internal chat
export const SERVICE_INTERNAL_CHAT = 0;

//initial data
export const SET_UP_APP_CONSTANTS = 'SET_UP_APP_CONSTANTS';

//skills
export const UPDATE_AGENT_SKILL = 'UPDATE_AGENT_SKILL';
