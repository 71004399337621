import each from 'lodash/each'
import { WS_EVENT_CONNECTED, WS_EVENT_DISCONNECTED } from 'socket'
import {
	DATA_SYNC,
	WS_EVENT,
	evtCHAT_FINISH_SESSION,
	evtNOTIFICATION_MESSAGE,
	evtCHAT_SET_ERRAND_HIDDEN,
	evtCHAT_ERRAND_ADD,
	evtDATA_SYNC,
	evtAGENT_REJECT_VIDEO_CALL
} from '../constants/constants';
import {
	SIP_CALL_IDLE
	, SIP_CALL_CONNECTED
} from '../../common/v5/callConstants';
import { readNotification } from '../../common/v5/socket';
import AgentSocket from '../../common/v5/agentsocket';
import { DummyFunction
	, isTelavox
	, notifyOS
} from '../../common/v5/helpers';
import {
	websocketConnectionState
} from '../../common/v5/websocketConnectionState';
import {
	onCreateAnswerSuccess,
	handleReceiveICEFromClient,
	stopScreenShare,
	hangUpCall,
	handleReceiveICE,
	handleStopClientScreenShare,
	handleClientRejectScreenShare,
	handleCheckInvalidVideoError,
	onOfferingAgentCoBrowse
} from '../../common/v5/webRtcCall';
import { isMainMenuWorkflowErrandSelector } from '../selectors/hmf';
import { currentChatErrand
	, getCurrentErrand
	, getCurrentChannel } from '../selectors/errand';
import {
	sipCallStatus
	, sipCallConn
} from '../selectors/call';
import { chatErrand } from '../selectors/workflow';
import {
	closeErrandView,
	askForPaging,
	endOfCall,
	forceSaveErrand,
	loadAndOpenErrand,
	errandMinLoad,
	askedForVideoCall
} from './async/errand';
import { updateSipXferRef
	, updateSipErrandId
	, sipUpdateAvatar
	, resetCallTimer
	, updateSipRefId
	, startAutoRecording
} from './async/sippRtc';
import {
	setErrandCloseTimer,
	handleClientReadyForVideoCall,
	showVideoCallFrame,
	updateVideoInProgress,
	toggleVidChatClientMute,
	toggleVidChatClientWebcam,
	handleClientRejectVidCall,
	cancelConfirm,
	setPostMessageChat,
	handleVideoCallRequest,
	handleClientScreenShare,
	handleClientScreenShareOffer,
	handleAgentScreenShare,
	handleAgentScreenShareOffer,
	handleClientOfferCoBrowsing,
	toggleVidChatFullScreen
} from '../actions/errand';
import { refreshChatErrandCollaboration } from './async/echat';
import {
	loadList,
	setErrandPinToTop,
} from './async/workflow';
import {
	refreshCollaborationData,
} from './async/collaborate';
import {
	updateAventaStatus
} from './call';
import { invalidateCache } from './domain';
import {
	showPopupNotification,
} from './hmf';
import {
	agentStatusOnLoad,
	customConfirm,
	DISMISS_BUTTONS
} from './async/hmf';

function handleNamespacedEvent(packet) {
	// TODO: add websocket redux actions creator helper that.
	return DummyFunction;
}

const isCollaboration = text => (
	text == "MsgCollaborationReply" ||
	text == "MsgCollaborationRequest" ||
	text == "MsgCollaborationReplyUpdate"
)

// More standard way of redux action object.
const syncData = data => ({
	type: DATA_SYNC,
	payload: data,
	meta: { timestamp: Date.now() }
})

const isDataSync = packet => {
	if (packet.event === evtDATA_SYNC) {
		return true
	}
}

export const wsEvent = packet => {
	const { namespace } = packet;
	if (namespace) {
		return handleNamespacedEvent(packet);
	} else if (isDataSync(packet)) {
		return syncData(packet.args[0])
	} else if (packet.type === WS_EVENT_CONNECTED ||
		packet.type === WS_EVENT_DISCONNECTED) {
		websocketConnectionState(packet.type)
	}
	return (dispatch, getState) => {
		const state = getState();
		let doShowpopup = false;
		if (packet.event == evtNOTIFICATION_MESSAGE) {
			// inject user's setting into action's payload to skip insert pending popup notifications
			// if it is disabled by user's setting
			packet.args[0].blueBubbleNotification = state.app.workflow.fetchWfSettings.data["user.blue.bubble.notification"];
		}
		if (packet.event == evtCHAT_FINISH_SESSION) {
			const hasVidChat = getState().app.errand.chat ? getState().app.errand.chat.videoCall : false;
			const hasSharedScreen = getState().chat.socket.agentSharingScreen;
			const sid = getState().app.errand.chat ? getState().app.errand.chat.sessionId : "";
			let o = packet.args[0];
			if (o.isOwner || o.guestLeaving || o.isStale) {
				let state = getState();
				if (state.app.errand.chat && state.app.errand.chat.sessionId == o.sessionId) {
					// TODO: check for willingness to close the errand.
					dispatch(closeErrandView(true));
				} else {
					if(hasVidChat) {
						//close video call if it happen
						if(sid) {
							hangUpCall(sid, false, true);
							dispatch(showVideoCallFrame(sid, false, true));
						}
					}
				}
			} else{
				if(hasVidChat) {
					hangUpCall(sid, false, true);
					dispatch(showVideoCallFrame(sid, false, true))
				}
				if(hasSharedScreen) {
					stopScreenShare(sid, false);
				}
			}
		}
		if (packet.event ===  evtCHAT_SET_ERRAND_HIDDEN){
			let o = packet.args[0];
			if ( o.chat ) {
				dispatch(closeErrandView(true));
			}
		}
		//To automatically load a newly added chat
		//when there's no errand/chat currently opened.
		const eid = getCurrentErrand(state)
		if (packet.event === evtCHAT_ERRAND_ADD) {
			const o = packet.args[0];
			// Only when agent working on errand
			if (isMainMenuWorkflowErrandSelector(state)) {
				if (eid == 0) {
					dispatch(loadAndOpenErrand(o.errand.id));
					if(isTelavox() == true){
						dispatch(setPostMessageChat(true));
					}
				}
			}
		} else if (packet.event == evtNOTIFICATION_MESSAGE) {
			const { messages } = packet.args[0];
			const msgToSkip = [];
			const seen = {};
			let needLoadList;
			let alreadyLoadList;
			each(messages, v => {
				if (!v.read) {
					const msg = JSON.parse(v.text);
					if (isCollaboration(msg.text)) {
						if (process.env.NODE_ENV !== 'production') {
							console.log('dbg: collaboration message')
						}
						const { eeThread, linkTo: errandId } = msg
						if (seen[eeThread]) {
							return
						}
						seen[eeThread] = true
						if (errandId === eid) {
							alreadyLoadList = dispatch(refreshCollaborationData(
								errandId,
								eeThread,
								currentChatErrand(state)
							))
						} else {
							const chat = chatErrand(state, errandId)
							if (chat) {
									refreshChatErrandCollaboration(chat.sessionId)
							} else if (!needLoadList) {
								needLoadList = true
							}
							dispatch(invalidateCache(eeThread, 'externalExpertThreads'))
						}
					} else if (msg.text == "MsgNewVoiceErrand") {
						if(state.app.errand.currentErrand){
							console.info("current errand id:",
								state.app.errand.currentErrand.id);
						} else {
							// TODO: currentErrand should NOT be falsy else it means something
							// wrongly modify the redux store.
							console.info("current errand is undefined/null");
						}
						if(state.app.workflow.fetchWfSettings.data["aventaEnabled"] == true &&
							typeof msg.linkTo !== 'undefined' &&
							msg.linkTo != 0){
							let canSave = [Workflow.Errand.SERVICE_EMAIL,
								Workflow.Errand.SERVICE_MANUAL,
								Workflow.Errand.SERVICE_SMS,
								Workflow.Errand.SERVICE_MANUAL_SMS,
								Workflow.Errand.SERVICE_VOICE,
								Workflow.Errand.SERVICE_MANUAL_VOICE,
								Workflow.Errand.SERVICE_SIP_VOICE]
							if(state.app.errand.currentErrand != null &&
							state.app.errand.currentErrand.id != 0) {
								if(canSave.includes(getCurrentChannel(state))){
									dispatch(forceSaveErrand())
								}
							}
							console.info("pin:",msg.linkTo);
							msgToSkip.push(v.id);
							dispatch(setErrandPinToTop(msg.linkTo,
								msg.cipherKey, true));
							dispatch(loadAndOpenErrand(msg.linkTo));
							dispatch(updateAventaStatus(SIP_CALL_CONNECTED));
							dispatch(updateSipErrandId(msg.linkTo));
							dispatch(updateSipRefId(msg.extRefId));
						}
					} else if (msg.text == "MsgLoadVoiceErrand") {
						if( typeof msg.linkTo !== 'undefined' &&
							msg.linkTo != 0 && state.app.errand.currentErrand &&
							state.app.errand.currentErrand.id == 0) {
							console.info("pin:",msg.linkTo);
							msgToSkip.push(v.id);
							dispatch(updateSipXferRef(
								msg.externalReferenceId));
							dispatch(setErrandPinToTop(msg.linkTo,
								msg.cipherKey, true));
							dispatch(loadAndOpenErrand(msg.linkTo));
						dispatch(updateSipErrandId(msg.linkTo));
						}
					} else if (msg.text == "MsgVoiceErrandOutbound"){
						if(state.app.errand.currentErrand &&
							state.app.errand.currentErrand.id == msg.eid){
							msgToSkip.push(v.id);
							dispatch(askForPaging(v.id, msg.errand,
								msg.outdialNumber, msg.eid));
							dispatch(updateSipErrandId(0));
						}
						return false;
					} else if (msg.text == "MsgVoiceTagErrand"){
						if(state.app.errand.currentErrand){
							//possible changes: add code to popup
							//force taging window here
						}
					} else if (msg.text == "MsgVoiceOutboundEnd"){
						msgToSkip.push(v.id);
						if(sipCallStatus(state) !== SIP_CALL_IDLE ) {
							readNotification(v.id);
							if(msg.askPage) {
								msgToSkip.push(v.id);
								dispatch(askForPaging(v.id, msg.errand,
									msg.outdialNumber, msg.eid));
								return false;
							} else if(msg.outdialNumber &&
								msg.outdialNumber !== ""){
								if(features.browserOsNotify !== true) {
									alert(endOfCall + " ["+ msg.outdialNumber
										+ "]");
								} else {
									notifyOS("Cention", endOfCall + " ["+
										msg.outdialNumber + "]", 0);
								}
							} else {
								let sipServer = state.app.workflow.fetchWfSettings.data["sipPwd"];
								if(sipServer == ""){
									if(features.browserOsNotify !== true) {
										alert(endOfCall);
									} else {
										notifyOS("Cention", endOfCall,0);
									}
								}
							}
							dispatch(updateSipErrandId(0));
							if(msg.endTime > 0){
								dispatch(setErrandCloseTimer(msg.eid,
									msg.endTime));
							}
							dispatch(updateAventaStatus(SIP_CALL_IDLE));
							setTimeout(function(){
								dispatch(agentStatusOnLoad());
							}, 3000);
						}
						return false;
					} else if (msg.text == "MsgNewAssigedErrand") {
						needLoadList = true;
					}
				}
			});
			packet.args[0].msgToSkip = msgToSkip;
			// force refresh errandList upon received collaboration reply notification
			if (!alreadyLoadList && needLoadList) {
				dispatch(loadList("collaborationReply"));
			}
			doShowpopup = true;
		}

		//Video call related
		if (packet.event == "video-ready") { //Agent responded, video call is ready on client
			let o = packet.args[0];
			dispatch(handleClientReadyForVideoCall(o.sessionId, true));
		} else if (packet.event == "video-offer") { //Got video call/screen share offer from client
			let o = packet.args[0];
			if(o.init) {
				if(state.chat.socket.onVideoCallRequest) {
					let conflictCallWarning = I("Already have call request in progress");
					alert(conflictCallWarning);
					dispatch(cancelConfirm());
				} else {
					let agentNotAvailable = false;
					//put an offer ongoing state
					dispatch(handleVideoCallRequest(true));
					if(state.chat.socket && state.chat.socket.onVideoCall) {
						agentNotAvailable = state.chat.socket.onVideoCall;
					}
					if(agentNotAvailable){
						AgentSocket.SendEvent(evtAGENT_REJECT_VIDEO_CALL, {
							sessionId: o.sessionId
						}, r => {
							console.log("Dbg: Agent busy with other video call", r);
						});
						dispatch(handleVideoCallRequest(false));
					}else{
						if(o) {
							dispatch(askedForVideoCall(o));
						}
					}
				}
			}else {
				//check if co browse
				if(o.coBrowse) {
					onOfferingAgentCoBrowse(o.sdp, o.sessionId);
					dispatch(showVideoCallFrame(o.sessionId, true, true));
				}
				//client screen sharing offer in progress
				dispatch(handleClientScreenShareOffer(true));
				dispatch(askedForVideoCall(o, true));
			}
		} else if (packet.event == "new-ice-candidate") {
			let o = packet.args[0];
			if(o) {
				handleReceiveICEFromClient(o);
			}
		} else if (packet.event == "video-answer") {
			//Client answering agent's video call
			let o = packet.args[0];
			onCreateAnswerSuccess(o.sdp, o.sessionId, o.init);
			if(o.init) {
				console.log("Client answering video call");
			} else {
				console.log("Client answering screen sharing from agent");
				dispatch(handleAgentScreenShare(true));
				dispatch(handleAgentScreenShareOffer(false));
			}
			dispatch(handleClientRejectVidCall(false)); //tocheck : why was this here?
		} else if (packet.event == "hang-up") {
			let sessionId = getState().app.errand.chat ? getState().app.errand.chat.sessionId : "";
			if(!state.chat.socket.onVideoCall) {
				//Client missed call agent
				if(getState().chat.socket.onVideoCallRequest) {
					dispatch(cancelConfirm());
				}
			} else {
				//Client hanging up call
				if(getState().app.errand.chat && getState().app.errand.chat.videoCallFromAgent) {
					hangUpCall(sessionId, true, false);
				} else {
					//client was initiated the call
					if(getState().app.errand.chat){
						hangUpCall(sessionId, true, false);
					}
				}
				if(getState().app.errand.chat){
					dispatch(updateVideoInProgress(getState().app.errand.chat.sessionId, false));
				}
			}
		} else if (packet.event == "client-disable-audio") {
			dispatch(toggleVidChatClientMute(true));
		} else if (packet.event == "client-enable-audio") {
			dispatch(toggleVidChatClientMute(false));
		} else if (packet.event == "client-disable-video") {
			dispatch(toggleVidChatClientWebcam(true));
		} else if (packet.event == "client-enable-video") {
			dispatch(toggleVidChatClientWebcam(false));
		} else if (packet.event == "video-chat-error") {
			let o = packet.args[0];
			if (o.data.data["type"] == "INVALID_REQUEST_STATE") {
				handleCheckInvalidVideoError(o.data);
			} else {
				alert("Error : "+o.data.data["msg"]);
			}
		} else if (packet.event == "client-bg-effect-error") {
			const dismissBtn = DISMISS_BUTTONS;
			let setClientBgErrorMgsg = I("Your client's browser does not support background effects.");
			dispatch(customConfirm(
				setClientBgErrorMgsg
				, dismissBtn,
			));
		} else if (packet.event == "CLIENT_REJECT_VIDEO_CALL") {
			let o = packet.args[0];
			hangUpCall(o.sessionId, true, false);
			dispatch(handleClientRejectVidCall(true));
		} else if (packet.event == "CLIENT_STOP_SCREEN_SHARE") {
			let o = packet.args[0];
			if(o.coBrowse) {
				dispatch(handleClientOfferCoBrowsing(o.sessionId, false));
				dispatch(toggleVidChatFullScreen(false));
			}
			dispatch(handleClientScreenShare(false));
			handleStopClientScreenShare();
		} else if (packet.event == "action-event") {
			const { data } = packet.args[0];
			const msg = JSON.parse(data);
			if(typeof msg !== 'undefined' && msg != null){
				if(msg.actionType == "load-errand"){
					if(msg.errandId != 0 && msg.externalXrefId != ""){
						dispatch(updateSipXferRef(
							msg.externalXrefId));
						dispatch(updateSipRefId(msg.externalXrefId));
						dispatch(setErrandPinToTop(msg.errandId,
							msg.cipherKey, true));
						dispatch(loadAndOpenErrand(msg.errandId));
						dispatch(updateSipErrandId(msg.errandId));
						dispatch(startAutoRecording(msg.errandId));
						dispatch(sipUpdateAvatar(msg.from));
						if(msg.timerReset == true){
							dispatch(resetCallTimer(Date.now()));
						}
					}
				} else if(msg.actionType == "update-status"){
					dispatch(agentStatusOnLoad());
				} else if(msg.actionType == "load-min-errand"){
					if( typeof msg.linkTo !== 'undefined' &&
						msg.linkTo != 0 &&
						state.app.errand.currentErrand &&
						state.app.errand.currentErrand.id == msg.linkTo){
						dispatch(errandMinLoad(msg.linkTo));
					}
				}
			}
		} else if (packet.event == "CLIENT_REJECT_SCREEN_SHARE") {
			let o = packet.args[0];
			handleClientRejectScreenShare(o.sessionId);
		} else if (packet.event == "CLIENT_STOP_AGENT_SCREEN_SHARE") {
			let o = packet.args[0];
			stopScreenShare(o.sessionId, true);
			dispatch(handleAgentScreenShare(false));
		} else if (packet.event == "CLIENT_ACTIVATE_COBROWSE") {
			// and show co browse toolbar
			dispatch(handleClientOfferCoBrowsing(o.sessionId, true));
			//into full screeen
			dispatch(toggleVidChatFullScreen(true));
		}
		if(doShowpopup == true) {
			dispatchWsAndNotification(dispatch, packet);
		} else {
			dispatch({ type: WS_EVENT, packet });
		}
		return Promise.resolve();
	};
};

function dispatchWsAndNotification(dispatch, packet){
	return new Promise((resolve, reject) => {
		resolve(dispatch({ type: WS_EVENT, packet }));
	})
	.then(() => {
		dispatch(showPopupNotification());
	});
}
