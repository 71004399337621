// one stop for all available endpoints - arrange base on alphabets from a-z and
// assuming the const var all in lowercase. After alphabet then shortest come
// first.

export const CentionSlash = '/Cention/';
export const SocketSlash = '/socket/';
export const AgentAPISlash = 'agent.api/';
export const AgentCallAPISlash = 'agent.call.api/';

// /Cention/web
export const CentionWeb = '/Cention/web/';
export const CentionFile = '/Cention/fa/'; // can't use /Cention/file which may cause conflict with some regexp with /file/xxx

// use this prefix to mark an endpoint for attachment file that
// to be served by file-d server. During rest call it will replaced with /s/<ws>/Cention/fa/
export const FilePrefix = '/fix-me/';

// -----------------------------------------------------------------------------
export const ContactcardsList = 'admin/contactcards';
export const ContactcardsEntryList = 'admin/contactcards';
export const AddEntryToContactCard = 'admin/contactcards/entry';
export const DeleteEntryFromContactCard = 'admin/contactcards/entry';
export const AdminGlobalAddressbookEdit = 'admin/globalAddressbook/edit';
export const AdminGlobalAddressbookGet = 'admin/globalAddressbook/get';
export const AdminGlobalAddressbookList = 'admin/globalAddressbook/list';
export const AdminGlobalAddressbookSave = 'admin/globalAddressbook/save';
export const AdminGlobalAddressbookDelete = 'admin/globalAddressbook/remove';
export const AdminGlobalAddressbookUpload = 'admin/globalAddressbook/uploads';
export const AdminErrandInternalState = 'admin/errandInternalstate';
export const AdminFilesUnzipfeedbacktemplate = 'admin/files/unzipfeedbacktemplate';
export const AdminLeaderboard= 'admin/leaderboard';
export const AdminPinReports = 'admin/pinReports';
export const AdminReports = 'admin/reports';
export const AdminReportsExport = 'admin/reports/export';
export const AdminReportsExportV5 = 'admin/reports/export/v5';
export const AdminReportsOverviewLayout = 'admin/reports/overview/layout';
export const ScheduleReport = 'admin/scheduledReports';
export const AdminTagList = 'admin/tag/list';
export const AdminTagRemove = 'admin/tag/remove';
export const AdminTagSave = 'admin/tag/save';
export const AdminTagAppend = 'admin/tag/append';
export const AdminTagUpload = 'admin/tag/upload';
export const AdminTagNameAll = 'admin/tag/name/all';
export const AdminTagSimpleList = 'admin/tag/simple-list';
export const AgentAreas = 'agentAreas';
export const AgentTemplates = 'agentTemplates';
export const AgentCurrentStatus = 'agent/currentStatus';
export const AgentSetPreference = 'agent/preferences';
export const AgentGetSelectedStatus = 'agent/getSelectedStatus';
export const AgentLastSeen = 'agent/lastSeen';
export const AgentPing = 'agent/ping';
export const AgentCallLog = "agent/callLog";
export const IsAgentLoggedIn = 'agent/isAgentLoggedIn';
export const Agents = 'agents';
export const Folders = 'folders'
export const Areas = 'areas';
export const OrganizationOverviewAreas = 'organizationOverviewAreas';
export const Channels = 'channels';
export const ChatIPAddressBlacklist = 'chat/blacklist';
export const CollaborationAnswers = 'collaboration/answers';
export const CollaborationQueryRecipients = 'collaboration/query_recipients';
export const CollaborationQuerySender = 'collaboration/queryCanSend';
export const CommonCallApi = 'errand/commonCallApi';
export const CommonSnoopApi = 'errand/snoop';
export const AgentFwdCallToExt = 'errand/agentfwdcalltoext';
export const SipManualCallStatus = 'sip/manualcallstatus';
export const SipIsSipAvailable = 'sip/sipissipavailable';
export const Constants = 'constants/:name?';
export const ErrandAcquire = 'errand/acquire';
export const ErrandAddAttachmentToErrand = 'errand/addAttachmentToErrand';
export const ErrandAgentStatistics = 'errand/agentStatistics';
export const ErrandEmailDownload = 'errand/email/download';
export const ErrandEmailPreview = 'errand/email/preview';
export const ErrandEmailManualPreview = 'errand/email/manualpreview';
export const ErrandAreaData = 'errand/areaData';
export const ErrandAreas = 'errand/areas';
export const ErrandBasic = 'errand/basic/';
export const ErrandMinLoad = 'errand/minload/';
export const ErrandChangeErrandArea = 'errand/changeErrandArea';
export const ErrandChangeErrandAgent = 'errand/changeErrandAgent';
export const ErrandChangeErrandFolder = 'errand/changeErrandFolder';
export const ErrandCloseErrand = 'errand/closeErrand';
export const ErrandCloseErrands = 'errand/closeErrands';
export const ErrandContacts = 'errand/contacts';
export const ErrandMyErrands = 'errand/myerrands';
export const ErrandFetchLinkedErrands = 'errand/fetchlinkederrands';
export const ErrandDeleteErrands = 'errand/deleteErrands';
export const ErrandExtendedData = 'errand/extendedData';
export const ErrandSuggestedAnswer = 'errand/suggestedAnswer';
export const ErrandFetchClientAddress = 'errand/fetchClientAddress';
export const ErrandFetchAreaConfirmation = 'errand/fetchAreaConfirmation';
export const ErrandFetchAreaErrandCount = 'errand/fetchAreaErrandCount';
export const ErrandFetchClientsAddressList = 'errand/fetchClientsAddressList';
export const ErrandFetchWorkflowSettings = 'errand/fetchWorkflowSettings';
export const ErrandFetchWorkflowLimitedSettings = 'errand/fetchWorkflowLimitedSettings';
export const ErrandHeaderSettings = 'errand/headerSettings';
export const ErrandHistory = 'errand/history';
export const ErrandKnowledgeBase = 'errand/library/tree';
export const ErrandKnowledgeBaseCategory = 'errand/library/category/tree';
export const ErrandKnowledgeBaseList = 'errand/library/list';
export const ErrandKnowledgeBaseFeaturedQuestion = 'errand/library/featuredQuestion';
export const ErrandKnowledgeBaseUserVote = 'errand/library/userVote'
export const ErrandRewriteAnswerBase = 'errand/openAI/rewriteAnswer'
export const ErrandList = 'errand/list';
export const ErrandNotes = 'errand/notes';
export const ErrandNotesDeleteAttachment = FilePrefix+'errand/notes/deleteAttachment';
export const ErrandNotesUploadAttachment = FilePrefix+'errand/notes/uploadAttachment';
export const ErrandPassiveChangeErrandArea = 'errand/passiveChangeErrandArea';
export const ErrandChangeInternalState = 'errand/changeErrandInternalState';
export const ErrandSendInternalStateChange = 'errand/sendInternalStateChangeOut';
export const ErrandPreview = 'errand/preview/';
export const ErrandPublishErrand = 'errand/publishErrand';
export const ErrandQueueErrandToUser = 'errand/queueErrandToUser';
export const ErrandTokenDecrypt = 'errand/tokenDecrypt';
export const ErrandRemoveTempAttachment = FilePrefix+'errand/removeTempAttachment';
export const ErrandReopenErrand = 'errand/reopenErrand';
export const ErrandResendErrand = 'errand/resendErrand';
export const ErrandReturnErrands = 'errand/returnErrands';
export const LinkErrand = 'errand/linkErrand';
export const ErrandSendErrand = 'errand/sendErrand';
export const SendBulkErrands = 'errand/sendBulkErrands';
export const ErrandSuggestErrandForLibrary = 'errand/suggestErrandForLibrary';
export const ErrandUnpublishErrand = 'errand/unpublishErrand';
export const ErrandUpdateErrand = 'errand/updateErrand';
export const GetAreaNotification = 'errand/fetchAreaConfirmation';
export const ErrandPrintContent = 'errand/printErrand';
export const ErrandPinToTop = "errand/errandPinToTop";
export const ErrandUpdatePriority = 'errand/errandsPriority';
export const ErrandUpdateLockToMe = 'errand/errandsLockToMe';
export const ErrandFetchAreaTags = 'errand/fetchAreaTags';
export const ErrandFBRatings = 'errand/facebookFetchRatings';
export const ErrandUpdateDueDate = 'errand/errandsDueDate';
export const ErrandUploadAnswerAttachment = FilePrefix+'errand/uploadAnswerAttachment';
export const ErrandDoPaging = 'errand/doPaging';
export const ErrandStartCloseCountdown = 'errand/startCloseCountdown';
export const ExternalexpertAddressList = 'externalexpert/address/list';
export const ExternalexpertAddressSave = 'externalexpert/address/save';
export const ExternalexpertAddressRemove = 'externalexpert/address/remove';
export const ExternalexpertAddressRemoveAvatar = FilePrefix+'externalexpert/address/removeAvatar';
export const ExternalexpertAnswer = 'externalexpert/answer';
export const ExternalexpertAnswerSave = 'externalexpert/answer/save';
export const ExternalexpertEdit = 'externalexpert/edit';
export const ExternalexpertFetchMessage = 'externalexpert/fetchMessage';
export const ExternalexpertLightWithAction = 'externalexpert/lightWithAction';
export const ExternalexpertList = 'externalexpert/list';
export const ExternalexpertSend = 'externalexpert/send';
export const ExternalexpertUpdate = 'externalexpert/update';
export const ExternalexpertLastSave = 'externalexpert/lastSave';
export const ExternalExpertQueries = 'externalExpertQueries';
export const ExtQueueDoneErrand = 'extqueue/doneerrand';
export const File = 'file';
export const FavouriteChatAgent = 'agent/favouriteChatAgent';
export const Groups = 'groups';
export const HangupApiCall = 'errand/hangupApiCall';
export const OutboundApiCall = 'errand/outboundApiCall';
export const Salutation = 'salutation';
export const SalutationEdit = 'salutation/edit';
export const SalutationSave = 'salutation/save';
export const SalutationDelete = 'salutation/delete';
export const Signature = 'signature';
export const SignatureEdit = 'signature/edit';
export const SignatureSave = 'signature/save';
export const SignatureDelete = 'signature/delete';
export const SmppconfigurationEdit = 'smppconfiguration/edit';
export const SmppconfigurationList = 'smppconfiguration/list';
export const Systemgroup = 'systemgroup';
export const SystemgroupSwitch = 'systemgroup/switch';
export const Templates = 'templates';
export const TemplateDelete = 'template/delete';
export const TemplateEdit = 'template/edit';
export const TemplateSave = 'template/save';
//quick replies
export const QuickReplies = 'quickreplies';
export const QuickReplyDelete = 'quickReply/delete';
export const QuickReplyEdit = 'quickReply/edit';
export const QuickReplySave = 'quickReply/save';

export const AdminFileArchive= 'admin/filearchive';
export const AdminFileArchiveEdit= 'admin/filearchive/edit';
export const AdminFileArchiveSave= 'admin/filearchive/save';
export const AdminFileArchivePreview = 'admin/filearchive/preview'
export const AdminFileArchiveDownload = FilePrefix+'admin/filearchive/download'
export const RouteKeywords = 'arearoutingrule';
export const RouteAutoTags = 'autotagrule';
export const TagKeywords = 'admin/tag';
export const AdminOrganisation = 'admin/organisations';
export const AdminGetOrganisationById = 'admin/org';
export const RouteSip = 'siproutingrule';
export const AutoTagUploadList = 'autotagrule/upload';
export const TimeTimespan = 'time/timespan';
export const TimeTimespanItems = 'time/timespanitems';
export const TimeTimeformat = 'time/timeformat';
export const TimeTimezones = 'time/timezones';
export const Wordlist = 'wordlist';
export const WordlistEdit = 'wordlist/edit';
export const WordlistSave = 'wordlist/save';
export const WordlistDelete = 'wordlist/delete';
export const ExportList = 'exports/list';
export const ExportCallList = 'exports/callList'
export const ExportCalls = 'exports/calls'
export const SimpleTagListByArea = 'exports/tags';
export const SaveExport = 'exports';
export const CreateExport = 'exports';
export const RemoveExport = 'exports';
export const ActivateExport = 'exports';
export const GeneratedExport = 'exports';
export const GeneratedCdrExport = 'exports/calls/generatedcdrexport';
export const GeneratedPreview = 'exports/preview';
export const RemoveGeneratedExport = 'generatedExports';
export const FetchExportFile = 'generatedExports';
export const CustomerByAddress = 'errand/getClientByAddress';
export const GetCustomerNotes = 'errand/notes';
export const DeleteCustomerNote = 'errand/notes/delete';
export const AddCustomerNote = 'errand/notes/save';
export const GetContactBook = 'admin/contactcards';
export const GetAnnoucement = 'admin/announcement';
export const ErrandStatus = 'errand/errandStatus';
export const ChatErrandStatus = 'errand/chatErrandStatus';
export const GetClientAvatar = FilePrefix+'errand/clientAvatar';
export const PostCustomerAvatar = FilePrefix+'errand/uploadClientAvatar'
export const PostCustomerDelAvatar = FilePrefix+'errand/removeClientAvatar'
export const AgentChatSourceConfig = 'agent/chatSourceConfig';
export const AgentFavourite = 'agent/favourite';
export const AgentAventaLogin = 'agent/aventaLogin';
export const CanClose = 'agent.api/canClose';
export const GetAvailableSipAgents = 'agent/getAvailableSipAgents';
export const GetAllSipAgents = 'agent/getAllSipAgents';
export const GetActiveCallLogs = 'agent/activeCallLog';
export const GetSipNumberInUse = 'agent/getSipNumbersInUse';
export const RecordEditorSize = 'recordTextAreaSizes';
export const ShareReport = 'admin/shareReport';
export const SavedReport = 'admin/savedReport';
export const ReportKeys = 'admin/reportkeys';
export const ReportGroups = 'admin/reportgroups';
export const ReportPreview = 'admin/reports/preview';
export const LastErrandInThread = 'errand/lastErrandInThread';
export const SetErrandPostponeDate = 'errand/setPostponeDate';
export const ErrandFBEmotions = 'errand/facebookReactions';
export const FetchErrandId = 'errand/id';
export const GetErrandData = 'errand/openErrand'
export const GetSummarized ='errand/getSummarizedHistory'
export const VerifySendingByChannel = 'errand/verifySending'
export const GetErrandSlaTime = 'errand/fetchErrandSlaTime'
export const GetErrandAcquireOwnerStatus = 'errand/getAcquireErrandOwnerStatus';
export const AgentWorkingOnErrand = 'errand/agentWorkingOnErrand';
export const IncomingEmailErrands = 'errand/incomingEmailErrands';
export const ActiveErrandList = 'errand/activeErrandList';
export const ActiveAgents = 'errand/activeAgents';
//fetch CRM data
export const CRMSystemData = 'errand/emailTrigger/fetch';
export const ConnectedAgentAreas = 'connectedAgentAreas';
export const StatisticsAgentAreas = 'statisticsAgentAreas';

// translation
export const Translate = 'translate';
export const TranslateDetect = 'translate/detect';

// internal messages
export const InternalMessage = 'message/list';
export const IMSendMessage = 'message/sendMessage';
export const AgentcardsEntryList = '/agents/list';
export const AddEntryToAgentCard = 'admin/agentcards/entry';
export const GetAgentBook = 'admin/agentcards';
export const GetGroupBook = 'group';
export const IMCounter = 'message/counter';
export const IMDeleteMessages = 'message/deleteMessages';
// export const IMMoveMessages = 'message/moveMessages';
export const IMMessageRead = 'message/updateRead';
export const IMSaveDraft = 'message/savedraft';
export const GetAgentGroups = 'groups';
export const NewIMCount = 'message/newimcount';
export const IMRestoreMessages = 'message/restoreMessages';
export const InternalMessageHistory = 'message/history';

// socket
export const AcceptInternalChat = 'acceptinternalchat';
export const Agentlist = 'agentlist';
export const Answerinvite = 'answerinvite';
export const Forward = 'forward';
export const GetAreas = 'getAreas';
export const GetAgents = 'getAgents';
export const Queues = 'queues';
export const Sessiondata = 'sessiondata';
export const SocketAgentCallApiAccept = 'accept';
export const SocketAgentCallApiOutboundPhones = 'outboundphones';
export const SocketAgentApiAcceptchat = 'acceptchat';
export const SocketAgentApiChatsessioncount = 'chatsessioncount';
export const ClosedChatAPI = 'getclosedchat';
export const SocketAgentApiChatStatus = 'chatStatus';

// gdpr
export const Anonymize = 'contact/anonymize';
export const ExportLog = 'contact/exportlog/';
export const ContactExport = 'contact/export/';

// membershipd
export const Membershipd = '/hasmembership';

// admin
export const AdminAWSSESIdentities = 'admin/awsses/:region/identities/:id?';
export const AdminAgentsAccessKeys = 'admin/agents/:id/access-keys/:key?';
export const AdminChatbots = 'admin/chatbots';
export const AdminChatbotsIntents = 'admin/chatbots/:id/intents/:iid?/:libraries*';
export const AdminChatbotsTrainingphrases = 'admin/chatbots/trainingphrases';
export const AdminFilesAreaarchiveimages = 'admin/files/areaarchiveimages';
export const AdminReceiptgreetings = 'admin/receiptgreetings/:receipt?/:id?';
export const AdminReceiptgreetingsFeedbacksResponseimages = FilePrefix+'admin/receiptgreetings/feedbacks/responseimages';
export const AdminReceiptgreetingsFeedbacksResponsetemplates = FilePrefix+'admin/receiptgreetings/feedbacks/responsetemplates';
export const AdminServiceaccounts = 'admin/serviceaccounts';
export const AdminSmtp = 'admin/smtp/:id?';
export const AgentAdmin = 'admin/agents';
export const AgentEdit = 'admin/agents/edit';
export const AgentList = 'admin/agents/list';
export const AgentNew = 'admin/agents/new';
export const AgentValidate = 'admin/agents/validate';
export const AgentGetSalutations = 'admin/agent/getSalutations';
export const AgentGetSignatures = 'admin/agent/getSignatures';
export const AgentAddMP = 'admin/agent/addMPSpecialisation';
export const AgentRemoveMP = 'admin/agent/removeMPSpecialisation';
export const AgentUploadAvatar = FilePrefix+'admin/agent/uploadAgentAvatar';
export const AgentRemoveAvatar = FilePrefix+'admin/agent/removeAgentAvatar';
export const AgentValidateExternalID = 'admin/agent/validateExternalID';
export const AgentDeactivate = 'admin/agent/deactivateAgent';
export const AgentActivate = 'admin/agent/activeAgent';
export const AgentUploadList = FilePrefix+'admin/agent/uploadAgentAttachment';
export const AgentImportStatus = 'admin/agent/agentImportStatus';
export const AgentUnlock = 'admin/agent/unlockAgent';
export const ReviewAddresses = 'admin/reviewmanager/keywords';
export const ReviewAgents = 'admin/reviewmanager/agents';
export const ReviewAddressesFile = 'admin/reviewmanager/keywords/file';
export const CompanyList = 'admin/companies';
export const ConfigChangeLog = 'admin/configchangelog';
export const CompanyNew = 'admin/companies/save';
export const CompanyRemove = 'admin/companies/delete';
export const ChatWidgetCfgNew = 'admin/chatwidgetconfig/save';
export const ChatWidgetCfgList = 'admin/chatwidgetconfig';
export const ChatWidgetCfgDefList = 'admin/chatwidgetconfig/default';
export const ChatWidgetCfgDelete = 'admin/chatwidgetconfig/delete';
export const FaqWidgetCfgNew = 'admin/faqwidgetconfig/save';
export const FaqWidgetCfgList = 'admin/faqwidgetconfig';
export const FaqWidgetCfgDefList = 'admin/faqwidgetconfig/default';
export const FaqWidgetCfgDelete = 'admin/faqwidgetconfig/delete';
export const VoiceWidgetCfgNew = 'admin/voicewidgetconfig/save';
export const VoiceWidgetCfgList = 'admin/voicewidgetconfig';
export const VoiceWidgetCfgDefList = 'admin/voicewidgetconfig/default';
export const VoiceWidgetCfgDelete = 'admin/voicewidgetconfig/delete';
export const AdminAgentSipStatus = 'admin/agentsipstatus';
export const AdminAgentStatusList = 'admin/agentstatuslist';
export const AdminStunTurn = 'admin/stunturn';
export const AdminStunTurnStatus = 'admin/stunturnstatus';
export const AdminAgentSipStatusValidate = 'admin/agentsipstatus/validate';
export const AdminClassifier = 'admin/classifier';
export const AgentEmailAvailable = 'admin/agents/emailAvailable';

// admin accounts
export const AdminAccountEmail= 'admin/account/email';
export const AdminAccountLine= 'admin/account/line';
export const AdminAccountFacebook= 'admin/account/facebook';
export const AdminAccountFacebookSave= 'facebook/account/minimalSave';
export const AdminAccountInstagram= 'admin/account/instagram';
export const AdminAccountTwitter= 'admin/account/twitter';
export const AdminAccountLinkedIn= 'admin/account/linkedin';
export const AdminAccountHubSpot= 'admin/account/hubspot';
export const AdminAccountJira= 'admin/account/jira';
export const AdminAccountYoutube= 'admin/account/youtube';
export const AdminAccountGoogleReview= 'admin/account/googlereview';
export const AdminAccountGoogleChat= 'admin/account/googlechat';
export const AdminAccountGooglePlay= 'admin/account/googleplay';
export const AdminAccountTrustpilot= 'admin/account/trustpilot';
export const AdminAccountTelegram= 'admin/account/telegram';
export const AdminAccountWhatsApp= 'admin/account/whatsapp';
export const AdminAccountViber= 'admin/account/viber';
export const AdminAccountSlack= 'admin/slackaccount';
export const AdminAccountTwilio = 'twilio/accounts';
export const AdminAccountSMPP = 'smppconfiguration';
export const AdminAccountMSTeams = 'admin/account/msteams';
export const AdminAccountMSTeamsCreateGroup = 'admin/msteams/creategroup';
export const AdminAccountMSTeamsManageGroup = 'admin/msteams/addgroupmember';

//Routing Groups
export const RoutingGroups = 'routinggroups';

//Call recordings
export const CallRecordings = 'integration/savedmedias';

//Agent SIP Login
export const AgentSipLogin = 'integration/agentsiplogin';

//Sip Priority Numbers
export const SipPriorityNumbers = 'integration/sipprioritynumbers';

//Call IVR Admin
export const CallAdminIVR = 'integration/adminivr';
export const CallAdminIVRPromptUpload = FilePrefix+'integration/adminpromptupload';
export const CallAdminIVRMOHUpload = FilePrefix+'integration/adminmohupload';
export const CallAdminIVRDeploy = 'integration/admindeployivr';

//Call Admin Sip Trunk
export const CallAdminSipTrunk = 'integration/adminsiptrunk';

//Contact card
export const ContactCards = 'admin/contactcards';
export const SaveContactCards = 'admin/contactcards/entry';
export const MergeContactCards = 'admin/contactcards/merge';

//CORS
export const CORSWhitelist = 'corsconfig';

export const AdminGroups = 'group';
export const GroupFolders = 'group/folder';
export const GroupAgents = 'group/connection';

// Knowledgebase
export const AdminLibrary = 'admin/library';
export const AdminLibraryList = 'admin/library/list';
export const AdminLibraryRemove = 'admin/library/remove';

// Knowledgebase Tree
export const AdminLibraryTreeRoots = 'admin/library/tree/roots';
export const AdminLibraryTreeRemove = 'admin/library/tree/remove';
export const AdminLibraryTreeInsert = 'admin/library/tree/insert';

// Knowledgebase Category
export const AdminLibraryCategory = 'admin/library/category';
export const AdminLibraryCategoryList = 'admin/library/category/list';
export const AdminLibraryCategoryRemove = 'admin/library/category/remove';

// Knowledgebase Question
export const AdminLibraryQuestion = 'admin/library/question';
export const AdminLibraryQuestionList = 'admin/library/question/list';
export const AdminLibraryQuestionRemove = 'admin/library/question/remove';

// Knowledgebase Suggestion // Suggestion uses Question save and remove
export const AdminLibrarySuggestion = 'admin/library/suggestion';
export const AdminLibrarySuggestionList = 'admin/library/suggestion/list';

// Knowledgebase Rating
export const AdminLibraryRating = 'admin/library/rating';
export const AdminLibraryRatingList = 'admin/library/rating/list';
export const AdminLibraryRatingRemove = 'admin/library/rating/remove';

export const CreateManualErrand = "integration/createmanual";

// Admin APIs
export const AdminCallbackAPI = 'admin/simplecallback';
export const AdminJWTAPI = 'admin/api/jwt';

// User Errand's Launchpad (Personalize)
export const FetchForwardedErrands = "errand/forwardedErrands";
export const FetchIncomingCollaborations = "errand/incomingCollabs";
export const FetchDueErrandsInAWeek = "errand/dueErrandsInAWeek";
export const FetchExpiringErrands = "errand/expiringErrands";
export const DeleteLaunchpadWidget = 'agent/deleteLaunchpadWidget';
export const AddNewLaunchpadWidget = 'agent/addLaunchpadWidget';

// twoFA
export const TwoFANewSecret = 'twofanewsecret';
export const VerifyTwoFAToken = 'verifytwofatoken' ;

// Admin Skills
export const SkillsCategory = 'admin/skills/category';
export const Skills = 'admin/skills';
export const SkillProf = 'admin/skills/proficiency';
export const SkillAgent = "admin/skills/agent";
export const SkillArea = "admin/skills/area";

// Admin Agent Assist
export const AgentAssist = 'admin/agentassist';

// Admin upload document for Generative AI
export const AdminGenerativeAIDocument = 'admin/genai/document';
export const AdminGenerativeAIDocumentPreview = 'admin/genai/document/preview'
export const AdminGenerativeAIDocumentDownload = FilePrefix+'admin/genai/document/download'
export const AdminLanguageList = 'admin/languages';