import moment from 'moment';
import * as constant from '../../redux/constants/constants';
import AgentSocket from '../../common/v5/agentsocket';
import SearchSocket from '../../common/v5/searchsocket';
import {fetchAvatarByClient, setAgentPreference} from './async/workflow';

const SEARCH_ERRAND = 'errand', SEARCH_AREA='area', SEARCH_TAGS='tag', SEARCH_KB='knowledgebase', SEARCH_CONTACTCARD='contactCard', SEARCH_EECONTACT='eeContact', SEARCH_CUSTOMERNOTES='custNotes', SEARCH_ERRANDNOTES='errandNotes', SEARCH_COLLABORATION='collaboration';
export const changeSearchText = value =>({
	type: constant.SET_ERRAND_SEARCH_BOX,
	payload: value
});
export const showSearchToggleAction = (actionFor,toggle) =>({
	type: constant.SHOW_ERRAND_SEARCH_CROSS_BOX,
	payload: {actionFor: actionFor, value: toggle}
});
export const handleSearchTextChange = (actionFor, val) =>({
	type: constant.GLOBAL_SEARCH_TEXT_CHANGE,
	payload: {actionFor: actionFor, value: val}
});
export const handleSearchResultChange = (actionFor, val) =>({
	type: constant.GLOBAL_SEARCH_HEADER_DROP_DOWN,
	payload: {actionFor: actionFor, value: val}
});
export const initAgentSearch = wfs => (dispatch, getState) => {
	let workflow = getState().app.workflow;
	let areas = []
		, agents = [0]
		, channels = []
		//, tags = []
		;
	let wfAreas = workflow.connectedAgentAreas.data.areas
		, wfAgents = workflow.agents.data.agents
		, services = getState().server.services.byName
		//, wfTags = workflow.adminTagList.data.tags
		;
	if(wfAreas && wfAreas.length > 0) {
		wfAreas.forEach(a => {
			if(a.Areas && a.Areas.length > 0) {
				a.Areas.forEach(el =>{
					areas.push(el.Id)
				});
			}
		});
	}
	wfAgents.forEach(el =>{
		agents.push(el.Id);
	});
	Object.keys(services).forEach(key => {
		channels.push(services[key]);
	});
	// wfTags.forEach(el =>{
	// 	tags.push(el.tagId);
	// })
	dispatch({type: constant.GLOBAL_SEARCH_INIT_BY_MCAM
			, payload:{wfs
					, areas: areas.join(",")
					, agents: agents.join(",")
					, channels: channels.join(",")
					//, tags: tags.join(",")
				}
			});
	return Promise.resolve();
}

export const handleResetSearch = () => (dispatch, getState) => {
	const wfs = getState().app.workflow.fetchWfSettings.data;
	if(wfs){
		dispatch(initAgentSearch(wfs));
	}
	dispatch(handleSearchTextChange(constant.SEARCH_TEXT_CLEAR, ""));
	dispatch({
		type: constant.HANDLE_SEARCH_RESET,
		payload: true
	});
}

export const handleResetOffset = () => (dispatch, getState) =>{
	dispatch({
		type: constant.HANDLE_SEARCH_RESET,
		payload: true
	});
}

export const handleHeaderSearchHide = val =>({
	type: constant.HEADER_SEARCH_RESULT_HIDE,
	payload: val
});
export const handleReplySearchResultHide = val =>({
	type: constant.REPLY_SEARCH_RESULT_HIDE,
	payload: val
});
export const setCurrentSearchSidebar = (opt) =>({
	type: constant.SET_CURRENT_SEARCH_SIDEBAR_OPTION,
	payload:opt
});
export const unsetSearchSidebar = (opt) =>({
	type: constant.UNSET_SEARCH_SIDEBAR_OPTION,
	payload: false
});
export const handleSearchFilters = (optFor, tgl) =>({
	type: constant.HANDLE_SEARCH_FILTER_OPTION,
	payload:{option: optFor, value: tgl}
});
export const handleResetSearchFilter = p =>({
	type: constant.HANDLE_SEARCH_RESET_FILTER,
	payload: {...p}
});
export const handleSearchFilterArea = aid =>({
	type: constant.HANDLE_SEARCH_FILTER_BY_AREA,
	payload: aid
});
export const handleSearchFilterAgent = aid =>({
	type: constant.HANDLE_SEARCH_FILTER_BY_AGENT,
	payload: aid
});
export const handleSearchFilterChannel = cid =>({
	type: constant.HANDLE_SEARCH_FILTER_BY_CHANNEL,
	payload: cid
});
export const handleSearchFilterTag = tid =>({
	type: constant.HANDLE_SEARCH_FILTER_BY_TAG,
	payload: tid
});
export const handleToggleDateInterval = tgl =>({
	type: constant.HANDLE_TOGGLE_SEARCH_DATE_INTERVAL,
	payload: tgl
});
export const handleSearchFilterAdvOpt = val =>({
	type: constant.HANDLE_TOGGLE_SEARCH_ADVANCED_OPT,
	payload: val
});
export const handleSearchFilterErrandType = val =>({
	type: constant.HANDLE_SEARCH_ERRAND_TYPE,
	payload: val
});
export const handleSearchFromDate = val =>({
	type: constant.HANDLE_SEARCH_FROM_DATE,
	payload: val
});
export const handleSearchToDate = val =>({
	type: constant.HANDLE_SEARCH_TO_DATE,
	payload: val
});
export const handleShowAllByType = val =>({
	type: constant.HANDLE_SEARCH_SHOW_ALL_ITEM,
	payload: val
});
export const handleProcessing = val =>({
	type: constant.HANDLE_SEARCH_PROCESSING,
	payload: val
});
export const handleSearchSpinner = val =>({
	type: constant.HEADER_SEARCH_ONGOING,
	payload: val
});
export const handleReplySearchSpinner = val =>({
	type: constant.REPLY_SEARCH_ONGOING,
	payload: val
});
export const handlePagination = (offset, cpCtx, page) =>({
	type: constant.HANDLE_SEARCH_PAGINATION,
	payload: {offset: offset, currentPaginateCtx: cpCtx, selectedPage: page}
});
export const handeSortDirection = (val) => (dispatch, getState) => {
	return dispatch(setAgentPreference({searchSortDirection: val}))
		.then(() => {
			dispatch(doGlobalSearchByWS(constant.GLOBAL_SEARCH_FROM_BODY));
		});
};
export const handeSortAttribute = (val) => (dispatch, getState) => {
	return dispatch(setAgentPreference({searchSortAttribute: val}))
		.then(() => {
			dispatch(doGlobalSearchByWS(constant.GLOBAL_SEARCH_FROM_BODY));
		});
};
export const handleFilterByLabel = val => ({
	type: constant.HANDLE_FILTER_SEARCH,
	payload: val
});
// mak: to be obsoleted and replaced by handleFilterByLabel
export const handleFilterLabelByMy = val =>({
	type: constant.FILTER_SEARCH_ERRAND_MY,
	payload: val
});
// mak: to be obsoleted and replaced by handleFilterByLabel
export const handleFilterLabelByAll = val =>({
	type: constant.FILTER_SEARCH_ERRAND_ALL,
	payload: val
});
// mak: to be obsoleted and replaced by handleFilterByLabel
export const handleFilterLabelByClose = val =>({
	type: constant.FILTER_SEARCH_ERRAND_CLOSE,
	payload: val
});
//sockets
export const doGlobalSearchByWS = (searchPositon, setHeaderDropDown) => {
	return (dispatch, getState) => {
		let wfs = getState().app.workflow.fetchWfSettings;
		let agent = wfs.data.activeUserId;
		let agentTZOffset = wfs.data.agentTimezoneOffset;
		let state = getState().app.search.ui;
		let searchInputs = getState().app.search.inputs;
		let searchTerm = (searchPositon === constant.GLOBAL_SEARCH_FROM_HEADER ? searchInputs.headerText :
			searchPositon === constant.GLOBAL_SEARCH_FROM_REPLY ? searchInputs.replyText : searchInputs.bodyText);
		let params = {
			agentId: agent,
			space: workspace
		}
		, sortDirection = wfs.data["searchSortDirection"]
		, sortAttribute = wfs.data["searchSortAttribute"]
		;
		params.searchHeader = (searchPositon === constant.GLOBAL_SEARCH_FROM_HEADER ||
			searchPositon === constant.GLOBAL_SEARCH_FROM_REPLY);
		if(searchTerm !== "")
			params.term = searchTerm;

		if(searchPositon === constant.GLOBAL_SEARCH_FROM_HEADER ||
			searchPositon === constant.GLOBAL_SEARCH_FROM_REPLY){
			params.searchType = 'global';
		}else {
			params.searchType = getSearchType(state.globalSearch);
		}
		params.agentIds = state.filters.agents;
		params.areaIds = state.filters.areas;
		params.serviceTypes = state.filters.channels;
		params.tagIds = state.filters.tags;
		params.startDate = timestamp(state.filters.date_from, ' 00:00:00', agentTZOffset);
		params.endDate = timestamp(state.filters.date_to, ' 23:59:59', agentTZOffset);
		// If no date interval is specified, default will show 3 months records
/* 		if( state.globalSearch.isErrands ){
			if(state.filters.date_from == ""){
				params.startDate = timestamp(currentHumanDate(), ' 00:00:00', agentTZOffset);
				//Test code params.startDate = timestamp('2017-09-01', ' 00:00:00');
			}
			if(state.filters.date_to == ""){
				params.endDate = timestamp(currentHumanDate(), ' 23:59:59', agentTZOffset);
			}
		} */
		params.errandType = state.filters.errandTypes;
		params.includeInactive = state.filters.showInactive;
		params.offset = getOffsetByCTX(state);
		params.limit = parseInt(state.bodySearch.topResults, 10);
		params.sortDirection = (sortDirection === 0 ? "asc" : "desc");
		params.sortAttribute = getSearchAttribute(sortAttribute);
		params.currentPagination = state.bodySearch.currentPagination;
		params.filterBy = state.globalSearch.filterBy;
		SearchSocket.SendEvent(constant.evtGLOBAL_SEARCH, params, rs =>{
			if(typeof rs !== "undefined") {
				dispatch({ type: constant.WS_EVENT_GLOBAL_SEARCH, packet:rs.msg[0], paginateCtx: state.bodySearch.currentPagination});
				dispatch(handleProcessing(false));
				dispatch(handleSearchSpinner(false));
				dispatch(handleReplySearchSpinner(false));
				dispatch(fetchAvatarByClient(false));
				if(setHeaderDropDown == true){
					dispatch(handleSearchResultChange("header",rs.msg[0]));
				}
			}
		});
		return Promise.resolve();
	};
};

const getOffsetByCTX = (state) =>{
	let currentPageCTX = state.bodySearch.currentPagination;
	if( currentPageCTX === SEARCH_ERRAND )
		return parseInt(state.bodySearch.errandOffset, 10);
	else if( currentPageCTX === SEARCH_COLLABORATION )
		return parseInt(state.bodySearch.errandOffset, 10);
	else if( currentPageCTX === SEARCH_AREA )
		return parseInt(state.bodySearch.areaOffset, 10);
	else if( currentPageCTX === SEARCH_TAGS )
		return parseInt(state.bodySearch.tagOffset, 10);
	else if( currentPageCTX === SEARCH_CONTACTCARD )
		return parseInt(state.bodySearch.ccOffset, 10);
	else if( currentPageCTX === SEARCH_KB )
		return parseInt(state.bodySearch.kbOffset, 10);
	else if( currentPageCTX === SEARCH_EECONTACT )
		return parseInt(state.bodySearch.eeOffset, 10);
	else if( currentPageCTX === SEARCH_CUSTOMERNOTES )
		return parseInt(state.bodySearch.cnotesOffset, 10);
	else if( currentPageCTX === SEARCH_ERRANDNOTES )
		return parseInt(state.bodySearch.enotesOffset, 10);
	else
		return parseInt(state.bodySearch.errandOffset, 10);
}

const currentHumanDate = () =>{
	let dt = new Date();
	let dd = dt.getDate();
	let mm = dt.getMonth()+1;
	let yyyy = dt.getFullYear();
	if(dd < 10) { dd = '0' + dd }
	if(mm < 10) { mm = '0' + mm }

	return yyyy+'/'+mm+'/'+dd;
}

const timestamp =(date, time, tz)=>{
	let UTCDate = moment.utc(`${date} ${time} ${tz}`, "YYYY/MM/DD HH:mm:ss Z");
	return UTCDate.unix();
}

const getSearchType = (state) =>{
	if(state.isErrands)
		return 'errand';
	else if(state.isCollaborations)
		return 'collaboration';
	else if(state.isAreas)
		return 'area';
	else if(state.isTags)
		return 'tag';
	else if(state.isContactCard)
		return 'contactCard';
	else if(state.isKnowledgeBase)
		return 'knowledgebase';
	else if(state.isEEContacts)
		return 'eeContact';
	else if(state.isCustomerNotes)
		return 'custNotes';
	else if(state.isErrandNotes)
		return 'errandNotes';
	else
		return 'global';
}

const getSearchAttribute = (attr) =>{
	switch(attr){
		case 0:
			return 'date-arrive';
		case 1:
			return 'displayId';
		case 2:
			return 'subject';
		case 3:
			return 'area';
		case 4:
			return 'agent';
		case 5:
			return 'from';
		case 6:
			return 'date-close';
		default:
			return "date-arrive";
	}
}

export const selectErrandSearchResult = (id, select) => ({
	type: constant.SELECT_ERRAND_IN_SEARCH_RESULT,
	payload: {id, select}
});

export const selectAllErrandsSearchResult = (select) => ({
	type: constant.SELECT_ALL_ERRANDS_IN_SEARCH_RESULT,
	payload: select
})
