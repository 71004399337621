import {
	getNewOrganisations,
	GetOrganisationById,
	SaveOrganisationExternal,
	SaveOrganisationAreaExternal,
	DeleteOrganisationExternal
} from './ajax';
import {
	keyGetOrganisations,
	keyGetOrganisationById,
	keySaveOrganisationExternalSetting,
	keyDeleteOrganisationExternalSetting
} from '../../constants/keys';
import {
	admin
} from '../../selectors/admin';
import {
	async
} from '../../util';

const agentFetchOrganisationList = (p) => async(getNewOrganisations(p),
	admin[keyGetOrganisations]
);

const FetchOrganisationByID = (p) => async(GetOrganisationById(p),
	admin[keyGetOrganisationById]
);

const saveOrganisationExternalSetting = (p) => async(SaveOrganisationExternal(p),
	admin[keySaveOrganisationExternalSetting]
);

const saveAreaExternalSetting = (p) => async(SaveOrganisationAreaExternal(p),
	admin[keySaveOrganisationExternalSetting]
);

const deleteOrganisationExternalSetting = (p) => async(DeleteOrganisationExternal(p),
	admin[keyDeleteOrganisationExternalSetting]
);


export const fetchOrganisationList = (p) => (dispatch) => {
	dispatch(agentFetchOrganisationList(p))
};

export const getOrganisationById = (p) => (dispatch) => {
	dispatch(FetchOrganisationByID(p))
}

export const SaveExternalSetting = (p) => (dispatch) => {
	dispatch(saveOrganisationExternalSetting(p))
	.then(()=>{
		dispatch(FetchOrganisationByID(p.id))
	})
}

export const DeleteExternalSetting = (p) => (dispatch) => {
	dispatch(deleteOrganisationExternalSetting(p))
	.then(()=>{
		dispatch(FetchOrganisationByID(p.id))
	})
}

export const SaveAreaExternalSetting = (p) => (dispatch) => {
	dispatch(saveAreaExternalSetting(p))
	.then(()=>{
		dispatch(FetchOrganisationByID(p.id))
	})
}