import {
  CHATBOTS_ADD_QUESTION,
  CHATBOTS_CLEAR_ALL_QUESTIONS,
  CHATBOTS_EDIT_FIELD,
  CHATBOTS_FINISH_EDIT,
  CHATBOTS_START_EDIT,
  CHATBOTS_UPDATE_UI_FIELD,
  CHATBOTS_RESET_INTENT_LIST
} from '../constants/constants'
import { adminActionMap, adminEditActionMap } from '../reducers/chatbot'
import { editHeaderAlertData } from './hmf'

export const {
  [CHATBOTS_ADD_QUESTION]: addQuestion,
  [CHATBOTS_CLEAR_ALL_QUESTIONS]: clearAllQuestions
} = adminActionMap

export const {
  [CHATBOTS_START_EDIT]: updateAdminStartEdit,
  [CHATBOTS_EDIT_FIELD]: updateAdminEditField,
  [CHATBOTS_FINISH_EDIT]: updateAdminFinishEdit
} = adminEditActionMap

export const editSelectedTrainPhrase = selected => editHeaderAlertData({
  selected: { $set: selected }
})

export const updateChatbotUIField = (field, value) => ({
  type: CHATBOTS_UPDATE_UI_FIELD,
  payload: { field, value }
})

export const resetIntentList = (id, list) => ({
  type: CHATBOTS_RESET_INTENT_LIST,
  payload: {id, list}
})