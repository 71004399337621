import {
  AWSSES_EDIT_FIELD,
  AWSSES_FINISH_EDIT,
  AWSSES_START_EDIT
} from '../constants/constants'
import { adminEditActionMap } from '../reducers/awsses'

export const {
  [AWSSES_START_EDIT]: updateAdminStartEdit,
  [AWSSES_EDIT_FIELD]: updateAdminEditField,
  [AWSSES_FINISH_EDIT]: updateAdminFinishEdit
} = adminEditActionMap
