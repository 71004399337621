import each from 'lodash/each'
import update from 'immutability-helper'
import { DATA_SYNC } from '../constants/constants'

// constants that match Golang package 'c3/wire/Field'. ONLY export those
// 'field' that can be requested to refresh.
const COLLABORATION_LIGHT = 1
export const ERRAND_COLLABORATION_STATUS = 2
const COLLABORATION_LIST = 3
const HISTORY = 4

// constants that match Golang package 'c3/wire/Target'. Any reducer that update
// field MUST put register a name there.
export const CHAT_LIST = 1
export const CURRENT_CHAT = 2
// TODO: NOTE1
export const ERRAND_BASIC = 3
export const ERRAND_CONTACTS = 4
export const ERRAND_LIST = 5
export const DOMAIN_BASICERRANDS = 6
export const DOMAIN_COLLABORATION_LIST = 7
export const DOMAIN_HISTORY = 8
export const ERRAND_HISTORIES = 9

const chatErrandUpdater = (chat, updator) => update(chat, { errand: updator })

const chatErrandTarget = {
  select: state => state.errand,
  updater: chatErrandUpdater
}

const getChatBySession = (
  chat,
  { payload: { sessionId } }
) => chat.sessionId === sessionId

const chatTarget = { updater: chatErrandUpdater, check: getChatBySession }

const basicErrandTarget = {
  select: state => state.data,
  updater: (state, updator) => update(state, { data: updator })
}

const checkThread = (
  state,
  action
) => state.threadId == action.payload.data.thread

const normalizedTarget = {
  select: state => state.byId,
  updater: (state, updator) => update(state, { byId: updator })
}

const normalizedTargetId = idGetter => ({
  select: (state, action) => state.byId[idGetter(state, action)],
  updater: (state, updator, action) => update(
    state,
    { byId: { [idGetter(state, action)]: updator } }
  )
})

// Both 'condition' and 'updator' MUST be independent of redux branch but
// dependent of data structure from backend. 'targets' MUST list all the reducer
// that listen to the data sync and provide info how to select and update data
// for 'condition' and 'updator' respectively. 'targets[x].check' acts as
// 'condtion' and is used  to fine tune condition that change depending reducer.
// 'targets[x].check' is higher precedence than 'condition'.
const checkAndUpdaters = {
  [COLLABORATION_LIGHT]: {
    targets: {
      [CHAT_LIST]: chatErrandTarget,
      [CURRENT_CHAT]: chatErrandTarget,
      [ERRAND_BASIC]: basicErrandTarget
    },
    condition: checkThread,
    updator: ({ payload: { data: { light } } }) => ({
      data: {
        collaboration: {
          light: { $set: light }
        }
      }
    })
  },
  [COLLABORATION_LIST]: {
    targets: {
      [DOMAIN_COLLABORATION_LIST]: normalizedTarget
    },
    condition: (state, { payload: { data: { thread, errand } } }, result) => {
      const threads = state[errand]
      if (!threads) {
        return false
      }
      let found
      each(threads.list, ({ id }, index) => {
        if (id === thread) {
          found = { index }
          return false
        }
      })
      return found
    },
    updator: ({ payload: { data: { errand, read, light } } }, index) => ({
      [errand]: {
        extra: {
          light: { $set: light }
        },
        list: {
          [index]: {
            read: { $set: read }
          }
        }
      }
    })
  },
  [ERRAND_COLLABORATION_STATUS]: {
    targets: {
      [CHAT_LIST]: chatTarget,
      [CURRENT_CHAT]: chatTarget,
      [ERRAND_BASIC]: basicErrandTarget
    },
    updator: ({ payload: { data } }) => ({
      data: {
        collaboration: { $set: data }
      }
    })
  },
  [HISTORY]: {
    targets: {
      [DOMAIN_HISTORY]: normalizedTargetId(
        (_, action) => action.payload.data.threadId
      ),
      [ERRAND_HISTORIES]: true
    },
    condition: (state, { payload: { data: { eid } } }, result) => {
      if (!state) {
        return false
      }
      let found
      each(state.data, ({ eid: _eid }, index) => {
        if (eid === _eid) {
          found = { index }
          return false
        }
      })
      return found
    },
    updator: ({ payload: { data } }, index) => ({
      data: {
        [index]: {
          $set: data
        }
      }
    })
  }
}

const isValidTarget = (targets, name) => {
  // TODO: look wrong condition as targets should be true and targets[name] true
  if (!targets || targets[name]) {
    return true
  }
}

export const createDataSyncReducer = reducer => (state, action) => {
  if (action.type !== DATA_SYNC) {
    return state
  }
  return reducer(state, action)
}

const createChecker = (checker, select) => {
  if (typeof select === 'function') {
    return (state, ...args) => checker(select(state, ...args), ...args)
  }
  return checker
}

const decodeWrappedJSON = wrapped => {
  if (typeof wrapped === 'string') {
    try {
      return JSON.parse(wrapped)
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('error decode wrapped JSON string:', e)
      }
    }
  }
}

const c3JSONStringMarker = '_c3JSONString'

const checkAndDecodeAction = action => {
  const encoded = action.payload.data[c3JSONStringMarker]
  if (typeof encoded === 'undefined') {
    return action
  }
  return update(
    action, {
      payload: {
        data: {
          $apply: original => decodeWrappedJSON(encoded) || original
        }
      }
    }
  )
}

// Use this to create reducer that update the data. So far only chat list and
// current chat get updated.
// TODO: put a reducer at any redux store that has the basic errand data struct.
// See NOTE1.
//       1) domain basic errand.
//       2) errand list.
//       3) other contacts basic errand list.
//       4) errand fetch basic data.
export const createSetupCondtionReducer = (
  reducer,
  targetName
) => (state, action) => {
  action = checkAndDecodeAction(action)
  const { payload } = action
  const { field, targets } = payload
  const handler = checkAndUpdaters[field]
  if (!isValidTarget(targets, targetName) || typeof handler === 'undefined') {
    return state
  }
  const { condition, updator } = handler
  if (typeof updator !== 'function' ||
    !handler.targets ||
    !handler.targets[targetName]) {
    if (process.env.NODE_ENV !== 'production') {
      console.trace('dbg: error wrong updater:', targetName, field)
    }
    return state
  }
  let checker
  const { targets: { [targetName]: { check, select, updater } } } = handler
  if (typeof check === 'function') {
    checker = check
  } else if (typeof condition === 'function') {
    checker = condition
  } else {
    if (process.env.NODE_ENV !== 'production') {
      console.trace('dbg: no valid condition checker:', targetName, field)
    }
    return state
  }
  let changer
  if (typeof updater === 'function') {
    changer = (state, ...args) => updater(
      state,
      updator(action, ...args),
      action
    )
  } else {
    changer = (state, ...args) => update(state, updator(action, ...args))
  }
  return reducer(state, action, createChecker(checker, select), changer)
}
