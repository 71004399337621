import each from 'lodash/each';
import update from 'immutability-helper';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import { I } from '../../../common/v5/config';
import { doNothing } from '../../../common/constants';
import {
	allOrgAreasToAreaIDsString
	, getInnerGroupIDsFromGroupedArray
} from '../../../common/helpers';
import {
	createPromiseControllers
	, pleaseWaitString
} from '../../../common/v5/helpers';
import {
	getAdminLeaderboard,
	getAdminPinReports,
	getAdminReports,
	getAdminReportsExport,
	getAdminReportsExportV5,
	getAdminReportsOverviewLayout,
	getGroups,
	getOneAdminPinReports,
	getTimeTimespan,
	getTimeTimespanItems,
	getTimeformat,
	getTimezones,
	getCollaboratorList,
	deleteAdminPinReports,
	postAdminReportsOverviewLayout,
	postAdminPinReports,
	postOneAdminPinReports,
	postOneAdminReports,
	postOneAdminReportsDrilldown,
	postScheduleReport,
	getScheduleReport,
	deleteScheduleReport,
	putScheduledReport,
	reportDrilldownExportREST,
	postShareReport,
	getSavedReport,
	getReportKeys,
	getReportGroups,
	postReportPreview,
	putAdminReports,
	postAdminReports,
	deleteCustomReport,
	getOneTimeSpan,
	deleteTimeConfig,
	postTimeSpan,
	putTimeSpan
} from './ajax';
import {
	keyAdminLeaderboard,
	keyAdminOneReports,
	keyAdminPinReports,
	keyAdminReportDrilldown,
	keyAdminReports,
	keyAdminReportsExport,
	keyAdminTagSimpleList,
	keyAgents,
	keyCreateAdminPinReports,
	keyDeleteOneAdminPinReports,
	keyGetReportsOverviewLayout,
	keyGroups,
	keyOneAdminPinReports,
	keySaveReportsOverviewLayout,
	keyTimeTimespan,
	keyTimeformat,
	keyTimeTimezones,
	keyUpdateOneAdminPinReports,
	keyCreateScheduleReport,
	keyShowScheduledReports,
	keyShareReportDone,
	keyGetSavedReport,
	keyAdminCustomReports,
	keyGetReportKeys,
	keyGetReportGroups,
	keyReportPreview,
	keyCustomTimeSpan,
	keyTimespanFormats,
	keyCollaboratorList
} from '../../constants/keys';
import {
	AT_ALL,
	CL_OVERVIEW,
	CL_REPORT,
	CS_EXIST,
	CS_PIN,
	CS_REPORT,
	CS_SPECIAL,
	// CT_EXPANDABLE_TABLE,
	CT_GENERAL_BAR,
	CT_GENERAL_TABLE,
	CT_LEADERBOARD,
	CT_LINE,
	CT_VERT_BAR,
	CT_PIE,
	CT_CONTACTSTOPLIST,
	DT_ABSOLUTE,
	DT_RELATIVE,
	DT_ERRANDS,
	ET_EXCEL,
	LT_CLOSED,
	LT_HANDLING_AND_CLOSED,
	LT_HANDLING_TIME,
	OC_ACTIVE_AGENTS,
	OC_LEADERBOARD,
	OC_ORGANISATION,
	OL_MID_PANE,
	OL_RIGHT_PANE,
	RL_LEFT_PANE,
	RL_RIGHT_PANE,
	RL_BOTTOM_PANE,
	RL_COMPARE_PANE,
	RT_CHART,
	RT_TABLE,
	RDT_SAME_DAY,
	// RDT_SAME_MONTH,
	SCHEDULED_REPORT,
	VIEW_REPORT,
	emptyArray,
	SR_INCOMING,
	SR_CHAT_SESSIONS,
	SR_CLOSED,
	SR_REPLIES,
	SR_AVG_RESP_TIME,
	SR_AVG_UNANS_TIME,
	SR_AVG_HANDLE_TIME,
	SR_CLOSE_STATUS,
	SR_SLR,
	SR_LEADERBOARD,
	SR_HANDLE_TIME,
	SR_RESP_TIME,
	SR_CLASSIFY_AREA,
	SR_CLASSIFY_CHANNEL,
	SR_DEL_ERRANDS,
	SR_INC_CHAT_REQ,
	SR_SATISFY_METER,
	SR_USER_ACTIVITY,
	SR_AVR_CHAT_MSG_RESP_TIME,
	SR_AVR_CHAT_RESP_TIME,
	SR_CAP_UTIL_CHAT,
	SR_CLOSED_UNANSWERED,
	SR_ORG_OVERVIEW,
	SR_ACTIVE_AGENT,
	SR_FACEBOOK_RATING,
	SR_COLLABORATION,
	SR_NUMBER_OF_CONTACTS,
	SR_CLOSED_AND_ANSWERED,
	SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP,
	SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION,
	SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP,
	SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION,
	SR_CHAT_RATING,
	SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP,
	SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION,
	SR_INBOUND_AND_OUTBOUND_CALLS,
	SR_SIP_MISSED_CALLS,
	SR_SIP_ABANDONED_CALLS,
} from '../../../common/v5/constants';
import { PC_NEW_CHART_LAYOUT } from '../../../common/v5/statisticConstants';
import {
	async
	, autoPolling
	, loadOnceCreator
	, multiAsync
	, triggerActionWhenFinish
	, getAreasIDViaOrgObj
} from '../../util';
import { generateCentionWebURL } from './common';
import {
	clearPopWaiting
	, popWaitingWithoutCatch
	, togglePopAlert
} from '../hmf';
import { normalizeAgents } from '../workflow';
import {
	getWorkflowState
	, onceAdminTagSimpleList
	, onceAgentAreas
	, onceAgents
	, onceAreas
	, onceFolders
	, onceOrganizationOverviewAreas
	, fetchAvatarByClient
} from './workflow';
import { postDownloadJSON, getAgentAreasAndDefaultEmpty } from './errand';
import {
	changeView,
	changeOverviewGrid,
	clearChartDataById,
	removeOverviewChartContent,
	requestChartImageData,
	// showHideDrilldownDataTable,
	showDrilldownDataTable,
	statisticsReady,
	storeDrilldownParamters,
	updateOverviewChartMap,
	updateOverviewGrid,
	updateReportLayout,
	updateReportParamId,
	updateReportCompareParamId,
	updateScheduleReport,
	// updateChartParameters,
	updateShareParam,
	openCreateReport,
	updateCreateReport,
	resetCreateReport,
	toggleCreateReport,
	toggleConfigTime,
	openConfigTimeForm,
	updateConfigTime,
	resetConfigTime
} from '../statistics';
import {
	chartIdentifier,
	getChartUpdater,
	getPinReportParam,
	initGridData,
	normalizedExecutiveSystemReportLayout,
	statisticsMap as statistics
} from '../../reducers/statistics';
import {
	onlyActiveAgentsSelector,
	onlyActiveAreasSelector,
	activeAreaListSelector
} from '../../selectors/workflow';
import {
	getCurrentStatus
} from '../../selectors/hmf';
import {
	chartIdAndInfo,
	chartsLayoutMemo,
	getCurrentReportChartId,
	getFinalTargetChart,
	getReportsById,
	getStartDay as _getStartDay,
	getSystemReportsByName,
	getStatisticsRoot,
	getStatisticsState,
	normalizeOverviewLayoutMemo,
	onlyActiveStatisticAreasSelector,
	overviewChartMapMemo,
	overviewGridMemo,
	plainOverviewGridLayout,
	reportDataFromChartLayoutId
} from '../../selectors/statistics';
import { getQueueChat, getFullChatStatus } from './chat';
import { adminActionAlert, adminActionStatus, toggleAdminEdit } from '../admin';

const getExportedReport = url => window.location = generateCentionWebURL(url);

const exportReportBase = (reportId, parameter) => async(
	getAdminReportsExportV5(reportId, parameter)
	, statistics[keyAdminReportsExport]
	, {reportId}
);

export const exportReport = (reportId, parameter) => dispatch =>
	dispatch(exportReportBase(reportId, parameter))
		.then(result => {
			if (!result || !result.uri) {
				return Promise.reject("error exporting report: " + result);
			}
			getExportedReport(result.uri);
		});

function oneReportDrilldownCreator(ajax, key) {
	return (id, parameter) => async(ajax(id, parameter), statistics[key]);
}

const oneReportDrilldown = oneReportDrilldownCreator(
	postOneAdminReportsDrilldown
	, keyAdminReportDrilldown
);

const leaderboard = (relativeDateType, leaderBoardType, oclayout) =>
	async(
		getAdminLeaderboard({relativeDateType, leaderBoardType}),
		statistics[keyAdminLeaderboard],
		{relativeDateType, leaderBoardType, oclayout}
	);

const bothLeaderboards = (relativeDateType, layout) => dispatch => {
	return dispatch(leaderboard(relativeDateType, LT_CLOSED, layout))
		.then(() =>
			dispatch(leaderboard(RDT_SAME_DAY, LT_HANDLING_TIME, layout)));
};

const leaderboards = (layout, {relativeDateType}) =>
	leaderboard(relativeDateType, LT_HANDLING_AND_CLOSED, layout);

const initReportInputParams = {
		reportType: RT_CHART
		, dateType: DT_ABSOLUTE
		, relativeDateType: RDT_SAME_DAY
		, agentType: AT_ALL
		, completeResult: false
		, aggregateType: DT_ERRANDS
	}
	;
// const startOfDay = (startDayUnix, offset) => {
// 	console.log('dbg: start day');
// 	if (!offset) {
// 		offset = "+0000";
// 	}
// 	return moment.unix(startDayUnix).utcOffset(offset).startOf('day')
// 		.format('YYYY/MM/DD');
// };
//
// const memoizedStartOfDay = memoizeOne(startOfDay);

function getIDsFromArray(array, idField, condition) {
	if (!array || !array.length) {
		return emptyArray;
	}
	let ids = [];
	$.each(array, (i,v) => {
		if (typeof condition === 'function' && !condition(v)) {
			return;
		}
		ids.push(v[idField]);
	});
	return ids;
}

function getIDsFromObject(obj, arrayField, idField, condition) {
	return getIDsFromArray(obj[arrayField], idField, condition);
}

function findSmallestID(obj, arrayField, idField) {
	if (!obj || !obj[arrayField]) {
		return 0;
	}
	const array = obj[arrayField].slice().sort((a, b) => {
			if (a[idField] < b[idField]) {
				return -1;
			}
			if (a[idField] > b[idField]) {
				return 1;
			}
			// a must be equal to b
			return 0;
		});
	return array[0][idField];
};

const allAreaIDs = areas => allOrgAreasToAreaIDsString(areas);

const memoizedAllAreaIDs = memoizeOne(allAreaIDs);

const allChannelTypes = channels => {
	console.log('dbg: channels');
	let chns = [];
	$.each(channels, k => {
		chns.push(k);
	});
	return chns.join(',');
};

const memoizedAllChannelTypes = memoizeOne(allChannelTypes);

const allTagIDs = (simpleTags, includeUntagged) => {
	console.log('dbg: simple-tags');
	if (!simpleTags || !simpleTags.tags) {
		return "";
	}
	let tags = getInnerGroupIDsFromGroupedArray(simpleTags.tags, 'Tags', 'id');
	if (includeUntagged) {
		tags = tags.slice();
		tags.push(0);
	}
	return tags.join(',');
};

const memoizedAllTagIDs = memoizeOne(allTagIDs);

const allAgentIDs = agents => getIDsFromArray(agents, 'Id').join(',');

const memoizedAllAgentIDs = memoizeOne(allAgentIDs);

const allGroupIDs = groups =>
	getIDsFromObject(groups, 'groups', 'Id').join(',');

const memoizedAllGroupIDs = memoizeOne(allGroupIDs);

const myTimezoneID = timezones => {
	console.log('dbg: my timezone');
	if (!timezones || !timezones.userTimeZone) {
		return 0;
	}
	return timezones.userTimeZone.Id;
};

const memoizedMyTimezoneID = memoizeOne(myTimezoneID);

const smallestTimespanID = timespans => {
	console.log('dbg: timespans');
	return findSmallestID(timespans, 'timespan', 'Id');
};

const memoizedSmallestTimespanID = memoizeOne(smallestTimespanID);

const smallestTimeformatID = timeformats => {
	console.log('dbg: custom timeformat');
	return findSmallestID(timeformats, 'timeformats', 'Id');
};

const memoizedSmallestTimeformatID = memoizeOne(smallestTimeformatID);

const getStartDay = _getStartDay;

const defReportInputParams = (state, withExport, reportId) => {
	const wf = state.app.workflow
		, { agentTimezoneOffset } = wf.fetchWfSettings.data
		, startDay = getStartDay(agentTimezoneOffset)
		, s = update(initReportInputParams, {$merge: {
				startTime: startDay
				, endTime: startDay
				, users: memoizedAllAgentIDs(onlyActiveAgentsSelector(state))
				, groups: memoizedAllGroupIDs(
					getStatisticsState(state, keyGroups).data
				)
				// previously base on areas parse from server which equivalent
				// to '/areas' endpoint as it changes base on user permission.
				, areas: memoizedAllAreaIDs(onlyActiveStatisticAreasSelector(
					state, {reportId}
				))
				, channels: memoizedAllChannelTypes(state.server.services.byId)
				, tags: memoizedAllTagIDs(getWorkflowState(state,
					keyAdminTagSimpleList).data, true)
				, timeStampFormat: memoizedSmallestTimespanID(
					getStatisticsState(state, keyTimeTimespan).data)
				, timeFormat: memoizedSmallestTimeformatID(
					getStatisticsState(state, keyTimeformat).data)
				, timeZoneId: memoizedMyTimezoneID(
					getStatisticsState(state, keyTimeTimezones).data)
				, collaborators: ""
			}})
		;
	if (!withExport) {
		return s;
	}
	return update(s, {$merge: {exportFormat: ET_EXCEL}});
};

const defReportsParamsWithExport = state => defReportInputParams(state, true);

const loadOnce = (key, ajax) =>
	loadOnceCreator(statistics, key, ajax, getStatisticsState);

const onceReports = loadOnce(keyAdminReports, getAdminReports);

const onceGroups = loadOnce(keyGroups, getGroups);

const onceTimespan = loadOnce(keyTimeTimespan, getTimeTimespan);

const onceTimeformat = loadOnce(keyTimeformat, getTimeformat);

const onceTimezones = loadOnce(keyTimeTimezones, getTimezones);

const onceTimespanItems = loadOnce(keyTimespanFormats, getTimeTimespanItems);

const onceCollaborators = loadOnce(keyCollaboratorList, getCollaboratorList);

const _onceOverviewLayout = loadOnce(
	keyGetReportsOverviewLayout
	, getAdminReportsOverviewLayout
);

const onceOverviewLayout = () => (
	dispatch
	, getState
) => {
	if (!PC_NEW_CHART_LAYOUT) {
		return Promise.resolve();
	}
	return dispatch(_onceOverviewLayout())
	.then(({ content }) => {
		if (content) {
			dispatch(changeOverviewGrid(content, getState()));
		}
	})
	.catch(err => {
		console.log("error getting overview layout:", err);
	});
};

const _oncePinReports = loadOnce(keyAdminPinReports, getAdminPinReports);

const oncePinReports = () => dispatch => dispatch(_oncePinReports())
	.then(() => dispatch(onceOverviewLayout()));

const saveOverviewLayoutBase = content => async(
	postAdminReportsOverviewLayout({content})
	, statistics[keySaveReportsOverviewLayout]
);

const saveOverviewLayout = content => saveOverviewLayoutBase(content);

const asyncGetChartData = {
	[OC_LEADERBOARD]: (id, type, param, layout, robotAuto) => leaderboards(layout, param)
};

const oneOverviewChart = (
	id
	, type
	, param
	, oclayout
	, robotAuto
	, loadClientAvatar
) => {
	let cancel;
	const o = (dispatch, getState) => {
		const status = getCurrentStatus(getState());
		if (robotAuto && status && status.status != "Available") {
			return Promise.reject({err: "skip polling"});
		} else {
			const async = multiAsync(
				postOneAdminReports(
					id
					, robotAuto ? update(param, {autoRefresh: {$set: true}}) : param
				)
				, statistics[keyAdminOneReports]
				, {endpointParam: {id, type}, param, oclayout}
			);
			async.setCancelState(cancel);
			return dispatch(async)
			.then((ret) => {
				if (loadClientAvatar) {
					return dispatch(fetchAvatarByClient(robotAuto))
					.catch(err => {
						console.log("error when fetch avatar for report:", err);
					})
					.then(() => ret);
				}
				return ret;
			});
		}
	};
	o.cancel = _cancel => cancel = _cancel;
	return o;
};

function mergeInitParam(initParam, param) {
	if (!param) {
		param = initParam;
	} else if (initParam) {
		param = update(param, {$merge: initParam});
	}
	return param;
}

const initChartPeriodicHandlers = initGridData => {
	const handlers = {};
	each(initGridData, (_, k) => {
		handlers[k] = {};
	});
	return handlers;
};

const CHART_POLLING_TIME = 60000;

const chartPeriodicHandlers = initChartPeriodicHandlers(initGridData);

function gridChartHandler({ layout, idx }) {
	const obj = {};
	obj.get = () => chartPeriodicHandlers[layout][idx];
	obj.set = handler => chartPeriodicHandlers[layout][idx] = handler;
	obj.remove = () => { delete chartPeriodicHandlers[layout][idx] };
	return obj;
}

function chartHandler(chartId) {
	const obj = {};
	obj.get = () => chartPeriodicHandlers[chartId];
	obj.set = handler => chartPeriodicHandlers[chartId] = handler;
	obj.remove = () => { delete chartPeriodicHandlers[chartId] };
	return obj;
}

function getChartPeriodicHandler(chartId) {
	if (typeof chartId === "object") {
		return gridChartHandler(chartId);
	}
	return chartHandler(chartId);
}

const getChartAsync = (store, id, type, oclayout, initParam, param) => {
	let chartAsync
	, loadClientAvatar = false
	;
	if (type === CS_SPECIAL) {
		if (typeof oclayout === "object") {
			throw "grid chart currently do not support special endpoint chart source";
		}
		chartAsync = asyncGetChartData[id];
		if (!chartAsync) {
			return {error: new Error('unhandle special async chart')};
		}
	} else {
		if (type === CS_PIN) {
			if (!param) {
				if (!initParam) {
					return {
						error: new Error('no previous pin report parameter')
					};
				}
				param = initParam;
			}
			id = param.reportId;
			// delete param.reportId; // request report no need reportId field
		} else if (type === CS_REPORT) {
			let reports;
			const typeofId = typeof id;
			if (typeofId === "string") {
				reports = getSystemReportsByName(store);
			} else if (typeofId === "number") {
				reports = getReportsById(store);
			} else {
				return {error: new Error('unsupportted id type for CS_REPORT')};
			}
			if (!reports) {
				return {error: new Error('no reports data from server')};
			}
			const report = reports[id];
			if (!report) {
				return {
					error: new Error('report '+id+' not found in database')
				};
			}
			if (report.hasKeyFigureThatFilterByClient) {
				loadClientAvatar = true;
			}
			id = report.Id;
			if (!param) {
				// getting report can not have empty param.
				param = defReportInputParams(store, false, id);
			}
		} else {
			return {error: new Error('invalid report source type')};
		}
		chartAsync = oneOverviewChart
	}
	const creator = robotAuto => chartAsync(
		id
		, type
		, mergeInitParam(initParam, param)
		, oclayout
		, robotAuto
		, loadClientAvatar
	);
	return {async: creator(), creator};
};

const dipatchChartPeriodicAsync = (dispatch, chartAsyncCreator, oclayout) => {
	const hc = getChartPeriodicHandler(oclayout);
	let h = hc.get();
	if (!h) {
		h = autoPolling(chartAsyncCreator, CHART_POLLING_TIME);
		hc.set(h);
	}
	return dispatch(h.start());
};

const chartFetchAsync = (
	id
	, type
	, oclayout
	, initParam
	, param
) => (dispatch, getState) => {
	const {
		creator
		, error
	} = getChartAsync(getState(), id, type, oclayout, initParam, param);
	if (error) {
		return Promise.reject({err: error});
	}
	return dipatchChartPeriodicAsync(dispatch, creator, oclayout);
};

export const chartOnload = (
	{ id, type, param: initParam }
	, layout
	, position
	, idx
	, index
	, param
) => chartFetchAsync(
	id
	, type
	, chartIdentifier(layout, position, idx, index)
	, initParam
	, param
);

function clearPreviousHandlers(dispatch, layout, position, idx, index) {
	const chartId = chartIdentifier(layout, position, idx, index)
		, handler = getChartPeriodicHandler(chartId)
		, previousHandlers = handler.get()
		;
	if (previousHandlers) {
		handler.remove();
		dispatch(previousHandlers.stop());
	}
	return chartId;
}

// TODO: useless.
const chartReloadCreator = (
	id
	, type
	, initParam
	, layout
	, position
	, idx
	, index
	, param
) => dispatch => {
	const oclayout = clearPreviousHandlers(
		dispatch
		, layout
		, position
		, idx
		, index
	);
	return dispatch(chartFetchAsync(id, type, oclayout, initParam, param));
};

// When an existing chart that is polling data from backend and its parameters
// change from front-end, then procedures will first be stop the previous
// polling and recreate new periodic handlers with new updated parameters.
// TODO: useless.
const updateChartParam = (
	{ id, type }
	, layout
	, position
	, idx
	, index
	, param
) => chartReloadCreator(
	id
	, type
	, null
	, layout
	, position
	, idx
	, index
	, param
);

const dummyFunction = doNothing;

export const chartOnUnload = (id, layout, position, idx, index) => {
	const oclayout = chartIdentifier(layout, position, idx, index)
		, hc = getChartPeriodicHandler(oclayout)
		, h = hc.get()
		;
	if (process.env.NODE_ENV !== 'production') {
		console.log("dbg: chart unload async:", layout, idx, id, oclayout);
	}
	hc.remove();
	if (!h) {
		return dummyFunction;
	}
	return h.stop();
};

const onceAgentsAndNormalize = () => triggerActionWhenFinish(onceAgents(true), normalizeAgents());

const statisticsOnLoadBase = () => (dispatch, getState) => {
	const dispatches = [
		dispatch(oncePinReports())
		, dispatch(onceAgentAreas())
		, dispatch(onceAgentsAndNormalize())
		, dispatch(onceFolders())
		// , dispatch(onceAreas())
		, dispatch(onceOrganizationOverviewAreas())
		, dispatch(onceReports())
		, dispatch(onceGroups())
		, dispatch(onceTimespan())
		, dispatch(onceTimeformat())
		, dispatch(onceTimezones())
		, dispatch(onceAdminTagSimpleList())
	];
	dispatches.push(dispatch(onceCollaborators()));
	return $.when(...dispatches).then(() => {
			if (!features["cention-reports"] &&
				!features["admin.show-executive-report"] &&
				features["agent-data-overview-statistics"]) {
					const store = getState(),
					reports = getSystemReportsByName(store),
					report = reports[SR_ACTIVE_AGENT]
					;

					dispatch(switchReportPageByReportData(VIEW_REPORT,
						report, {id: report.Id}));
			}
			dispatch(statisticsReady());
		});
};

// TODO: refactor this to use createBroadcaster.
const createStatisticsOnLoad = () => {
	let p = null;
	return () => dispatch => {
		if (p) {
			return p;
		}
		p = new Promise((resolve, reject) => {
			dispatch(statisticsOnLoadBase())
				.then(() => resolve())
				.catch(() => reject())
				.then(() => {
					p = null;
				})
		});
		return p;
	};
};

export const statisticsOnLoad = createStatisticsOnLoad();

// object map which system report report need custom layout creator. The value
// is function that will be passed a parameter (param) that may contains an
// object of requested report parameter during the switch to report page action.
// 'param' must take into account during layout creation. The function must
// return object that carry the report page layout as keys.
const MAP_SYSTEM_REPORTS_REPORT_PANES = {
	// [SR_LEADERBOARD]: param => ({
	// 	[RL_RIGHT_PANE]: {
	// 		id: {
	// 			layout: CL_OVERVIEW
	// 			, position: OL_MID_PANE
	// 			, index: 0
	// 		}
	// 		, type: CS_EXIST
	// 		, param
	// 	}
	// 	, [RL_LEFT_PANE]: undefined // NOTE: for future reference
	// })
	// , [SR_ORG_OVERVIEW]: param => ({
	// 	[RL_RIGHT_PANE]: {
	// 		id: {
	// 			layout: CL_OVERVIEW
	// 			, position: OL_RIGHT_PANE
	// 			, index: 0
	// 		}
	// 		, type: CS_EXIST
	// 		, param
	// 	}
	// })
};

const createExistChart = (layout, position, idx, index, chart) => {
	const result = {id: {layout, position, idx, index}, type: CS_EXIST};
	if (typeof chart !== "undefined") {
		result.chart = chart;
	}
	return result;
};

const defaultReportLeftPane = () => createExistChart(
	CL_REPORT
	, RL_RIGHT_PANE
	, null
	, 0
	, CT_GENERAL_BAR // TODO: change to CT_VERT_BAR to reduce duplications
);

const defaultReportComparePane = () => createExistChart(
	CL_REPORT
	, RL_COMPARE_PANE
	, null
	, 0
	, CT_GENERAL_BAR
);

const defaultReportPageParam = {
	dateType: DT_RELATIVE
	, relativeDateType: RDT_SAME_DAY
};

const defaultReportRightPane = (report, param) => {
	if (!param) {
		param = defaultReportPageParam;
	}
	return {
		id: report.SystemReport ? report.Name : report.Id
		, type: CS_REPORT
		, param
	};
};

const defaultReportBottomPane = () => createExistChart(
	CL_REPORT
	, RL_RIGHT_PANE
	, null
	, 0
	, CT_GENERAL_TABLE // CT_EXPANDABLE_TABLE
);

const genReportPanesFromOverview = (layout, position, idx, index) => [
	defaultReportLeftPane()
	, createExistChart(layout, position, idx, index)
	, defaultReportBottomPane()
	, createExistChart(layout, position, idx, index)
	, defaultReportComparePane()
];

function checkAndUpdateOneReportPane(panes, which, defaultCreator) {
	const customPane = panes[which];
	if (typeof customPane === "undefined") {
		return defaultCreator();
	}
	return customPane;
}

function checkAndUpdateReportPanesBase(report, param) {
	const reportName = report.Name;
	if (!report.SystemReport || typeof MAP_SYSTEM_REPORTS_REPORT_PANES[reportName] === "undefined") {
		return;
	}
	const creator = MAP_SYSTEM_REPORTS_REPORT_PANES[reportName];
	if (typeof creator !== "function") {
		return;
	}
	const customPanes = creator(param)
		, left = checkAndUpdateOneReportPane(customPanes, RL_LEFT_PANE, () => defaultReportLeftPane())
		, right = checkAndUpdateOneReportPane(customPanes, RL_RIGHT_PANE, () => defaultReportRightPane(report, param))
		, bottom = checkAndUpdateOneReportPane(customPanes, RL_BOTTOM_PANE, () => defaultReportBottomPane())
		, compare = checkAndUpdateOneReportPane(customPanes, RL_COMPARE_PANE, () => defaultReportRightPane(report, param))
		, subCompare = checkAndUpdateOneReportPane(customPanes, RL_COMPARE_PANE, () => defaultReportComparePane())
		;
	return [left, right, bottom, compare, subCompare];
}

// if the requested system report 'report' appear in excecutive report then
// it'll uses the executive report settings as its top right chart.
function checkAndUpdateExecutiveSystemReport(report, param, overviewLayout) {
	const reportName = report.Name;
	if (!report.SystemReport || !overviewLayout[reportName]) {
		return;
	}
	const { chart, position, idx, index } = overviewLayout[reportName];
	return genReportPanesFromOverview(CL_OVERVIEW, position, idx, index);
}

function moreCheckAndUpdateReportPanes(report, param, overviewLayout) {
	const result = checkAndUpdateReportPanesBase(report, param);
	if (result) {
		return result;
	}
	return checkAndUpdateExecutiveSystemReport(report, param, overviewLayout);
}

// allow custom changes to whole system report front-end display base on map.
// See: MAP_SYSTEM_REPORTS_REPORT_PANES for more information. If no custom
// layout then it'll check if the system report appear in executive reports,
// then generate layout base on it.
function checkAndUpdateReportPanes(report, param, overviewLayout) {
	const result = moreCheckAndUpdateReportPanes(report, param, overviewLayout);
	if (!result) {
		return [
			defaultReportLeftPane()
			, defaultReportRightPane(report, param)
			, defaultReportBottomPane()
			, defaultReportRightPane(report, param)
			, defaultReportComparePane()
		];
	}
	return result;
}

const MAP_SYSTEM_REPORTS_REPORT_CHART = {
	[SR_CHAT_SESSIONS]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_INCOMING]: {
		[RL_LEFT_PANE]: CT_LINE,
		[RL_COMPARE_PANE]: CT_LINE
	},
	[SR_CLOSED]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_REPLIES]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_AVG_RESP_TIME]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_AVG_UNANS_TIME]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_AVG_HANDLE_TIME]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_CLOSE_STATUS]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_SLR]: {
		[RL_LEFT_PANE]: CT_LINE,
		[RL_COMPARE_PANE]: CT_LINE
	},
	[SR_LEADERBOARD]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_HANDLE_TIME]: {
		[RL_LEFT_PANE]: CT_LINE,
		[RL_COMPARE_PANE]: CT_LINE
	},
	[SR_RESP_TIME]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_CLASSIFY_AREA]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_CLASSIFY_CHANNEL]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_DEL_ERRANDS]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_INC_CHAT_REQ]: {
		[RL_LEFT_PANE]: CT_LINE,
		[RL_COMPARE_PANE]: CT_LINE
	},
	[SR_SATISFY_METER]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_USER_ACTIVITY]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_AVR_CHAT_MSG_RESP_TIME]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_AVR_CHAT_RESP_TIME]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_CAP_UTIL_CHAT]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_CLOSED_UNANSWERED]: {
		[RL_LEFT_PANE]: CT_LINE,
		[RL_COMPARE_PANE]: CT_LINE
	},
	[SR_ORG_OVERVIEW]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_ACTIVE_AGENT]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_FACEBOOK_RATING]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_COLLABORATION]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_NUMBER_OF_CONTACTS]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR,
		[RL_RIGHT_PANE]: CT_CONTACTSTOPLIST
	},
	[SR_CLOSED_AND_ANSWERED]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_CHAT_RATING]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_INBOUND_AND_OUTBOUND_CALLS]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
	[SR_SIP_MISSED_CALLS]: {
		[RL_LEFT_PANE]: CT_LINE,
		[RL_COMPARE_PANE]: CT_LINE
	},
	[SR_SIP_ABANDONED_CALLS]: {
		[RL_LEFT_PANE]: CT_VERT_BAR,
		[RL_COMPARE_PANE]: CT_VERT_BAR
	},
};

function checkAndUpdateCustomPaneChart(whichPane, customPanes, pane) {
	const customChart = customPanes[whichPane];
	if (typeof customChart !== "undefined") {
		pane.chart = customChart;
	}
}

// this allows system reports to change their chart type to different chart base
// MAP_SYSTEM_REPORTS_REPORT_CHART if the default auto generated chart type is
// not suitable for the system reports.
function checkAndUpdateSpecialSystemReportChart(
	report
	, left
	, right
	, bottom
	, compare
	, subCompare
	, chartFinder
) {
	if (!report.SystemReport) {
		return;
	}
	const customPanes = MAP_SYSTEM_REPORTS_REPORT_CHART[report.Name];
	if (typeof customPanes === "undefined") {
		return;
	}
	checkAndUpdateCustomPaneChart(RL_LEFT_PANE, customPanes, left);
	checkAndUpdateCustomPaneChart(RL_RIGHT_PANE, customPanes, right);
	checkAndUpdateCustomPaneChart(RL_BOTTOM_PANE, customPanes, bottom);
	checkAndUpdateCustomPaneChart(RL_COMPARE_PANE, customPanes, compare);
	checkAndUpdateCustomPaneChart(RL_COMPARE_PANE, customPanes, subCompare);

	// It is possible that right chart generate from overview chart which may
	// conflict with custom chart. When that happen, here we check and remove
	// right chart to let statistics selector to choose the chart base on report.
	if (right.type === CS_EXIST) {
		const chartId = chartFinder(right);
		if (chartId.chart === left.chart) {
			const newRight = defaultReportRightPane(report, chartId.param);
			right.id = newRight.id;
			right.type = newRight.type;
			right.chart = newRight.chart;
			right.param = newRight.param;
		}
	}
}

const reportPageLayoutCreator = (
	report
	, param
) => ({ chartFinder, normalizedOverviewLayout }) => {
	const [
		left
		, right
		, bottom
		, compare
		, subCompare
	] = checkAndUpdateReportPanes(report, param, normalizedOverviewLayout);
	checkAndUpdateSpecialSystemReportChart(
		report
		, left
		, right
		, bottom
		, compare
		, subCompare
		, chartFinder
	);
	return [[left], [right], [bottom], [compare],[subCompare]];
};

function finalTargetChart(store, chart, layout, position, idx, index) {
	return getFinalTargetChart(
		chartsLayoutMemo(store)
		, chart
		, getStatisticsState(store, keyAdminPinReports)
		, layout
		, position
		, idx
		, index
	);
}

function resolveTargetChart(
	store
	, chart
	, preLayout
	, prePos
	, preIdx
	, preIndex
) {
	let {
			id
			, layout
			, position
			, idx
			, index
		} = finalTargetChart(store, chart, preLayout, prePos, preIdx, preIndex)
		;
	if (typeof id === 'undefined') {
		id = chart;
	}
	if (typeof layout === 'undefined') {
		layout = preLayout;
	}
	if (typeof position === 'undefined') {
		position = prePos;
	}
	if (typeof idx === 'undefined') {
		idx = preIdx;
	}
	if (typeof index === 'undefined') {
		index = preIndex;
	}
	return {id, layout, position, idx, index};
}

function genReportPageLayout(report, param, state) {
	let normalizedOverviewLayout;
	if (typeof state === "undefined") {
		normalizedOverviewLayout = normalizedExecutiveSystemReportLayout;
	} else {
		normalizedOverviewLayout = normalizeOverviewLayoutMemo(state);
	}
	return reportPageLayoutCreator(report, param)({
		chartFinder: chartId => resolveTargetChart(
			state
			, chartId
			, null
			, null
			, null
			, null
		).id
		, normalizedOverviewLayout
	});
}

const getVolatileChartAsync = (chartID, layout, position, idx, index) => {
	let notFirstLoad;
	return robotAuto => (dispatch, getState) => {
		const store = getState()
			, identifier = {layout, position, idx, index}
			;
		let id, type, initParam, param;
		if (!chartID) {
			const chart = chartLayoutInfoMemo(store, identifier);
			if (!chart) {
				return Promise.reject({err: new Error(
					'no valid chart: ' + layout + ':' + position + ':' + index
				)});
			}
			const n = resolveTargetChart(
				store
				, chart
				, layout
				, position
				, idx
				, index
			);
			chartID = n.id;
			layout = n.layout;
			position = n.position;
			idx = n.idx;
			index = n.index;
		}
		id = chartID.id;
		type = chartID.type;
		const { id: oclayout, info } = chartIdAndInfo(store, identifier);
		if (info && info.param) {
			param = info.param;
		}
		if (!notFirstLoad) {
			notFirstLoad = true;
			initParam = chartID.param;
		}
		const {
			creator
			, error
		} = getChartAsync(store, id, type, oclayout, initParam, param);
		if (error) {
			return Promise.reject({err: error});
		}
		return dispatch(creator(robotAuto));
	};
};

const volatileChartAsyncCreator = getVolatileChartAsync;

const volatileChartAsync = (
	id
	, layout
	, position
	, idx
	, index
) => (dispatch, getState) => dipatchChartPeriodicAsync(
	dispatch
	, volatileChartAsyncCreator(id, layout, position, idx, index)
	, chartIdentifier(layout, position, idx, index)
);

const chartReload = volatileChartAsync;

// TODO: temporary useless
const dynamicChartAsync = (
	layout
	, position
	, idx
	, index
) => (dispatch, getState) => dipatchChartPeriodicAsync(
	dispatch
	, volatileChartAsyncCreator(null, layout, position, idx, index)
	, chartIdentifier(layout, position, idx, index)
);

// TODO: when report page need to support dynamic layout, then this function
// need to be refactored as it is now always assume static chart layout.
const switchReportPageBase = (view, data, reportLayout) => (dispatch, getState) => {
	let asyncs = [];
	dispatch(updateReportLayout(reportLayout));
	dispatch(changeView(view, data));
	const store = getState();
	$.each(reportLayout, (i, v) => {
		console.log("DBG: switchReportPageBase reportLayout i => ", i);
		$.each(v, (j, w) => {
			const {
				id
				, layout
				, position
				, idx
				, index
			} = resolveTargetChart(store, w, CL_REPORT, i, null, j);
			console.log("Target chart ", resolveTargetChart(store, w, CL_REPORT, i, null, j));
			// NOTE: purposely clear all previous periodic handlers first before
			// actually trigger the AJAX because this allow one less AJAX to be
			// triggered. If we clear and straight away trigger periodic AJAX,
			// then any linked chart (chart source type CS_EXIST), will stop
			// the previous linked chart and trigger its own periodic AJAX.
			clearPreviousHandlers(dispatch, layout, position, idx, index);
			asyncs.push(chartReload(id, layout, position, idx, index));
			if (i === RL_LEFT_PANE && j === 0) {
				dispatch(
					updateReportParamId(
						id
						, chartIdentifier(
							layout
							, position
							, idx
							, index
						)
					)
				);
			} else if (i === RL_COMPARE_PANE) {
				dispatch(
					updateReportCompareParamId(
						id
						, chartIdentifier(
							layout
							, position
							, idx
							, index
						)
					)
				);
			}
		});
	});
	let dispatches = [];
	$.each(asyncs, (i,v) => {
		// do the actual AJAX triggering here.
		dispatches.push(dispatch(v));
	});
	return $.when(...dispatches);
};

export const switchReportPage = (view, report, data) => (
	dispatch
	, getState
) => dispatch(switchReportPageBase(
	view
	, data
	, genReportPageLayout(report, undefined, getState())
));

const switchReportPageById = (
	view
	, reportId
	, reportLayout
) => switchReportPageBase(view, {id: reportId}, reportLayout);

const switchReportPageByReportData = (view, report, param) => (
	dispatch
	, getState
) => dispatch(switchReportPageById(
	view
	, report.Id
	, genReportPageLayout(report, param, getState())
));

export const reloadReportData = paramId => dispatch => {
	const handlers = getChartPeriodicHandler(paramId).get();
	if (!handlers) {
		return Promise.reject("no report handler");
	}
	return dispatch(handlers.restart());
};

// TODO: useless
const genReportLayoutFromOverview = (layout, position, idx, index) => {
	const [
			left
			, right
			, bottom
		] = genReportPanesFromOverview(layout, position, idx, index)
		;
	return [[left], [right], [bottom]];
};

export const linkToReportPage = (id, layout, position, index) => (
	dispatch
	, getState
) => {
	if (id.type !== CS_REPORT) {
		return Promise.reject({err: "only support link to system report"});
	}
	const state = getState()
		, report = reportDataFromChartLayoutId(state, id)
		;
	if (!report) {
		return Promise.reject({err: "system report " + id.id + " not found"});
	}
	return dispatch(switchReportPageById(
		VIEW_REPORT
		, report.Id
		, genReportPageLayout(report, undefined, state)
	));
};

const createScheduleReport = p => async(
	postScheduleReport(p)
	, statistics[keyCreateScheduleReport]
);

const listScheduledReports = () => async(
	getScheduleReport()
	, statistics[keyShowScheduledReports]
);

export const saveScheduleReport = () => (dispatch, getState) => {
	const store = getState()
		, statistic = getStatisticsRoot(store)
		, schedule = statistic.scheduleReport
		, view = statistic.view
		, charts = statistic.charts
		;
	let parameter = schedule.createNew;
	dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
	if (view.schedule.activeId > 0) {
		let id = view.schedule.activeId;
		return dispatch(putScheduledReport(id, parameter))
		.then(r => {
			if(r.status) {
				dispatch(updateScheduleReport("id", r.id))
				dispatch(updateScheduleReport("status", r.status))
				let msg = I("Report updated successfully.");
				let type = "success";
				if(r.error) {
					console.log("error:", r.error);
					msg = r.status;
					type = "error";
					dispatch(adminActionStatus({ status: 3, msg: r.error }));
				} else {
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
					dispatch(toggleAdminEdit(0, false, false));
					dispatch(showScheduledReports());

				}
				const alertMsg = {
					show: true,
					msg,
					type
				}
				dispatch(adminActionAlert(alertMsg));
			}
		});
	}

	if (!view.reportParamId) {
		return ;
	}

	const chart = getChartUpdater(view.reportParamId.id).get(charts);
	if (!chart) {
		return;
	}

	return dispatch(createScheduleReport(parameter))
		.then(r => {
			if(r && r.status === "done") {
				if(r.status) {
					dispatch(updateScheduleReport("id", r.id))
					dispatch(updateScheduleReport("status", r.status))
					let msg = I("Report scheduled successfully.");
					let type = "success";
					if(r.error) {
						console.log("error:", r.error);
						msg = r.status;
						type = "error";
						dispatch(adminActionStatus({ status: 3, msg: r.error }));
					} else {
						dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
					}
					const alertMsg = {
						show: true,
						msg,
						type
					}
					dispatch(adminActionAlert(alertMsg));
				}
			}
		});
};

export const showScheduledReports = () => dispatch => {
	return dispatch(listScheduledReports())
	.then(r => {
		//console.log("dbg: Fetching schedule reports done");
	});
}

export const removeScheduleReport = (id) => dispatch => {
	dispatch(deleteScheduleReport(id))
	.then(r => {
		return dispatch(listScheduledReports());
	});
}

function oneReportDrilldownExport(id, parameter) {
	return postDownloadJSON(
		reportDrilldownExportREST().createIdEndpoint(id)
		, parameter
	);
}

function drilldownReportBase(id, parameter, groups, key, isExport) {
	let drilldown = {key}
		, ajax
		;
	if (isExport) {
		ajax = oneReportDrilldownExport;
	} else {
		ajax = oneReportDrilldown;
		drilldown.countTotal = false;
		drilldown.includeHeaders = true;
	}
	if (groups) {
		drilldown.groups = groups;
	}
	parameter = update(parameter, {$merge: {drilldown}});
	return ajax(id, parameter);
}

const FETCHING_REPORT_DATA = I("fetching report data...");

function drilldownReportOrExport(id, parameter, groups, key, isExport) {
	return dispatch => {
		dispatch(popWaitingWithoutCatch(pleaseWaitString(FETCHING_REPORT_DATA)));
		dispatch(drilldownReportBase(id, parameter, groups, key, isExport))
			.then(res => {
				dispatch(clearPopWaiting());
				if (!isExport) {
					dispatch(storeDrilldownParamters({id, parameter, groups, key}));
					dispatch(showDrilldownDataTable());
				}
			})
			.catch(err => {
				if (isExport) {
					if (!err) {
						err = I("Error when exporting Drill down report.");
					}
					return dispatch(togglePopAlert(err));
				} else {
					dispatch(clearPopWaiting());
				}
			});
	};
}

export const drilldownReport = (id, parameter, groups, key) =>
	drilldownReportOrExport(id, parameter, groups, key);

const drilldownExportBase = (id, parameter, groups, key) =>
	drilldownReportOrExport(id, parameter, groups, key, true);

export const drilldownExport = () => (dispatch, getState) => {
	const {
			id
			, parameter
			, groups
			, key
		} = getStatisticsRoot(getState()).view.drilldown.parameters
		;
	return dispatch(drilldownExportBase(id, parameter, groups, key));
}

const doShareReport = p => async(
	postShareReport(p)
	, statistics[keyShareReportDone]
);

const fetchReportKeys = () => async(
	getReportKeys()
	, statistics[keyGetReportKeys]
);

const fetchReportGroups = () => async(
	getReportGroups()
	, statistics[keyGetReportGroups]
);

const fetchReportPreview = (p) => async(
	postReportPreview(p)
	, statistics[keyReportPreview]
);

const [
	startImageRequest
	, imageRequestControl
] = createPromiseControllers();

const startRequestForChartImage = id => requestChartImageData(id, true);

const stopRequestForChartImage = id => requestChartImageData(id, false);

export const requestImageResult = (
	{ layout, position, idx, index }
	, success
	, base64ImageDataOrErr
) => dispatch => {
	dispatch(stopRequestForChartImage(chartIdentifier(
		layout
		, position
		, idx
		, index
	)));
	if (!success) {
		imageRequestControl("reject", base64ImageDataOrErr);
		return;
	}
	imageRequestControl("resolve", base64ImageDataOrErr);
};

const getChartImageById = id => dispatch => {
	const p = startImageRequest(true)
		.then(imageData => {
			if (process.env.NODE_ENV !== 'production') {
				console.log("dbg: image data requested:", imageData);
			}
			return imageData;
		})
		.catch(err => console.log("dbg: error get chart image:", id, err))
		;
	dispatch(startRequestForChartImage(id));
	return p;
};

export const shareReport = () => (dispatch, getState) => {
	const store = getState()
		, statistic = getStatisticsRoot(store)
		, share = statistic.shareReport
		, view = statistic.view
		, charts = statistic.charts
		, chartLayoutId = view.reportParamId.id
		, chart = getChartUpdater(chartLayoutId).get(charts)
		;
	if (!chart || !chart.endpointParam) {
		return;
	}
	// TODO: use following code to get image by request to share
	// dispatch(getChartImageById(chartLayoutId)).then(imageData => {});
	if (share.imageLeftChart == "") {
		return;
	}
	let parameters = update(chart.param, {$merge: {
		selectedAgent: share.selectedAgent,
		emailAddress: share.emailAddress,
		imageLeftChart: share.imageLeftChart,
		reportId: chart.endpointParam.id
	}});
	return dispatch(doShareReport(parameters))
		.then(r => {
			if(r && r.status === "done") {
				dispatch(updateShareParam("shareReportId", r.id));
				dispatch(updateShareParam("status", r.status));
			}
		});
}

const savedReport = p => async(
	getSavedReport(p)
	, statistics[keyGetSavedReport]
);

export const loadSavedReport = id => (dispatch, getState) => {
	return dispatch(savedReport(id))
	.then(param => {
		if (param && param.data) {
			const p = param.data
				, { reportId } = p
				, state = getState()
				, report = getReportsById(state)[reportId]
				;
			// previous chart data must be cleared before changing report
			// because different report may carry different data format. If
			// wrong data go into chart may trigger javascript error.
			dispatch(clearChartDataById(getCurrentReportChartId(state)));
			dispatch(switchReportPageByReportData(VIEW_REPORT, report, p));
		}
	});
}

const fetchCustomReports = p => async(
	getAdminReports(p)
	, statistics[keyAdminCustomReports]
);
const showAdminCustomReport = id => async(
	postOneAdminReports(id, {info: true})
	, statistics[keyAdminOneReports]
);

export const loadCustomReports = () => dispatch => {
	let p = {
		active: true,
		system: false
	};
	dispatch(fetchCustomReports(p));
}

export const openCustomReport = (id) => dispatch => {
	dispatch(fetchReportKeys());
	dispatch(fetchReportGroups());
	if(id){
		dispatch(openCreateReport(id));
		dispatch(showAdminCustomReport(id))
	}else{
		dispatch(resetCreateReport());
		dispatch(openCreateReport());
	}
}

export const saveCustomReport = () => (dispatch, getState) => {
	const store = getState()
		, statistic = getStatisticsRoot(store)
		, createReport = statistic.createReport
		, view = statistic.view;
	dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
	let dispatchee, id = 0;
	let parameter = createReport.createNew;
	if (view.createReport.activeId > 0) {
		id = view.createReport.activeId;
		parameter.active = true;
		dispatchee = putAdminReports(id, parameter);
	} else {
		dispatchee = postAdminReports(parameter);
	}

	return dispatch(dispatchee)
	.then(r => {
		dispatch(updateCreateReport("id", id))
		dispatch(updateCreateReport("status", r.status))
		dispatch(toggleAdminEdit(0, false, false));
		dispatch(resetCreateReport());
		dispatch(loadCustomReports());

		if(r.status) {
			let msg = I("Report updated successfully.");
			let type = "success";
			if(r.error) {
				console.log("error:", r.error);
				msg = r.status;
				type = "error";
				dispatch(adminActionStatus({ status: 3, msg: r.error }));
			} else {
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			}
			const alertMsg = {
				show: true,
				msg,
				type
			}
			dispatch(adminActionAlert(alertMsg));
		}
	})
	.catch(() => {
		console.log("you shall not pass");
		const alertMsg = {
			show: true,
			msg: I("Report save failed."),
			type: "error"
		}
		dispatch(adminActionAlert(alertMsg));
		dispatch(adminActionStatus({ status: 3, msg: I("Report save failed.") }));
	});

}

export const removeCustomReport = (id) => dispatch => {
	dispatch(deleteCustomReport(id))
	.then(r => {
		return dispatch(loadCustomReports());
	});
}

export const generateReportPreview = (p) => dispatch => {
	dispatch(fetchReportPreview(p));
}

const loadTimeSpans = () => async(
	getTimeTimespan()
	, statistics[keyTimeTimespan]
)

const getTimeSpan = id => async(
	getOneTimeSpan(id)
	, statistics[keyCustomTimeSpan]
);

const saveTimespan = (p) => async(
	postTimeSpan(p)
	, statistics[keyTimeTimespan]
);

const updateTimespan = (id, p) => async(
	putTimeSpan(id, p)
	, statistics[keyTimeTimespan]
)

export const openConfigTime = (id) => dispatch => {
	dispatch(openConfigTimeForm(id));
	dispatch(fetchTimeSpanItems());
	if(id){
		dispatch(getTimeSpan(id))
	}
}

export const fetchTimeSpanItems = () => dispatch => {
	dispatch(onceTimespanItems())
	.then(r => {
	})
}

export const removeTimeConfig = (id) => dispatch => {
	dispatch(deleteTimeConfig(id))
	.then(r => {
		dispatch(loadTimeSpans());
	});
}

export const saveConfigTime = () => (dispatch, getState) => {
	const store = getState()
		, statistic = getStatisticsRoot(store)
		, configTime = statistic.configTime
		, view = statistic.view;

	let dispatchee, id = 0;
	let parameter = {
		name: configTime.createNew.name,
		items: configTime.createNew.selectedTimespan
	};
	if (view.configTime.activeId > 0) {
		id = view.configTime.activeId;
		dispatchee = updateTimespan(id, parameter);
	} else {
		dispatchee = saveTimespan(parameter);
	}
	dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));

	return dispatch(dispatchee)
	.then(r => {
		dispatch(updateConfigTime("id", id))
		dispatch(updateConfigTime("status", r.status));
		dispatch(resetConfigTime());
		dispatch(loadTimeSpans());
		if(r.status) {
			let msg = I("Time format updated successfully.");
			let type = "success";
			if(r.error) {
				console.log("error:", r.error);
				msg = r.status;
				type = "error";
				dispatch(adminActionStatus({ status: 3, msg: r.error }));
			} else {
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			}
			const alertMsg = {
				show: true,
				msg,
				type
			}
			dispatch(adminActionAlert(alertMsg));
			dispatch(toggleAdminEdit(0, false, false));
		}
	})
}

export const saveOverviewGrid = (grid, chartMap) => {
	const plainGrid = plainOverviewGridLayout(grid, chartMap);
	if (process.env.NODE_ENV !== 'production') {
		console.log("dbg: this will save as backend db:", plainGrid);
	}
	return saveOverviewLayout(plainGrid);
};

export const updateOverviewGridOrChart = (grid, chartMap, removee) => (
	dispatch
	, getState
) => {
	if (typeof grid === "function") {
		grid = grid(overviewGridMemo(getState()));
	}
	if (typeof chartMap === "function") {
		chartMap = chartMap(overviewChartMapMemo(getState()));
	}
	dispatch(updateOverviewGrid(grid));
	if (typeof chartMap === "object") {
		dispatch(updateOverviewChartMap(chartMap));
	}
	if (typeof removee === "object") {
		const { index, shortid } = removee;
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: removal chart index:", index, "shortid:", shortid);
		}
		const hc = getChartPeriodicHandler({layout: CL_OVERVIEW, idx: shortid})
			, h = hc.get()
			;
		if (h) {
			hc.remove();
			dispatch(h.stop());
		}
		dispatch(removeOverviewChartContent(shortid));
	}
};

export const getLiveReportQueueChat = () => (dispatch, getState) => {
	const store = getState()
	, wf = store.app.workflow
	, areas = getAreasIDViaOrgObj(getAgentAreasAndDefaultEmpty(wf));
	;
	if (areas && areas.length > 0) {
		dispatch(getQueueChat(areas));
	}
}

export const getAgentWithChatStatus = () => (dispatch, getState) => {
	dispatch(getFullChatStatus());
}
