import {
	KB_CHANGE_ACTIVE_TAB
	, KB_DELETE_LIST
	, KB_CREATE_NEW
	, KB_EDIT_CATEGORY
	, KB_EDIT_LIBRARY
	, KB_EDIT_QUESTION
	, KB_EDIT_SUGGESTION
	, KB_RESET_EDIT
	, KB_SAVE_ID
	, KB_SELECT_TREE_ROOT
	, KB_INSERT_NODE
	, KB_REMOVE_NODE
	, SA_CHANGE_SOURCE
	, SA_CHANGE_INPUT
	, SA_CHANGE_SEARCH_STATE
	, KB_LOADING_LIBRARY
	, KB_UPDATE_CHATBOTS,
} from '../constants/constants';

export const selectTreeRoot = (id) => ({
	type: KB_SELECT_TREE_ROOT,
	payload: id
});

export const editLibrary = (id) => ({
	type: KB_EDIT_LIBRARY,
	payload: id
});

export const editCategory = (id) => ({
	type: KB_EDIT_CATEGORY,
	payload: id
});

export const editQuestion = (id) => ({
	type: KB_EDIT_QUESTION,
	payload: id
});

export const editSuggestion = (id) => ({
	type: KB_EDIT_SUGGESTION,
	payload: id
});

export const changeActiveTab = (id) => ({
	type: KB_CHANGE_ACTIVE_TAB,
	payload: id
});

export const resetEdit = () => ({
	type: KB_RESET_EDIT
});

export const createNew = (title) => ({
	type: KB_CREATE_NEW
	, payload: title
});

export const deleteID = (id) => ({
	type: KB_DELETE_LIST
	, payload: id
});

export const saveID = (id) => ({
	type: KB_SAVE_ID
	, payload: id
});

export const insertNode = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: KB_INSERT_NODE
				, payload: id
			});
			return true;
		} catch (error) {
			return false;
		}
	}
};

export const removeNode = (id) => ({
    type: KB_REMOVE_NODE
    , payload: id
});

export const changeSource = (source) => ({
	type: SA_CHANGE_SOURCE
	, payload: source
});

export const changeInput = (value) => ({
	type: SA_CHANGE_INPUT
	, payload: value
});

export const changeSearchState = (value) => ({
	type: SA_CHANGE_SEARCH_STATE
	, payload: value
});

export const loadingLibrary = (status) => ({
	type: KB_LOADING_LIBRARY
	, payload: status
});

export const updateChatbotsID = (id, iid) => ({
	type: KB_UPDATE_CHATBOTS
	, payload: {id, iid}
});