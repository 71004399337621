import {
  RECEIPT_GREETINGS_EDIT_FIELD,
  RECEIPT_GREETINGS_FINISH_EDIT,
  RECEIPT_GREETINGS_START_EDIT
} from '../constants/constants'
import { adminEditActionMap } from '../reducers/receiptGreeting'

export const {
  [RECEIPT_GREETINGS_START_EDIT]: updateAdminStartEdit,
  [RECEIPT_GREETINGS_EDIT_FIELD]: updateAdminEditField,
  [RECEIPT_GREETINGS_FINISH_EDIT]: updateAdminFinishEdit
} = adminEditActionMap
