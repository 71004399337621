import { SHOW_INSERT_PROTECT } from '../constants/constants'
import { createDirectActionCreator } from '../util'
import { constants } from '../selectors/server'

const getProtectReplyContentPatent = state => {
  const { start, end } = constants(state).c3template.protectedReplyContent
  return [start, end]
}

export const wrapProtectReplyContentMarker = (content, state) => {
  const [start, end] = getProtectReplyContentPatent(state)
  return start + content + end
}

export const showInsertProtect = createDirectActionCreator(SHOW_INSERT_PROTECT)

export const closeInsertProtect = () => showInsertProtect(false)
