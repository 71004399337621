// provide domain cache functionality
import { doNothing } from "../../../common/constants";
import {
	invalidateCache
} from '../domain';
import {
	ADD_DOMAIN_DATA,
	INVALIDATE_DOMAIN_CACHE
} from '../../constants/constants';
import {
	domainTransfer,
	getNormalizedDomain
} from '../../util';

// cachedAsync is helper function to get cache-able async data by providing the
// domain name dName and domain transfer action constant xName.
export const cachedAsync = (
	id,
	dName,
	async,
	xName,
	reload
) => (dispatch, getState) => {
	let domain;
	if(!reload) {
		domain = getNormalizedDomain(getState().domain, dName, id);
	} else {
		dispatch(invalidateCache(id, dName));
	}
	if(!domain) {
		if (process.env.NODE_ENV !== 'production') {
			console.log('dbg: uncache:', dName, id);
		}
		return dispatch(async)
			.then(() => {
				const domain = getNormalizedDomain(
					getState().domain,
					dName,
					id
				);
				if(xName) {
					dispatch(domainTransfer(xName, {domain, id}));
				}
				return domain;
			});
	}
	if(xName) {
		dispatch(domainTransfer(xName, {domain, id}));
	}
	return $.when(domain);
};

// If 'cancel' true then async must be created with 'cancellableAsync'.
export const tryCancellableCachedAsync = (
	id
	, domain
	, async
	, transform
	, force
	, cancel
) => (dispatch, getState) => {
	if (!force) {
		const dd = getNormalizedDomain(getState().domain, domain, id);
		if (dd) {
			const promise = Promise.resolve(dd);
			if (cancel) {
				promise.cancel = doNothing
			}
			return promise;
		}
	}
	return dispatch(async)
		.then(data => {
			if (transform && typeof transform === "function") {
				data = transform(data);
			}
			dispatch(domainTransfer(
				ADD_DOMAIN_DATA
				, {domain, id, data, time: Date.now()}
			));
			return data;
		});
};

// tryCachedAsync is easier to use and more fine tune than cachedAsync. It wraps
// an 'async' to provide it cache handlers.
//    'id' is the domain identifier which used by `byId` field.
//    'domain' is the domain branch name for the cache storage.
//    'transform' is optional where it should be a function that transform
//                received data to another format before being stored into
//                cache.
//    'force' is optional, will force async always trigger, without using cache.
export const tryCachedAsync = tryCancellableCachedAsync

// reloadCachedAsync is helper function to always reload cached async data and
// re-cache again without going through the respective async reducer. Pass a
// 'pure function' if async data need to be transform before put into cached
// data.
export const reloadCachedAsync = (
	id,
	domain,
	async,
	transform
) => tryCachedAsync(id, domain, async, transform, true);
