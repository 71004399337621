import { createPromiseControllers } from '../../../common/v5/helpers';
import { clearInsertionText, insertText } from '../cke';
import { wrapProtectReplyContentMarker } from '../common';

const [
    insertionTextStart
    , insertionTextControl
] = createPromiseControllers();

export const insertContent = text => dispatch => {
    const p = insertionTextStart(true);
    dispatch(insertText(text));
    return p;
};

export const insertProtectedReplyContent = text => (
	dispatch
	, getState
) => dispatch(insertContent(wrapProtectReplyContentMarker(text, getState())));

export const clearInsertionContent = () => dispatch => {
    dispatch(clearInsertionText());
    insertionTextControl();
};
