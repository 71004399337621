// Collaboration related redux action code. Dependent on errand. This mainly to
// seperate it from errand which is hugh code now.
import update from 'immutability-helper';
import {
	getCRMdatas,
	getExternalexpertEdit,
	getExternalexpertLightWithAction,
	getExternalexpertList,
	getExternalExpertQueries,
	getExternalexpertThread,
	getOneCollaborationQueryRecipients,
	postCollaborationAnswers,
	postExternalexpertAnswerSave,
	postExternalexpertSend,
	postExternalexpertUpdate,
	postExternalexpertLastSave
} from './ajax';
import { loadList } from './workflow';
import {
	checkEmptyAnswer,
	checkEmptyRecipient,
	checkTranslate,
	canSendCollab,
	errand as errandMap
} from './errand';
import {
	changeCollaborationRole,
	reloadCurrentExpert,
	updateCurrentExpertIDs,
	updateExpertAnswerState,
	saveCollabStart,
	agentEENotTyping
} from '../collaborate';
import {
	invalidateCache
} from '../domain';
import {
	changeHistorySelections
	, selectShowReply
	, toggleChatNavDD
	, selectChatReplyTab
	, markEELastSaveTriggered
} from '../errand';
import {
	keyEEAnswerSave,
	keyExternalexpertEdit,
	keyFetchExternalExpertList,
	keyFetchExternalQueries,
	keyFetchExternalExpertThread,
	keySendEE,
	keyUpdateAnswerEE,
	keySavedEE,
	keyTurnExternalExpertLightOff,
	keyCRMDataFetch
} from '../../constants/keys';
import {
	CHAT_DONE_GETTING_CANDIDATES,
	CHAT_GETTING_CANDIDATES,
	CHAT_SET_CANDIDATES,
	ERRAND_CLASSIFICATION,
	XFER_DOMAIN_EE_EDIT,
	XFER_DOMAIN_EE_LIST,
	AGENTEE_BUSY_TYPING,
	SAVE_EE
} from '../../constants/constants';
import {
	async,
	externalExpertEditDomainID,
	multiAsync,
	mcamResult
} from '../../util';
import {
	cachedAsync
} from './cache';
import { emptyArray } from '../../../common/constants';
import {
	COL_MASK_LIGHT_ON,
	D_EE_EDIT,
	EXPERT_ANS_IDLE,
	EXPERT_ANS_SENDING,
	EXPERT_ANS_SAVING,
	ROLE_AGENT,
	RPLY_ERRAND,
	RPLY_COLLABORATE,
	RPLY_EXT_FWD,
	TMR_AUTO_SAVE,
	THROTTLED_TMR,
	TMR_AGENT_NO_TYPING
} from '../../../common/v5/constants';
import {
	checkEmptyHTML,
	isChatAndCollaborateInternalChat,
	isCollaboration,
	getUniqueAddress
} from '../../../common/v5/helpers';
import {
	getChatCandidates,
	refreshChatErrandCollaboration,
	subscribeAgentPresence,
	unsubscribeAgentPresence,
} from './echat';
import {
	currentChatErrand,
	currentCollabChannel,
	currentErrandReplyChannel,
	getCurrentErrand,
	collaborationInputs,
	isEEActionInProgressSelector,
	getEEUploadedAttachments,
	isCollabInputsChangedMemoize,
	isEEIdleAnswer
} from '../../selectors/errand';
import {
	getDomainEEThreads
} from '../../selectors/collaborate';
import { 
	popErrorOnly, 
	togglePopAlert,
	togglePopWaiting,
	clearPopWaiting
  } from '../hmf';
import {
	addTimeout,
	removeTimeout
} from 'redux-timeout';

////////////////////////////////////////////////////////////////////////////////
// AJAX
//
const eeEdit = (errandID, query, answer, reload) => cachedAsync(
	externalExpertEditDomainID(errandID, query, answer),
	D_EE_EDIT,
	async(
		getExternalexpertEdit({params: JSON.stringify({errand: errandID, query,
			answer})}),
		errandMap[keyExternalexpertEdit],
		{errand: errandID, query, answer}
	),
	XFER_DOMAIN_EE_EDIT,
	reload
);

const fetchEEList = (id, reload) => (dispatch, getState) => {
	const q = {errand: id};
	return dispatch(cachedAsync(
		id,
		'externalExpertList',
		async(getExternalexpertList(q), errandMap[keyFetchExternalExpertList],
			q),
		XFER_DOMAIN_EE_LIST,
		reload))
		.then(response => {
			if(response.error) {
				return Promise.reject(new Error(response.error));
			}
			return response;
		})
		.catch(err => {
			// TODO: send notification error here
		});
};

export const fetchEEThread = (thread, errandId, read) => (dispatch, getState) => {
	const eelist = getState().domain.externalExpertList.byId[errandId];
	let lightoff;
	if(typeof read !== 'undefined') {
		// fetch collaboration thread from errand owner.
		lightoff = true;
		if(eelist && eelist.list) {
			$.each(eelist.list, (i,v) => {
				if (v.id !== thread && !v.read &&
					(v.flag & COL_MASK_LIGHT_ON)) {
					// NOTE: COL_MASK_LIGHT_ON detection is needed as it is to
					// avoid light is not off because of thread that has no
					// answer and still unread. This behaviour different from
					// previous version which seem more like a bug.
					lightoff = false;
					return false;
				}
			});
		}
	}
	// read can be undefined in which case should happen during internal
	// collaboration to avoid the query marked as read.
	const q = {thread, errand: errandId, read, lightoff};
	return dispatch(multiAsync(getExternalexpertThread(q),
		errandMap[keyFetchExternalExpertThread], q));
};

const fetchExternalQueries = id => async(getOneCollaborationQueryRecipients(id),
	errandMap[keyFetchExternalQueries], {errand: id}
);

const sendEE = p => (dispatch, getState) => {
	return dispatch(async(postExternalexpertSend({params: JSON.stringify(p)}),
		errandMap[keySendEE]))
		.then(response => {
			if(!response.success) {
				return Promise.reject(new Error(response.error));
			}
			return response;
		})
		.catch(err => {
			dispatch(popErrorOnly(err))
			// TODO: send notification error here
		});
};

const updateEE = p => async(postExternalexpertUpdate({params: JSON.stringify(p)}),
		errandMap[keyUpdateAnswerEE]
);

const answerQuery = p => (dispatch, getState) => dispatch(async(
	postCollaborationAnswers({params: JSON.stringify(p)}),
	errandMap[keyEEAnswerSave]))
	.then(response => {
		if(!response.success) {
			return Promise.reject(new Error(response.error));
		}
		return response;
	})
	.catch(err => {
		// TODO: send notification error here
	});

const refreshCollaborationAtList = (chat, origin) => dispatch => {
	if (chat) {
		refreshChatErrandCollaboration(chat.sessionId)
	} else {
		dispatch(loadList(origin)) // TODO: use websocket once ready
		return true
	}
}

// TODO: there are two places use the same action in 4.5 for EE. Check that out.
export const toggleCollaborationLight = errandID => (dispatch, getState) => {
	const p = {errand: errandID};
	return dispatch(async(getExternalexpertLightWithAction(p),
		errandMap[keyTurnExternalExpertLightOff], p))
		.then(response => {
			if(response.error) {
				return Promise.reject(new Error(response.error));
			}
			const state = getState();
			dispatch(refreshCollaborationAtList(
				currentChatErrand(state),
				"toggleCollaborationLight"
			));
			dispatch(invalidateCache(errandID, 'externalExpertList'));
			if (errandID === getCurrentErrand(state)) {
				dispatch(fetchEEList(errandID, true));
			}
			return response;
		})
		.catch(err => {
			// TODO: send notification error here
		});
};

////////////////////////////////////////////////////////////////////////////////
// Actions code
//
// function getSelectedInternalCollaborators(selectedAgents) {
// 	if(!selectedAgents) {
// 		return [];
// 	}
// 	let agentIDs = [];
// 	$.each(selectedAgents, (i,v) => {
// 		agentIDs.push(parseInt(v, 10));
// 	});
// 	return agentIDs;
// }

const onLoadFetchEEThread = (thread, errandId) => dispatch => {
	return dispatch(fetchEEThread(thread, errandId))
		.then(() => {
			// switch to expert role and open collaboration
		})
		.then(() => {
			// clear direct EE thread loading
		});
};

export const multiDispatchesEE = (errand, reload) => (dispatch, getState) => {
	let dispatches = [
		dispatch(fetchExternalQueries(errand)),
		dispatch(fetchEEList(errand, reload))
	];
	const directLoad = getState().app.errand.collaborationInputs.onLoad;
	if(directLoad) {
		console.log('dbg: direct onload EE thread:', thread);
		dispatches.push(dispatch(onLoadFetchEEThread(directLoad.threadID,
			errand)));
	}
	// get the main thing first
	return $.when(...dispatches)
		.then(() => {
			// get all EE thread list one by one here or let user enable which
			// to get.
			console.log('dbg: test EE list');
		});
};

export const refreshCollaborationData = (id, thread, chat) => (
	dispatch,
	getState
) => {
	const result = dispatch(refreshCollaborationAtList(
		chat,
		"refreshCollaborationData"
	));
	dispatch(invalidateCache(thread, 'externalExpertThreads'));
	if (id === getCurrentErrand(getState())) {
		dispatch(reloadCurrentExpert()); // TODO: remove this once websocket ready
		dispatch(multiDispatchesEE(id, true)); // TODO: remove this once websocket ready
	}
	dispatch(selectShowReply(RPLY_ERRAND, false));
	return result
};

function getIDsFromArrayObject(array) {
	let uploaded = [];
	$.each(array, (i,v) => {
		uploaded.push(v.id);
	});
	return uploaded;
}

function createUpdateExpertObject(state, force, autoSave, option) {
	const e = state.app.errand, c = e.collaborationInputs,
		errandID = e.currentErrand.id;
    const hc = e.fetchHistory.data.opr;
    let selectedErrands = [];
    $.each(hc, (k, v) => {
        if (v.selected) {
            // doesn't care whether int or string as later it'll turn
            // into string no matter how.
            selectedErrands.push(k);
        }
	});
	selectedErrands.reverse();
    let selectedHistory = selectedErrands.join(',');
	let uploaded = getIDsFromArrayObject(c.uploaded_attachments);
	let saved = getIDsFromArrayObject(c.saved_attachments);

	return {
		errandID: errandID,
		queryID: c.queryID,
		answerID: c.answerID,
		signature: c.update_signature,
		subject: c.update_subject,
		internals: c.selectedAgents,
		areas: c.selectedAreas,
		collabChannel: currentCollabChannel(state),
		externalExpertAddresses: getUniqueAddress(c.update_to),
		ccAddresses: getUniqueAddress(c.update_cc),
		bccAddresses: getUniqueAddress(c.update_bcc),
		saveRecipient: c.saveRecipient,
		includeCollabHistory: c.threadID != 0 ? c.includeCollabHistory : false,
		includeErrandHistory: c.includeErrandHistory,
		htmlBody: checkEmptyHTML(c.update_answer, c.plain_answer),
		selectedAttachment: c.selectedAttachment,
		attachments: getIDsFromArrayObject(c.uploaded_attachments),
		savedAttachments: getIDsFromArrayObject(c.saved_attachments),
		archiveAttachments: getIDsFromArrayObject(c.archive_attachments),
		libraryAttachments: getIDsFromArrayObject(c.library_attachments),
		messageHistory: selectedHistory,
		threadID: c.threadID,
		savedQueryID: c.savedQueryID,
		forceSave: force,
		autoSave: autoSave
	};
}

const optReplyCollaborate = {reply: RPLY_COLLABORATE};

const collaborationTranslate = (param, option) => checkTranslate(param, option, true, false);
const canSendCollabToChannel = (param, option) => canSendCollab(param, option);

// Send an expert query.
const sendExpertQuery = chat => (dispatch, getState) => {
	const e = getState().app.errand
	const { id } = e.currentErrand
	const { threadID } = e.collaborationInputs
	const option = optReplyCollaborate
	dispatch(updateExpertAnswerState(EXPERT_ANS_SENDING));
	const param = createUpdateExpertObject(getState(), true, false, option);
	if (process.env.NODE_ENV !== 'production') {
		console.log('dbg: sending expert queries', param, { threadID, id });
	}
	dispatch(checkEmptyRecipient(param, option))
		.then(({param, option}) => dispatch(checkEmptyAnswer(param, option)))
		.then(({param, option}) => dispatch(collaborationTranslate(param, option)))
		.then(({param, option}) => dispatch(canSendCollabToChannel(param, option)))
		.then(({param}) => dispatch(sendEE(param)))
		.then(() => {
			dispatch(refreshCollaborationData(id, threadID, chat));
			dispatch(updateExpertAnswerState(EXPERT_ANS_IDLE));
		})
		.catch(err => {
			console.log('dbg: error send expert query:', err);
			dispatch(updateExpertAnswerState(EXPERT_ANS_IDLE));
		});
};

// passing empty argument meaning changing reply type which do not has any
// specific expert creation unless it is first time loading the expert creation
// which will create new expert thread for it.
const prepareCreateExpertQuery = (errandID, threadID, queryID, answerID) => (dispatch, getState) => {
	const state = getState(), e = state.app.errand,
		expert = e.collaborationInputs, preErrandID = e.currentErrand.id;
	if(expert.replyQuery) {
		let updateThreadID;
		if(threadID) {
			updateThreadID = threadID;
		} else {
			updateThreadID = 0;
		}
		dispatch(changeCollaborationRole(ROLE_AGENT, updateThreadID));
	}
	if(!errandID) {
		// creating new expert thread under current errand.
		threadID = 0;
		queryID = 0;
		answerID = 0;
	} else {
		if(errandID !== preErrandID) {
			console.log('dbg: expert MUST always has same errand:', preErrandID,
				errandID);
			return;
		}
	}
	errandID = preErrandID;
	const {queryID: preQueryID, answerID: preAnswerID} = expert,
		preIDs = externalExpertEditDomainID(preErrandID, preQueryID,
			preAnswerID),
		currIDs = externalExpertEditDomainID(errandID, queryID, answerID);
	if(expert.errandID && preIDs === currIDs) {
		// do nothing if previous editing is same and it is first time create
		// new thread.
		return;
	}
	dispatch(eeEdit(errandID, queryID, answerID))
		.then(() => {
			dispatch(updateCurrentExpertIDs(errandID, threadID, queryID,
				answerID));
		});
};

const prepareSavedExpertQuery = (errandID, threadID, queryID, answerID) => (dispatch, getState) => {
	const state = getState(), e = state.app.errand,
		expert = e.collaborationInputs, preErrandID = e.currentErrand.id;
	if(expert.replyQuery) {
		let updateThreadID;
		if(threadID) {
			updateThreadID = threadID;
		} else {
			updateThreadID = 0;
		}
		dispatch(changeCollaborationRole(ROLE_AGENT, updateThreadID));
	}
	if(!errandID) {
		// creating new expert thread under current errand.
		threadID = 0;
		queryID = 0;
		answerID = 0;
	} else {
		if(errandID !== preErrandID) {
			console.log('dbg: expert MUST always has same errand:', preErrandID,
				errandID);
			return;
		}
	}

	errandID = preErrandID;
	const {queryID: preQueryID, answerID: preAnswerID} = expert,
		preIDs = externalExpertEditDomainID(preErrandID, preQueryID,
			preAnswerID),
		currIDs = externalExpertEditDomainID(errandID, queryID, answerID);
	if(expert.errandID && preIDs === currIDs) {
		// do nothing if previous editing is same and it is first time create
		// new thread.
		return;
	}
	dispatch(eeEdit(errandID, queryID, answerID))
		.then(() => {
			dispatch(updateCurrentExpertIDs(errandID, threadID, queryID,
				answerID));
		});
};
export const replyExpertQuery = (errandID, threadID, queryID, answerID) => (dispatch, getState) => {
	dispatch(prepareCreateExpertQuery(errandID, threadID, queryID, answerID));
	dispatch(selectShowReply(RPLY_COLLABORATE, true));
};

export const editExpertQuery = (errandID, threadID, queryID, answerID) => (dispatch, getState) => {
	dispatch(prepareSavedExpertQuery(errandID, threadID, queryID, answerID));
	dispatch(selectShowReply(RPLY_COLLABORATE, true));
};
// create new external expert thread.
export const createExpertQueryThread = () => (dispatch, getState) => {
	dispatch(prepareCreateExpertQuery());
	dispatch(selectShowReply(RPLY_COLLABORATE, true));
};

const getInternalChatData = (chat, which) => dispatch => {
	if (!isChatAndCollaborateInternalChat(chat, which)) {
		return;
	}
	dispatch({ type: CHAT_GETTING_CANDIDATES, chat });
	dispatch(getChatCandidates())
	.then(
		response => {
			subscribeAgentPresence();
			dispatch({ type: CHAT_SET_CANDIDATES, chat, response });
		},
		failed => { // TODO: this isn't common pattern for promise failure
			dispatch({ type: CHAT_DONE_GETTING_CANDIDATES, chat });
			dispatch(togglePopAlert(I("An error occurred while getting the agent list.")));
		}
	);
}

const selectShowReplyChatAware = (
	dispatch,
	chat,
	previousShow,
	which,
	currentShow
) => {
	if (chat) {
			dispatch(selectChatReplyTab(false));
			if (previousShow) {
				dispatch(selectShowReply(which, currentShow));
			} else {
				dispatch(selectShowReply(which, false));
				dispatch(toggleChatNavDD(false));
			}
	} else {
		dispatch(selectShowReply(which, currentShow));
	}
}

export const changeReply = (which, show) => (dispatch, getState) => {
	const state = getState()
	const chat = state.app.errand.chat
	if (isChatAndCollaborateInternalChat(chat, which)) {
		changeHistorySelections(dispatch, state, which);
		dispatch(getInternalChatData(chat, which));
		dispatch(selectShowReply(which));
		return;
	} else if (chat) {
		dispatch({
			type: CHAT_SET_CANDIDATES,
			chat,
			response: { list: emptyArray }
		});
		unsubscribeAgentPresence();
	}
	const previousShow = state.app.errand.ui.reply.show
	if (!isCollaboration(which)) {
		selectShowReplyChatAware(dispatch, chat, previousShow, which, show)
		// Pre-select current errand for forward to external
		changeHistorySelections(dispatch, state, which);
		return;
	}
	// only collaboration fall to here.
	changeHistorySelections(dispatch, state, which);
	if (!state.app.errand.collaborationInputs.errandID) {
		// first time access collaboration reply
		let errandId = state.app.errand.currentErrand.id
		dispatch(invalidateCache(externalExpertEditDomainID(errandId, 0, 0), D_EE_EDIT));
		dispatch(prepareCreateExpertQuery())
		show = true
	}
	selectShowReplyChatAware(dispatch, chat, previousShow, which, show)
};

export const loadCollaboration = which => (dispatch, getState) => {
	const state = getState();
	const chat = state.app.errand.chat;
	changeHistorySelections(dispatch, state, which);
	dispatch(getInternalChatData(chat, which));
}

function createInternalAnswerCollaboration(state, query) {
	const e = state.app.errand, c = e.collaborationInputs;
	return {
		param: {
			internal: true,
			query,
			message: checkEmptyHTML(c.update_answer, c.plain_answer),
			attachments: getIDsFromArrayObject(c.uploaded_attachments),
		},
		option: update(optReplyCollaborate, {answerQuery: {$set: true}})
	};
}

const answerCollaborationQuery = queryID => (dispatch, getState) => {
	dispatch(updateExpertAnswerState(EXPERT_ANS_SENDING));
	const state = getState(), e = state.app.errand, {id} = e.currentErrand,
		{param, option} = createInternalAnswerCollaboration(state, queryID),
		{threadID} = e.collaborationInputs;
	console.log('dbg: answering internal collaborations', {param, option});
	dispatch(checkEmptyAnswer(param, option))
		.then(({param, option}) => dispatch(collaborationTranslate(param, option)))
		.then(({param}) => dispatch(answerQuery(param)))
		.then(() => {
			dispatch(refreshCollaborationData(id, threadID));
			dispatch(updateExpertAnswerState(EXPERT_ANS_IDLE));
		})
		.catch(err => {
			console.log('dbg: error send expert query:', err);
			dispatch(updateExpertAnswerState(EXPERT_ANS_IDLE));
		});
};

export const submitCollaboration = chat => (dispatch, getState) => {
	if (process.env.NODE_ENV !== 'production') {
		console.log('dbg: submit collaboration');
	}
	const c = getState().app.errand.collaborationInputs;
	if (!c.replyQuery) {
		dispatch(sendExpertQuery(chat));
	} else {
		dispatch(answerCollaborationQuery(c.queryID, chat));
	}
};

const rawSaveCollab = (force, isAutoSave) => (dispatch, getState) => {
	dispatch(saveCollabStart());
	const state = getState()
		, e = state.app.errand
		, id = e.currentErrand.id
		, threadID = e.collaborationInputs.threadID

		;
	dispatch(updateExpertAnswerState(EXPERT_ANS_SAVING));
	if (!force && !isCollabInputsChangedMemoize(state)) {
		console.log('dbg: no change so no save');
		dispatch(updateExpertAnswerState(EXPERT_ANS_IDLE));
		// promise must resolve with undefined here as indication of no saving
		// occurs.
		return Promise.resolve();
	}
	const option = optReplyCollaborate
	const param = createUpdateExpertObject(getState(), force, isAutoSave, option);
	if (process.env.NODE_ENV !== 'production') {
		console.log('dbg: saving expert queries', param, { threadID, id });
	}
	return dispatch(collaborationTranslate(param, option))
		.then(({param, option}) => dispatch(canSendCollabToChannel(param, option)))
		.then(({param}) => dispatch(updateEE(param)))
		.then(data => {
			const { result, error } = mcamResult(data);
			if (error) {
				return Promise.reject({err: error});
			}
			dispatch(updateExpertAnswerState(EXPERT_ANS_IDLE));

			// invalidate cache upon successful save, 
			// so that edit the same query will fetch the latest data			
			let collabInputs = collaborationInputs(getState())
			let errandID = collabInputs.errandID;
			let queryID = collabInputs.queryID;
			let answerID = collabInputs.answerID;
			dispatch(invalidateCache(externalExpertEditDomainID(errandID, queryID, answerID), D_EE_EDIT));

			// reload the EE thread, so that changes are visible in EE thread
			const q = {thread: threadID, errand: errandID, read: true, lightoff: true};
			dispatch(async(getExternalexpertThread(q), errandMap[keyFetchExternalExpertThread], q))
			.then(() => dispatch(multiDispatchesEE(id, true)))
			.then(() => ({result: result.result}))
		})
		.catch(err => {
			console.log('dbg: error save expert query:', err);
			dispatch(updateExpertAnswerState(EXPERT_ANS_IDLE));
		});
};

export const rawHardSaveCollab = (force, shouldLoadList) => dispatch =>
	dispatch(rawSaveCollab(force, true))
		.then(() => {
			dispatch(markEELastSaveTriggered(true));
			if (shouldLoadList) {
				dispatch(loadList("rawHardSaveCollab"));
			}
		});

export const saveCollabIfNeeded = () => (dispatch, getState) => {
	let state = getState();
	if (!isEEActionInProgressSelector(state)) {
		let hasChanged = isCollabInputsChangedMemoize(getState());
		let unsavedAttachments = getEEUploadedAttachments(getState());
		if (hasChanged || unsavedAttachments) {
			dispatch(togglePopWaiting(I("Please wait while saving collaboration...")));
			return dispatch(rawHardSaveCollab(true, false))
			.then(() => {
				dispatch(clearPopWaiting());
			})
			.catch(err => {
				dispatch(clearPopWaiting());
			});
		} else {
			return Promise.resolve();
		}	
	} else {
		return Promise.resolve();
	}	
}

function saveCollabFactory() {
	let lastTimeout = Date.now();
	return isAutoSaveTimeout => (dispatch, getState) => {
		if (!isAutoSaveTimeout && (Date.now() - lastTimeout) < TMR_AUTO_SAVE) {
			// when agent stopped typing and before auto save timeout
			return;
		}
		const state = getState()
			, errand = state.app.errand
			;
		if (!isEEActionInProgressSelector(state)
			&& isEEIdleAnswer(state)
			&& errand.ui.lastSaveEE.canSave) {
			return dispatch(rawSaveCollab(false, true))
				.then(result => {
					if (!getState().app.errand.ui.lastSaveEE.triggered) {
						dispatch(markEELastSaveTriggered(true));
					}
					if (result && result.result) {
						lastTimeout = Date.now();
					}
				});
		} else {
			return dispatch(saveCollabStart());
		}
	};
}

const tmrAgentIdle = TMR_AGENT_NO_TYPING + THROTTLED_TMR;

export const enableAutoSaveCollab = () => dispatch => {
	const saveCollabFunc = saveCollabFactory();
	dispatch(addTimeout(
		tmrAgentIdle
		, AGENTEE_BUSY_TYPING
		, () => {
			// run this function if AGENTEE_BUSY_TYPING (action) has not been
			// dispatched in tmrAgentIdle amount of time
			dispatch(agentEENotTyping());
			dispatch(saveCollabFunc(false));
		}
	));
	// run save EE if SAVE_EE (action) has not been dispatched in 
	// TMR_AUTO_SAVE amount of time
	return dispatch(addTimeout(
		TMR_AUTO_SAVE
		, SAVE_EE
		, () => dispatch(saveCollabFunc(true))
	));
};

export const stopAutoSaveCollab = () => dispatch => {
	dispatch(removeTimeout(SAVE_EE));
	dispatch(removeTimeout(AGENTEE_BUSY_TYPING));
};
