import { saveAs } from 'file-saver'
import {
  TXT_ACCESS_KEY,
  TXT_AGENT_ID_ACCESS_KEY_ID,
  TXT_CREATING_ACCESS_KEY
} from '../../../common/texts'
import {
  TXT_ARE_YOU_SURE_DELETE
} from '../../../common/v5/receiptGreetingConstants'
import {
  deleteAdminAgentsAccessKeys,
  getAdminAgentsAccessKeys,
  postAdminAgentsAccessKeys
} from './ajax'
import {
  keyDeleteAdminAgentsAccessKeys,
  keyGetAdminAgentsAccessKeys,
  keySaveAdminAgentsAccessKeys
} from '../../constants/keys'
import { async } from '../../util'
import { adminAsyncMap, keysByAgent } from '../../selectors/agentAccessKey'
import { createCommonAdminAsyncs } from './receiptGreeting'

const listBase = agentID => {
  const params = { id: agentID }
  return async(
    getAdminAgentsAccessKeys(params),
    adminAsyncMap[keyGetAdminAgentsAccessKeys],
    params
  )
}

export const list = agentID => (dispatch, getState) => {
  const keysMap = keysByAgent(getState())
  const keys = keysMap[agentID]
  if (keys) {
    return Promise.resolve(keys)
  }
  return dispatch(listBase(agentID))
}

const { remove, save } = createCommonAdminAsyncs(
  (agentID, accessKeyID, isNew) => {
    if (isNew) {
      return { id: agentID }
    }
    return { id: agentID, key: accessKeyID }
  },
  undefined,
  adminAsyncMap,
  {
    remove: {
      ajax: deleteAdminAgentsAccessKeys,
      key: keyDeleteAdminAgentsAccessKeys,
      confirmationTextGetter: (agentID, accessKeyID) => (
        TXT_ARE_YOU_SURE_DELETE
        .replace('{ITEM_TYPE}', TXT_ACCESS_KEY)
        .replace(
          '{ID}',
          TXT_AGENT_ID_ACCESS_KEY_ID
            .replace('{AGENT ID}', agentID)
            .replace('{ACCESS KEY ID}', accessKeyID)
        )
      )
    },
    save: {
      ajax: postAdminAgentsAccessKeys,
      key: keySaveAdminAgentsAccessKeys,
      success: data => {
        saveAs(
          new Blob(
            [JSON.stringify(data)],
            { type: 'application/octet-stream' }
          ),
          'access-key.json'
        )
        return data
      },
      text: TXT_CREATING_ACCESS_KEY
    }
  }
)

export const deleteKey = remove

export const create = (agentID, params) => save(
  { which: agentID, isNew: true },
  undefined,
  params
)
